import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetGalleryCategory = () => {
  async function getGalleryCategory() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/super-user/gallery-category/`
    );
    return res.data.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getGalleryCategory",
    getGalleryCategory
  );

  return {
    data,
  };
};
