import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetConfigChoiceList = (category) => {
  async function getConfigChoiceList() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/category/?config_category=${category}`
    );
    return res.data?.map(({
        uuid: value,
        name:label,
        ...rest
      }) => ({
        value,
        label,
        ...rest
      }));
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getConfigChoiceList",
    getConfigChoiceList
  );

  return {
    data,
  };
};
