import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Image,
  Center,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import { useSignIn } from "./useSignIn";
import { PageTitle } from "components/Common/PageTitle";
import { SectionTitle } from "components/Common/SectionTitle";
import ForgetPassword from "components/Common/ForgetPassword";

function SignIn() {
  // Chakra color mode
  const bgForm = useColorModeValue("white", "orange.700");

  const { handleSubmit, register, toast, onSubmit } = useSignIn();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box mt={"150px"}>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "445px", md: "445px", sm: "90vw" }}
        background="transparent"
        borderRadius="15px"
        p="40px"
        m={{ base: "20px", md: "auto" }}
        bg={bgForm}
        boxShadow={useColorModeValue(
          "0px 5px 14px rgba(0, 0, 0, 0.05)",
          "unset"
        )}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Center mb={'1rem'}>
            <Image
              src={process.env.PUBLIC_URL + "/logo.png"}
              height="10rem"
            />
          </Center>
          {/* <Center>
            <PageTitle title="STORE" />
          </Center> */}

          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              id="email"
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your Email"
              mb="24px"
              size="lg"
              {...register("email")}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              id="password"
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              {...register("password")}
            />

            <Button
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              bg={"#3AB689"}
              color="white"
              _hover={{ background: "green" }}
              type="submit"
            >
              SIGN IN
            </Button>
          </FormControl>
        </form>
        {/* forget password */}
        <Text
          textAlign={"center"}
          color="green"
          cursor={"pointer"}
          fontSize={"sm"}
          onClick={onOpen}
        >
          Forget Password?
        </Text>
        <Text
          textAlign={"center"}
          color="green"
          cursor={"pointer"}
          fontSize={"sm"}
          mt={'1rem'}
        >
          On logging in to our system. You agree our <br></br><u>Terms & Conditions</u>
        </Text>
        
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p="0px!important">
              <ForgetPassword onClose={onClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
}

export default SignIn;
