import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostCategoriesApi = () => {
  async function postPostCategories(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/category/`,
      values
    );
    return res;
  }

  const postPostCategoriesMutation = useMutation(postPostCategories);

  return {
    postPostCategoriesMutation,
  };
};
