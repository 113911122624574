import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetBrand = () => {
  async function getBrand() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/brand-list/v2/`);

    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getBrand",
    getBrand
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
