import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAddCustomerApi } from "./useAddCustomerApi";

export const useAddCustomer = () => {
  let history = useHistory();
  const toast = useToast();
  const toastIdRef = useRef();
  const { register, handleSubmit, control, reset } = useForm();

  const { postCustomerMutation } = useAddCustomerApi();
  const { isLoading, isError, data, error, isSuccess } = postCustomerMutation;

  const onSubmit = (values) => {
    const formData = new FormData();
    postCustomerMutation.mutate({
      uuid: localStorage.getItem("customer_uuid") ?? "",
      customer_name: values.customername,
      customer_phone: values.number,
    });
  };

  useEffect(() => {
    console.log("oo", data);
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "New Customer Added!",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      console.log("cc", localStorage);
      //       location.reload();
      localStorage.setItem("customer_number", data?.data?.data?.customer_phone);
      localStorage.setItem("customer_name", data?.data?.data?.customer_name);
      localStorage.setItem("customer_uuid", data?.data?.data?.uuid);

      // history.push("/admin/category-list");
    }

    if (isError) {
      toast({
        title: "Missing Information",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postCustomerMutation.isSuccess,
    postCustomerMutation.isLoading,
    postCustomerMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    data,
  };
};
