import axios from "axios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useSendCodeApi = () => {

  async function postSendCode(values) {
    const res = await axios.post(
      `${BASE_URL}/api/send-otp/`,
      values
    );
    return res;
  }

  const postSendCodeMutation = useMutation(postSendCode);

  return {
    postSendCodeMutation,
  };
};
