import React, { useState, useEffect } from "react";
import { BsPeople } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import AddIcon from "../../../../../../assets/icons/add.png";

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

export default function RetailerBill({
  selectedItems,
  setSelectedItems,
  submitValues,
  invoiceId,
  invId,
}) {
  const toast = useToast();
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const history = useHistory();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [subtotal, setSubtotal] = useState(0);
  const [sub, setSub] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [sku, setSku] = useState("");

  const handleDelete = (itemId) => {
    const updatedItems = selectedItems?.filter((item) => item.uuid !== itemId);
    setSelectedItems(updatedItems);
  };
  const handleQuantityChange = (productId, action) => {
    setSelectedItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product?.uuid) {
          if (product.uuid === productId) {
            const newQuantity =
              action === "increase"
                ? product.quantity + 1
                : product.quantity - 1;
            return { ...product, quantity: newQuantity };
          }
        } else {
          if (product.product === productId) {
            const newQuantity =
              action === "increase"
                ? product.quantity + 1
                : product.quantity - 1;
            return { ...product, quantity: newQuantity };
          }
        }

        return product;
      })
    );
  };
  const fetchData = async () => {
    if (invId !== "undefined") {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/order/?invoice_uuid=${invId}`
      );
      console.log("ee", response);
      setInvoiceNumber(response?.data[0]?.invoiceNumber);
      setSelectedItems(response?.data[0]?.invoice_item);
      setTotalDiscount(response?.data[0]?.total_discount);
      setTotalAmount(response?.data[0]?.total_amount);
      setSub(response?.data[0]?.sub_total)
    }
  };

  useEffect(() => {
    fetchData();
  }, [invId]);

  const calculateSubtotal = () => {
    const total = selectedItems?.reduce((accumulator, product) => {
      return accumulator + (product.complimentry === true ? 0 : product.quantity * product.selling_price);
    }, 0);
    setSubtotal(total);
  };

  useEffect(() => {
    calculateSubtotal();
  }, [selectedItems]);
  console.log("xxx", selectedItems);
  const paymentOrder = () => {
    history.push(`/admin/payment/${invId}`);
  };
  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("Text");
    setSku(pastedText);
  };
  const fetchApi = async () => {
    if (sku) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/itemsbysku/?sku_upc=${sku}`
      );
      if (response?.data?.data) {
        if (response?.data?.data?.available_stock > 0) {
          setSelectedItems([
            ...selectedItems,
            {
              ...response?.data?.data,
              quantity: 1,
              product: response?.data?.data?.uuid,
              uuid: "",
            },
          ]);
        } else {
          toast({
            title: "Out of Stock",
            description: "This product is out of stock",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
        document.getElementById("sku").value = "";
      }
    }
  };
  useEffect(() => {
    fetchApi();
  }, [sku]);
  return (
    <Box
      minW={"25rem"}
      position={"fixed"}
      minH={breakPoint[0] ? "80vh" : "90vh"}
      display={"flex"}
      flexFlow={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box
          display={"flex"}
          borderBottom={"1px dashed #A9A9A9"}
          paddingBottom={"1rem"}
          width={"100%"}
          p={"1rem"}
        >
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"590"}
            >
              ORDER #
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"600"}
            >
              {invoiceNumber}
            </Text>
          </Box>
          <Box marginRight={"1rem"} ml="3rem">
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"600"}
            >
              <Input
                fontSize={"15px"}
                onChange={(e) => setSku(e.target.value)}
                onPaste={handlePaste}
                width={"10rem"}
                type="text"
                textAlign={"right"}
                id="sku"
              />
            </Text>
          </Box>
        </Box>
        <Table variant="unstyled" size="md" w={"100%"}>
          <Tbody>
            <Tr>
              <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                Items
              </Td>
              <Td
                color={"#828282"}
                fontSize={"12px"}
                fontWeight="600"
                pl={"2.85rem"}
              >
                Qty
              </Td>
              <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                Amt
              </Td>
            </Tr>

            {selectedItems?.map((item, index) => {
              console.log("bb", item);
              return (
                <Tr key={index}>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    paddingRight={"0px"}
                  >
                    <Text
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                      width={"11rem"}
                      fontSize={"sm"}
                    >
                      {item?.name}
                    </Text>
                    <Flex mt="0.5rem" gap="0.5rem">
                      <Text fontSize="12px">Dis %</Text>

                      <Input
                        placeholder="%"
                        height="14px"
                        fontSize="12px"
                        border={"none"}
                        width={"2rem"}
                        borderBottom={"1px solid #1D2521"}
                        type="number"
                        defaultValue={item?.discount ?? 0}
                        padding="0"
                        borderRadius={"none"}
                        id={`dis-${item?.uuid}`}
                      />
                      <Text fontSize="12px"> Complimentry</Text>

                      <Checkbox
                        id={`com-${item?.uuid}`}
                        defaultChecked={
                          item?.complimentry == true ? true : false
                        }
                        fontSize="xs"
                      ></Checkbox>
                    </Flex>
                  </Td>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    w={"110px"}
                    paddingRight={"0px"}
                  >
                    <Button
                      size="xs"
                      mr="5px"
                      bg="none"
                      onClick={() =>
                        handleQuantityChange(
                          item?.uuid ? item?.uuid : item?.product,
                          "decrease"
                        )
                      }
                      disabled={item?.qty <= 1}
                    >
                      -
                    </Button>
                    {item?.quantity ? item.quantity : item?.qty}
                    <Button
                      size="xs"
                      bg={"none"}
                      onClick={
                        item?.is_unlimited === false &&
                        item?.closing === item?.quantity
                          ? () =>
                              toast({
                                title: "Inventory unavailable",
                                position: "top-right",
                                status: "error",
                                duration: 1000,
                                isClosable: true,
                              })
                          : () =>
                              handleQuantityChange(
                                item?.uuid ? item?.uuid : item?.product,
                                "increase"
                              )
                      }
                    >
                      +
                    </Button>
                  </Td>
                  {item?.quantity ? (
                    <Td
                      color={"#000000"}
                      fontSize={"12px"}
                      lineHeight={"14px"}
                      fontWeight={"600"}
                      display={"flex"}
                      gap="3px"
                    >
                      {item?.selling_price * item?.quantity}

                      <AiFillDelete
                        color="#EC5A5A"
                        size={13}
                        cursor={"pointer"}
                        onClick={() => handleDelete(item.uuid)}
                      />
                    </Td>
                  ) : (
                    <Td
                      color={"#000000"}
                      fontSize={"12px"}
                      lineHeight={"14px"}
                      fontWeight={"600"}
                      display={"flex"}
                      gap="3px"
                    >
                      {item?.selling_price * item?.qty}

                      <AiFillDelete
                        color="#EC5A5A"
                        size={13}
                        cursor={"pointer"}
                        onClick={() => handleDelete(item.uuid)}
                      />
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Box px={"1rem"}>
        <TotalAmount
          breakPoint={breakPoint}
          subtotal={subtotal}
          submitValues={submitValues}
          invoiceId={invoiceId}
          paymentOrder={paymentOrder}
          // total_discount={total_discount}
          totalDiscount={totalDiscount}
          totalAmount={totalAmount}
          sub={sub}
        />
      </Box>
    </Box>
  );
}
function TotalAmount({
  breakPoint,
  subtotal,
  submitValues,
  invoiceId,
  paymentOrder,
  totalDiscount,
  totalAmount,
  sub
}) {
  return (
    <Box
      border={"1px dashed #A9A9A9"}
      borderRadius={"14px 14px 0px 0px"}
      padding={"1rem"}
      bottom={"1rem"}
      right={"1rem"}
      width={"100%"}
      backgroundColor={"#FAFAFA"}
    >
      {/* <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      /> */}
      {/* TOTAL */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Text fontWeight={"600"} fontSize={"13px"}>
            SUBTOTAL
          </Text>
        </Box>

        <Box>
          <Text fontWeight={"600"}>Rs.{subtotal}</Text>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Text fontWeight={"600"} fontSize={"13px"}>
            Discount
          </Text>
        </Box>

        <Box>
          <Text fontWeight={"600"}>Rs.{totalDiscount}</Text>
        </Box>
      </Box>
      <Box
        borderTop={"1px dashed #A9A9A9 "}
        paddingBlock={"1rem"}
        display={"flex"}
        mt="2rem"
        gap={breakPoint[0] ? "10rem" : "13rem"}
      >
        <Text fontSize={"20px"} fontWeight={"600"}>
          TOTAL
        </Text>
        <Text fontSize={"20px"} fontWeight={"600"}>
          Rs.{subtotal -totalDiscount}
        </Text>
      </Box>
      {/* BUTTON */}
      <Box display={"flex"} mt="1rem" mb={"1rem"} gap={"2rem"}>
        <Box>
          <Button
            color="#ffff"
            bg="buttongreen.400"
            ml="10px"
            w={"100%"}
            variant="secondary"
            onClick={() => {
              submitValues(), calculateDiscount();
            }}
          >
            <Image src={AddIcon} width={"14px"} mr="8px" />
            Update Order
          </Button>
        </Box>

        <Button
          height={"40px"}
          _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
          size="sm"
          color="#ffff"
          bg={"buttongreen.400"}
          variant="secondary"
          onClick={paymentOrder}
        >
          MAKE PAYMENT
        </Button>
      </Box>
    </Box>
  );
}
