import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostManufacturerApi = () => {

  async function postPostManufacturer(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/manufacturers/`,
      values
    );
    return res;
  }

  const postPostManufacturerMutation = useMutation(postPostManufacturer);

  return {
    postPostManufacturerMutation,
  };
};
