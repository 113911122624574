/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { HamburgerIcon } from "@chakra-ui/icons";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  const {
    sidebarWidth,
    setSidebarWidth,
    toggleSidebar,
    setToggleSidebar,
  } = useContext(SidebarContext);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const {
    variant,
    children,
    fixed,
    secondary,
    brandText,
    onOpen,
    ...rest
  } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("black", "gray.200");
  let secondaryText =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let navbarPosition = "absolute";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <></>
    // <Flex
    //   position={navbarPosition}
    //   boxShadow={navbarShadow}
    //   // bg={navbarBg}
    //   bg="white"
    //   // color="blackAlpha.600"
    //   borderColor={navbarBorder}
    //   filter={navbarFilter}
    //   backdropFilter={navbarBackdrop}
    //   borderWidth="1.5px"
    //   borderStyle="solid"
    //   transitionDelay="0s, 0s, 0s, 0s"
    //   transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
    //   transition-property="box-shadow, background-color, filter, border"
    //   transitionTimingFunction="linear, linear, linear, linear"
    //   alignItems={{ xl: "center" }}
    //   borderRadius="2px"
    //   display="flex"
    //   minH="75px"
    //   justifyContent={{ xl: "center" }}
    //   lineHeight="25.6px"
    //   mx="auto"
    //   mt={secondaryMargin}
    //   pb="8px"
    //   left={document.documentElement.dir === "rtl" ? "30px" : ""}
    //   right={document.documentElement.dir === "rtl" ? "" : "30px"}
    //   px={{
    //     sm: paddingX,
    //     md: "30px",
    //   }}
    //   ps={{
    //     xl: "12px",
    //   }}
    //   pt="8px"
    //   // top="18px"
    //   w={{ sm: "calc(100vw - 30px)", xl: "calc(100vw - 76.5px - 275px)" }}
    // >
    //   <Flex
    //     w="100%"
    //     flexDirection={{
    //       sm: "column",
    //       md: "row",
    //     }}
    //     alignItems={{ xl: "center" }}
    //   >
    //     {/* <Box mb={{ sm: "8px", md: "0px" }}> */}
    //       {/* <Breadcrumb>
    //         <BreadcrumbItem color={mainText}>
    //           <BreadcrumbLink href="#" color={secondaryText}>
    //             Pages
    //           </BreadcrumbLink>
    //         </BreadcrumbItem>

    //         <BreadcrumbItem color={mainText}>
    //           <BreadcrumbLink href="#" color={mainText}>
    //             {brandText} 
    //           </BreadcrumbLink>
    //         </BreadcrumbItem>
    //       </Breadcrumb> */}
    //       {/* Here we create navbar brand, based on route name */}
    //       {/* <Link
    //         // color={mainText}
    //         color="blackAlpha.800"
    //         href="#"
    //         bg="inherit"
    //         borderRadius="inherit"
    //         fontWeight="bold"
    //         _hover={{ color: { mainText } }}
    //         _active={{
    //           bg: "inherit",
    //           transform: "none",
    //           borderColor: "transparent",
    //         }}
    //         _focus={{
    //           boxShadow: "none",
    //         }}
    //       >
    //         {brandText}
    //       </Link> */}
    //     {/* </Box> */}
    //     {/* <HamburgerIcon
    //       w="100px"
    //       h="20px"
    //       ms="20px"
    //       color="#fff"
    //       cursor="pointer"
    //       display={{ sm: "none", xl: "block" }}
    //       onClick={() => {
    //         setSidebarWidth(sidebarWidth === 275 ? 120 : 275);
    //         setToggleSidebar(!toggleSidebar);
    //       }}
    //     /> */}
    //     {/* <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
    //       <AdminNavbarLinks
    //         onOpen={props.onOpen}
    //         logoText={props.logoText}
    //         secondary={props.secondary}
    //         fixed={props.fixed}
    //         scrolled={scrolled}
    //       />
    //     </Box> */}
    //   </Flex>
    // </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
