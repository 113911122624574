import { Center, Text } from "@chakra-ui/react";
import React from "react";
import { IoMdAddCircle } from "react-icons/io";
import { globalStyles } from "theme/styles";
import { PageSubTitle } from "./PageSubTitle";

const AddUI = ({ label }) => {
  return (
    <Center gap="1rem">
      <Text color={globalStyles.customStyle.fontColor.color}>{label}</Text>
      <IoMdAddCircle
        size={20}
        color={globalStyles.customStyle.fontColor.color}
      />
    </Center>
  );
};

export default AddUI;
