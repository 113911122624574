import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import Upload from "./Component/Upload";
import { useGetGalleryCategory } from "../../../api/useGetGalleryCategory";
import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import AddCategoryModel from "./Component/AddCategoryModel";
import useGallery from "./useGallery";

const Gallery = ({ register }) => {
  const { data } = useGetGalleryCategory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabIndex, setTabIndex] = useState(0);
  const { setSelectedImageForPreview, selectedImageForPreview } = useGallery();
  // console.log("selectedImageForPreview", selectedImageForPreview);

  return (
    <>
      <Tabs
        mt="1rem"
        index={tabIndex}
        onChange={(index) => setTabIndex(data.length === index ? 0 : index)}
      >
        <TabList>
          {data?.map((item, index) => (
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
              key={index}
            >
              {item.name}
            </Tab>
          ))}

          <Tab
            style={globalStyles.customStyle.tab}
            _selected={globalStyles.customStyle.selectedTab}
            _focus={"none"}
            color={globalStyles.customStyle.fontColor.color}
            onClick={() => onOpen()}
          >
            + Add
          </Tab>
        </TabList>

        <TabPanels>
          {data?.map((item, index) => (
            <TabPanel key={index}>
              <Grid templateColumns={"repeat(4,1fr)"} cursor="pointer" gap={4}>
                <GridItem
                  colSpan={{ base: 4, md: 4, sm: 4 }}
                  // onClick={handleClick}
                >
                  <Upload
                    name={item.name}
                    register={register}
                    tabIndex={tabIndex}
                    index={index}
                    setSelectedImageForPreview={setSelectedImageForPreview}
                  />
                </GridItem>
                {selectedImageForPreview &&
                  selectedImageForPreview.map((item, index) => (
                    <GridItem key={index} colSpan={{ base: 1, md: 1, sm: 1 }}>
                      <Image
                        src={URL.createObjectURL(item)}
                        objectFit={"cover"}
                        height={"4rem"}
                        width={"4rem"}
                        borderRadius={"5px"}
                        border={"1px solid orange"}
                        // onClick={handleClick}
                      />
                    </GridItem>
                  ))}
              </Grid>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {/* add gallery category model */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="0px!important">
            <AddCategoryModel />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Gallery;
