import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetGroup = (next, query) => {
  async function getGroup() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/inventory-group/?page_number=${next}&search=${query}`
    );
    return res.data;
  }

  // const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
  //   "getGroup",
  //   getGroup
  // );
  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getGroup", next, query],
    () => getGroup(next, query)
  );
  return {
    data,
  };
};
