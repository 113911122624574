import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditTableApi = (table) => {

    async function editTable(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/floor-table/update-table/?uuid=${table}`,
            values
        );
        return res;
    }

    const editTableMutation = useMutation(editTable);

    return {
        editTableMutation,
    };
};
