import React, { useEffect, useState } from 'react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	Flex,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	Grid,
	GridItem,
	HStack,
	Image,
	Text,
	VStack,

} from "@chakra-ui/react";
import { Loading } from 'components/Common/Loading';
import PaginationButton from 'components/Common/PaginationButton copy';
import { globalStyles } from 'theme/styles';
import Moment from 'react-moment';
import useEditEmployee from './edit/useEditEmployee';
import { PageText } from "components/Common/PageText";
import ImageIcon from "components/Common/ImageIcon";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelSelect } from 'components/Common/LeftSideLabelSelect';
import { COUNTRIES } from 'constants/CONSTANTS';
import ResetCover from 'views/Authentication/Reset/ResetBasic';

export default function EditEmployee({ data, isLoading, next, setNext, refetch }) {
	const {
		isOpen,
		onOpen,
		onClose,
		setValue,
		isError,
		error,
		isSuccess,
		register,
		control,
		handleSubmit,
		onFinalSubmit,
		reset
	} = useEditEmployee(data);
	const [selectedValue, setSelectedValue] = useState("")
	const handleEdit = (val) => {
		console.log("kk", val)
		setSelectedValue(val)
		onOpen()
	}
	useEffect(() => {
		if (selectedValue) {
			setValue("uuid", selectedValue?.uuid)
			setValue("first_name", selectedValue?.first_name)
			setValue("last_name", selectedValue?.last_name)
			setValue("email", selectedValue?.email)
			setValue("dob", selectedValue?.dob)
			setValue("gender", selectedValue?.gender)
			setValue("country", selectedValue?.address[0]?.country)
			setValue("city", selectedValue?.address[0]?.city)
			setValue("state", selectedValue?.address[0]?.state)
			setValue("contact", selectedValue?.contact[0]?.value)

		}
	}, [selectedValue])
	useEffect(() => {
		if (isSuccess)
			onClose()
		reset()
		refetch()
	}, [isSuccess])
	return (
		<Box>
			<EditModal selectedValue={selectedValue} isOpen={isOpen} onClose={onClose} handleSubmit={handleSubmit} onFinalSubmit={onFinalSubmit} register={register} data={data} control={control} />
			<Table variant="striped">

				<Tr >
					<Th style={globalStyles.customStyle.tableHeading}> First Name</Th>
					<Th style={globalStyles.customStyle.tableHeading}>Last name</Th>
					<Th style={globalStyles.customStyle.tableHeading}> Email </Th>
					<Th style={globalStyles.customStyle.tableHeading}> DOB </Th>
					<Th style={globalStyles.customStyle.tableHeading}> Gender</Th>
					<Th style={globalStyles.customStyle.tableHeading}> Contact</Th>
					<Th style={globalStyles.customStyle.tableHeading}> Country</Th>
					<Th style={globalStyles.customStyle.tableHeading}> State</Th>
					<Th style={globalStyles.customStyle.tableHeading}> City</Th>

					{/* <Th style={globalStyles.customStyle.tableHeading}> Invoice Amount</Th> */}
				</Tr>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Tbody>
							{data?.map((item, index) => (
								<>
									<Tr
										cursor={"pointer"}
										onClick={() => handleEdit(item)}
										key={index}
									// onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
									>
										{/* <input type={'hidden'} {...register('uuid')} value={selectedValue?.uuid} /> */}

										<Td

										>
											<Text>{item?.first_name}</Text>
										</Td>
										<Td>
											<Text>{item?.last_name}</Text>
										</Td>
										<Td>
											<Text>{item?.email}</Text>
										</Td>
										<Td>
											<Moment format="DD-MM-YYYY">{item?.dob}</Moment>
										</Td>
										<Td>
											<Text>{item?.gender}</Text>
										</Td>

										<Td>
											<Text>{item?.contact[0]?.value}</Text>
										</Td>
										<Td>
											<Text>{item?.address[0]?.country}</Text>
										</Td>
										<Td>
											<Text>{item?.address[0]?.state}</Text>
										</Td><Td>
											<Text>{item?.address[0]?.city}</Text>
										</Td>


									</Tr>
								</>
							))}
						</Tbody>
						<PaginationButton
							isPrevious={data?.previous}
							isNext={data?.next}
							next={next}
							setNext={setNext}
						/>
					</>
				)}
			</Table>
		</Box>
	)
}

function EditModal({ selectedValue, isOpen, onClose, handleSubmit, onFinalSubmit, register, data, control }) {
	console.log("data", data)
	return (
		<Box>
			<Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Employee</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onFinalSubmit)}>
							<Box>
								{/* <input type={'hidden'} {...register('uuid')} value={selectedValue?.uuid} /> */}

								<LeftSideLabelInput
									label="First Name"
									// defaultValue={selectedValue?.first_name}
									placeholder="First Name"
									register={register}
									registerName={"first_name"}
								/>
								<LeftSideLabelInput
									label="Last Name"
									// defaultValue={selectedValue?.last_name}
									placeholder="Last Name"
									register={register}
									registerName={"last_name"}
								/>


								<LeftSideLabelInput
									disabled={true}
									label="Email Address"
									// defaultValue={selectedValue?.email}
									placeholder="Primary Email Address"
									register={register}
									registerName={"email"}
								/>
								<LeftSideLabelInput
									label="Birthdate"
									defaultValue={selectedValue?.dob}
									type="date"
									register={register}
									registerName={"dob"}
								/>

								<LeftSideLabelSelect
									gap="0rem"
									label="Gender"
									data={[
										{ label: "Male", value: "Male" },
										{ label: "Female", value: "Female" },
									]}
									control={control}
									register={register}
									registerName={"gender"}
								/>
								<LeftSideLabelInput
									label="Contact"
									// defaultValue={selectedValue?.contact[0]?.value}

									placeholder="Primary Phone Number"
									register={register}
									registerName={"contact"}
								/>
								<LeftSideLabelSelect
									gap="0rem"
									label="Country"
									data={COUNTRIES}
									// defaultValue={selectedValue?.address[0]?.country}
									register={register}
									registerName={"country"}
									control={control}
								/>
								<LeftSideLabelInput
									// defaultValue={selectedValue?.address[0]?.city}
									label="City"
									placeholder="City"
									register={register}
									registerName={"city"}
								/>
								<LeftSideLabelInput
									// defaultValue={selectedValue?.address[0]?.state}
									label="State"
									placeholder="State"
									register={register}
									registerName={"state"}
								/>
								<Flex gap="1rem" mt="3rem" mb="3rem">
									<SaveButton />
									<CancelButton onClose={onClose} />
								</Flex>
							</Box>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}