import { Box, Input,Button,Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PageTitle } from "./PageTitle";
import { SectionTitle } from "./SectionTitle";
import { useSendCode } from "views/Pages/signin/useSendCode";
import { useNewPassword } from "views/Pages/signin/useNewPassword";

export default function ForgetPassword({onClose}){

    const [isSent,setIsSent] = useState(false);
    const [email,setEmail] = useState("");
    const [pass,setPass] = useState("");
    const [pass1,setPass1] = useState("");
    const { handleSubmit, register, toast, onSubmit,isSuccess } = useSendCode();
    const { handleSubmit:handleSubmitNew, register:registerNew , toast:toastNew, onSubmit:onSubmitNew,isSuccess:isSuccessNew } = useNewPassword();

    useEffect(() => {
        if(isSuccess){
            setIsSent(true);
        }
    },[isSuccess])

    useEffect(()=>{
        if(isSuccessNew){
            onClose();
        }
    },[isSuccessNew])

    return (
        isSent?
        <form onSubmit={handleSubmitNew(onSubmitNew)}>
            <Box borderBottom="1px solid #EAEAEA">
                <PageTitle title="Enter Security Code" />
            </Box>

            <Box padding={'1rem'} borderBottom="1px solid #EAEAEA">
                <SectionTitle title="Please check your email for a text message with your code. Your code is 4 digit characters long." />
                <Input id="otp" name="otp" placeholder="Enter Code" type="number" mt={"1rem"} required mb={"1rem"} maxLength="4" {...registerNew("otp")} />
                <Input id="email" name="email" type="hidden" value={email} {...registerNew("email")}/>

                <SectionTitle title={"New Password"} />
                <Input id="new_password" name="new_password" placeholder="New Password" type="password" mt={"1rem"} required {...registerNew("new_password")} onChange={e=>setPass(e.target.value)}/>
                <Text mt={"1rem"} fontSize={"xs"} color="red">{pass === pass1 ? "":"password doesn't match" }</Text>
                <Input id="password1" name="password1" placeholder="Retype Password" type="password"  required {...registerNew("password1")} onChange={e=>setPass1(e.target.value)}/>
            </Box>

            <Box p={"1rem"} justifySelf="center">
                <Text textAlign={"center"} color="orange.500" cursor={"pointer"} fontSize={"sm"} float="left" pt={"0.5rem"} onClick={handleSubmit(onSubmit)} >Resend Code?</Text>
                
                <Button type="submit" color="white" bg={"orange.500"} float={"right"} disabled={pass === pass1 ? false:true}>
                    Continue
                </Button>
                <Button onClick={onClose} mr="1rem" float={"right"} mb={'1rem'}>
                    Cancel
                </Button>
            </Box>
        </form>
        :
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box padding={'1rem'} borderBottom="1px solid #EAEAEA">
                <PageTitle title="Find Your Account" />
            </Box>
            <Box padding={'1rem'} borderBottom="1px solid #EAEAEA">
                <SectionTitle title="Please enter your email to change password for your account." />
                <Input id="email" name="email"   placeholder="abc@xyz.com" type="email" mt={"1rem"} required {...register("email")} onChange={(e)=>setEmail(e.target.value)}/>
            </Box>
            <Box m={"1rem"} float="right">
                <Button onClick={onClose} mr="1rem">
                    Cancel
                </Button>
                <Button color="white" bg={"orange.500"} type="submit">
                    Send OTP
                </Button>
            </Box>
        </form>
    );
}