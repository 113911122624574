import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useCallback } from "react";

export const useNotify = () => {
  const toast = useToast();

  const successNotify = useCallback((msg) => {
    toast({
      description: msg,
      status: "success",
      duration: 1000,
      isClosable: true,
      position: "top-right",
    });
  }, []);

  const errorNotify = useCallback((msg) => {
    toast({
      description: msg,
      status: "error",
      duration: 1000,
      isClosable: true,
      position: "top-right",
    });
  }, []);

  return {
    successNotify,
    errorNotify,
  };
};
