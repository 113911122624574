import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostAdjustmentApi = () => {
  async function postPostAdjustmentApi(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/inventry-item/adjust/`,
      values
    );
    return res;
  }

  const postPostAdjustmentMutation = useMutation(postPostAdjustmentApi);

  return {
    postPostAdjustmentMutation,
  };
};
