import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostBookedTable = (uuid) => {
  async function postBookedTable() {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/order/?uuid=${uuid}`
    );
    return res;
  }

  const postBookedTableMutation = useMutation(postBookedTable);

  return {
    postBookedTableMutation,
  };
};
