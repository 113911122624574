import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useDeleteReceipe = () => {
  async function deleteReceipe(uuid) {
    let res = await authorizationAxios.delete(
      `${BASE_URL}/api/receipe/delete/`,
      {
        data: {
          uuid: uuid,
        },
      }
    );
    return res;
  }
  const deleteReceipeMutation = useMutation(deleteReceipe);

  return {
    deleteReceipeMutation,
  };
};
