import React from "react";
import { Button } from "@chakra-ui/react";
import { globalStyles } from "theme/styles";

export const SaveButton = () => {
  return (
    <Button
      style={globalStyles.customStyle.defaultBtnStyle}
      size="sm"
      // onClick={onClick}
      type="submit"
    >
      Save
    </Button>
  );
};
