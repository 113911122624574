import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import React from "react";

const Contact = () => {
  return (
    <>
      <Grid templateColumns={"repeat(2,1fr)"}>
        <GridItem>
          <LeftSideLabelInput label="Emergency Contact" type="text" />
          <LeftSideLabelInput label="Alternative Contract" type="text" />
        </GridItem>
        <GridItem>
          {" "}
          <LeftSideLabelInput label="Relationship" type="text" />
          <LeftSideLabelInput label="Email" type="text" />
        </GridItem>
      </Grid>
      <Flex gap="2rem" mt="3rem">
        <SaveButton />
        <CancelButton />
      </Flex>
    </>
  );
};

export default Contact;
