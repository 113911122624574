import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { globalStyles } from "../../../../theme/styles";

const Document = () => {
  return (
    <Box>
      <Grid templateColumns="repeat(3, 1fr)">
        <GridItem colSpan={2}></GridItem>
        <GridItem>
          <Tabs>
            <TabList>
              <Tab
                style={globalStyles.customStyle.tab}
                _selected={globalStyles.customStyle.selectedTab}
                _focus={"none"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Passport
              </Tab>
              <Tab
                style={globalStyles.customStyle.tab}
                _selected={globalStyles.customStyle.selectedTab}
                _focus={"none"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Profile
              </Tab>
              <Tab
                style={globalStyles.customStyle.tab}
                _selected={globalStyles.customStyle.selectedTab}
                _focus={"none"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Documents
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <p>one!</p>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
              <TabPanel>
                <p>three!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Document;
