import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetAdjustmentAuditApi } from "../../api/useGetAdjustmentAudit";
import PaginationButton from "components/Common/PaginationButton copy";

const AuditTable = ({ uuid }) => {
  const [next, setNext] = useState(1);
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  const { data, refetch } = useGetAdjustmentAuditApi(uuid, next);
  console.log("ii", data);
  useEffect(() => {
    refetch();
  }, [uuid]);

  return (
    <TableContainer>
      <Table variant="striped">
        <TableCaption>Audits cannot be deleted/updated!</TableCaption>
        <Thead>
          <Tr>
            <Th>Adjusted Date</Th>
            <Th>Reason</Th>
            <Th>Opening Stock</Th>
            <Th>Closing Stock</Th>
            <Th>Adjusted Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data?.data?.map((item, idx) => (
              <Tr key={idx}>
                <Td>{item.date}</Td>
                <Td>{item.reason}</Td>
                <Td>{item.openingstock}</Td>
                <Td>{item.closingstock}</Td>
                <Td>{item.quantity_adjustment}</Td>
              </Tr>
            ))}
          {data?.data?.length === 0 && (
            <Tr>
              <Td> -- </Td>
              <Td> -- </Td>
              <Td> NO DATA </Td>
              <Td> -- </Td>
              <Td> -- </Td>
            </Tr>
          )}
        </Tbody>
        <PaginationButton
          isPrevious={data?.previous}
          isNext={data?.next}
          next={next}
          setNext={setNext}
        />
        <Tfoot>
          {/* <Tr>
            <Th>Date Adjusted</Th>
            <Th>Reason</Th>
            <Th>Opening Stock</Th>
            <Th>Closing Stock</Th>
            <Th>Adjusted Quantity</Th>
          </Tr> */}
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default AuditTable;
