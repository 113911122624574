
import { Box, Flex, Grid, GridItem, Image, Input, Spacer, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { PageText } from 'components/Common/PageText'
import { MdDeleteForever } from 'react-icons/md'
import { IoImageOutline } from "react-icons/io5";
import { useGetSearchItems } from 'views/Pages/CommonApi/useGetSearchItems'

export default function SelectItem({ index, register, getValues, setValue, remove }) {

    const [text, setText] = useState('')
    const [img, setImg] = useState('')
    const toast = useToast()
    const breakPoint = useMediaQuery("(max-width: 1400px)");

    let { data: searchItems, refetch } = useGetSearchItems(text);
    const [display, setDisplay] = useState(true)

    useEffect(() => {
        refetch();
    }, [text])

    const onChangeHandler = (text) => {
        setText(text)
        setValue('items.' + index + '.name', text)
        setDisplay(true)
    }

    const suggestHandler = (item) => {
        if (getValues('items')?.some(itm => itm.uuid === item.uuid)) {
            toast({
                title: 'Already Added',
                description: "Add another item!",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            })
        } else {
            setDisplay(false)
            setImg(item?.image[0]?.image)
            setValue('items.' + index + '.name', item.name)
            setValue('items.' + index + '.image', item?.image[0]?.image);
            setValue('items.' + index + '.stock', item.closing_stock);
            setValue('items.' + index + '.uuid', item.uuid)
            setText('')
            searchItems = [];
        }
    }

    const handleNewQuantityChange = (value) => {
        setValue('items.' + index + '.quantity_adjustment', +value - +getValues('items.' + index + '.stock'));
    }

    const handleAdjustedQuantityChange = (value) => {
        setValue('items.' + index + '.newQuantity', +value + +getValues('items.' + index + '.stock'))
    }

    return (
        <>
            <GridItem key={index}>
                <Box position='relative'>
                    <Flex h='4rem' minW={breakPoint[0] ? '10rem' : '35rem'}>
                        <Box w='5rem' h='100%' border={'1px solid lightgray'}>
                            {
                                img ?
                                    <Image
                                        borderRadius='none'
                                        boxSize='100%'
                                        fit='contain'
                                        p={1}
                                        border='1px solid #D3D3D3'
                                        src={getValues('items.' + index + '.image')}
                                    />
                                    :
                                    <IoImageOutline fontSize={'4rem'} color='lightgray' />
                            }
                        </Box>
                        <Input
                            h='100%'
                            w='100%'
                            borderRadius='none'
                            value={getValues('items.' + index + '.name')}
                            {...register('items.' + index + '.name')}
                            onChange={(e) => onChangeHandler(e.target.value)}
                            onBlur={() => {
                                setTimeout(() => {
                                    setDisplay(false);
                                    searchItems = [];
                                }, 500);
                            }}
                        />
                        <input type={'hidden'} {...register('items.' + index + '.uuid')} />
                    </Flex>
                    {
                        display &&
                        <Box position='absolute' w='100%' bg='white' h='fit-content' zIndex='2'>
                            {searchItems?.items.map((item, idx) => {
                                return (
                                    <Flex
                                        key={idx}
                                        border='1px solid #E2E8F0'
                                        p={2}
                                        h='4rem'
                                        _hover={{ cursor: 'pointer', bg: 'gray.200' }}
                                        onClick={() => suggestHandler(item)}
                                    >
                                        <Box
                                            // w={breakPoint[0] ? '0rem' : '10rem'}
                                            whiteSpace='nowrap'
                                            overflow='hidden'
                                        >
                                            <PageText text={item.name} />
                                        </Box>
                                        <Spacer />
                                        <Flex direction='column' align='flex-end'>
                                            <PageText text='Stock on hand' />
                                            <PageText text={item.closing_stock + ' pcs'} />
                                        </Flex>
                                    </Flex>
                                )
                            })}
                        </Box>
                    }
                </Box>
            </GridItem>
            <GridItem border='1px solid #D3D3D3'>
                <Flex
                    h='100%'
                    px='1rem'
                    justify='end'
                    align='center'
                >
                    <PageText text={getValues('items.' + index + '.stock')} />
                </Flex>
            </GridItem>
            <GridItem border='1px solid #D3D3D3'>
                <Flex
                    h='100%'
                    justify='end'
                    align='center'
                >
                    <Input
                        h='100%'
                        w='100%'
                        borderRadius='none'
                        defaultValue={0}
                        required
                        textAlign={'right'}
                        type='number'
                        {...register('items.' + index + '.newQuantity')}
                        onChange={(e) => handleNewQuantityChange(e.target.value)}
                    />
                </Flex>
            </GridItem>
            <GridItem border='1px solid #D3D3D3'>
                <Flex
                    h='100%'
                    justify='end'
                    align='center'
                >
                    <Input
                        textAlign={'right'}
                        h='100%'
                        w='100%'
                        borderRadius='none'
                        defaultValue={0}
                        type='number'
                        {...register('items.' + index + '.quantity_adjustment', {
                            valueAsNumber: true,
                        })}
                        onChange={(e) => handleAdjustedQuantityChange(e.target.value)}
                        required
                    />
                </Flex>
            </GridItem>
            <GridItem border='1px solid #D3D3D3' display='flex' alignItems='center' justifyContent='center'>
                <MdDeleteForever size='2rem' m='auto' color='red' cursor={'pointer'} onClick={() => remove(index)} />
            </GridItem>
        </>
    )
}
