import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { authorizationAxios } from "axios/authorizationAxios";
import { PageText } from "components/Common/PageText";
import { PageTitle } from "components/Common/PageTitle";
import { SectionTitle } from "components/Common/SectionTitle";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetInventoryDetailApi } from "./api/useGetInventoryDetailApi";
import Highlight from "./component/Highlight";
import ItemListTable from "./component/ItemListTable";
import { SaveButton } from "components/Common/SaveButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { useAddGroupItem } from "./useAddGroupItem";
import MultipleImage from "../Inventory/Add/component/MultipleImage";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { pt } from "date-fns/locale";

export default function InventoryDetail() {
  const location = useLocation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let today = new Date().getTime();
  const date = new Date(today);
  const seconds =
    Math.floor(date.getTime() / 1000) +
    "-" +
    Math.floor(Math.random() * (100 - 1 + 1) + 1);

  const { data } = useGetInventoryDetailApi(
    location?.state?.id
      ? location?.state?.id
      : new URLSearchParams(location.search).get("id")
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    handleUpload,
    handleClick,
    hiddenFileInput,
    file,
  } = useAddGroupItem();

  const handleEdit = () => {
    history.push({
      pathname: "/admin/inventory-detail-edit",
      search:
        "?id=" +
        (location?.state?.id
          ? location?.state?.id
          : new URLSearchParams(location.search).get("id")),
      state: {
        id: location?.state?.id
          ? location?.state?.id
          : new URLSearchParams(location.search).get("id"),
      },
    });
  };

  return (
    <>
      <TopSearch />
      <Box p={"1rem"} pt={"5rem"}>
        <Flex direction="column" p={2} gap={10}>
          <Flex>
            <Flex direction="column">
              {/* <Text fontSize="2rem">{data?.group?.title}</Text> */}
              <PageTitle
                title={data ? data["group-details"].name : ""}
                style={{ padding: 0 }}
              />
              <Text fontSize="1.1rem">
                {" "}
                {data ? data["group-details"].item_count : ""} item(s)
              </Text>
              <Highlight
                brand={data ? data["group-details"].brand : ""}
                manufacturer={data ? data["group-details"].manufactured_by : ""}
                category={data ? data["group-details"].category?.name : ""}
              />
              <Flex direction="column">
                <SectionTitle title={"Description"} mt="0.15rem" />
                <PageText
                  text={data ? data["group-details"].description : ""}
                />
              </Flex>
            </Flex>
            <Spacer />
            <Box>
              <Button
                borderRadius="2xl"
                fontSize="md"
                fontWeight="normal"
                verticalAlign="center"
                color="white"
                bg="#3AB689"
                w={"7rem"}
                onClick={() => handleEdit()}
              >
                Edit
              </Button>
              <Button
                borderRadius="2xl"
                fontSize="md"
                fontWeight="normal"
                verticalAlign="center"
                color="white"
                bg="#3AB689"
                w={"7rem"}
                ml={"1rem"}
                onClick={onOpen}
              >
                Add Item
              </Button>
            </Box>
          </Flex>

          <ItemListTable data={data?.items} />
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Add Group Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                type="hidden"
                {...register("inventory_group_uuid")}
                value={data ? data["group-details"]?.uuid : ""}
              />

              <LeftSideLabelInput
                type="text"
                label="Item Name"
                register={register}
                registerName={"name"}
              />
              <LeftSideLabelInput
                type="text"
                label="SKU"
                register={register}
                registerName={"sku"}
                defaultValue={seconds}
              />
              <LeftSideLabelInput
                type="number"
                label="Cost Price"
                defaultValue="0"
                register={register}
                registerName={"closing_stock"}
              />
              <LeftSideLabelInput
                type="number"
                label="Selling Price"
                defaultValue="0"
                register={register}
                registerName={"selling_price"}
              />
              <LeftSideLabelInput
                type="number"
                label="Stock"
                defaultValue="0"
                register={register}
                registerName={"closing_stock"}
              />
              <Box display={"flex"} mt={"1rem"} mb={"1rem"}>
                <Text fontSize={"sm"} w={"7rem"}>
                  Is Unlimited
                </Text>
                <Checkbox
                  {...register("is_unlimited")}
                  defaultChecked={false}
                />
              </Box>

              <MultipleImage
                file={file}
                handleUpload={handleUpload}
                handleClick={handleClick}
                hiddenFileInput={hiddenFileInput}
              />
            </ModalBody>

            <ModalFooter>
              <SaveButton />
              <Button
                mr={3}
                height={"2rem"}
                fontSize={"sm"}
                ml={"1rem"}
                onClick={onClose}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
