import { Input, Box, HStack, Text } from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import {AiOutlineCloseCircle} from "react-icons/ai";

const SuccessRate = ({successRate,register}) => {

    const {fields, remove, append} = successRate;


    return (
        <Box mt="1.5rem">
            {fields.map((field, index) => (
            <HStack gap={'1rem'} key={field.id} mb={'1rem'}>
                <Input
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                    size="sm"
                    {...register(`success.${index}.title`)} 
                    />

                <Input
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                    size="sm"
                    {...register(`success.${index}.description`)} 
                    />

                <Input
                    type={'file'}
                    w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                    {...register(`success.${index}.image`)} 
                    />

                <AiOutlineCloseCircle color="red" cursor={"pointer"} onClick={()=>remove(index)}/>
            </HStack>
            ))}
                <Text
                  marginLeft={'16.5rem'}
                  color={globalStyles.customStyle.fontColor.color}
                  cursor="pointer"
                  mt={'1.5rem'}
                  onClick={() => append({})}
                >
                  + Add Another
                </Text>
        </Box>
    );
}

export default SuccessRate;