import { useEffect, useRef, useState } from 'react'
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { useEditEmpoyeeApi } from '../api/useEditEmployeeApi';

export default function useEditEmployee(data) {
        const toast = useToast();
        const { isOpen, onOpen, onClose } = useDisclosure()
        const toastIdRef = useRef();
        const { register, handleSubmit, reset, control, setValue } = useForm()
        const { editEmployeesMutation } = useEditEmpoyeeApi()
        const { isError, error, isSuccess } = editEmployeesMutation;
        const isLoading = editEmployeesMutation.isLoading;
        function onFinalSubmit(data) {
                const formData = new FormData();
                console.log("xx", data)
                formData.append('uuid', data.uuid);
                formData.append('contact', data.contact);
                formData.append('country', data.country);
                formData.append('city', data.city);
                formData.append('state', data.state);
                formData.append('dob', data.dob);
                formData.append('email', data.email);
                formData.append('first_name', data.first_name);
                formData.append('last_name', data.last_name);
                formData.append('gender', data.gender);
                editEmployeesMutation.mutate(formData);
        }

        useEffect(() => {
                if (isSuccess) {
                        toastIdRef.current = toast({
                                title: "Employee Updated!",
                                position: "top-right",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                        });

                }
                if (isLoading) {
                        toastIdRef.current = toast({
                                title: "loading.....",
                                position: "top-right",
                                status: "success",
                                duration: 1000,
                                isClosable: true,
                        });
                }
                if (isError) {
                        toast({
                                title: "Missing Information",
                                position: "top-right",
                                // description: "You will shortly contact you.",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                        });
                }
        }, [
                editEmployeesMutation.isSuccess,
                editEmployeesMutation.isLoading,
                editEmployeesMutation.isError,
        ]);

        return {
                isOpen,
                onOpen,
                onClose,
                isLoading,
                isError,
                error,
                isSuccess,
                register,
                control,
                handleSubmit,
                onFinalSubmit,
                setValue,
                reset
        }
}
