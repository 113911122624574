import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import { FaTshirt, FaUser } from 'react-icons/fa'
import { globalStyles } from 'theme/styles'

export default function ImageIcon({ src, person, height, width, borderRadius, iconSize, display, visibility }) {
    return (
        <Box
            h={height ? height : '2.4rem'}
            minH={height ? height : '2.4rem'}
            w={width ? width : '2.4rem'}
            minW={width ? width : '2.4rem'}
            // borderRadius={borderRadius ? borderRadius : '0.5rem'}
            bg='white'
            overflow='hidden'
            display={display ? display : 'grid'}
            visibility={visibility ? visibility : ''}
            placeItems='center'
            cursor={"pointer"}
            // shadow='base'
        >
            {src ?
                <Image src={src} objectFit='cover' h='100%' w='100%' />
                :
                person ?
                    <FaUser
                        size={iconSize ? iconSize : '1.5rem'}
                        color={globalStyles.colors.mediumDark}
                    />
                    :
                    <FaTshirt
                        size={iconSize ? iconSize : '1.5rem'}
                        color={globalStyles.colors.mediumDark}
                    />
            }
        </Box>
    )
}
