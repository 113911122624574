import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useDeleteImageApi = () => {
  async function deleteImage(uuid) {
    let res = await authorizationAxios.delete(
      `${BASE_URL}/api/delete-image/${uuid}/`
    );
    return res;
  }
  const deleteImageMutation = useMutation(deleteImage);

  return {
    deleteImageMutation,
  };
};
