import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState: { loading: false },

  reducers: {
    profile: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.profile = payload;
    },
  },
});

export const profileReducer = profileSlice.reducer;
export const { profile } = profileSlice.actions;
