import { useForm } from "react-hook-form";
import { useAddInventoryGroupApi } from "../api/useAddInventoryGroupApi";
import { useAddKitchenInventoryApi } from "./useAddKitchenInventoryApi";
import { useEffect } from "react";
import { useNotify } from "common hooks/useNotify";

export const useAddKitchenInventory = () => {
  const { postAddKitchenInventoryMutation } = useAddKitchenInventoryApi();

  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postAddKitchenInventoryMutation;
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    resetField,
    reset,
  } = useForm();

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("unit", values.unit);
    formData.append("available_stock", values.quantity);
    postAddKitchenInventoryMutation.mutate(formData);
  };
  console.log("succ", isSuccess);
  useEffect(() => {
    if (isSuccess) {
      successNotify("Kitchen Inventory Added Successfully");
      reset();
    }

    if (isError) {
      errorNotify(
        error?.response?.data.message
          ? error?.response?.data.message
          : error.message
      );
    }
  }, [
    postAddKitchenInventoryMutation.isSuccess,
    postAddKitchenInventoryMutation.isLoading,
    postAddKitchenInventoryMutation.isError,
  ]);
  return {
    handleSubmit,
    register,
    control,
    onSubmit,
    isSuccess,
    getValues,
    setValue,
    watch,
    unregister,
    resetField,
    reset,
  };
};
