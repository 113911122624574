import { useToast } from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import { useProfileApi } from "../api/useGetProfileApi";
import { useGetProfileApi } from "../api/useGetProfileApi";

export const useProfile = () => {
  const { dataGetProfile } = useGetProfileApi();
  // const dataGetProfile = useSelector((state) => state.profile.profile);

  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  let history = useHistory();

  // const getProfileData = (values) => {
  //   getProfileMutation.mutate(values);
  // };

  return {
    toast,
    dataGetProfile,
  };
};
