import { Text } from "@chakra-ui/react";
import React from "react";

export const PageTitle = ({ title, style, pl, fontW, p, pt, pb, ml }) => {
  return (
    <Text
      p={p ?? "3rem"}
      pl={pl ? pl : "3rem"}
      pt={pt ? pt : "3rem"}
      pb={pb ? pb : ""}
      style={style}
      fontSize={"xl"}
      color="blackAlpha.900"
      fontWeight={fontW}
    >
      {title}
    </Text>
  );
};
