import {
	HStack,
	useDisclosure,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Table,
	Tbody,
	Tr,
	Th,
	useMediaQuery,
} from "@chakra-ui/react";
import SearchUI from "components/Common/SearchUI";
import { globalStyles } from "theme/styles";
import React, { useEffect, useState } from "react";
import AddUI from "components/Common/AddUI";
import SortIcon from "components/Common/SortIcon";
import { useGetBrandsApi } from "../api/useGetBrandsApi";
import EditBrand from "../edit/EditBrand";
import { Loading } from "components/Common/Loading";
import { NoResultUI } from "components/Common/NoResultUI";
import PaginationButton from "components/Common/PaginationButton";
import AddBrand from "../add/AddBrand";

const Brands = ({isOpen, onOpen, onClose}) => {
	// const { isOpen, onOpen, onClose } = useDisclosure();
	const breakPoint = useMediaQuery("(min-width: 1400px)");

	const [brandList, setBrandList] = useState()
	const [searchText, setSearchText] = useState('')
	const [next, setNext] = useState(1);

	const { data: searchData, refetch, isLoading } = useGetBrandsApi(searchText, next)

	useEffect(() => {
		if (searchData) {
			setBrandList(searchData)
		}
	}, [searchData])

	useEffect(() => {
		setNext(1)
		const timer = setTimeout(() => {
			refetch()
		}, 500);

		return () => clearTimeout(timer)
	}, [searchText])

	useEffect(() => {
		const timer = setTimeout(() => {
			refetch()
		}, 500);

		return () => clearTimeout(timer)
	}, [next])

	return (
		<Box bg={"white"} minH="100vh" pos="relative">
				
			<AddBrand isOpen={isOpen} onClose={onClose} refetch={refetch} />

			{isLoading ?
				<Loading />
				:
				!brandList?.length ?
					<NoResultUI />
					:
					<>
						<Table variant="striped">
							<Tr>
								<Th>Brands Name</Th>
								<Th>Color Code</Th>
								<Th>Slogan</Th>
							</Tr>
							<Tbody>
								{brandList?.map((data, index) => {
									return (
										<EditBrand data={data} index={index} refetch={refetch} />
									);
								})}
							</Tbody>
						</Table>

						<PaginationButton
							isPrevious={brandList?.previous}
							isNext={brandList?.next}
							next={next}
							setNext={setNext}
						/>
					</>
			}
		</Box>
	);
};

export default Brands;
