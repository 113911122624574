import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAddPeakApi } from "../api/useAddPeakApi";
import { useHistory } from "react-router-dom";

export const useAddPeak = () => {
  const { postAddPeakMutation } = useAddPeakApi();
  const { isLoading, isError, data, error, isSuccess } = postAddPeakMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
  let history = useHistory();

  const { register, handleSubmit,control } = useForm();
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("country", values.country);
    formData.append("altitude", values.altitude);
    formData.append("difficulty", values.difficulty);
    formData.append("latitude", values.latitude);
    formData.append("longitude", values.longitude);
    formData.append("description", values.description);
    var category = ""
    values?.category?.map((item,idx)=>{
      if((idx+1) === values.category.length){
        category = category.concat('"'+item.value+'"')
      }else{
        category = category.concat('"'+item.value+'",')
      }
    })
    formData.append("category",'['+category+']' );

    formData.append(
      "cover_image",
      uploadPhotoFile.length !== 0
        ? uploadPhotoFile[0]
        : ''
    );
    postAddPeakMutation.mutate(formData);
  };

  const uploadPhotoRef = useRef(null);

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    SetUploadPhotoFile(Array.from(event.target.files));
  }

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "Peak Added successfully!",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });

        history.push("/admin/peak/list");
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data.message ? error?.response?.data.message : error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postAddPeakMutation.isSuccess,
    postAddPeakMutation.isLoading,
    postAddPeakMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile
  };
};
