import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import SearchUI from "components/Common/SearchUI";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ImageIcon from "components/Common/ImageIcon";
import { useSalesReport } from "./useSalesReport";
import Moment from "react-moment";
import { NoDataMsg } from "components/Common/NoDataMsg";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { Loading } from "components/Common/Loading";
import { useReactToPrint } from "react-to-print";
import { AlertModal } from "components/AlertModal";
import { usePatchInvoiceVoid } from "../api/usePatchInvoiceVoid";
import { useNotify } from "common hooks/useNotify";
import Invoice from "../components/Invoice";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import PaginationButton from "components/Common/PaginationButton";

export const SalesReport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify } = useNotify();
  const { patchInvoiceVoidMutation } = usePatchInvoiceVoid();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const {
    data,
    mutatedData,
    brandList,
    brandSelectionHandler,
    brandSelected,
    next,
    setNext,
    isLoading,
    refetch,
  } = useSalesReport();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [invoiceData, setInvoiceData] = useState();

  function listClickHandler(list) {
    onOpen();
    setInvoiceData(list);
  }

  let voidUuid = useRef(null);

  function alertHandler() {
    const values = {
      uuid: voidUuid.current,
      void_reason: "static",
    };
    patchInvoiceVoidMutation.mutate(values);
  }

  function voidHandler(uuid) {
    voidUuid.current = uuid;
    alertOnOpen();
  }

  const { isSuccess } = patchInvoiceVoidMutation;

  useEffect(() => {
    if (alertIsOpen) {
      onClose();
    }
  }, [alertIsOpen]);

  useEffect(() => {
    if (isSuccess) {
      // console.log("patchInvoiceVoidMutation", patchInvoiceVoidMutation?.data);
      successNotify(patchInvoiceVoidMutation?.data?.data?.message);
      voidUuid.current = null;
      alertOnClose();
      refetch();
    }
  }, [isSuccess]);

  return (
    <Box bg="white" minH="100vh">
      <Tabs
        w="vw"
        borderColor={globalStyles.customStyle.borderColor.borderColor}
      >
        <TabList>
          <Tab
            style={globalStyles.customStyle.tab}
            _selected={globalStyles.customStyle.selectedTab}
            _focus="none"
            p="1.5rem"
          >
            All
          </Tab>
        </TabList>

        <TabPanels>
          {isLoading ? (
            <Loading />
          ) : (
            <TabPanel>
              <Table variant="striped">
                <Tr>
                  <Th style={globalStyles.customStyle.tableHeading}>
                    Item Name
                  </Th>
                  <Th style={globalStyles.customStyle.tableHeading}>Brand</Th>
                  <Th style={globalStyles.customStyle.tableHeading}>
                    Invoice Date
                  </Th>
                  <Th style={globalStyles.customStyle.tableHeading}>
                    Quantity
                  </Th>
                  <Th style={globalStyles.customStyle.tableHeading}>
                    Invoice Amount
                  </Th>
                  <Th>Complimentry</Th>
                </Tr>
                <Tbody>
                  {mutatedData?.map((list) => {
                    return (
                      <Tr
                        onClick={() => listClickHandler(list)}
                        cursor={"pointer"}
                      >
                        <Td>
                          <HStack>
                            <ImageIcon person src={list?.image[0]?.image} />
                            <Box>
                              <Text>{list?.productName}</Text>
                              <Text color="red" fontSize={"xs"}>
                                {list?.is_void ? "VOID" : ""}
                              </Text>
                            </Box>
                          </HStack>
                        </Td>
                        <Td>{list?.brand}</Td>
                        <Td>
                          <Moment format="DD-MM-YYYY">
                            {list?.invoiceDate}
                          </Moment>
                        </Td>
                        <Td>{list?.quantity}</Td>
                        <Td>
                          {list?.currency} {list?.totalAmount}
                        </Td>
                        <Td>
                          {list?.complimentry ? (
                            <AiOutlineCheckCircle color="green" size="1.2rem" />
                          ) : (
                            <RxCrossCircled color="red" size="1.2rem" />
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
                {mutatedData?.length === 0 && (
                  <Tr cursor={"pointer"}>
                    <Td></Td>
                    <Td></Td>
                    <Td>
                      <NoDataMsg msg="No Data Found" pos="relative" />
                    </Td>
                    <Td></Td>
                  </Tr>
                )}
              </Table>
              <PaginationButton
                isPrevious={data?.previous}
                isNext={data?.next}
                next={next}
                setNext={setNext}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <HStack style={globalStyles.customStyle.customSearchNav}>
        <Box style={globalStyles.customStyle.customSearchNav_sec1}>
          <SearchUI label={"Search Group"} />
        </Box>
        <Box
          style={globalStyles.customStyle.customSearchNav_sec2}
          pt="1rem !important"
        >
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="unstyled"
              fontWeight={"normal"}
              color={globalStyles.colors.orange[500]}
            >
              {brandSelected ? brandSelected.label : "Sort by Brand Name"}
            </MenuButton>
            <MenuList>
              {brandList?.map((list) => {
                return (
                  <MenuItem onClick={() => brandSelectionHandler(list)}>
                    {list?.label}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={() =>
                  brandSelectionHandler({ value: "All", label: "All" })
                }
              >
                All
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box
          style={globalStyles.customStyle.customSearchNav_sec3}
          color="orange.500"
          // onClick={logoutHandler}
        >
          {/* <SortIcon /> */}
          Logout
        </Box>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Invoice invoiceData={invoiceData} ref={componentRef} />
          </ModalBody>

          <ModalFooter mt="2rem">
            <Flex gap="1.5rem">
              <Button
                colorScheme="green"
                minW="6rem"
                shadow="lg"
                onClick={handlePrint}
              >
                Print
              </Button>
              {!invoiceData?.is_void && (
                <Button
                  colorScheme="red"
                  minW="6rem"
                  onClick={() => voidHandler(invoiceData?.uuid)}
                  fontSize="sm"
                >
                  VOID
                </Button>
              )}
              <Button minW="6rem" shadow="base" onClick={onClose}>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {alertIsOpen && (
        <AlertModal
          onClick={alertHandler}
          isOpen={alertIsOpen}
          onClose={alertOnClose}
          alertTitle={"Void Invoice"}
          alertMsg={"Are you sure you want to void this Invoice?"}
        />
      )}
    </Box>
  );
};
