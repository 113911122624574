import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  bodyText,
} from "@chakra-ui/react";
export default function CancelModal({
  isOpen,
  onOpen,
  onClose,
  cancelOrder,
  bodyText,
}) {
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            mt="2rem"
            fontSize={"20px"}
            textAlign={"center"}
            fontWeight="bold"
          >
            {bodyText}
          </ModalBody>

          <ModalFooter gap="1.5rem" mt="1rem" justifyContent={"center"}>
            <Button colorScheme="red" w="6rem" shadow="base" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="orange"
              w="6rem"
              shadow="base"
              mr={3}
              onClick={() => {
                onClose();
                cancelOrder();
              }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
