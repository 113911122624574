import React, { useEffect, useRef, useState } from "react";
import {
    Box,
	Flex,
	Grid,
	GridItem,
	HStack,
	Switch,
	Td,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import useEditTable from "./useEditTable";
import ImageIcon from "components/Common/ImageIcon";
import Edit from "../../../../../assets/img/edit.png"

export default function EditTable({ data, index, refetch }) {

	const {
		isOpen,
		onOpen,
		onClose,
		isLoading,
		isError,
		error,
		isSuccess,
		register,
		handleSubmit,
		uploadPhotoFile,
		SetUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		onFinalSubmit,
	} = useEditTable(data);

	useEffect(() => {
		if (isSuccess) {
			onClose();
			refetch();
		}
	}, [isSuccess]);

	return (
        <>
            <Td width={'10rem'}>
				<Box onClick={onOpen}>
					<ImageIcon src={Edit} height="1rem" width="1rem"/>
				</Box>
			</Td>
            <Modal key={index} isOpen={isOpen} onClose={onClose} size={"lg"}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Edit Table</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <form onSubmit={handleSubmit(onFinalSubmit)}>
                                <input
                                    type={"hidden"}
                                    {...register("uuid")}
                                    value={data?.uuid}
                                />
                                <input
                                    type={"hidden"}
                                    {...register("floor")}
                                    value={data?.floor}
                                />
                                <Grid templateColumns={"1fr 1fr"}>
                                    <GridItem>
                                        <LeftSideLabelInput
                                            defaultValue={data?.name}
                                            label="Table name"
                                            type="text"
                                            register={register}
                                            registerName={"name"}
                                        />
                                        <LeftSideLabelInput
                                            label="Sort No."
                                            type="text"
                                            defaultValue={data?.table_number}
                                            register={register}
                                            registerName={"table_number"}
                                        />
                                        <HStack mt={'1rem'}>
                                            <Text fontSize={'sm'}>Make Unavaiable</Text>
                                            <Switch defaultChecked={data?.unavailable} {...register("unavailable")} />
                                        </HStack>
                                        <Flex gap="2rem" mt="3rem" p="1rem">
                                            <SaveButton />
                                            {/* <CancelButton onClick={onClose} /> */}
                                        </Flex>
                                    </GridItem>
                                </Grid>
                            </form>
                        </ModalBody>
                    </ModalContent>
            </Modal>
        </>
		
	);
}
