import {
  Box,
  useDisclosure,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import OrderItem from "./OrderItem";
import OrderPayment from "./OrderPayment";
import { useLocation, useParams } from "react-router-dom";
import EditPayment from "./EditPayment";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import TabPayment from "./TabPayment";
import { CloseIcon } from "@chakra-ui/icons";
import { useNotify } from "common hooks/useNotify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Order() {
  const location = useLocation();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableInfo, setTableInfo] = useState([]);
  const param = useParams();
  const { successNotify } = useNotify();
  const history = useHistory();

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

  const fetchData = async () => {
    if (param.uuid) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?invoice_number=${param.uuid}`
      );
      setTableInfo(response?.data[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cancelOrder = async () => {
    const response = await authorizationAxios.post(
      `${BASE_URL}/api/order/cancel_order/?uuid=${tableInfo?.uuid}`
    );
    if (response?.status === 200) {
      successNotify("Order canceled successfully");
      localStorage.removeItem("customer_name");
      localStorage.removeItem("customer_number");
      localStorage.removeItem("customer_uuid");

      const reloadTimeout = setTimeout(() => {
        history.push("/admin/table-list");
      }, 2000);

      return () => clearTimeout(reloadTimeout);
    }
  };
  return (
    <Box>
      <TopSearch />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexWrap={breakPoint[0] ? "wrap" : ""}
      >
        <Box width={"100%"}>
          <OrderItem
            isCancelOpen={isCancelOpen}
            onCancelOpen={onCancelOpen}
            onCancelClose={onCancelClose}
            cancelOrder={cancelOrder}
            menuData={param.uuid}
            isMenuModalOpen={isMenuModalOpen}
            setIsMenuModalOpen={setIsMenuModalOpen}
          />
        </Box>

        <Box
          padding={breakPoint[0] ? "1.5rem" : ""}
          width={breakPoint[0] ? "100%" : ""}
          margin={breakPoint[0] ? "auto" : ""}
        >
          <OrderPayment
            isCancelOpen={isCancelOpen}
            onCancelOpen={onCancelOpen}
            onCancelClose={onCancelClose}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            menuData={param.uuid}
          />
        </Box>
      </Box>

      {/* modal */}
      <EditPayment
        tableInfo={tableInfo}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        menuData={param.uuid}
      />
    </Box>
  );
}
