import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const peakSlice = createSlice({
  name: "productListSlice",
  initialState: {},

  reducers: {
    peak: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.specificPeak = payload;
    },
  },
});

export const peakReducer = peakSlice.reducer;
export const { peak } = peakSlice.actions;
