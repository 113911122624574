import { useEffect, useRef, useState } from 'react'
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { useEditChangepasswordApi } from './useEditChangepasswordApi';

export default function useEditChangepassword() {
	const toast = useToast();

	const toastIdRef = useRef();
	const { register, handleSubmit, reset } = useForm()

	const { editChangepasswordMutation } = useEditChangepasswordApi()
	const { isLoading, isError, error, isSuccess } = editChangepasswordMutation;

	function onFinalSubmit(data) {
		editChangepasswordMutation.mutate(data)
	}

	useEffect(() => {
		if (isSuccess) {
			toastIdRef.current = toast({
				title: "Password Updated!",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
            reset({});

		}

		if (isLoading) {
			toastIdRef.current = toast({
				title: "loading.....",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		}
		if (isError) {
			toast({
				title: "Password didn't match!",
				position: "top-right",
				// description: "You will shortly contact you.",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [
		editChangepasswordMutation.isSuccess,
		editChangepasswordMutation.isLoading,
		editChangepasswordMutation.isError,
	]);

	return {
		isLoading,
		isError,
		error,
		isSuccess,
		register,
		handleSubmit,
		onFinalSubmit
	}
}
