import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetReportApi = (startdate, enddate, next) => {
  async function getReport() {
    if (startdate && enddate) {
      const res = await authorizationAxios.get(
        `${BASE_URL}/api/report/?start_date=${startdate}&end_date=${enddate}&next=${next}`
      );
      return res.data;
    } else {
      return "Select Parameters";
    }
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getReport", startdate, enddate, next],
    () => getReport(startdate, enddate, next)
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
