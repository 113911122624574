import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { globalStyles } from "theme/styles";
import { NoDataMsg } from "components/Common/NoDataMsg";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { Loading } from "components/Common/Loading";
import PaginationButton from "components/Common/PaginationButton copy";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { useAddResolve } from "../api/useAddResolve";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useGetEmployeeReportApi } from "../api/useGetEmployeeReport";

const EmployeeReport = () => {
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "All",
  }); // Set the default selected option to 'all'
  const history = useHistory();
  const [resolveOpen, setResolveOpen] = useState(false);
  const [id, setId] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const {
    handleSubmit: handleResolveSubmit,
    onSubmit: onResolveSubmit,
    register,
    control,
    data: resolveData,
    isSuccess,
  } = useAddResolve();
  const [next, setNext] = useState(1);
  const fetchData = async () => {
    const response = await authorizationAxios.get(
      `${BASE_URL}/api/employee-list/`
    );
    console.log("oo", response);
    setEmployeeList(response?.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const payStatus = [
    { value: "true", label: "Paid", code: "Paid" },
    { value: "false", label: "Unpaid", code: "Unpaid" },
  ];
  const resolveType = [
    { value: "pay later", label: "Pay Later", code: "pay later" },
    { value: "to owner", label: "To owner", code: "to owner" },
  ];

  const handleResolveOpen = (val) => {
    setResolveOpen(true);
    setCustomerNumber(val.customer.customer_number);

    setId(val.uuid);
  };
  const { data, refetch, isLoading } = useGetEmployeeReportApi(
    selectedOption.value,
    document.getElementById("startdate")?.value,
    document.getElementById("enddate")?.value,
    next
  );
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  console.log("kk", isLoading);
  return (
    <Box bg="white" minH="100vh">
      <Modal isOpen={resolveOpen} onClose={() => setResolveOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resolve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleResolveSubmit(onResolveSubmit)}>
              <input type={"hidden"} value={id} {...register("uuid")} />

              <LeftSideLabelSelect
                ml="-2rem"
                label="Resolve Type"
                data={resolveType}
                register={register}
                registerName={"resolve_type"}
                control={control}
              />

              <LeftSideLabelInput
                label="Customer"
                type="text"
                register={register}
                defaultValue={customerNumber}
                registerName={"customer"}
              />
              <LeftSideLabelTextArea
                w="15.5rem"
                label="Remarks"
                type="text"
                register={register}
                registerName={"remarks"}
              />
              <Flex gap="1rem" mt="3rem">
                <SaveButton />
                <CancelButton onClose={() => setResolveOpen(false)} />
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        display={"flex"}
        p={"1rem 0.5rem"}
        gap={breakPoint[0] ? "1rem" : "2rem"}
        borderBottom={"1px solid lightgray"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        {/* <Text fontWeight={"bold"} width={"15%"}>
          CUSTOMER REPORT:
        </Text> */}

        <Box w={"15rem"} ml={breakPoint[0] && "0.5rem"}>
          <Select
            placeholder="Select Employee..."
            options={[
              { value: "", label: "All" },
              ...employeeList?.map((item) => ({
                value: item.uuid,
                label: item.first_name + " " + item.last_name,
              })),
            ]}
            value={selectedOption}
            onChange={handleSelectChange}
          />
        </Box>

        <Box display={"flex"} alignItems={"center"}>
          <Text w={breakPoint[0] ? "3rem" : "6rem"} ml={"1rem"}>
            Start Date :{" "}
          </Text>
          <Input
            defaultValue={""}
            width={breakPoint[0] ? "8rem" : "10rem"}
            type="date"
            id="startdate"
          />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Text w={breakPoint[0] ? "3rem" : "6rem"} ml={"1rem"}>
            End Date :{" "}
          </Text>
          <Input
            defaultValue={""}
            width={breakPoint[0] ? "9rem" : "10rem"}
            type="date"
            id="enddate"
          />
        </Box>

        <Button
          width={"5rem"}
          onClick={() => refetch()}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
          mt={breakPoint[0] && "0.25rem"}
        >
          Submit
        </Button>
      </Box>

      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}> EMployee Name</Th>
          <Th style={globalStyles.customStyle.tableHeading}> Total Orders</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Total Sales</Th>
          <Th style={globalStyles.customStyle.tableHeading}>
            {" "}
            Total Canceled{" "}
          </Th>
          <Th style={globalStyles.customStyle.tableHeading}>
            {" "}
            Total Waste Canceled
          </Th>
        </Tr>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Tbody>
              {data?.data?.map((item, index) => (
                <>
                  <Tr
                    cursor={"pointer"}
                    key={index}
                    // onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
                  >
                    <Td>
                      <Text>{item?.first_name + " " + item?.last_name}</Text>
                    </Td>
                    <Td>
                      <Text>{item?.total_orders}</Text>
                    </Td>
                    <Td>
                      <Text>{item?.total_sales}</Text>
                    </Td>
                    <Td>
                      <Text>{item?.total_canceled}</Text>
                    </Td>
                    <Td>
                      <Text>{item?.total_waste_canceled}</Text>
                    </Td>
                  </Tr>
                </>
              ))}
              {data?.data === undefined ||
                (data?.data?.length == 0 && (
                  <Tr cursor={"pointer"}>
                    <Td></Td>
                    <Td></Td>
                    <Td>
                      <NoDataMsg msg="No Data Found" pos="relative" />
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                ))}
            </Tbody>
            <PaginationButton
              isPrevious={data?.previous}
              isNext={data?.next}
              next={next}
              setNext={setNext}
            />
          </>
        )}
      </Table>
    </Box>
  );
};
export default EmployeeReport;

function InvoiceModal({ isOpen, modalData, handleCloseModal, handleVoid }) {
  const componentRef = useRef();
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint data={modalData} ref={componentRef} />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button colorScheme="green">Print Order</Button>}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log("pp", props);
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Order Details</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props.data.invoiceNumber}
      </Text>
      {/* <Text textAlign={"center"} size="lg" mb={"1rem"}>
        Order Date#:{new Date()}
      </Text> */}
      <table>
        <tr
          style={{
            borderBottom: "1px dotted gray",
            textAlign: "left",
          }}
        >
          <th style={{ width: "150px", fontSize: "14px" }}>Descriptions</th>
          <th style={{ width: "50px", fontSize: "14px" }}>Qty</th>
          <th style={{ width: "30px", fontSize: "14px" }}>Price</th>
          <th style={{ width: "100px", fontSize: "14px" }}>Subtotal</th>
        </tr>
        {props?.data?.items?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px", fontSize: "12px" }}>
                {item?.product__name}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.quantity}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {props.data.currency}
                {item?.unitprice}
              </td>

              <td
                style={{
                  width: "100px",
                  fontSize: "12px",
                  marginRight: "10px",
                }}
              >
                {props.data.currency}
                {item?.billAmount}
              </td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Subtotal</td>
          <th style={{ width: "50px", fontSize: "12px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_bill_amount}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.discount_amount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Service charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}{" "}
            {((props.data.total_bill_amount - props.data.discount_amount) *
              props.data.tax) /
              100}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.adjustment_amount ?? 0}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Due Amount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.dueAmount ?? 0}
          </th>
        </tr>
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px", fontSize: "12px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_amount}
          </th>
        </tr>
      </table>
    </div>
  );
});
