import React from 'react'
import { Center, HStack, Box } from '@chakra-ui/react'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io'
import { globalStyles } from 'theme/styles'

export default function PaginationButton(
    {
        isPrevious,
        isNext,
        next,
        setNext
    }
) {
    return (
        <>
            <Center mt="1rem">
                <HStack cursor="pointer">
                    <Box
                        style={{ display: isPrevious ? "block" : "none" }}
                        onClick={() => setNext(next - 1)}
                        transition='transform .2s ease-in-out'
                        color={globalStyles.colors.orange[500]}
                        _active={{
                            transform: 'translateX(-4px) scale(.9) !important',
                            color: globalStyles.colors.orange[600]
                        }}
                        _hover={{
                            transform: 'translateX(-4px)',
                            color: globalStyles.colors.orange[600]
                        }}
                    >
                        <IoIosArrowDropleftCircle fontSize="3rem" />
                    </Box>
                    <Box
                        display={isNext ? "block" : "none"}
                        onClick={() => setNext(next + 1)}
                        transition='transform .2s ease-in-out'
                        color={globalStyles.colors.orange[500]}
                        _active={{
                            transform: 'translateX(4px) scale(.9) !important',
                            color: globalStyles.colors.orange[600]
                        }}
                        _hover={{
                            transform: 'translateX(4px)',
                            color: globalStyles.colors.orange[600]
                        }}
                    >
                        <IoIosArrowDroprightCircle fontSize="3rem" />
                    </Box>
                </HStack>
            </Center>
        </>
    )
}
