import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetEmployee = (next) => {
        async function getEmployee() {
                const res = await authorizationAxios.get(
                        `${BASE_URL}/api/employee-list`
                );

                return res.data;
        }

        const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
                ["getEmployee"],
                () => getEmployee()
        );

        return {
                data,
                refetch,
                isLoading,
        };
};
