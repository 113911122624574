import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetBrandsApi = (searchText, next) => {
  async function getBrands() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/brand-list/v2/?page=${
        next ? next : 1
      }&search=${searchText}`
    );
    return res.data.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getBrands",
    getBrands
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
