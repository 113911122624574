import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetAdjustmentAuditApi = (uuid, next) => {
  async function getAdjustmentAuditApi() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/adjustment/audit/?uuid=${uuid}&page_number=${next}&page_size=${"5"}`
    );
    return res.data;
  }
  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getAdjustmentAuditApi", uuid, next],
    () => getAdjustmentAuditApi(uuid, next)
  );
  // const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
  //   "getAdjustmentAuditApi",
  //   getAdjustmentAuditApi
  // );

  return {
    data,
    refetch,
  };
};
