import React, { useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import { useGetSalesReportApi } from "./useGetSalesReportApi";
import Moment from "react-moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaginationButton from "components/Common/PaginationButton copy";
import { Loading } from "components/Common/Loading";

export default function UnpaidSalesReport() {
  const [next, setNext] = useState(1);

  const { data, refetch, isLoading } = useGetSalesReportApi(next);
  const history = useHistory();
  console.log("lkk", data);
  return (
    <Table variant="striped">
      <Tr>
        <Th style={globalStyles.customStyle.tableHeading}> Order Id</Th>
        <Th style={globalStyles.customStyle.tableHeading}>Order Date</Th>
        <Th style={globalStyles.customStyle.tableHeading}> Order Amount </Th>

        <Th style={globalStyles.customStyle.tableHeading}> Due Amount</Th>
        {/* <Th style={globalStyles.customStyle.tableHeading}> Invoice Amount</Th> */}
      </Tr>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Tbody>
            {data?.data?.map((item, index) => (
              <Tr
                cursor={"pointer"}
                key={index}
                // onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
              >
                <Td>
                  <Text>{item?.invoiceNumber}</Text>
                </Td>
                <Td>
                  <Moment format="DD-MM-YYYY">{item?.invoicedate}</Moment>
                </Td>
                <Td>
                  Rs.
                  {/* {item?.currency} */}
                  {item?.total_amount}
                </Td>

                <Td>Rs.{item?.dueAmount}</Td>
                <Td>
                  <Flex gap="0.5rem">
                    <Button
                      onClick={() =>
                        history.push(`/admin/payment/${item?.uuid}`)
                      }
                      colorScheme="green"
                    >
                      Pay
                    </Button>
                    <Button
                      onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
                    >
                      Details
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}

            {/* {data?.data === undefined && (
          <Tr cursor={"pointer"}>
            <Td></Td>
            <Td></Td>
            <Td>
              <NoDataMsg msg="No Data Found" pos="relative" />
            </Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        )} */}
          </Tbody>
          <PaginationButton
            isPrevious={data?.previous}
            isNext={data?.next}
            next={next}
            setNext={setNext}
          />
        </>
      )}
    </Table>
  );
}
