import React, { useRef } from "react";
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Button,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import BasicDetails from "./Component/BasicDetials/BasicDetails";
import Itinerary from "./Component/Itinerary/Itinerary";
import Gallery from "./Component/Gallery/Gallery";
import { PageTitle } from "components/Common/PageTitle";
import Include from "./Component/Include/Include";
import Additional from "./Component/Additional/Additional";
import SuccessRate from "./Component/SuccessRate/SuccessRate";
import { useState } from "react";
import { useAddExpedition } from "./useAddExpedition";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useFieldArray } from "react-hook-form";

const AddExpedition = () => {
  const formRef = useRef(null);

  // const formRef = useRef(null)
  // const formRef1 = useRef(null)
  const {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile,
    newInclude,
    setNewInclude,
    newItinerary,
    setNewItinerary,
  } = useAddExpedition();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "itinerary",
  });

  const successRate = useFieldArray({
    control,
    name: "success",
  });

  return (
    <Box bg={"white"} minH="100vh">
      <PageTitle title={"Add Expedition"} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs overflow={"hidden"}>
            <TabList
              id="scroll"
              variant="unstyled"
              gap={{ sm: "1rem", md: "1rem" }}
              overflowX={{ sm: "scroll", xl: "visible" }}
              overflowY="hidden"
              scrollBehavior="smooth"
              // onClick={tabHandler}
              p="1rem"
              bg={"#F8F8F8"}
              borderTop="2px solid #EAEAEA"
              borderBottom="2px solid #EAEAEA"
            >
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Basic Details
              </Tab>

              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Itinerary
              </Tab>
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Gallery
              </Tab>
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Include/Exclude
              </Tab>
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Sucess Rate
              </Tab>
              <Tab
                _selected={globalStyles.customStyle.unStyledTab}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
                pr="2rem"
                pl={"2rem"}
                _hover={globalStyles.customStyle.tabHover}
              >
                Additonals
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p="2rem" pt={"0"}>
                <BasicDetails
                  register={register}
                  control={control}
                  uploadPhotoOnClickHandler={uploadPhotoOnClickHandler}
                  uploadPhotoRef={uploadPhotoRef}
                  uploadPhotoChangeHandler={uploadPhotoChangeHandler}
                  uploadPhotoFile={uploadPhotoFile}
                />
              </TabPanel>
              <TabPanel p="2rem">
                <Itinerary
                  register={register}
                  control={control}
                  append={append}
                  fields={fields}
                  remove={remove}
                  newItinerary={newItinerary}
                  setNewItinerary={setNewItinerary}
                />
              </TabPanel>
              <TabPanel p="2rem" pt={"0"}>
                <Gallery register={register} />
              </TabPanel>
              <TabPanel p="2rem" pt={"0"}>
                <Include
                  register={register}
                  control={control}
                  newInclude={newInclude}
                  setNewInclude={setNewInclude}
                />
              </TabPanel>
              <TabPanel p="2rem" pt={"0"}>
                <SuccessRate successRate={successRate} register={register} />
              </TabPanel>
              <TabPanel p="2rem" pt={"0"}>
                <Additional />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Flex gap="2rem" mt="3rem">
            <SaveButton />
            <CancelButton />
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default AddExpedition;
