import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Slide,
} from "@chakra-ui/react";

export const AlertModal = ({
	isOpen,
	onClose,
	alertTitle,
	alertMsg,
	onClick,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{alertTitle}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{alertMsg}</ModalBody>

				<ModalFooter gap='1.5rem' mt='1rem'>
					<Button
						colorScheme="orange"
						w='6rem'
						shadow='base'
						onClick={onClick}
					>
						Yes
					</Button>
					<Button
						colorScheme="gray"
						w='6rem'
						shadow='base'
						onClick={onClose}
					>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
