import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import Moment from "react-moment";
import { NoDataMsg } from "components/Common/NoDataMsg";
import { useGetReportApi } from "../api/useGetReportApi";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";
import axios from "axios";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useGetBrandList } from "common apis/useGetBrandList";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { PageText } from "components/Common/PageText";
import ReactToPrint from "react-to-print";
import { Loading } from "components/Common/Loading";
import PaginationButton from "components/Common/PaginationButton copy";

const Report = () => {
  const { successNotify } = useNotify();
  const [next, setNext] = useState(1);
  const breakPoint = useMediaQuery("(max-width: 1200px)");

  const { data: getBrandList } = useGetBrandList();
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { data, refetch, isLoading } = useGetReportApi(
    document.getElementById("startdate")?.value,
    document.getElementById("enddate")?.value,
    next
  );

  const handleClick = async () => {
    const response = await authorizationAxios.get(
      `${BASE_URL}/api/report/?start_date=${
        document.getElementById("startdate")?.value
      }&end_date=${document.getElementById("enddate")?.value}
      
      }&export=true`
    );
    if (response?.status === 200) {
      successNotify(response?.data?.message);
    }
    console.log(response);
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleOpenModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
    setModalData(null);
  };

  return (
    <Box bg="white" minH="100vh">
      <InvoiceModal
        data={data}
        isOpen={isOpen}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        modalData={modalData}
      />
      <Box
        display={"flex"}
        p={"1rem"}
        borderBottom={"1px solid lightgray"}
        alignItems={"center"}
      >
        {/* {localStorage.getItem("company_type") == "Restaurant" && (
          <Text width={breakPoint[0] ? "12%" : "85%"}>SALES REPORT</Text>
        )} */}

        {localStorage.getItem("company_type") === "Retailer" && (
          <Box display={"flex"} alignItems={"center"}>
            <Text w={"8rem"}>Select Brand : </Text>
            <Box w={"12rem"}>
              <Select
                options={getBrandList?.map((item) => ({
                  label: item?.label,
                  value: item?.value,
                }))}
                value={selectedOption}
                onChange={handleSelectChange}
              />
            </Box>
          </Box>
        )}
        <Box display={"flex"} alignItems={"center"}>
          <Text w={"6rem"} ml={"1rem"}>
            Start Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="startdate" />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Text w={"5rem"} ml={"1rem"}>
            End Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="enddate" />
        </Box>
        <Button
          width={"5rem"}
          onClick={() => refetch()}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Submit
        </Button>

        {/* <Button
          width={"5rem"}
          onClick={handleClick}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Export
        </Button> */}
      </Box>

      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}>Order #</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Table #</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Guest</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Order Date</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Order Amount</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Payment Amount</Th>
        </Tr>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Tbody>
              {data?.data?.map((item, index) => (
                <Tr
                  cursor={"pointer"}
                  key={index}
                  onClick={() => handleOpenModal(item)}
                >
                  <Td>
                    <Text>{item?.invoiceNumber}</Text>
                  </Td>
                  <Td>{item?.table}</Td>
                  <Td>{item?.guest}</Td>
                  <Td>
                    <Moment format="DD-MM-YYYY">{item?.invoicedate}</Moment>
                  </Td>
                  <Td>
                    {item?.currency} {item?.total_amount}
                  </Td>
                  <Td>
                    {item?.payment_amount ? (
                      <>
                        {item?.currency}
                        {item?.payment_amount}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </Td>
                </Tr>
              ))}
              {data?.data === undefined && (
                <Tr cursor={"pointer"}>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <NoDataMsg msg="No Data Found" pos="relative" />
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
            <PaginationButton
              isPrevious={data?.previous}
              isNext={data?.next}
              next={next}
              setNext={setNext}
            />
          </>
        )}
      </Table>
    </Box>
  );
};
export default Report;

function InvoiceModal({ isOpen, modalData, handleCloseModal, handleVoid }) {
  const componentRef = useRef();
  console.log("xx", modalData);
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint data={modalData} ref={componentRef} />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button colorScheme="green">Print Order</Button>}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log("pp", props);
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Order Details</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props.data.invoiceNumber}
      </Text>
      {/* <Text textAlign={"center"} size="lg" mb={"1rem"}>
        Order Date#:{new Date()}
      </Text> */}
      <table>
        <tr
          style={{
            borderBottom: "1px dotted gray",
            textAlign: "left",
          }}
        >
          <th style={{ width: "150px", fontSize: "14px" }}>Descriptions</th>
          <th style={{ width: "50px", fontSize: "14px" }}>Qty</th>
          <th style={{ width: "30px", fontSize: "14px" }}>Price</th>
          <th style={{ width: "100px", fontSize: "14px" }}>Subtotal</th>
        </tr>
        {props?.data?.items?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px", fontSize: "12px" }}>
                {item?.product__name}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.quantity}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {props.data.currency}
                {item?.unitprice}
              </td>

              <td
                style={{
                  width: "100px",
                  fontSize: "12px",
                  marginRight: "10px",
                }}
              >
                {props.data.currency}
                {item?.billAmount}
              </td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Subtotal</td>
          <th style={{ width: "50px", fontSize: "12px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_bill_amount}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.discount_amount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Service charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}{" "}
            {((props.data.total_bill_amount - props.data.discount_amount) *
              props.data.tax) /
              100}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.adjustment_amount ?? 0}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Due Amount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.dueAmount ?? 0}
          </th>
        </tr>
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px", fontSize: "12px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_amount}
          </th>
        </tr>
      </table>
    </div>
  );
});
