import React, { useState } from "react";
import { useGetGalleryCategory } from "../../../api/useGetGalleryCategory";

const useGallery = () => {
  const { data } = useGetGalleryCategory();
  // document.getElementById("image1")?.addEventListener("click", myFunction);
  // function myFunction() {
  //   console.log("you clicked me");
  // }

  const [selectedImageForPreview, setSelectedImageForPreview] = useState();

  return { data, setSelectedImageForPreview, selectedImageForPreview };
};

export default useGallery;
