import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetInventoryDetailApi = (id) => {
  async function getInventoryDetailApi() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/group/?uuid=${id}`
    );
    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getInventoryDetailApi",
    getInventoryDetailApi
  );

  return {
    data,
  };
};
