import { Text } from "@chakra-ui/react";
import React from "react";

export const PageText = ({ text, style, mt }) => {
  return (
    <Text fontSize={"sm"} color="blackAlpha.900" style={style} mt={mt}>
      {text}
    </Text>
  );
};
