import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import SelectItem from './SelectItem'
import { MdAddCircle } from 'react-icons/md'
import { globalStyles } from 'theme/styles'
import { useFieldArray } from 'react-hook-form'
import { usePostAdjustment } from '../usePostAdjustment'
import { LeftSideLabelInput } from 'components/Common/LeftSideLabelInput'
import { SaveButton } from 'components/Common/SaveButton'
import { LeftSideLabelSelect } from 'components/Common/LeftSideLabelSelect'

export default function ItemDetailListAdjustment() {

    // const [itemCount, setItemCount] = useState(1)
    const {
        handleSubmit,
        register,
        onSubmit,
        setValue,
        getValues,
        watch,
        unregister,
        control,
        isSuccess
    } = usePostAdjustment();

    const title = ['ITEMS DETAIL', 'QUANTITY AVAILABLE', 'NEW QUANTITY ON HAND', 'QUANTITY ADJUSTED', '']
    const reason =[{label:'Restock',value:'Restock'},
    {label:'Damage',value:'Damage'},
    {label:'Waste',value:'Waste'},
    {label:'Adjusted to Match Stock',value:'Adjusted to Match Stock'},
    {label:'Give Away',value:'Give Away'},
    {label:'Other',value:'Other'}]

    const { fields, remove, append } = useFieldArray({
        control,
        name: "items",
    });

    useEffect(() => {
        if (isSuccess) {
            remove();
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [isSuccess])

    return (
        <Box >
            <form onSubmit={handleSubmit(onSubmit)} >
                <LeftSideLabelInput label='Reference*' register={register} registerName={'reference_number'} />
                <LeftSideLabelInput label='Date*' type='date' required='true' register={register} registerName={'date'} />
                <LeftSideLabelSelect label='Reason*' register={register} control={control} data={reason} registerName={'reason'} ml="-0.7rem" />

                <Grid templateColumns='repeat(4, 1fr) 4rem' mt='3rem'>

                    {/* FOR TITLES */}
                    {title.map((item, index) => {
                        return (
                            <GridItem key={index} border='1px solid #D3D3D3'>
                                <Flex
                                    justify={index ? 'end' : ''}
                                    mx='1rem'
                                    my='.5rem'
                                >
                                    <Text style={globalStyles.customStyle.tableHeading}>{item}</Text>
                                </Flex>
                            </GridItem>
                        )
                    })}

                    {/* FOR DATA */}
                    {
                        fields?.map((item, index) => (
                            <SelectItem index={index} register={register} getValues={getValues} setValue={setValue} remove={remove} />
                        ))
                    }

                </Grid>

                <Text
                    color={globalStyles.customStyle.fontColor.color}
                    cursor="pointer"
                    mt="1.5rem"
                    onClick={() => append({ name: '', stock: 0 })}
                  >
                    + Add Another
                  </Text>

                <Box mt='1.5rem'>
                    <SaveButton />
                </Box>

            </form>

        </Box>
    )
}
