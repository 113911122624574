import { Box,Button,Grid, GridItem, Center, Image } from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { PageTitle } from "components/Common/PageTitle";
import { useAddGalleryCategory } from "../useAddGalleryCategory";

export default function AddCategoryModel({onClose}){
    
    const { handleSubmit, 
        register, 
        toast, 
        onSubmit,
        isSuccess,
        uploadPhotoRef,
        uploadPhotoOnClickHandler,
        uploadPhotoChangeHandler,
        uploadPhotoFile } = useAddGalleryCategory();

    return(
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PageTitle title="Add Category" />
                <Box pl={'2rem'}>
                    <Grid templateColumns='repeat(2, 1fr)' gap={'2rem'}>
                        <GridItem>
                            <LeftSideLabelInput label="Title" register={register} registerName={'name'}/>
                            <LeftSideLabelInput label="Description" register={register} registerName={'description'}/>
                        </GridItem>
                        <GridItem>
                            <Center>
                                <Box onClick={uploadPhotoOnClickHandler} cursor="pointer">
                                    <input
                                        type="file"
                                        hidden
                                        ref={uploadPhotoRef}
                                        onChange={uploadPhotoChangeHandler}
                                    />
                                    <Center
                                    w="12rem"
                                    h="8rem"
                                    borderTop={"1px dashed #808080"}
                                    borderLeft={"1px dashed #808080"}
                                    borderRight={"1px dashed #808080"}
                                    borderTopRadius="8px"
                                    >
                                    <Image
                                        src={
                                        uploadPhotoFile.length !== 0
                                            ? URL.createObjectURL(uploadPhotoFile[0])
                                            : ''
                                        }
                                        w="13rem"
                                        h="7.8rem"
                                        borderTopRadius="8px"
                                        objectFit={"cover"}
                                    />
                                    </Center>
                                    <Center
                                    w="12rem"
                                    h="2rem"
                                    border={"1px solid #808080"}
                                    borderBottomRadius="8px"
                                    >
                                    Upload Photo
                                    </Center>
                                </Box>
                            </Center>
                        </GridItem>
                    </Grid>
                </Box>

                <Box p={"2rem"} justifySelf="center">
                    
                    <Button type="submit" color="white" bg={"orange.500"} w="8rem">
                        Add
                    </Button>
                    <Button onClick={onClose} ml="1rem" w={"8rem"}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </>
    );
}