import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { usePostBrandApi } from "../api/usePostBrandApi";
import { useHistory } from "react-router-dom";

export const useAddBrand = () => {
	let history = useHistory();
	const toast = useToast();

	const [uploadPhotoFile, setUploadPhotoFile] = useState([]);
	const toastIdRef = useRef();
	const uploadPhotoRef = useRef(null);
	const { register, handleSubmit, control, reset } = useForm();

	const { postPostBrandMutation } = usePostBrandApi();
	const { isLoading, isError, data, error, isSuccess } = postPostBrandMutation;

	const onSubmit = (values) => {
		const formData = new FormData();

		formData.append("name", values.name);
		formData.append("color_code", values.color_code);
		formData.append("slogan", values.slogan);

		formData.append(
			"logo",
			uploadPhotoFile.length !== 0
				? uploadPhotoFile[0]
				: ''
		);

		postPostBrandMutation.mutate(formData);
	};

	function uploadPhotoOnClickHandler() {
		uploadPhotoRef.current.click();
	}

	function uploadPhotoChangeHandler(event) {
		setUploadPhotoFile(Array.from(event.target.files));
	}

	useEffect(() => {
		if (isSuccess) {
			toastIdRef.current = toast({
				title: "New Brand Added!",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});

			history.push("/admin/setting");
		}

		if (isLoading) {
			toastIdRef.current = toast({
				title: "loading.....",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		}
		if (isError) {
			toast({
				title: "Missing Information",
				position: "top-right",
				// description: "You will shortly contact you.",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [
		postPostBrandMutation.isSuccess,
		postPostBrandMutation.isLoading,
		postPostBrandMutation.isError,
	]);

	return {
		handleSubmit,
		register,
		control,
		reset,
		toast,
		onSubmit,
		isSuccess,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		setUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoFile
	};
};
