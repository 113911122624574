import React, { useEffect, useState } from "react";
import { useGetBrandList } from "views/Pages/Inventory/api/useGetBrandList";
import { useGetSalesList } from "../api/useGetSalesList";

export const useSalesReport = () => {
  const [brandSelected, setBrandSelected] = useState(null);
  const [next, setNext] = useState(1);
  const { data, refetch, isLoading, remove } = useGetSalesList(
    brandSelected ? brandSelected?.value : "",
    next
  );
  console.log("Xx", data);
  // mutatedData state is used for storing the calculated "Invoice amount" , which is calculated using dicount, tax amount and adjustment.
  const [mutatedData, setMutatedData] = useState();
  useEffect(() => {
    setMutatedData(
      data?.data?.map((item) => {
        let totalTemp = 0;
        let disountTotalTemp = (
          (item?.discount / 100) *
          item?.unitprice *
          item?.quantity
        ).toFixed(2);
        let taxTotalTemp = (
          (item?.tax / 100) *
          (item?.unitprice * item?.quantity - disountTotalTemp)
        ).toFixed(2);

        totalTemp = (
          item?.unitprice * item?.quantity +
          +taxTotalTemp -
          +disountTotalTemp +
          item?.adjustment_amount
        ).toFixed(2);
        // setTotal(totalTemp);

        item.totalAmount = totalTemp;

        return item;
      })
    );
  }, [data]);
  const { data: brandList } = useGetBrandList();

  useEffect(() => {
    remove();
    refetch();
  }, [next]);

  useEffect(() => {
    if (brandSelected) {
      refetch();
      setNext(1);
    }
  }, [brandSelected]);

  function brandSelectionHandler(brand) {
    setBrandSelected(brand);
  }
  return {
    data,
    mutatedData,
    brandList,
    brandSelectionHandler,
    brandSelected,
    next,
    setNext,
    isLoading,
    refetch,
  };
};
