import React from 'react'
import {
    Flex,
    Text,
    VStack
} from '@chakra-ui/react'
import { LeftSideLabelText } from 'components/Common/LeftSideLabelText'


export default function Highlight({ unit, brand, manufacturer, category }) {
    // console.log('unit testing' , unit)
    return (
        <>
            <LeftSideLabelText mt="--0.5rem" label="Unit" value={'pcs'} />
            {brand && <LeftSideLabelText mt="--0.5rem" label="Brand" value={brand} />}
            {manufacturer && <LeftSideLabelText mt="--0.5rem" label="Manufacturer" value={manufacturer} />}
            {category && <LeftSideLabelText mt="--0.5rem" label="Category" value={category} />}
        </>
    )
}
