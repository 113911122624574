import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";
import React from "react";

export const useGetFloor = (searchText) => {
  async function getFloor() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/floor-table/`);
    return res.data?.map(({ uuid: value, name: label, ...rest }) => ({
      value,
      label,
      ...rest,
    }));
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getFloor",
    getFloor
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
