import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React from "react";
import { globalStyles } from "theme/styles";
import OverView from "./Component/OverView/OverView";

const AllBooking = () => {
  return (
    <Box bg={"white"} minH="100vh">
      <Box pt={" 1rem"}>
        <Tabs>
          <TabList>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
              fontWeight="bold"
            >
              Overview
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Flights
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Hotels
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Travel Plan
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Package and Costing
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Guides
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Special Request
            </Tab>
            <Tab
              style={globalStyles.customStyle.tab}
              _selected={globalStyles.customStyle.selectedTab}
              _focus={"none"}
            >
              Equipment
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <OverView />
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default AllBooking;
