import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditBrandApi = (brand) => {

    async function editBrand(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/brands/${brand}/`,
            values
        );
        return res;
    }

    const editBrandMutation = useMutation(editBrand);

    return {
        editBrandMutation,
    };
};
