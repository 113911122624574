import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

export const LeftSideLabelText = ({ label, type, onChange, value,mt }) => {
  return (
    <Flex gap="1.2rem" alignItems="center" mt={mt?mt:"1.5rem"} mb={'0rem'} >
      <Box w="8rem" fontSize={"sm"}>
        {label}
      </Box>
      <Box>
        <Text
          fontSize="sm"
        >
          : {value}
        </Text>
      </Box>
    </Flex>
  );
};
