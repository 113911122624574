import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

export const RightSideLabelText = ({ label, value }) => {
    return (
        <Flex justify='end'>
            <Box fontSize="sm"> {label} :</Box>
            <Box minW='10rem' >
                <Text fontSize="sm" textAlign='right'>  {value} </Text>
            </Box>
        </Flex>
    );
};
