import {
  Text,
  Box,
  Flex,
  Button,
  InputLeftElement,
  InputGroup,
  Input,
  HStack,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { globalStyles } from "theme/styles";
import { IoCalendar } from "react-icons/io5";
import { Profile as SidebarProfile } from "components/Common/Profile";

import { useParams } from "react-router-dom";
import {
  ChevronDownIcon,
  EditIcon,
  HamburgerIcon,
  Search2Icon,
} from "@chakra-ui/icons";
import routes from "routes.js";
import Sidebar from "components/Sidebar/Sidebar";
import { CgClose } from "react-icons/cg";
import AddUI from "components/Common/AddUI";
import { PageTitle } from "components/Common/PageTitle";
import UnpaidSalesReport from "views/Pages/Report/List/UnpaidSalesReport";
import Report from "views/Pages/Report/List/Report";
import UserReport from "views/Pages/Report/List/UserReport";
import KitchenReport from "views/Pages/Report/List/KitchenReport";

export default function TopSearch({
  submitValues,
  searchQuery,
  setSearchQuery,
  add,
  onOpen,
  activePage,
  text,
  reportDrop,
  setActivePage,
  settingDrop,
}) {
  const [urlValue, setUrlValue] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const params = useParams();

  useEffect(() => {
    const link = window.location.href.split("/");
    setUrlValue(link?.[link.length - 1]);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    // Cleanup function
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [open, setOpen] = useState(false);
  const breakPoint = useMediaQuery("(max-width: 1200px)");

  return (
    <>
      {open && (
        <Box cursor={"pointer"} position={"absolute"} bg={"white"} zIndex={100}>
          <Sidebar
            routes={routes}
            logo={
              <>
                <CgClose size={"1.5rem"} color="#38a169" />
              </>
            }
            display="none"
            setOpen={setOpen}
          />
        </Box>
      )}
      <Flex
        bg={"white"}
        alignItems={"center"}
        pr={"3rem"}
        height={"75px"}
        borderBottom={"1px solid  #ECEDED"}
        justifyContent={"space-between"}
        position={"fixed"}
        width={"100vw"}
        zIndex={100}
        paddingRight={breakPoint[0] ? "2rem" : "6rem"}
        paddingLeft={"1rem"}
      >
        {/* {urlValue === "profile" ? <SidebarProfile /> : <Box></Box>} */}
        <HStack>
          {breakPoint[0] && (
            <HamburgerIcon
              color={"#3AB689"}
              height={"2rem"}
              width={"2rem"}
              cursor={"pointer"}
              onClick={() => setOpen(!open)}
            />
          )}
          <SidebarProfile />
        </HStack>
        {text && activePage === "Unpaidsales" && !breakPoint[0] && (
          <PageTitle title="Unpaid Sales Report" />
        )}
        {text && activePage === "Invoice" && !breakPoint[0] && (
          <PageTitle title="Invoice Report" />
        )}
        {text && activePage === "userReport" && !breakPoint[0] && (
          <PageTitle title="Customer Report" />
        )}
        {text && activePage === "KitchenReport" && !breakPoint[0] && (
          <PageTitle title="KItchen Report" />
        )}
        {text && activePage === "EmployeeReport" && !breakPoint[0] && (
          <PageTitle title="Employee Report" />
        )}

        {setSearchQuery && (
          <Box>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Search2Icon color="gray.300" />}
              />
              <Input
                backGround="#F0F0F0"
                searchWidth="300px"
                borderRadius="10px"
                placeholder={"search any item..."}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
              />
            </InputGroup>
          </Box>
        )}
        {reportDrop && breakPoint[0] && (
          <Box ml="24rem">
            <Menu>
              <MenuButton
                backgroundColor={"#3ab689"}
                borderWidth="1px"
                borderRadius="8px"
                borderColor="transparent"
                color={"#fff"}
                padding="0.5rem 1rem"
                rightIcon={<ChevronDownIcon />}
              >
                Select Report
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setActivePage("Unpaidsales")}>
                  {" "}
                  Unpaid Sales
                </MenuItem>
                <MenuItem onClick={() => setActivePage("Invoice")}>
                  {" "}
                  Invoice Report
                </MenuItem>
                <MenuItem onClick={() => setActivePage("userReport")}>
                  {" "}
                  Customer Report
                </MenuItem>
                <MenuItem onClick={() => setActivePage("KitchenReport")}>
                  {" "}
                  Kitchen Report
                </MenuItem>
                <MenuItem onClick={() => setActivePage("EmployeeReport")}>
                  {" "}
                  Employee Report
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        {settingDrop && breakPoint[0] && (
          <Box ml="15rem">
            <Menu>
              <MenuButton
                backgroundColor={"#3ab689"}
                borderWidth="1px"
                borderRadius="8px"
                borderColor="transparent"
                color={"#fff"}
                padding="0.5rem 1rem"
                rightIcon={<ChevronDownIcon />}
              >
                Menu
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setActivePage("Category")}>
                  {" "}
                  Group Category
                </MenuItem>
                {localStorage.getItem("company_type") === "Retailer" && (
                  <MenuItem onClick={() => setActivePage("Category")}>
                    {" "}
                    Manufacturer
                  </MenuItem>
                )}
                {localStorage.getItem("company_type") === "Restaurant" && (
                  <MenuItem onClick={() => setActivePage("Floor")}>
                    {" "}
                    Floor&Table
                  </MenuItem>
                )}

                <MenuItem onClick={() => setActivePage("Security")}>
                  {" "}
                  Security
                </MenuItem>
                <MenuItem onClick={() => setActivePage("Employee")}>
                  {" "}
                  Employee
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}

        {/* <SearchBar backGround="#F0F0F0" searchWidth="300px" borderRadius="10px" /> */}
        {add ? (
          <Box onClick={() => onOpen()} cursor={"pointer"}>
            <AddUI label={"Add New " + activePage} />
          </Box>
        ) : (
          <Text
            style={globalStyles.textColor}
            fontSize={"13px"}
            display={"flex"}
            alignItems={"center"}
            gap={"3px"}
          >
            {!breakPoint[0] && (
              <>
                <IoCalendar />
                {currentDateTime.toLocaleString()}
              </>
            )}
            {params.uuid && window.location.href.includes("/order/") ? (
              <Button
                color="#ffff"
                bg="buttongreen.400"
                ml="10px"
                variant="secondary"
                onClick={submitValues}
              >
                <EditIcon mr={"0.5rem"} />
                UPDATE
              </Button>
            ) : (
              <Box></Box>
            )}
          </Text>
        )}
      </Flex>
    </>
  );
}
