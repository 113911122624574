import { Box,Flex,Grid,GridItem,HStack,Input,Center,Image } from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { PageTitle } from "components/Common/PageTitle";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { COUNTRIES } from "constants/CONSTANTS";

  const AddPackage = () => {
    
    return (
      <Box direction="column">
        <Box h="97vh" bg={"white"}>
          {/* header */}

          <PageTitle title={"Add Package"} />

          <Box p="1rem" pb={"0"}>
            <form>
              <Grid templateColumns={"repeat(2, 1fr)"}>
                <GridItem>
                  <Flex pl="2rem" flexDirection={"column"}>
                    <LeftSideLabelInput type="text" label="Package Name" />
                    <LeftSideLabelTextArea label="Description" />
                    <LeftSideLabelSelect label="Package Action" data={COUNTRIES}/>
                    <LeftSideLabelSelect label="Package Status" data={COUNTRIES}/>
                    
                    <HStack gap="2rem" mt="5rem">
                      <SaveButton /> <CancelButton />{" "}
                    </HStack>
                  </Flex>
                </GridItem>

                <GridItem>
                  <Box cursor="pointer">
                    <Center
                      w="12rem"
                      h="8rem"
                      borderTop={"1px dashed #808080"}
                      borderLeft={"1px dashed #808080"}
                      borderRight={"1px dashed #808080"}
                      borderTopRadius="8px"
                    >
                      <Image
                        src={''}
                        alt={'upload image'}
                        w="13rem"
                        h="7.8rem"
                        borderTopRadius="8px"
                        objectFit={"cover"}
                      />
                    </Center>
                    <Center
                      w="12rem"
                      h="2rem"
                      border={"1px solid #808080"}
                      borderBottomRadius="8px"
                    >
                      Upload Photo
                    </Center>
                  </Box>
                </GridItem>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default AddPackage;
  