import { HomeIcon } from "components/Icons/Icons";
import AddInventory from "views/Pages/Inventory/Add/AddInventory";
import InventoryList from "views/Pages/Inventory/List/InventoryList";
import Sales from "views/Pages/Inventory/Sales/Sales";
import InventoryDetail from "views/Pages/InventoryDetail/InventoryDetail";

// import Sales from "views/Pages/Inventory/Sales/Sales";
import NewAdjustment from "views/Pages/InventoryAdjustment/NewAdjustment";
import AddNewItem from "views/Pages/NewItem/AddNewItem";
import { SalesReport } from "views/Pages/SalesReport/list/SalesReport";
import {
  IoDocument,
  IoFileTray,
  IoHome,
  IoSettings,
  IoFolderOpenSharp,
} from "react-icons/io5";
import { BsGrid } from "react-icons/bs";
import TableList from "views/Pages/TabeList/List/TableList";
import MenuList from "views/Pages/Menu/List/MenuList";
import Order from "views/Pages/Order/List/Order";
import InventoryMenu from "views/Pages/InventoryMenu/InventoryMenu";
import { BiLogOut } from "react-icons/bi";
import Settings from "views/Pages/Settings/Settings";
import Dashboard from "views/Pages/Dashboard/Dashboard";
import RetailerDashboard from "views/Pages/Dashboard/Retailer/RetailerDashboard";
import { Logout } from "views/Pages/Logout/Logout";
import RetaiItem from "views/Pages/Menu/List/Retailer/RetaiItem";
import Reports from "views/Pages/Users/Reports";
import BrandReport from "views/Pages/Reports/BrandReport";
import Report from "views/Pages/Report/List/Report";
import ReportList from "views/Pages/Report/List/ReportList";
const dashRoutes = [
  {
    layout: "/admin",
    path: "/dashboard/",
    icon: <IoHome />,
    component:
      localStorage.getItem("company_type") === "Retailer"
        ? RetailerDashboard
        : Dashboard,
  },
  // {
  //   layout: "/admin",
  //   path: "/retail-item",
  //   icon: <IoHome />,
  //   component: RetaiItem,
  // },

  {
    layout: "/admin",
    path:
      localStorage.getItem("company_type") === "Restaurant"
        ? "/table-list"
        : "/retail-item",
    icon: <IoDocument color="inherit" />,
    component:
      localStorage.getItem("company_type") === "Restaurant"
        ? TableList
        : RetaiItem,
  },

  ...(localStorage.getItem("employee_type") !== "Employee"
    ? [
        {
          layout: "/admin",
          path: "/inventory/bulk-add",
          icon: <BsGrid color="inherit" />,
          component: InventoryMenu,
        },
      ]
    : []),
  ...(localStorage.getItem("employee_type") !== "Employee"
    ? [
        {
          layout: "/admin",
          path: "/reports",
          icon: <IoFolderOpenSharp color="inherit" />,
          component:
            localStorage.getItem("company_type") === "Retailer"
              ? BrandReport
              : ReportList,
        },
      ]
    : []),

  {
    layout: "/admin",
    path: "/setting",
    icon: <IoSettings color="inherit" />,
    component: Settings,
  },

  {
    layout: "/admin",
    path: "/home",
    icon: <BiLogOut color="inherit" />,
    component: Logout,
  },
];

export default dashRoutes;
