import { PageTitle } from 'components/Common/PageTitle'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	Flex,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	Text,

} from "@chakra-ui/react";
import React, { useEffect, useState } from 'react'
import { useAddEmployee } from './add/useAddEmployee';
import { LeftSideLabelInput } from 'components/Common/LeftSideLabelInput';
import { SaveButton } from 'components/Common/SaveButton';
import { CancelButton } from 'components/Common/CancelButton';
import { LeftSideLabelSelect } from 'components/Common/LeftSideLabelSelect';
import { COUNTRIES } from 'constants/CONSTANTS';
import { useGetEmployee } from './api/useGetEmployee';
import { Loading } from 'components/Common/Loading';
import PaginationButton from 'components/Common/PaginationButton copy';
import { globalStyles } from 'theme/styles';
import Moment from 'react-moment';
import EditEmployee from './EditEmployee';

export default function Employee({ isOpen, onOpen, onClose }) {
	const { data, refetch, isLoading } = useGetEmployee();
	const [next, setNext] = useState(1);
	const [employeeList, setEmployeeList] = useState("")
	const {
		handleSubmit,
		register,
		control,
		reset,
		toast,
		onSubmit,
		isSuccess,
	} = useAddEmployee();
	useEffect(() => {
		if (isSuccess) {
			onClose()
			reset()
			refetch()
		}
	}, [isSuccess])

	console.log("xx", data)
	return (
		<Box>
			<EditEmployee data={data} isLoading={isLoading} next={next} setNext={setNext} refetch={refetch} />
			<AddEmployee isOpen={isOpen} onClose={onClose} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} control={control} />
		</Box>

	)
}
function AddEmployee({ isOpen, onClose, handleSubmit, onSubmit, register, control }) {
	return (
		<Box>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add Employees</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box>
								<LeftSideLabelInput
									label="First Name"
									placeholder="First Name"
									register={register}
									registerName={"first_name"}
								/>
								<LeftSideLabelInput
									label="Last Name"
									placeholder="Last Name"
									register={register}
									registerName={"last_name"}
								/>


								<LeftSideLabelInput
									label="Email Address"
									placeholder="Primary Email Address"
									register={register}
									registerName={"email"}
								/>
								<LeftSideLabelInput
									label="Birthdate"
									type="date"
									register={register}
									registerName={"dob"}
								/>

								<LeftSideLabelSelect
									gap="0rem"
									label="Gender"
									data={[
										{ label: "Male", value: "Male" },
										{ label: "Female", value: "Female" },
									]}
									control={control}
									register={register}
									registerName={"gender"}
								/>
								<LeftSideLabelInput
									label="Contact"
									placeholder="Primary Phone Number"
									register={register}
									registerName={"contact"}
								/>
								<LeftSideLabelSelect
									gap="0rem"
									label="Country"
									data={COUNTRIES}
									register={register}
									registerName={"country"}
									control={control}
								/>
								<LeftSideLabelInput
									label="City"
									placeholder="City"
									register={register}
									registerName={"city"}
								/>
								<LeftSideLabelInput
									label="State"
									placeholder="State"
									register={register}
									registerName={"state"}
								/>
								<Flex gap="1rem" mt="3rem" mb="3rem">
									<SaveButton />
									<CancelButton onClose={onClose} />
								</Flex>
							</Box>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}