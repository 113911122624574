import { EditIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import React from "react";

const EditButton = () => {
  return (
    <Button
      style={globalStyles.customStyle.editButton}
      rightIcon={<EditIcon />}
    >
      Edit
    </Button>
  );
};

export default EditButton;
