import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  Center,
} from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { LeftSideLabelText } from "components/Common/LeftSideLabelText";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import React from "react";
import { IoCloudUploadOutline, IoCloseCircleOutline } from "react-icons/io5";

const AddLocation = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Location</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns={"1fr 1fr"}>
            <GridItem>
              {" "}
              <LeftSideLabelInput label="Location name" type="text" />
              <LeftSideLabelSelect label="Region" />
              <LeftSideLabelInput label="Altitude" type="text" />
              <LeftSideLabelTextArea label="Description" />
              <Flex gap="2rem" mt="3rem" p="1rem">
                <SaveButton />
                <CancelButton />
              </Flex>
            </GridItem>
            <GridItem>
              {" "}
              <Box>
                <Center
                  w="12rem"
                  h="8rem"
                  borderTop={"1px dashed #808080"}
                  borderLeft={"1px dashed #808080"}
                  borderRight={"1px dashed #808080"}
                  borderTopRadius="8px"
                >
                  <IoCloudUploadOutline fontSize={"3rem"} color="lightgrey" />
                </Center>
                <Center
                  w="12rem"
                  h="2rem"
                  border={"1px solid #808080"}
                  borderBottomRadius="8px"
                >
                  Upload Cover
                </Center>
              </Box>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddLocation;
