import React from "react";
import { Flex, Box, Input, Text } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { AiFillCalendar } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";

const LeftSideDatePicker = ({ label, date, setDate }) => {
  return (
    <Flex gap="1.2rem" alignItems="center" mt="1.5rem">
      <Box w="8rem" fontSize={"sm"}>
        {label}
      </Box>
      <Box>
        <DatePicker
          name={`addLocationStartDate`}
          selected={Date.parse(date)}
          placeholderText="mm/dd/yyyy"
          customInput={<CustomInput />}
          onChange={setDate}
          // value={formik.values.addLocationStartDate}
        />
      </Box>
    </Flex>
  );
};

export default LeftSideDatePicker;

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Flex
      onClick={props.onClick}
      ref={ref}
      border="0.1px solid #e5e4e4"
      w={{ base: "15rem", md: "15rem", sm: "10rem" }}
      justifyContent={"space-between"}
      // justifyItems={"center"}
      alignItems="center"
      padding={"0.3rem"}
      borderRadius="4px"
      fontSize={"sm"}
      cursor="pointer "
    >
      {" "}
      <Text>{props.value || props.placeholder}</Text>
      <AiFillCalendar onClick={props.onClick} />
    </Flex>
  );
});
