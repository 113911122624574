import { Box, Flex } from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideDatePicker from "components/LeftSideDatePicker";
import React from "react";

const Job = ({ dataGetProfile, register, jobStartDate, setJobStartDate }) => {
  return (
    <Box>
      <LeftSideDatePicker
        label="Start Date"
        date={jobStartDate}
        setDate={setJobStartDate}
      />
      {/* <LeftSideLabelInput
        label="Job Started Date"
        type="text"
        value={dataGetProfile?.experience[0]?.start_date}
        register={register}
        registerName={"start_date"}
      /> */}
      <LeftSideLabelInput
        label="Position"
        type="text"
        register={register}
        registerName={"job"}
      />
      <Flex gap="2rem" mt="3rem">
        <SaveButton />
        <CancelButton />
      </Flex>
    </Box>
  );
};

export default Job;
