import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditPaymentApi = () => {
  async function postEditPeak(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/order/update/`,
      values
    );
    return res;
  }

  const postEditPeakMutation = useMutation(postEditPeak);

  return {
    postEditPeakMutation,
  };
};
