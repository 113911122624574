import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import food from "../../../assets/img/food2.png";

export default function Breakfast({ responseData, scrollRef }) {
  return (
    <Box mt="2rem" className="responsive-title">
      {responseData?.map((item, index) => {
        console.log(item);
        if (item?.product?.length > 0) {
          return (
            <Box mt="16px" key={item.uuid} id={`div-${item.uuid}`}>
              <Text className="food-title">{item?.name.toUpperCase()}</Text>
              <Box
                className="break-box"
                border={"1px solid #E2E2E2"}
                borderRadius={"10px"}
                padding={"1rem"}
                mt="16px"
              >
                {item?.product?.map((val, i) => {
                  return (
                    <Box className="fooditem-container">
                      <Box className="food-image_container">
                        <Image className="food-img" src={val?.image} />
                      </Box>
                      <Box width={"65%"}>
                        <Text
                          className="box-name"
                          fontWeight={"600"}
                          fontSize={"20px"}
                        >
                          {val?.name}
                        </Text>
                        <Text
                          className="box-description"
                          fontSize={"16px"}
                          mt="0.5rem"
                          color={"#000"}
                        >
                          {val?.description}
                        </Text>
                      </Box>

                      <Box>
                        <Text
                          fontSize={"18px"}
                          fontWeight={"600"}
                          className="box-price"
                        >
                          Rs.{val?.selling_price}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
}
