import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useDeleteFloor = () => {
  async function deleteFloor(floor) {
    const res = await authorizationAxios.delete(
      `${BASE_URL}/api/floor/${floor}/`
    );
    return res;
  }

  const deleteFloorMutation = useMutation(deleteFloor);

  return {
    deleteFloorMutation,
  };
};
