import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Input,
  Textarea,
  HStack,
  Image,
  Button,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { IoCloudUploadOutline } from "react-icons/io5";
import React from "react";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useGetPeak } from "views/Pages/ExpeditionAndPackage/Peak/api/useGetPeak";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { LeftSideLabelMultiSelect } from "components/Common/LeftSideLabelMultiSelect";
import { useGetConfigChoiceList } from "views/Pages/CommonApi/useGetConfigChoiceList";
import { useAddExpedition } from "../../useAddExpedition";
import { useRef, forwardRef } from "react";
import useBasicDetails from "./useBasicDetails";

const BasicDetails = ({
  uploadPhotoOnClickHandler,
  uploadPhotoRef,
  uploadPhotoChangeHandler,
  uploadPhotoFile,
  register,
  control,
}) => {
  const { data } = useGetPeak();
  const ExpedType = useGetConfigChoiceList("ExpeditionType");

  return (
    <Grid templateColumns={"1fr 1fr"}>
      <GridItem>
        <LeftSideLabelMultiSelect
          label="Select Peak"
          data={data}
          register={register}
          registerName={"peak"}
          control={control}
        />
        <LeftSideLabelInput
          label="Expedition Title"
          type="text"
          register={register}
          registerName={"title"}
        />
        <LeftSideLabelSelect
          label="Type"
          type="text"
          data={ExpedType?.data}
          register={register}
          registerName={"category"}
          control={control}
        />
        <LeftSideLabelInput
          label="Duration"
          type="text"
          register={register}
          registerName={"duration"}
        />
        <HStack>
          <LeftSideLabelInput
            type="text"
            label="Base Camp Lat,Lon"
            register={register}
            registerName={"latitude"}
          />
          <Input
            type={"text"}
            borderColor="#D3D3D3"
            borderRadius={"0.3rem"}
            w={{ base: "15rem", md: "15rem", sm: "10rem" }}
            size="sm"
            mt="1.5rem!important"
            {...register("longitude")}
          />
        </HStack>
        <Flex gap="1rem" alignItems="center" mt="1.5rem">
          <Box w="8rem" fontSize={"sm"}>
            About
          </Box>
          <Box>
            <Textarea
              w="30.8rem"
              placeholder="Write about package Details."
              {...register("about")}
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem>
        <Box onClick={uploadPhotoOnClickHandler} cursor="pointer">
          <input
            type="file"
            hidden
            ref={uploadPhotoRef}
            onChange={uploadPhotoChangeHandler}
            {...register("image")}
          />
          <Center
            w="12rem"
            h="8rem"
            borderTop={"1px dashed #808080"}
            borderLeft={"1px dashed #808080"}
            borderRight={"1px dashed #808080"}
            borderTopRadius="8px"
          >
            <Image
              src={
                uploadPhotoFile.length !== 0
                  ? URL.createObjectURL(uploadPhotoFile[0])
                  : ""
              }
              w="13rem"
              h="7.8rem"
              borderTopRadius="8px"
              objectFit={"cover"}
            />
          </Center>
          <Center
            w="12rem"
            h="2rem"
            border={"1px solid #808080"}
            borderBottomRadius="8px"
          >
            Upload Photo
          </Center>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default BasicDetails;
