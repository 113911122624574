import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { usePostTableApi } from "../api/usePostTableApi";
import { useHistory } from "react-router-dom";

export const useAddTable = () => {
  let history = useHistory();
  const toast = useToast();

  const [uploadPhotoFile, setUploadPhotoFile] = useState([]);
  const toastIdRef = useRef();
  const uploadPhotoRef = useRef(null);
  const { register, handleSubmit, control, reset } = useForm();

  const { postPostTableMutation } = usePostTableApi();
  const { isLoading, isError, data, error, isSuccess } = postPostTableMutation;

  const onSubmit = (values) => {
    const formData = new FormData();

    // formData.append("name", values.name);
    // formData.append("sort", values.sort);

    // formData.append(
    // 	"logo",
    // 	uploadPhotoFile.length !== 0
    // 		? uploadPhotoFile[0]
    // 		: ''
    // );

    postPostTableMutation.mutate({
      name: values.name,
      floor: values.floor,
      table_number: values.table_number ? values.table_number : 0,
      unavailable: values.unavailable,
    });
  };

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    setUploadPhotoFile(Array.from(event.target.files));
  }

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "New Table Added!",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      history.push("/admin/setting");
    }

    //     if (isLoading) {
    //       toastIdRef.current = toast({
    //         title: "loading.....",
    //         position: "top-right",
    //         status: "success",
    //         duration: 9000,
    //         isClosable: true,
    //       });
    //     }
    if (isError) {
      toast({
        title: "Missing Information",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postPostTableMutation.isSuccess,
    postPostTableMutation.isLoading,
    postPostTableMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    setUploadPhotoFile,
    uploadPhotoRef,
    uploadPhotoFile,
  };
};
