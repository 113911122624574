import { useToast } from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSignInApi } from "./api/useSignInApi";
import { signIn } from "./slice/signInSlice";

export const useSignIn = () => {
  const { postSignInMutation } = useSignInApi();
  const { isLoading, isError, data, error, isSuccess } = postSignInMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  let history = useHistory();

  const { register, handleSubmit } = useForm();
  const onSubmit = (values) => {
    postSignInMutation.mutate(values);
  };
  console.log("signin",data)

  useEffect(() => {
    if (isSuccess) {
      dispatch(signIn(data.data));
      toast.update(toastIdRef.current, {
        description: postSignInMutation.data.data.message,
      });
      localStorage.setItem("access_token", data.data.data.access_token);
      localStorage.setItem("refresh_token", data.data.data.refresh_token);
      localStorage.setItem("unAuthorized", "");
      localStorage.setItem("company_type", data.data.data.company_type);
      localStorage.setItem("employee_type", data.data.data.user_type);


      // console.log("access_token", data.data.data.access_token);
      // history.push("/admin/inventory/item-groups");
      history.push("/admin/dashboard/");
      window.location.reload();
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: "Authentication Failed!",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      // console.log("erroe", error);
    }
  }, [
    postSignInMutation.isSuccess,
    postSignInMutation.isLoading,
    postSignInMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    toast,
    onSubmit,
  };
};
