import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import Report from "./Report";
import UnpaidSalesReport from "./UnpaidSalesReport";
import UserReport from "./UserReport";
import KitchenReport from "./KitchenReport";
import EmployeeReport from "./EmployeeReport";

export default function ReportList() {
  const [activePage, setActivePage] = useState("Unpaidsales");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [reportDrop, setReportDrop] = useState(true);
  const [text, setTExt] = useState(true);
  return (
    <Box>
      <TopSearch
        onOpen={onOpen}
        activePage={activePage}
        reportDrop={reportDrop}
        setActivePage={setActivePage}
        text={text}
      />
      <Box
        display={"flex"}
        gap={"0.5rem"}
        justifyContent={"space-between"}
        background={"white"}
        pt={"4.5rem"}
      >
        <Box display={breakPoint[0] ? "none" : "block"}>
          <Accordion allowToggle minW={"13rem"} boxShadow="md" minH={"93vh"}>
            <>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("Unpaidsales")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Unpaid Sales
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("Invoice")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Invoice Report
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("userReport")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Customer Report
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("KitchenReport")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Kitchen Report
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("EmployeeReport")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Employee Report
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>
            </>
          </Accordion>
        </Box>

        <Box width={"100%"}>
          {activePage === "Unpaidsales" && <UnpaidSalesReport />}
          {activePage === "Invoice" && <Report />}
          {activePage === "userReport" && <UserReport />}
          {activePage === "KitchenReport" && <KitchenReport />}
          {activePage === "EmployeeReport" && <EmployeeReport />}
        </Box>
      </Box>
    </Box>
  );
}
