import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetMenuItems = () => {
  async function getCategory() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/category/`);

    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getCategory",
    getCategory
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
