import { useForm } from "react-hook-form";
import { useEffect, useRef } from "react";
import { toast } from "@chakra-ui/react";
import { useEditRetailerPaymentApi } from "./useEditRetailerPaymentApi";

export const useEditRetailerPayment = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();
  const { postEditRetailerMutation } = useEditRetailerPaymentApi();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postEditRetailerMutation;
  const toastIdRef = useRef();
  const onSubmit = () => {
    const values = watch();
    const formData = new FormData();
    formData.append("uuid", values.uuid);
    formData.append("currency", values.currency);
    formData.append("discount", values.discount);
    formData.append("tax", values.tax);
    formData.append("invoicedate", values.invoicedate);

    formData.append("adjustment_amount", values.adjustment);

    postEditRetailerMutation.mutate(formData);
  };

  return {
    register,
    control,
    onSubmit,
    handleSubmit,
    setValue,
    isSuccess,
    getValues,
    isError,
    isLoading,
    error,
    data,
  };
};
