import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddExpeditionApi = () => {

  async function postAddExpedition(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/super-user/expedition/`,
      values
    );
    return res;
  }

  const postAddExpeditionMutation = useMutation(postAddExpedition);

  return {
    postAddExpeditionMutation,
  };
};
