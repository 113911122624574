import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { usePostEmployeesApi } from "../api/usePostEmployeesApi";

export const useAddEmployee = () => {
        let history = useHistory();
        const toast = useToast();
        const toastIdRef = useRef();
        const { register, handleSubmit, control, reset } = useForm();
        const { postPostEmployeesMutation } = usePostEmployeesApi();
        const {

                isError,
                data,
                error,
                isSuccess,
        } = postPostEmployeesMutation;
        const isLoading = postPostEmployeesMutation.isLoading;

        const onSubmit = (values) => {
                const formData = new FormData();
                console.log("values", values)
                postPostEmployeesMutation.mutate({
                        contact: values.contact,
                        country: values.country,
                        city: values.city,
                        state: values.state,
                        dob: values.dob,
                        email: values.email,
                        first_name: values.first_name,
                        last_name: values.last_name,
                        gender: values.gender,
                });
        };
        console.log("err", postPostEmployeesMutation)
        useEffect(() => {
                if (isSuccess) {
                        toastIdRef.current = toast({
                                title: "New Employee Added!",
                                position: "top-right",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                        });

                        // history.push("/admin/category-list");
                }

                if (isLoading) {
                        toastIdRef.current = toast({
                                title: "loading.....",
                                position: "top-right",
                                status: "success",
                                duration: 9000,
                                isClosable: true,
                        });
                }
                if (isError) {
                        toast({
                                title: postPostEmployeesMutation?.error?.response?.data?.message,
                                position: "top-right",
                                // description: "You will shortly contact you.",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                        });
                }
        }, [
                postPostEmployeesMutation.isSuccess,
                postPostEmployeesMutation.isLoading,
                postPostEmployeesMutation.isError,
        ]);

        return {
                handleSubmit,
                register,
                control,
                reset,
                toast,
                onSubmit,
                isSuccess,
        };
};
