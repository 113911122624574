import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetCloth = (uuid, searchText, next) => {
  async function getCloth() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/brand-item/?uuid=${uuid}&search=${searchText}&page_number=${next}`
    );

    return res.data;
  }

  // const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
  //   "getCloth",
  //   getCloth
  // );
  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getCloth", uuid, searchText, next],
    () => getCloth(uuid, searchText, next)
  );
  return {
    data,
    refetch,
    isLoading,
  };
};
