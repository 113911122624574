import {
  Text,
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Table,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import React, { useEffect, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { usePostRecipe } from "../../api/usePostRecipe";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { AiFillDelete, AiOutlineCloseCircle } from "react-icons/ai";
import { SaveButton } from "components/Common/SaveButton";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useGetRecipi } from "views/Pages/Inventory/api/useGetRecipi";
import { useKitchenList } from "views/Pages/Inventory/List/useKitchenList";
import { useReceipeKitchen } from "views/Pages/Inventory/api/useReceipeKitchen";
import { useDeleteReceipe } from "views/Pages/InventoryDetail/api/useDeleteReceipe";

import { PageText } from "components/Common/PageText";
import { PageTitle } from "components/Common/PageTitle";
export default function ReceipeTable({ data, uuid }) {
  const {
    handleSubmit,
    register,
    onSubmit,
    getValues,
    watch,
    unregister,
    control,
    reset,
    setValue,
    isSuccess,
  } = usePostRecipe();
  const toast = useToast();

  const [unitValue, setUnitValue] = useState("gm");
  const [receipeData, setReceipeData] = useState("");
  const selectedRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const { data: kitchenList } = useReceipeKitchen();
  const selectedItenaryIndex = useRef();

  const { deleteReceipeMutation } = useDeleteReceipe();
  const {
    isLoading,
    isError,
    error,
    isSuccess: isSuccessDelete,
    data: dataDelete,
  } = deleteReceipeMutation;

  const { fields, remove, append } = useFieldArray({
    control,
    name: "item",
  });
  const {
    fields: newfields,
    remove: removefields,
    append: appendfields,
  } = useFieldArray({
    control,
    name: "newitem",
  });
  useEffect(() => {
    watch();
  }, []);

  const fetchData = async () => {
    if (data?.data?.uuid) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/get-add/receipe/?uuid=${uuid}`
      );
      console.log("res", response);
      reset({ item: response?.data?.data });
      setReceipeData(response?.data?.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, [uuid]);
  const handleDelete = async (val) => {
    deleteReceipeMutation.mutate(val);
  };
  useEffect(() => {
    if (isSuccessDelete) {
      toast({
        title: "Item deleted successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
      return () => clearTimeout(reloadTimeout);
    }

    if (isError) {
      toast({
        title: error?.response?.data.message
          ? error?.response?.data.message
          : error.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    deleteReceipeMutation.isSuccess,
    deleteReceipeMutation.isLoading,
    deleteReceipeMutation.isError,
  ]);
  const handleItemChange = (index) => {
    setSelectedValue(getValues(`item.${index}.kitchen_inventory`));
    kitchenList?.map((item) => {
      if (item?.uuid === selectedValue) {
        setValue(`item.${index}.unit`, item?.unit_name);
        console.log(item?.unit_name);
      }
    });
  };
  console.log("aa", kitchenList);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <>
            {fields.map((field, index) => (
              <Flex
                gap="1rem"
                alignItems={"end"}
                key={field.id}
                padding={"0px"}
                onClick={() => handleItemChange(index)}
                mb={"0.5rem"}
              >
                {" "}
                <input
                  {...register("sales_inventory")}
                  type={"hidden"}
                  value={data?.data.uuid}
                />
                <LeftSideLabelSelect
                  labelW={"0"}
                  isLabel={false}
                  type="text"
                  data={kitchenList?.map((item) => ({
                    label: item?.name + " (" + item?.unit_name + ")",
                    value: item?.uuid,
                  }))}
                  register={register}
                  registerName={`item.${index}.kitchen_inventory`}
                  control={control}
                  mt={"0rem"}
                  defaultValue={field.kitchen_inventory}
                  placeholder="Kitchen Items"
                />
                <InputGroup maxW="20rem" ref={selectedRef}>
                  <Input
                    maxW={"15rem"}
                    placeholder="Deduct By"
                    {...register(`item.${index}.name`)}
                    defaultValue={field.quantity}
                  />
                  {/* <input
                    defaultValue={field.unit_name}
                    style={{ border: "1px solid grey", width: "3rem" }}
                    {...register(`item.${index}.unit`)}
                  /> */}
                  {/* <InputRightAddon ref={selectedRef} children={unitValue} /> */}
                </InputGroup>
                <Box mb="1rem">
                  <AiOutlineCloseCircle
                    color="red"
                    cursor="pointer"
                    // onClick={() => remove(index)}
                    onClick={() => {
                      field?.uuid ? handleDelete(field.uuid) : remove(index);
                    }}
                  />
                </Box>
              </Flex>
            ))}
            <Text
              cursor="pointer"
              mt="1rem"
              color="#3AB689"
              onClick={() => append({})}
            >
              + Add Another Item
            </Text>
          </>
        </>

        <Box mt="3rem">
          <SaveButton />
        </Box>
      </form>
    </div>
  );
}
