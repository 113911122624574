import { Text } from "@chakra-ui/react";
import React from "react";

export const SectionTitle = ({ title, mt }) => {
  return (
    <Text mt={mt ?? ""} fontSize={"md"} color="blackAlpha.900">
      {title}
    </Text>
  );
};
