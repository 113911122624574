import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Grid,
    GridItem,
    Center,
    Image,
  } from "@chakra-ui/react";
  import { CancelButton } from "components/Common/CancelButton";
  import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
  import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
  import { SaveButton } from "components/Common/SaveButton";
  import React, { useEffect } from "react";
  import { IoCloudUploadOutline } from "react-icons/io5";
  import { useAddFloor } from "./useAddFloor";
  
  const AddFloor = ({ isOpen, onClose, refetch }) => {
    const {
      handleSubmit,
      register,
      control,
      reset,
      toast,
      onSubmit,
      isSuccess,
      uploadPhotoOnClickHandler,
      uploadPhotoChangeHandler,
      setUploadPhotoFile,
      uploadPhotoRef,
      uploadPhotoFile,
    } = useAddFloor();
  
    useEffect(() => {
      if (isSuccess) {
        onClose();
        refetch();
      }
    }, [isSuccess]);
  
    useEffect(() => {
      if (!isOpen) {
        reset();
        setUploadPhotoFile([]);
      }
    }, [isOpen]);
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Floor</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid templateColumns={"1fr 1fr"} gap="3rem">
                <GridItem>
                  <LeftSideLabelInput
                    label="Floor name"
                    type="text"
                    register={register}
                    registerName={"name"}
                  />
                  {/* <LeftSideLabelInput
                    label="Sort No."
                    type="text"
                    register={register}
                    registerName={"sort"}
                  /> */}
                  <Flex gap="2rem" mt="3rem" p="1rem">
                    <SaveButton />
                    {/* <CancelButton onClick={onClose} /> */}
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default AddFloor;
  