import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Center,
  Image,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { COUNTRIES } from "../../../../../constants/CONSTANTS";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { PageTitle } from "components/Common/PageTitle";
import { useGetPeakType } from "../api/useGetPeakType";
import { LeftSideLabelMultiSelect } from "components/Common/LeftSideLabelMultiSelect";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useAddPeak } from "./useAddPeak";
import { useGetConfigChoiceList } from "views/Pages/CommonApi/useGetConfigChoiceList";

const AddPeak = () => {
  const {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile,
  } = useAddPeak();
  const { data } = useGetPeakType();
  const difficulty = useGetConfigChoiceList("Difficulty Level");

  return (
    <Box direction="column">
      <Box h="97vh" bg={"white"}>
        {/* header */}

        <PageTitle title={"Add Peak"} />

        <Box p="1rem" pb={"0"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns={"repeat(2, 1fr)"}>
              <GridItem>
                <Flex pl="2rem" flexDirection={"column"}>
                  <LeftSideLabelInput
                    register={register}
                    registerName={"name"}
                    type="text"
                    label="Peak Name"
                  />
                  <LeftSideLabelSelect
                    label="Country"
                    data={COUNTRIES}
                    register={register}
                    registerName={"country"}
                    control={control}
                  />
                  <LeftSideLabelInput
                    type="text"
                    label="Altitude"
                    register={register}
                    registerName={"altitude"}
                  />
                  <LeftSideLabelMultiSelect
                    label="Category"
                    data={data}
                    register={register}
                    registerName={"category"}
                    control={control}
                  />
                  {/* <LeftSideLabelInput type="text" label="Grade" register={register} registerName={"difficulty"}  value={'fb81d4cb-d895-4090-9279-3255315029a0'}/> */}
                  <LeftSideLabelSelect
                    label="Grade"
                    data={difficulty?.data}
                    register={register}
                    registerName={"difficulty"}
                    control={control}
                  />
                  <HStack>
                    <LeftSideLabelInput
                      type="text"
                      label="Lat,Lon"
                      register={register}
                      registerName={"latitude"}
                    />
                    <Input
                      type={"text"}
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                      size="sm"
                      mt="1.5rem!important"
                      {...register("longitude")}
                    />
                  </HStack>
                  <LeftSideLabelTextArea
                    label="Description"
                    register={register}
                    registerName={"description"}
                  />
                  <HStack gap="2rem" mt="5rem">
                    <SaveButton /> <CancelButton />{" "}
                  </HStack>
                </Flex>
              </GridItem>

              <GridItem>
                <Box onClick={uploadPhotoOnClickHandler} cursor="pointer">
                  <input
                    type="file"
                    hidden
                    ref={uploadPhotoRef}
                    onChange={uploadPhotoChangeHandler}
                  />
                  <Center
                    w="12rem"
                    h="8rem"
                    borderTop={"1px dashed #808080"}
                    borderLeft={"1px dashed #808080"}
                    borderRight={"1px dashed #808080"}
                    borderTopRadius="8px"
                  >
                    <Image
                      src={
                        uploadPhotoFile.length !== 0
                          ? URL.createObjectURL(uploadPhotoFile[0])
                          : ""
                      }
                      w="13rem"
                      h="7.8rem"
                      borderTopRadius="8px"
                      objectFit={"cover"}
                    />
                  </Center>
                  <Center
                    w="12rem"
                    h="2rem"
                    border={"1px solid #808080"}
                    borderBottomRadius="8px"
                  >
                    Upload Photo
                  </Center>
                </Box>
              </GridItem>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPeak;
