import React, { useState, useEffect } from "react";
import { useGetCloth } from "../api/useGetCloth";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import PaginationButton from "components/Common/PaginationButton copy";

export default function RetailerList({
  handleItemChange,
  menuCategory,
  searchQuery,
}) {
  const [next, setNext] = useState(1);

  const { data: getCloth, refetch } = useGetCloth(
    menuCategory,
    searchQuery,
    next
  );
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  useEffect(() => {
    if (searchQuery || next) {
      refetch();
    }
  }, [menuCategory, searchQuery, next]);
  return (
    <>
      <Box
        // display={breakPoint[0] ? "none" : "flex"}
        display={"flex"}
        flexWrap={"wrap"}
        gap={"1rem"}
        px={"1rem"}
        marginLeft={"100px"}
      >
        {getCloth?.data?.map((item, index) => {
          return (
            <Box
              width={"145px"}
              borderRadius={"10px"}
              border={"1px solid lightgray"}
              overflow={"hidden"}
              mt={"1rem"}
              boxShadow={"sm"}
              onClick={() => handleItemChange(item)}
              cursor={"pointer"}
            >
              <Text textAlign={'center'} lineHeight={'2rem'} position={'absolute'} borderTopLeftRadius={'0.5rem'} borderBottomRightRadius={'0.5rem'} background={"#3ab689"} color={'white'} fontSize={'sm'} width={'2rem'} height={'2rem'} >{item?.is_unlimited === true ? "∞" :item?.closing_stock}</Text>
              <Image
                objectFit={"cover"}
                src={item?.image_url}
                height={"120px"}
                width={"145px"}
              />

              <Box p="1" textAlign={"center"} borderTop={"1px solid lightgray"}>
                <Text
                  lineHeight={"18px"}
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  color={"#000000"}
                >
                  {item?.name}
                </Text>
                <Text
                  mt={"2px"}
                  fontWeight={"bold"}
                  fontSize={"12px"}
                  color={"#000000"}
                >
                  Rs.{item?.selling_price}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      <PaginationButton
        isPrevious={getCloth?.previous}
        isNext={getCloth?.next}
        next={next}
        setNext={setNext}
      />
    </>
  );
}
