import React from "react";
import { useHistory } from "react-router-dom";
import { useGetProfileApi } from "views/Pages/UserProfile/api/useGetProfileApi";
import { useGetGroup } from "../api/useGetGroup";

const useGroupList = (next, searchQuery) => {
  const { data } = useGetGroup(next, searchQuery);
  const history = useHistory();

  useGetProfileApi();

  return {
    history,
    data,
  };
};

export default useGroupList;
