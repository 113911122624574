import { Box, Flex } from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { SaveButton } from "components/Common/SaveButton";
import React from "react";

const Personal = () => {
  return (
    <>
      <Box>
        {" "}
        Here will be overview provided by client.Here will be overview provided
        by client.Here will be overview provided by client.Here will be overview
        provided by client.Here will be overview provided by client.Here will be
        overview provided by client.Here will be overview provided by
        client.Here will be overview provided by client.Here will be overview
        provided by client.Here will be overview provided by client.Here will be
        overview provided by client.Here will be overview provided by
        client.Here will be overview provided by client.Here will be overview
        provided by client.Here will be overview provided by client.Here will be
        overview provided by client.Here will be overview provided by client.
      </Box>

      <Flex gap="2rem" mt="3rem">
        <SaveButton />
        <CancelButton />
      </Flex>
    </>
  );
};

export default Personal;
