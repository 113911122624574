import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddKitchenInventoryApi = () => {
  async function postAddKitchenInventory(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/get-add/kitchenitem/`,
      values
    );
    return res;
  }

  const postAddKitchenInventoryMutation = useMutation(postAddKitchenInventory);

  return {
    postAddKitchenInventoryMutation,
  };
};
