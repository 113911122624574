import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useEffect } from "react";

import { useQuery, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { profile } from "../slice/profileSlice";

export const useGetProfileApi = () => {
  const dispatch = useDispatch();

  async function getProfile() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/profile/`);
    return res.data.data;
  }
  const {
    data: dataGetProfile,
    error,
    isError,
    isLoading,
    refetch,
    isSuccess,
  } = useQuery("getProfileApi", getProfile, {
    onSuccess: (data) => {
      dispatch(profile(data));
    },
  });

  return {
    dataGetProfile,
    isSuccess,
    refetch,
  };
};
