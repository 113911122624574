import { useToast } from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useNewPasswordApi } from "./api/useNewPasswordApi";
import { signIn } from "./slice/signInSlice";

export const useNewPassword = () => {
  const { postNewPasswordMutation } = useNewPasswordApi();
  const { isLoading, isError, data, error, isSuccess } = postNewPasswordMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  let history = useHistory();

  const { register, handleSubmit } = useForm();
  const onSubmit = (values) => {
    postNewPasswordMutation.mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "Password has been changed!",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postNewPasswordMutation.isSuccess,
    postNewPasswordMutation.isLoading,
    postNewPasswordMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    toast,
    onSubmit,
    isSuccess
  };
};
