import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetManufacturer = () => {
  async function getManufacturer() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/manufacturers/`
    );
    return res.data?.map(({
        uuid: value,
        name:label,
        ...rest
      }) => ({
        value,
        label,
        ...rest
      }));
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getManufacturer",
    getManufacturer
  );

  return {
    data,
  };
};
