import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsPeople } from "react-icons/bs";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useHistory } from "react-router-dom";
import { FaMoneyBillWave, FaMoneyCheckAlt } from "react-icons/fa";
import { usePostRetailerAmount } from "./api/usePostRetailerAmount";
import ReactToPrint from "react-to-print";

export default function OrderRetailPayment({ invId, isOpen, onOpen, onClose }) {
  const [totalAmount, setTotalAmount] = useState("");
  const [currency, setCurrency] = useState("Rs");
  const [payValue, setPayValue] = useState("");
  const [cashReceived, setCashReceived] = useState("");
  const [adjustment, setAdjustment] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [serviceAmount, setServiceAmount] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { postRetailerAmountMutation } = usePostRetailerAmount();
  const { isLoading, isError, error, isSuccess } = postRetailerAmountMutation;
  const toast = useToast();
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const [cashState, setCashState] = useState(0);
  const [cardState, setCardState] = useState(0);
  const [esewaState, setEsewaState] = useState(0);
  const [due, setDue] = useState(0);
  const [subTotal,setSubTotal] = useState(0);

  console.log("xx", totalAmount);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  useEffect(() => {
    if (activeTab === 1) {
      setPayValue("CARD");
    }
    if (activeTab === 2) {
      setPayValue("ESEWA");
    }
    if (activeTab === 0) {
      setPayValue("CASH");
    }
  }, [activeTab, payValue]);
  const fetchData = async () => {
    if (invId) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/order/?invoice_uuid=${invId}`
      );

      setTotalAmount(response?.data[0]?.total_amount);
      setDue(response?.data[0]?.dueAmount);
      setAdjustment(response?.data[0]?.adjustment_amount);
      setDiscountAmount(parseFloat(response?.data[0]?.total_discount));
      setServiceAmount(response?.data[0]?.tax);
      setCurrency(response?.data[0]?.currency);
      setResponse(response?.data[0]);
      setDueAmount(response?.data[0]?.dueAmount);
      setSubTotal(response?.data[0]?.sub_total);
    }
  };

  useEffect(() => {
    fetchData();
  }, [invId]);

  const priceAfterDisocunt = totalAmount;

  const serviceCharge = (serviceAmount / 100) * (subTotal + adjustment - discountAmount);
  
  function onChange() {
    setDue(
      totalAmount -
        parseFloat(
          document.getElementById("cash")?.value
            ? document.getElementById("cash")?.value
            : "0.0"
        ) -
        parseFloat(
          document.getElementById("card")?.value
            ? document.getElementById("card")?.value
            : "0.0"
        ) -
        parseFloat(
          document.getElementById("esewa")?.value
            ? document.getElementById("esewa")?.value
            : "0"
        )
    );
  }
  
  const payNow = (payValue) => {
    const finalData = {
      invoice_uuid: invId,
      payment_type: [
        {
          CASH: parseFloat(
            document.getElementById("cash")?.value
              ? document.getElementById("cash")?.value
              : "0.0"
          ),

          CARD: parseFloat(
            document.getElementById("card")?.value
              ? document.getElementById("card")?.value
              : "0.0"
          ),
          ESEWA: parseFloat(
            document.getElementById("esewa")?.value
              ? document.getElementById("esewa")?.value
              : "0.0"
          ),
        },
      ],
    };
    postRetailerAmountMutation.mutate(finalData);
  };
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: " Payment done successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      history.push("/admin/retail-item");
      // onCloseEdit();
    }
    if (isError) {
      toast({
        title: postRetailerAmountMutation?.error?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    postRetailerAmountMutation.isSuccess,
    postRetailerAmountMutation.isError,
  ]);
  console.log("ppo", response);

  return (
    <Box height={"100%"} w={"25rem"} mt={"70px"}>
      <Box borderBottom={"1px dashed #A9A9A9"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"1rem"}
          pb={"0rem"}
          alignItems={"center"}
        >
          <Text color={"#000000"} fontSize={"18px"} fontWeight={"600"}>
            PAYABLE AMOUNT
          </Text>
          <Text
            color="#3AB689"
            fontSize={"14px"}
            fontWeight={"700"}
            cursor={"pointer"}
            onClick={onOpen}
          >
            EDIT
          </Text>
        </Box>
        <Box
          p={"1rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text color={"#EC5A5A"} fontSize={"20px"} fontWeight={"700"}>
            Rs. {totalAmount}
          </Text>
        </Box>
      </Box>
      <Payment
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        activeTab={activeTab}
        due={due}
        priceAfterDisocunt={priceAfterDisocunt}
        setDue={setDue}
        serviceCharge={serviceCharge}
        discountAmount={discountAmount}
        serviceAmount={serviceAmount}
        setActiveTab={setActiveTab}
        handleTabChange={handleTabChange}
        payNow={payNow}
        cashReceived={cashReceived}
        setCashReceived={setCashReceived}
        adjustment={adjustment}
        onChange={onChange}
        currency={currency}
        response={response}
        dueAmount={dueAmount}
        cashState={cashState}
        setCashState={setCashState}
        cardState={cardState}
        setCardState={setCardState}
        esewaState={esewaState}
        setEsewaState={setEsewaState}
        subTotal={subTotal}
      />
    </Box>
  );
}
function Payment({
  payNow,
  totalAmount,
  due,
  discountAmount,
  serviceAmount,
  adjustment,
  onChange,
  serviceCharge,
  priceAfterDisocunt,
  currency,
  response,
  dueAmount,
  cashState,
  cardState,
  esewaState,
  setCardState,
  setCashState,
  setEsewaState,
  subTotal
}) {
  const [cashShow, setCashShow] = useState(true);
  const [cardShow, setCardShow] = useState(false);
  const [esewaShow, setEsewaShow] = useState(false);
  const componentRef = useRef();
  const {
    isOpen: isOpened,
    onOpen: onOpened,
    onClose: onClosed,
  } = useDisclosure();
  console.log("Xx", response);
  useEffect(() => {
    response?.payment_detail?.map((item) => {
      if (item?.type === "CASH") {
        if (item?.price && item?.price != 0) {
          // setCashState(item?.price);
          document.getElementById("cash").value = item?.price ?? 0;
          setCashShow(true);
        }
      }

      if (item?.type === "CARD") {
        if (item?.price && item?.price != 0) {
          // document.getElementById("card").value = item?.price ?? 0;
          setCardState(item?.price);
          setCardShow(true);
        }
      }
      if (item?.type === "BANK") {
        if (item?.price && item?.price != 0) {
          setEsewaState(item?.price ?? 0);
          // document.getElementById("esewa").value = item?.price ?? 0;
          setEsewaShow(true);
        }
      }
    });
  }, [response, cashState, cardState, esewaState]);
  return (
    <Box>
      <Box>
        <Box display={"flex"} p={"0.5rem"} gap={"0.5rem"}>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setCashShow(!cashShow)}
            bg={cashShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyBillWave
              size={"1.5rem"}
              color={cashShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={cashShow ? "#000" : "#DFDEDE"}>
              CASH
            </Text>
          </Box>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setCardShow(!cardShow)}
            bg={cardShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyCheckAlt
              size={"1.5rem"}
              color={cardShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={cardShow ? "#000" : "#DFDEDE"}>
              CARD
            </Text>
          </Box>
          <Box
            width={"100%"}
            cursor={"pointer"}
            onClick={() => setEsewaShow(!esewaShow)}
            bg={esewaShow ? "#EAF0F0" : "#FAFAFA"}
            textAlign={"-webkit-center"}
            p={"0.5rem"}
          >
            <FaMoneyBillWave
              size={"1.5rem"}
              color={esewaShow ? "#000" : "#DFDEDE"}
            />
            <Text fontWeight={"600"} color={esewaShow ? "#000" : "#DFDEDE"}>
              ESEWA
            </Text>
          </Box>
        </Box>
        {cashShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD CASH RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                defaultValue={0}
                border={"none"}
                width={"8rem"}
                borderBottom={"1px solid #1D2521"}
                borderRadius={"none"}
                type="number"
                textAlign={"right"}
                id="cash"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}

        {cardShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD CARD RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                border={"none"}
                borderRadius={"none"}
                width={"8rem"}
                borderBottom={"1px solid #1D2521"}
                defaultValue={cardState}
                type="number"
                textAlign={"right"}
                id="card"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}

        {esewaShow && (
          <Box
            mt={"0.5rem"}
            bg="#ECF3F5"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={"1rem"}
          >
            <Text fontSize={"16px"} fontWeight={"600"}>
              ADD ESEWA RECEIVED
            </Text>
            <HStack>
              <Text fontSize={"20px"} fontWeight={"600"}>
                {currency}
              </Text>

              <Input
                fontSize={"20px"}
                fontWeight={"600"}
                border={"none"}
                width={"8rem"}
                borderRadius={"none"}
                borderBottom={"1px solid #1D2521"}
                defaultValue={esewaState}
                type="number"
                textAlign={"right"}
                id="esewa"
                onChange={() => onChange()}
              />
            </HStack>
          </Box>
        )}
      </Box>

      <Box>
        {/* SUBTOTAL CALCULATION */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={"1rem"}
          borderBottom={"1px dashed #A9A9A9"}
        >
          <Box>
            <Text fontSize={"16px"} fontWeight={"600"}>
              SUBTOTAL
            </Text>
          </Box>
          <Box>
            <Text fontSize={"16px"} fontWeight={"600"}>
              Rs. {subTotal}
            </Text>
          </Box>
        </Box>

        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            ADJUSTMENT
          </Text>
          <Text fontSize="16px" fontWeight="700">
            Rs. {adjustment}
          </Text>
        </Box>
        
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            DISCOUNT
          </Text>
          {discountAmount ? (
            <Text fontSize="16px" fontWeight="700">
              Rs. {discountAmount}
            </Text>
          ) : (
            <Text fontSize="16px" fontWeight="700">
              Rs. 0
            </Text>
          )}
        </Box>
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            SERVICE CHARGE
          </Text>
          <Text fontSize="16px" fontWeight="700">
            {serviceAmount ? (
              <Text fontSize="16px" fontWeight="700">
                Rs.{parseFloat(serviceCharge)}
              </Text>
            ) : (
              <Text fontSize="16px" fontWeight="700">
                Rs. 0
              </Text>
            )}
          </Text>
        </Box>
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize="16px" fontWeight="700">
            TOTAL
          </Text>
          <Text fontSize="16px" fontWeight="700">
            Rs. {totalAmount}
          </Text>
        </Box>
        <Box
          p={"0.5rem"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {currency === "RS" ? (
            <>
              <Text fontSize="16px" fontWeight="700">
                DUE
              </Text>
              <Text fontSize="16px" fontWeight="700">
                Rs.{due}
              </Text>
            </>
          ) : (
            ""
          )}
        </Box>
        <Box p={"0.5rem"}>
          <Button
            background={"none"}
            padding={"0px"}
            fontSize={"sm"}
            onClick={onOpened}
          >
            Print Order
          </Button>
          <Button
            w={"100%"}
            _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
            variant="secondary"
            mt="1rem"
            color={"#ffff"}
            height={"72px"}
            bg={"buttongreen.400"}
            onClick={() =>
              payNow([
                {
                  CASH: parseFloat(
                    document.getElementById("cash")?.value
                      ? document.getElementById("cash")?.value
                      : "0.0"
                  ),
                  CARD: parseFloat(
                    document.getElementById("card")?.value
                      ? document.getElementById("card")?.value
                      : "0.0"
                  ),

                  ESEWA: parseFloat(
                    document.getElementById("esewa")?.value
                      ? document.getElementById("esewa")?.value
                      : "0.0"
                  ),
                },
              ])
            }
          >
            PAY NOW
          </Button>
        </Box>
      </Box>
      <Modal isOpen={isOpened} onClose={onClosed}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint
              ref={componentRef}
              data={response}
              adjustment={adjustment}
              serviceCharge={serviceAmount ? parseFloat(serviceCharge) : 0}
              discountAmount={discountAmount ? discountAmount : 0}
              total={totalAmount}
            />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button variant="ghost">Print Order</Button>}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Estimate</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props?.data?.invoiceNumber}
      </Text>
      {/* <Text textAlign={'center'} size="lg" mb={'1rem'}>Order Date#:{new Date()}</Text> */}
      <table>
        <tr style={{ borderBottom: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px" }}>Item</th>
          <th style={{ width: "50px" }}>Price</th>
          <th style={{ width: "30px" }}>Qty</th>
          <th style={{ width: "100px", textAlign: "right" }}>SUBTOTAL</th>
        </tr>
        {props?.data?.invoice_item?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px" }}>{item?.name}</td>
              <td style={{ width: "50px" }}>{item?.selling_price}</td>
              <td style={{ width: "30px" }}>{item?.qty ?? item?.quantity}</td>
              {
                item?.complimentry === true ?
                <td style={{ width: "100px", textAlign: "right" }}>
                 0
                </td>:""  
              }
              {item?.complimentry === false && item?.quantity && (
                <td style={{ width: "100px", textAlign: "right" }}>
                  {item?.selling_price * item?.quantity}{" "}
                </td>
              )}
              {item?.complimentry === false && item?.qty && (
                <td style={{ width: "100px", textAlign: "right" }}>
                  {item?.selling_price * item?.qty}{" "}
                </td>
              )}
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <td style={{ width: "150px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>
            {props?.adjustment}
          </th>
        </tr>
        
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>
            {props?.discountAmount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px" }}>Service Charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>
            {props?.serviceCharge}
          </th>
        </tr>

        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right" }}>{props?.total}</th>
        </tr>
      </table>
    </div>
  );
});
