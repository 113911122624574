import { Box, Input,Text,Button } from "@chakra-ui/react";
import { PageTitle } from "./PageTitle";
import { SectionTitle } from "./SectionTitle";
import { useNewEmail } from "views/Pages/UserProfile/Edit/useNewEmail";
import { useEffect,useState } from "react";
import { useUpdateEmail } from "views/Pages/UserProfile/Edit/useUpdateEmail";

export default function ChangeEmail({onClose}){
    
    const [email,setEmail] = useState("");
    const { handleSubmit:handleSubmitNew, register:registerNew , toast:toastNew, onSubmit:onSubmitNew,isSuccess:isSuccessNew } = useNewEmail();
    const [isSent,setIsSent] = useState(false);
    const { handleSubmit, register, toast, onSubmit,isSuccess } = useUpdateEmail();

    useEffect(()=>{
        if(isSuccessNew)
            setIsSent(true)
    },[isSuccessNew])

    useEffect(()=>{
        if(isSuccess)
            onClose();
    },[isSuccess])

    return(
        <>  {
                isSent ? 
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box borderBottom="1px solid #EAEAEA">
                        <PageTitle title="Verify OTP" />
                    </Box>

                    <Box padding={'1rem'} borderBottom="1px solid #EAEAEA">
                        <SectionTitle title={'Please enter OTP that was sent to your email: '+email} />
                        <Input id="otp" name="otp" mt={"1rem"} required mb={"1rem"} placeholder={'otp code here'} autoComplete="off" {...register("otp")}/>
                        <Input id="email" name="email" type="hidden" value={email} {...register("email")}/>
                    </Box>

                    <Box p={"1rem"} justifySelf="center">
                        
                        <Button type="submit" color="white" bg={"orange.500"} float={"right"}>
                            Verify OTP
                        </Button>
                        <Button onClick={onClose} mr="1rem" float={"right"} mb={'1rem'}>
                            Cancel
                        </Button>
                    </Box>
                </form>
                :
                <form onSubmit={handleSubmitNew(onSubmitNew)}>
                    <Box borderBottom="1px solid #EAEAEA">
                        <PageTitle title="Change Email" />
                    </Box>

                    <Box padding={'1rem'} borderBottom="1px solid #EAEAEA">
                        <SectionTitle title="Please enter your new email address." />
                        <Input id="email" name="email" mt={"1rem"} required mb={"1rem"} placeholder={'xyz@mail.com'} {...registerNew("email")} onChange={(e)=>setEmail(e.target.value)}/>
                    </Box>

                    <Box p={"1rem"} justifySelf="center">
                        <Button type="submit" color="white" bg={"orange.500"} float={"right"}>
                            Continue
                        </Button>
                        <Button onClick={onClose} mr="1rem" float={"right"} mb={'1rem'}>
                            Cancel
                        </Button>
                    </Box>
                </form>
            }
        </>
    );
}