import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetManufacturerApi = (searchText, next) => {
	async function getManufacturer() {
		const res = await authorizationAxios.get(
			`${BASE_URL}/api/manufacturers/?page=${next ? next : 1}&search=${searchText}`
		);
		return res.data;
	}

	const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
		"getManufacturer",
		getManufacturer
	);

	return {
		data,
		refetch,
		isLoading
	};
};
