import React, { useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Loading } from "components/Common/Loading";

import { globalStyles } from "theme/styles";
import Moment from "react-moment";
import { NoDataMsg } from "components/Common/NoDataMsg";
import { useGetReportApi } from "../api/useGetReportApi";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";
import axios from "axios";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { PageText } from "components/Common/PageText";
import { useGetBrandList } from "common apis/useGetBrandList";
import { useGetRetailerReportApi } from "./useGetRetailerReportApi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";
import PaginationButton from "components/Common/PaginationButton copy";
import { ChevronDownIcon } from "@chakra-ui/icons";

const RetailerReport = () => {
  const { successNotify } = useNotify();
  const [next, setNext] = useState(1);
  const { data: getBrandList } = useGetBrandList();
  const [selectedOption, setSelectedOption] = useState("");
  const { data, refetch, isLoading } = useGetRetailerReportApi(
    document.getElementById("startdate")?.value,
    document.getElementById("enddate")?.value,
    selectedOption.value,
    next
  );

  const handleClick = async () => {
    if (selectedOption?.value) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/brand/report/?start_date=${
          document.getElementById("startdate")?.value
        }&end_date=${document.getElementById("enddate")?.value}&brand=${
          selectedOption?.value
        }&export=true`
      );
      if (response?.status === 200) {
        successNotify(response?.data?.message);
      }
    } else {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/brand/report/?start_date=${
          document.getElementById("startdate")?.value
        }&end_date=${document.getElementById("enddate")?.value}&export=true`
      );
      if (response?.status === 200) {
        successNotify(response?.data?.message);
      }
    }
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  console.log("oo", isLoading);
  return (
    <Box bg="white" minH="100vh">
      <Box
        display={"flex"}
        p={"1rem"}
        borderBottom={"1px solid lightgray"}
        alignItems={"center"}
      >
        {localStorage.getItem("company_type") == "Restaurant" && (
          <Text width={"85%"}>SALES REPORT</Text>
        )}

        {localStorage.getItem("company_type") === "Retailer" && (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="unstyled"
              fontWeight={"normal"}
              color={globalStyles.colors.orange[500]}
            >
              {selectedOption?.label ?? "Sort by Brand Name"}
            </MenuButton>
            <MenuList>
              {getBrandList?.map((list) => {
                return (
                  <MenuItem onClick={() => handleSelectChange(list)}>
                    {list?.label}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={() =>
                  handleSelectChange({ value: "All", label: "All" })
                }
              >
                All
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <Box display={"flex"} alignItems={"center"}>
          <Text w={"6rem"} ml={"1rem"}>
            Start Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="startdate" />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Text w={"5rem"} ml={"1rem"}>
            End Date :{" "}
          </Text>
          <Input width={"10rem"} type="date" id="enddate" />
        </Box>
        <Button
          width={"5rem"}
          onClick={() => refetch()}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Submit
        </Button>

        {/* <Button
          width={"5rem"}
          onClick={handleClick}
          bg={"#3ab689"}
          color={"white"}
          ml={"1rem"}
        >
          Export
        </Button> */}
      </Box>

      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}> Item Name</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Selling Price</Th>
          <Th style={globalStyles.customStyle.tableHeading}> Invoice Date</Th>
          <Th style={globalStyles.customStyle.tableHeading}> Quantity</Th>
          {/* <Th style={globalStyles.customStyle.tableHeading}> Invoice Amount</Th> */}
          <Th style={globalStyles.customStyle.tableHeading}>Complimentry</Th>
        </Tr>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Tbody>
              {data?.data?.map((item, index) => (
                <Tr cursor={"pointer"} key={index}>
                  <Td>
                    <Text>{item?.productName}</Text>
                  </Td>
                  <Td>NPR.{item?.unitprice}</Td>
                  <Td>
                    <Moment format="DD-MM-YYYY">{item?.invoiceDate}</Moment>
                  </Td>
                  <Td>{item?.quantity}</Td>

                  {/* <Td>
                {item?.currency}
                {item?.billAmount}
              </Td> */}
                  <Td>
                    {item?.complimentry ? (
                      <AiOutlineCheckCircle color="green" size="1.2rem" />
                    ) : (
                      <RxCrossCircled color="red" size="1.2rem" />
                    )}
                  </Td>
                </Tr>
              ))}
              {data?.data === undefined && (
                <Tr cursor={"pointer"}>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <NoDataMsg msg="No Data Found" pos="relative" />
                  </Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              )}
            </Tbody>
            <PaginationButton
              isPrevious={data?.previous}
              isNext={data?.next}
              next={next}
              setNext={setNext}
            />
          </>
        )}
      </Table>
    </Box>
  );
};
export default RetailerReport;
