import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useMediaQuery,
} from "@chakra-ui/react";
import { authorizationAxios } from "axios/authorizationAxios";
import PaginationButton from "components/Common/PaginationButton copy";
import { BASE_URL } from "constants/BASE_URL";
import { useEffect, useState } from "react";

const WasteTable = ({ uuid }) => {
  const [next, setNext] = useState(1);
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  const [wasteData, setWasteData] = useState("");
  const [pageSize, setPageSize] = useState(1);
  const [pagnationData, setPaginationData] = useState("");
  const fetchData = async () => {
    if (uuid || next) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/waste/management/?uuid=${uuid}&page_number=${next}&page_size=${"5"}`
      );
      setWasteData(response?.data?.data);
      setPaginationData(response?.data);
      console.log("dd", response);
    }
  };
  console.log(wasteData);
  useEffect(() => {
    fetchData();
  }, [uuid, next]);
  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Quantity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {wasteData &&
            wasteData?.map((item, idx) => (
              <Tr key={idx}>
                <Td>{item.datetime}</Td>
                <Td>{item.quantity}</Td>
              </Tr>
            ))}
        </Tbody>
        <PaginationButton
          isPrevious={pagnationData?.previous}
          isNext={pagnationData?.next}
          next={next}
          setNext={setNext}
        />
      </Table>
    </TableContainer>
  );
};

export default WasteTable;
