import React, { useEffect,useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import { useAddLoaction } from "./Component/useAddLoaction";
import AddLocation from "./Component/AddLocation";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import {AiOutlineArrowLeft,AiOutlineCloseCircle} from "react-icons/ai";

const Itinerary = ({register,control, append, fields,remove,newItinerary,setNewItinerary}) => {
  
  const { isOpen, onOpen, onClose } = useAddLoaction();

  // useEffect(() => {
  //   append({});
  // }, []);

  const handleChange = (event) => {
    if(event === "addLocation")
      onOpen();
  }

  return (
    <>
      {newItinerary ? (
        <Box>
          <HStack mb={'2rem'} color={globalStyles.customStyle.fontColor.color} cursor="pointer" onClick={()=>setNewItinerary(false)}>
            <AiOutlineArrowLeft />
            <Text> Select From Dropdown 
            </Text>
          </HStack>
          {fields.map((field, index) => (
            <Grid templateColumns={"repeat(10 ,1fr)"} gap="1rem" mt="1rem" key={field.id}>
              <GridItem>
                <Input type="text" placeholder="Day" size={"sm"} {...register(`itinerary.${index}.name`)} />
              </GridItem>
              <GridItem colSpan={2}>
                {" "}
                <Select size={"sm"} onChange={(e)=>handleChange(e.target.value)} {...register(`itinerary.${index}.location`)}>
                  <option value="uuid">Namche Bazar</option>
                  <option value="addLocation" style={{ color: globalStyles.customStyle.fontColor.color }}>
                    + Add Location
                  </option>
                </Select>
              </GridItem>
              <GridItem colSpan={4}>
                <Input
                  type="text"
                  size={"sm"}
                  placeholder="Mention the Day Activity here"
                  {...register(`itinerary.${index}.activity`)}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <HStack>
                  <Select size={"sm"} placeholder="Select Hotel" colSpan={3} {...register(`itinerary.${index}.hotel`)}>
                    <option value="nice">nicee</option>
                  </Select>
                  <AiOutlineCloseCircle color="red" cursor={"pointer"} onClick={()=>remove(index)}/>
                </HStack>
              </GridItem>
            </Grid>
          ))}

          <Text
            color={globalStyles.customStyle.fontColor.color}
            cursor="pointer"
            mt="1rem"
            onClick={() => append({})}
          >
            + Add Another Day
          </Text>
        </Box>
      ) : (
        <>
          <Grid templateColumns={"repeat(2, 1fr)"} gap="1.2rem">
            <GridItem colSpan={2}>
              <LeftSideLabelSelect label="Select Itinerary" data={[{label:'Everest',value:'asdfasdfasdf'}]} register={register} registerName={'selected_itinerary'} control={control}/>
            </GridItem>
            <GridItem>
              <Text
                marginLeft={'9rem'}
                color={globalStyles.customStyle.fontColor.color}
                cursor="pointer"
                onClick={()=>setNewItinerary(true)}
              >
                + Create New Itinerary
              </Text>
            </GridItem>
          </Grid>
        </>
      )}

      {isOpen && <AddLocation isOpen={isOpen} onClose={onClose} />}

    </>
  );
};

export default Itinerary;
