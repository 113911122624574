import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Select from "react-select";
import { Controller } from "react-hook-form";

export const LeftSideLabelMultiSelect = ({
  label,
  data,
  onSelect,
  value,
  forPaymentDetails,
  register,
  registerName,
  control,
  onChange,
}) => {
  return (
    <Flex alignItems={"center"} gap="1.2rem" mt="1.5rem">
      {registerName ? (
        <>
          <Box w="8rem" fontSize={"sm"}>
            {label}
          </Box>
          <Box w={"15rem"}>
            {/* <Select options={data} value={value} onChange={onChange} isMulti /> */}
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  options={data}
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  isMulti
                />
              )}
              control={control}
              name={registerName}
              defaultValue={1}
              register={registerName}
            />
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Box w="8rem" fontSize={"sm"}>
            {label}
          </Box>
          <Box w={"15rem"}>
            <Select isMulti options={data} value={value} />
          </Box>
        </>
      )}
    </Flex>
  );
};
