import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAddExpeditionApi } from "../api/useAddExpeditionApi";
import { useHistory } from "react-router-dom";

export const useAddExpedition = () => {
  const { postAddExpeditionMutation } = useAddExpeditionApi();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postAddExpeditionMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
  const [newInclude, setNewInclude] = useState(false);
  const [newItinerary, setNewItinerary] = useState(false);

  let history = useHistory();

  const { register, handleSubmit, control } = useForm();
  const onSubmit = (values) => {
    // Add Expedition Steps
    // 1. Insert Basic Details Form and get Expedition UUID
    // If itinerary and Include/Exclude is selected from dropdown then send in same api
    // FORM DATA

    // 2. If custom Itinerary is created then send from here in List
    // If custom Include Exclude is created then send from here in Key value pair

    // 3. Insert Additional Data from here
    // send as list of key value pair

    // 4. Insert list of success rate from here

    // 5. Insert Gallery from here

    // const formData = new FormData();
    // formData.append("name", values.name);
    // formData.append("country", values.country);
    // formData.append("altitude", values.altitude);
    // formData.append("difficulty", values.difficulty);
    // formData.append("latitude", values.latitude);
    // formData.append("longitude", values.longitude);
    // formData.append("description", values.description);
    // var category = ""
    // values?.category?.map((item,idx)=>{
    //   if((idx+1) === values.category.length){
    //     category = category.concat('"'+item.value+'"')
    //   }else{
    //     category = category.concat('"'+item.value+'",')
    //   }
    // })
    // formData.append("category",'['+category+']' );

    // formData.append(
    //   "cover_image",
    //   uploadPhotoFile.length !== 0
    //     ? uploadPhotoFile[0]
    //     : ''
    // );
    // postAddExpeditionMutation.mutate(formData);
    // console.log("xxx", values);
    // console.log("inc", newInclude);
    // console.log("iti", newItinerary);
  };

  const uploadPhotoRef = useRef(null);

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    SetUploadPhotoFile(Array.from(event.target.files));
  }

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Expedition Added successfully!",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data.message
          ? error?.response?.data.message
          : error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postAddExpeditionMutation.isSuccess,
    postAddExpeditionMutation.isLoading,
    postAddExpeditionMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile,
    newInclude,
    setNewInclude,
    newItinerary,
    setNewItinerary,
  };
};
