import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

const useEditPeak = () => {
  const specificPeak = useSelector((state) => state.peak.specificPeak);
  const { reset, register, handleSubmit,control } = useForm();

  const [selectedPeakCategory, setSelectedPeakCategory] = useState();
  useEffect(() => {
    const peakCategory = specificPeak?.category.map(
      ({ name: label, uuid: value }) => ({
        label,
        value,
      })
    );
    setSelectedPeakCategory(peakCategory);
  }, [specificPeak]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues.peakName = specificPeak?.label;
    defaultValues.country = specificPeak?.country;
    defaultValues.altitude = specificPeak?.altitude;
    defaultValues.difficulty = specificPeak?.difficulty.name;
    defaultValues.latitude = specificPeak?.latitude;
    defaultValues.longitude = specificPeak?.longitude;
    defaultValues.description = specificPeak?.description;

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (values) => {
    // console.log("edit", values);
  };

  const handleCategoryChange = (options) => {
    setSelectedPeakCategory(options);
    // console.log("options", options);
  };
  return {
    specificPeak,
    selectedPeakCategory,
    register,
    handleSubmit,
    onSubmit,
    handleCategoryChange,
    control
  };
};

export default useEditPeak;
