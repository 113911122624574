import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetFloorApi = (searchText, next) => {
	async function getFloor() {
		const res = await authorizationAxios.get(
			`${BASE_URL}/api/floor/?page=${next ? next : 1}&search=${searchText}`
		);
        console.log('datazz',res.data)
		return res.data;
	}

	const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
		"getFloor",
		getFloor
	);

	return {
		data,
		refetch,
		isLoading
	};
};
