import React, { useState } from "react";

const useUpload = () => {
  const [selectedImage, setSelectedImage] = useState();

  function onChangeHandler(event) {
    setSelectedImage(Array.from(event.target.files));
  }

  return { onChangeHandler, selectedImage };
};

export default useUpload;
