import {
  HStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  useMediaQuery,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import SearchUI from "components/Common/SearchUI";
import { globalStyles } from "theme/styles";
import React, { useEffect, useState } from "react";
import AddUI from "components/Common/AddUI";
import SortIcon from "components/Common/SortIcon";
import { useGetFloorApi } from "../api/useGetFloorApi";
import { Loading } from "components/Common/Loading";
import { NoResultUI } from "components/Common/NoResultUI";
import PaginationButton from "components/Common/PaginationButton";
import EditFloor from "../edit/EditFloor";
import AddFloor from "../add/AddFloor";

const Floor = ({ isOpen, onOpen, onClose }) => {
  const breakPoint = useMediaQuery("(min-width: 1400px)");

  const [brandList, setBrandList] = useState();
  const [searchText, setSearchText] = useState("");
  const [next, setNext] = useState(1);

  const { data: searchData, refetch, isLoading } = useGetFloorApi(
    searchText,
    next
  );

  useEffect(() => {
    if (searchData) {
      setBrandList(searchData);
    }
  }, [searchData]);

  useEffect(() => {
    setNext(1);
    const timer = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [next]);

  return (
    <Box bg={"white"} minH="100vh" pos="relative">
      {isLoading ? (
        <Loading />
      ) : !brandList?.length ? (
        <NoResultUI />
      ) : (
        <Box>
          <Table variant="striped" width={"100%"}>
            <Tr>
              <Th width={"10rem"}>Sort No</Th>
              <Th width={"20rem"}>Floor Name</Th>
              <Th width={"10rem"}>Edit Floor</Th>
              <Th width={"10rem"}>Add Table</Th>
              <Th width={"10rem"}>Delete Floor</Th>
            </Tr>
            <Tbody></Tbody>
          </Table>
          <Accordion>
            {brandList?.map((data, index) => {
              return <EditFloor data={data} index={index} refetch={refetch} />;
            })}
          </Accordion>
          <PaginationButton
            isPrevious={brandList?.previous}
            isNext={brandList?.next}
            next={next}
            setNext={setNext}
          />
        </Box>
      )}
      <AddFloor isOpen={isOpen} onClose={onClose} refetch={refetch} />
    </Box>
  );
};

export default Floor;
