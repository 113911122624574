import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetEmployeeReportApi = (username, startdate, enddate, next) => {
  async function getEmployeeReport(key) {
    // Note the 'key' parameter
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/employee/report/?customer=${username ?? ""}&start_date=${
        startdate ?? ""
      }&end_date=${enddate ?? ""}&next=${next}`
    );
    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getEmployeeReport", startdate, enddate, next], // Use dynamic query key
    getEmployeeReport
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
