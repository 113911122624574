import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditChangepasswordApi = () => {

    async function editChangepassword(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/change-password/`,
            values
        );
        return res;
    }

    const editChangepasswordMutation = useMutation(editChangepassword);

    return {
        editChangepasswordMutation,
    };
};
