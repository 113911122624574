import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAddInventoryGroupApi } from "../api/useAddInventoryGroupApi";
import useMultipleImage from "./component/useMultipleImage";

export const useAddInventoryGroup = () => {
  const { postAddInventoryGroupMutation } = useAddInventoryGroupApi();
  const {
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
    countIndex,
  } = useMultipleImage();

  let today = new Date().toLocaleDateString();

  // console.log(today);
  const date = new Date(today);

  const seconds = Math.floor(date.getTime() / 1000);

  // console.log("seconds", seconds);
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postAddInventoryGroupMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
  const [newInclude, setNewInclude] = useState(false);
  const [newItinerary, setNewItinerary] = useState(false);

  let history = useHistory();

  // console.log("file", file);

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    resetField,
    reset,
  } = useForm();

  useEffect(() => {
    let defaultValues = {};
    defaultValues.discountAmount = 0;
    defaultValues.tax = 13;

    reset({ ...defaultValues });
  }, []);
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.groupname);
    formData.append("description", values.description);

    if (values.category) {
      formData.append("category", values.category);
    }
    if (localStorage.getItem("company_type") === "Retailer") {
      formData.append("manufactured_by", values.manufacturedBy);
      formData.append("brand", values.brand);
      formData.append("brand_fk", values.brand_fk);
      formData.append("manufacture_fk", values.manufacture_fk);
    }

    formData.append("attributes", JSON.stringify(values.cat));

    formData.append(
      "items",
      JSON.stringify(
        values?.item?.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.name === value.name &&
                t.name != undefined &&
                t.delete === false
            )
        )
      )
    );

    file?.forEach((image) => {
      formData.append("image", image);
    });

    postAddInventoryGroupMutation.mutate(formData);
  };

  const uploadPhotoRef = useRef(null);

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    SetUploadPhotoFile(Array.from(event.target.files));
  }

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Inventory Group Added successfully!",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/admin/inventory/item-groups");
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data.message
          ? error?.response?.data.message
          : error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postAddInventoryGroupMutation.isSuccess,
    postAddInventoryGroupMutation.isLoading,
    postAddInventoryGroupMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile,
    newInclude,
    setNewInclude,
    newItinerary,
    setNewItinerary,
    getValues,
    setValue,
    watch,
    unregister,
    resetField,
    reset,
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
    countIndex,
  };
};
