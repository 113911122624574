import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useDeleteItem = () => {
  async function deleteItem(uuid) {
    let res = await authorizationAxios.delete(
      `${BASE_URL}/api/order?uuid=${uuid}`
    );
    return res;
  }
  const deleteItemMutation = useMutation(deleteItem);

  return {
    deleteItemMutation,
  };
};
