import React, { useState } from "react";

const useMultipleImage = () => {
	const [file, setFile] = useState();

	// Create a reference to the hidden file input element
	const hiddenFileInput = React.useRef(null);
	const countIndex = React.useRef(-1);
	const handleClick = (event) => {
		hiddenFileInput.current.click();
		countIndex.current = -1; // this is done so that "countIndex" value is reset , when
		//countindex value is reset {...register will have } same state before rendering.
	};

	function handleUpload(event) {
		setFile(Array.from(event.target.files));

		// onImageSelected(Array.from(event.target.files));
	}
	return {
		file,
		handleUpload,
		handleClick,
		hiddenFileInput,
		countIndex,
	};
};

export default useMultipleImage;
