import { Box } from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { useAddInventoryGroup } from "./useAddInventoryGroup";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useGetUnit } from "views/Pages/Settings/Categories/api/useGetUnit";
import { useAddKitchenInventory } from "../api/useAddKitchenInventory";
const AddKitchenInventory = ({ activePage }) => {
  console.log(activePage);
  const {
    handleSubmit,
    register,
    control,
    onSubmit,
  } = useAddKitchenInventory();

  const { data: getUnit } = useGetUnit();
  console.log(getUnit);
  return (
    <Box bg={"white"}>
      {/* header */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p="1rem" pl={"3rem"} pb={"0"}>
          <>
            <LeftSideLabelInput
              labelW="3.5rem"
              type="text"
              label="Name"
              register={register}
              registerName={"name"}
            />
            <LeftSideLabelSelect
              register={register}
              control={control}
              registerName="unit"
              label="Unit"
              labelW="3.5rem"
              data={getUnit?.map((item) => ({
                label: item?.name,
                value: item?.uuid,
              }))}
            />
          </>
          <LeftSideLabelInput
            labelW="3.5rem"

            type="text"
            label="Quantity"
            register={register}
            registerName={"quantity"}
          />
        </Box>
        <Box mb="1rem" p="2rem 3rem">
          {" "}
          <SaveButton />
        </Box>
      </form>
    </Box>
  );
};

export default AddKitchenInventory;
