import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditEmpoyeeApi = () => {

        async function editEmployees(values) {
                const res = await authorizationAxios.put(
                        `${BASE_URL}/api/profile/web/update/`,
                        values
                );
                return res;
        }

        const editEmployeesMutation = useMutation(editEmployees);

        return {
                editEmployeesMutation,
        };
};
