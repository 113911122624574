import {
  Box,
  Button,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useGetIndividualMenu } from "../api/useGetIndividualMenu";
import PaginationButton from "components/Common/PaginationButton";
import { useGetAllMenu } from "../api/useGetAllMenu";
import { NoResultUI } from "components/Common/NoResultUI";
import CancelModal from "components/Common/CancelModal";

export default function MenuCategory({
  handleItemChange,
  menuUuid,
  menuCategory,
  searchQuery,
  categoryActive,
  subCategoryActive,
  selectedItems,
  setIsMenuModalOpen,
  cancelOrder,
  allMenu,
}) {
  const [next, setNext] = useState(1);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { data, refetch } = useGetIndividualMenu(menuUuid, searchQuery, next);
  const [invoiceId, setInvoiceId] = useState("");

  const { data: categoryData, refetch: refetchData } = useGetAllMenu(
    menuCategory,
    searchQuery,
    next
  );
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [mainMenu, setMainMenu] = useState(categoryData);
  useEffect(() => {
    if (subCategoryActive) {
      setMainMenu([]);
    } else {
      setMainMenu(categoryData);
    }
  }, [subCategoryActive, categoryData]);

  useEffect(() => {
    if (data && subCategoryActive) {
      setSelectedMenu(data);
    }
    if (categoryData && categoryActive) {
      setSelectedMenu(mainMenu);
    }
  }, [data, categoryData, , selectedMenu, categoryActive, subCategoryActive]);

  useEffect(() => {
    if (
      breakPoint[0] === false ||
      menuUuid ||
      searchQuery ||
      next ||
      selectedMenu
    ) {
      refetch();
    }
  }, [menuUuid, searchQuery, next]);

  useEffect(() => {
    if (menuCategory || selectedMenu) {
      refetchData();
    }
  }, [menuCategory, searchQuery, next]);

  useEffect(() => {
    if (menuCategory == "" && allMenu && subCategoryActive == false) {
      setSelectedMenu(categoryData);
    }
  }, [menuCategory, allMenu, subCategoryActive]);
  console.log("selectedMenu", subCategoryActive, allMenu);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      />
      <Box
        display={breakPoint[0] ? "none" : "flex"}
        flexWrap={"wrap"}
        px={"2rem"}
      >
        {data?.data?.length !== 0 ? (
          selectedMenu?.data?.map((item, index) => {
            return (
              <Box
                width={"145px"}
                borderRadius={"10px"}
                border={"1px solid lightgray"}
                overflow={"hidden"}
                ml={"1rem"}
                mt={"1rem"}
                boxShadow={"sm"}
                onClick={() => handleItemChange(item)}
                cursor={"pointer"}
                position="relative"
              >
                <Image
                  objectFit={"cover"}
                  src={item?.image}
                  height={"120px"}
                  width={"145px"}
                />
                <Box
                  position="absolute"
                  top="0rem" /* Adjust this value to position the stock text vertically */
                  left="0rem" /* Adjust this value to position the stock text horizontally */
                  background="#3ab689"
                  color="white"
                  fontSize="sm"
                  width="2rem"
                  height="2rem"
                  textAlign="center"
                  lineHeight="2rem"
                  borderTopLeftRadius={"0.5rem"}
                  borderBottomRightRadius={"0.5rem"}
                >
                  {item?.is_unlimited === true ? "∞" : item?.available_stock}
                </Box>
                <Box
                  p="1"
                  textAlign={"center"}
                  borderTop={"1px solid lightgray"}
                >
                  <Text
                    lineHeight={"18px"}
                    fontWeight={"bold"}
                    fontSize={"16px"}
                    color={"#000000"}
                  >
                    <div className="twolines">{item?.name}</div>
                  </Text>
                  <Text
                    mt={"2px"}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                    color={"#000000"}
                  >
                    Rs. {item?.selling_price}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <>
            <NoResultUI />
          </>
        )}
      </Box>
      <Box
        display={breakPoint[0] ? "flex" : "none"}
        flexWrap={"wrap"}
        px={"2rem"}
      >
        {data?.data?.length !== 0 ? (
          categoryData?.data?.map((item, index) => {
            return (
              <Box
                width={"145px"}
                borderRadius={"10px"}
                border={"1px solid lightgray"}
                overflow={"hidden"}
                ml={"1rem"}
                mt={"1rem"}
                boxShadow={"sm"}
                onClick={() => handleItemChange(item)}
                cursor={"pointer"}
                position={"relative"}
              >
                <Image
                  objectFit={"cover"}
                  src={item?.image}
                  height={"120px"}
                  width={"145px"}
                />
                <Box
                  position="absolute"
                  top="0rem" /* Adjust this value to position the stock text vertically */
                  left="0rem" /* Adjust this value to position the stock text horizontally */
                  background="#3ab689"
                  color="white"
                  fontSize="sm"
                  width="2rem"
                  height="2rem"
                  textAlign="center"
                  lineHeight="2rem"
                  borderTopLeftRadius={"0.5rem"}
                  borderBottomRightRadius={"0.5rem"}
                >
                  {item?.is_unlimited === true ? "∞" : item?.available_stock}
                </Box>
                <Box
                  p="1"
                  textAlign={"center"}
                  borderTop={"1px solid lightgray"}
                >
                  <Text
                    lineHeight={"18px"}
                    fontWeight={"bold"}
                    fontSize={"14px"}
                    color={"#000000"}
                  >
                    <div className="twolines">{item?.name}</div>
                  </Text>
                  <Text
                    mt={"2px"}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                    color={"#000000"}
                  >
                    Rs.{item?.selling_price}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <NoResultUI left={"0%"} />
        )}
      </Box>
      {breakPoint[0] && (
        <Box>
          <Button
            disabled={selectedItems?.length == 0 ? true : false}
            className="button-bottom1"
            onClick={() => setIsMenuModalOpen(true)}
          >
            Your order({selectedItems?.length})
          </Button>
          <Button
            disabled={selectedItems?.length == 0 ? true : false}
            bg="buttonred.400"
            className="button-bottom2"
            onClick={onOpen}
          >
            Cancel Order
          </Button>
        </Box>
      )}

      <PaginationButton
        isPrevious={data?.previous}
        isNext={data?.next}
        next={next}
        setNext={setNext}
      />
    </>
  );
}
