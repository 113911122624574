import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useReceipeKitchen = () => {
  async function getReceipeKitchen() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/get-add/kitchenitem/?data=All`
    );

    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getReceipeKitchen",
    getReceipeKitchen
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
