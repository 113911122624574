import {
  Box,
  Button,
  Image,
  Text,
  useMediaQuery,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { BsPeople } from "react-icons/bs";
import TableIcon from "../../../../assets/icons/table.png";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useNotify } from "common hooks/useNotify";
import { authorizationAxios } from "axios/authorizationAxios";
import AddIcon from "../../../../assets/icons/add.png";
import { BASE_URL } from "constants/BASE_URL";
import AddUI from "components/Common/AddUI";
import { globalStyles } from "theme/styles";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useAddCustomer } from "views/Pages/Order/List/api/useAddCustomer";

export default function MenuBill({
  selectedItems,
  setSelectedItems,
  tableNumber,
  bookedItem,
  tableId,
  invoiceName,
  submitValues,
  guest,
  setGuest,
}) {
  const [subtotal, setSubtotal] = useState(0);
  const { successNotify } = useNotify();
  const history = useHistory();
  const toast = useToast();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [customerNumber, setCustomerNumber] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleDelete = (itemId) => {
    const updatedItems = selectedItems.filter((item) => item.uuid !== itemId);
    setSelectedItems(updatedItems);
  };
  const {
    handleSubmit,
    onSubmit,
    register,
    data,
    isSuccess,
  } = useAddCustomer();
  const handleQuantityChange = (productId, action) => {
    setSelectedItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.uuid === productId) {
          const newQuantity =
            action === "increase" ? product.qty + 1 : product.qty - 1;
          return { ...product, qty: newQuantity };
        }
        return product;
      })
    );
  };
  const calculateSubtotal = () => {
    const total = selectedItems?.reduce((accumulator, product) => {
      return accumulator + product.qty * product.selling_price;
    }, 0);
    setSubtotal(total);
  };
  useEffect(() => {
    calculateSubtotal();
  }, [selectedItems]);

  const cancelOrder = async () => {
    if (tableId) {
      const response = await authorizationAxios.post(
        `${BASE_URL}/api/order/cancel_order/?uuid=${tableId}`
      );
      if (response?.status === 200) {
        successNotify("Order canceled successfully");

        const reloadTimeout = setTimeout(() => {
          location.reload();
        }, 2000);

        return () => clearTimeout(reloadTimeout);
      }
    } else {
      history.goBack();
    }
  };
  useState(() => {
    if (data) {
      setCustomerNumber(data?.data?.data?.customer_phone);
    }
  }, [data]);
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);
  return (
    <Box
      minW={"25rem"}
      position={"fixed"}
      minH={breakPoint[0] ? "80vh" : "90vh"}
      display={"flex"}
      flexFlow={"column"}
      justifyContent={"space-between"}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LeftSideLabelInput
                label="Phone number"
                type="number"
                defaultValue={localStorage.getItem("customer_number") ?? ""}
                register={register}
                registerName={"number"}
              />
              <LeftSideLabelInput
                label="Customer Name"
                type="text"
                defaultValue={localStorage.getItem("customer_name") ?? ""}
                register={register}
                registerName={"customername"}
              />
              <Flex gap="1rem" mt="3rem">
                <SaveButton />
                <CancelButton onClose={onClose} />
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px dashed #A9A9A9"}
          paddingBottom={"1rem"}
          width={"100%"}
          p={"1rem"}
        >
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"590"}
            >
              ORDER #
            </Text>
            <Text
              display={"flex"}
              alignItems={"baseline"}
              gap="5px"
              mt="5px"
              color={"#2C3832"}
              fontSize={breakPoint[0] ? "10px" : "12px"}
              fontWeight={"600"}
            >
              <BsPeople /> GUEST:
              <span style={{ color: "#D89F65" }}>
                <Button
                  size="xs"
                  mr="5px"
                  bg="none"
                  onClick={() => setGuest(guest - 1)}
                  disabled={guest <= 0}
                >
                  -
                </Button>
                {guest}
                <Button
                  size="xs"
                  bg={"none"}
                  onClick={() => setGuest(guest + 1)}
                >
                  +
                </Button>
              </span>
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"600"}
            >
              {bookedItem?.length ? bookedItem[0]?.invoiceNumber : "No order "}
            </Text>
            <Text
              display={"flex"}
              gap="5px"
              alignItems={"baseline"}
              mt="5px"
              color={"#2C3832"}
              fontSize={breakPoint[0] ? "10px" : "12px"}
              fontWeight={"600"}
            >
              {" "}
              <Image src={TableIcon} width={"10px"} />
              TABLE:
              {bookedItem?.length && (
                <span style={{ color: "#D89F65" }}>
                  {bookedItem[0]?.table_name}
                </span>
              )}
              <span style={{ color: "#D89F65" }}>{tableNumber?.name}</span>
            </Text>
            <Box cursor={"pointer"}>
              {localStorage.getItem("customer_name") ? (
                <>
                  <Text
                    color={globalStyles.customStyle.fontColor.color}
                    fontSize={"14px"}
                    onClick={onOpen}
                  >
                    {localStorage.getItem("customer_name")}
                  </Text>
                </>
              ) : localStorage.getItem("customer_number") ? (
                <>
                  <Text
                    color={globalStyles.customStyle.fontColor.color}
                    fontSize={"14px"}
                    onClick={onOpen}
                  >
                    {localStorage.getItem("customer_number")}
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    color={globalStyles.customStyle.fontColor.color}
                    fontSize={"14px"}
                    onClick={onOpen}
                  >
                    +Add Customer
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Table variant="unstyled" size="md" w={"100%"}>
          <Tbody>
            <Tr>
              <Td
                color={"#828282"}
                fontSize={"12px"}
                fontWeight="600"
                align="center"
              >
                Items
              </Td>
              <Td
                color={"#828282"}
                fontSize={"12px"}
                fontWeight="600"
                align="center"
              >
                Qty
              </Td>
              <Td
                color={"#828282"}
                fontSize={"12px"}
                fontWeight="600"
                align="center"
              >
                Amnt
              </Td>
            </Tr>

            {selectedItems?.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    width={"220px"}
                    align="center"
                  >
                    <div className="twolines">{item?.name}</div>
                  </Td>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    width={"90px"}
                    padding={"0px"}
                    fontWeight={"600"}
                    align="center"
                  >
                    <Button
                      size="xs"
                      mr="5px"
                      bg="none"
                      onClick={() =>
                        handleQuantityChange(item?.uuid, "decrease")
                      }
                      disabled={item?.qty <= 1}
                    >
                      -
                    </Button>
                    {item?.qty}
                    <Button
                      size="xs"
                      bg={"none"}
                      onClick={
                        item?.is_unlimited === false &&
                        item?.available_stock === item?.qty
                          ? () =>
                              toast({
                                title: "Inventory unavailable",
                                position: "top-right",
                                status: "error",
                                duration: 1000,
                                isClosable: true,
                              })
                          : () => handleQuantityChange(item?.uuid, "increase")
                      }
                    >
                      +
                    </Button>
                  </Td>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    display={"flex"}
                    gap="3px"
                    width={"90px"}
                    height={"60px"}
                    alignItems={"center"}
                    align="center"
                  >
                    {item?.selling_price * item?.qty}

                    <AiFillDelete
                      color="#EC5A5A"
                      size={13}
                      cursor={"pointer"}
                      onClick={() => handleDelete(item.uuid)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Box px={"1rem"}>
        <TotalAmount
          breakPoint={breakPoint}
          subtotal={subtotal}
          selectedItems={selectedItems}
          tableId={tableId}
          invoiceName={invoiceName}
          cancelOrder={cancelOrder}
          AddIcon={AddIcon}
          submitValues={submitValues}
        />
      </Box>
    </Box>
  );
}

function TotalAmount({
  subtotal,
  breakPoint,
  selectedItems,
  tableId,
  invoiceName,
  cancelOrder,
  submitValues,
}) {
  const history = useHistory();
  //if add order is success then push to payment page
  const paymentOrder = () => {
    history.push({
      pathname: `/admin/order-item/${invoiceName}`,
      state: selectedItems,
    });
  };
  return (
    <Box
      border={"1px dashed #A9A9A9"}
      borderRadius={"14px 14px 0px 0px"}
      padding={"1rem"}
      bottom={"1rem"}
      right={"1rem"}
      width={"100%"}
      backgroundColor={"#FAFAFA"}
    >
      {/* TOTAL */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Text fontWeight={"600"} fontSize={"13px"}>
            SUBTOTAL
          </Text>
        </Box>
        <Box>
          <Text fontWeight={"600"}>Rs.{subtotal}</Text>
        </Box>
      </Box>
      <Box
        borderTop={"1px dashed #A9A9A9 "}
        paddingBlock={"1rem"}
        display={"flex"}
        mt="2rem"
        gap={breakPoint[0] ? "10rem" : "13rem"}
      >
        <Text fontSize={"20px"} fontWeight={"600"}>
          TOTAL
        </Text>
        <Text fontSize={"20px"} fontWeight={"600"}>
          Rs.{parseFloat(subtotal)}
        </Text>
      </Box>
      {/* BUTTON */}
      <Box
        display={"flex"}
        gap={breakPoint[0] ? "1rem" : "4rem"}
        mt="1rem"
        mb={"1rem"}
      >
        {tableId ? (
          <Button
            height={"40px"}
            _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
            size="sm"
            color="#ffff"
            bg={"buttongreen.400"}
            variant="secondary"
            onClick={paymentOrder}
          >
            MAKE PAYMENT
          </Button>
        ) : (
          <>
            <Button
              color="#ffff"
              bg="buttongreen.400"
              ml="10px"
              w={"100%"}
              variant="secondary"
              onClick={submitValues}
            >
              <Image src={AddIcon} width={"14px"} mr="8px" />
              Add Order
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
