import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const usePostEmployeesApi = () => {
        async function postPostEmployees(values) {
                const res = await authorizationAxios.post(
                        `${BASE_URL}/api/add-employee/`,
                        values
                );
                return res;
        }

        const postPostEmployeesMutation = useMutation(postPostEmployees);

        return {
                postPostEmployeesMutation,
        };
};
