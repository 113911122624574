import React, { useEffect, useRef, useState } from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Table,
  Tbody,
  Text,
  Th,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Box, Center, Tr, Td } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { PageText } from "components/Common/PageText";
import ImageIcon from "components/Common/ImageIcon";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { CancelButton } from "components/Common/CancelButton";
import Edit from "../../../../../assets/img/edit.png";
import Plus from "../../../../../assets/img/plus.png";
import useEditFloor from "./useEditFloor";
import AddTable from "../add/AddTable";
import EditTable from "./EditTable";
import { AiFillDelete } from "react-icons/ai";
import { useDeleteFloor } from "../api/useDeleteFloor";
import { useDeleteTable } from "../api/useDeleteTable";

export default function EditFloor({ data, index, refetch }) {
  const {
    isOpen,
    onOpen,
    onClose,
    isLoading,
    isError,
    error,
    isSuccess,
    register,
    handleSubmit,
    uploadPhotoFile,
    SetUploadPhotoFile,
    uploadPhotoRef,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    onFinalSubmit,
  } = useEditFloor(data);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      refetch();
    }
  }, [isSuccess]);
  const toast = useToast();
  const { isOpen: open, onOpen: opened, onClose: closed } = useDisclosure();
  const { deleteFloorMutation } = useDeleteFloor();
  const { deleteTableMutation } = useDeleteTable();

  const {
    isError: isErrorDelete,
    isSuccess: isSuccessDelete,
    refetch: refetchDelete,
    error: errorResponse,
  } = deleteFloorMutation;
  const {
    isError: isErrorTableDelete,
    isSuccess: isSuccessTableDelete,
    data: dataTable,
  } = deleteTableMutation;
  const handleDelete = (val) => {
    deleteFloorMutation.mutate(val);
  };
  const handleDeleteTable = (val) => {
    deleteTableMutation.mutate(val);
  };

  useEffect(() => {
    if (isSuccessDelete) {
      toast({
        title: "Floor deleted successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
    }

    if (isErrorDelete) {
      toast({
        title: "Something went wrong",
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [deleteFloorMutation.isSuccess, deleteFloorMutation.isError]);
  useEffect(() => {
    if (isSuccessTableDelete) {
      toast({
        title: "Table deleted successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
    }

    if (isErrorTableDelete) {
      toast({
        title: deleteTableMutation?.error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [deleteTableMutation.isSuccess, deleteTableMutation.isError]);
  return (
    <AccordionItem key={index} width={"100%"}>
      <AccordionButton>
        <Table width={"100%"}>
          <Tbody>
            <Tr cursor="pointer">
              <Td width={"10rem"} textAlign={"left"}>
                <PageText text={data?.sort ? data?.sort : "N/A"} />
              </Td>
              <Td width={"20rem"}>
                <Text fontSize={"sm"}>{data?.name}</Text>
              </Td>
              <Td width={"10rem"}>
                <Box onClick={onOpen}>
                  <ImageIcon src={Edit} height="1rem" width="1rem" />
                </Box>
              </Td>
              <Td width={"10rem"} onClick={opened}>
                <Box cursor={"pointer"}>
                  <HStack>
                    <ImageIcon src={Plus} height="1rem" width="1rem" />
                    <Text fontSize={"sm"} color={"#6ed3cf"}>
                      Add Table
                    </Text>
                  </HStack>
                </Box>
              </Td>
              <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Edit Floor</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <form onSubmit={handleSubmit(onFinalSubmit)}>
                      <input
                        type={"hidden"}
                        {...register("uuid")}
                        value={data?.uuid}
                      />
                      <Grid templateColumns={"1fr 1fr"}>
                        <GridItem>
                          <LeftSideLabelInput
                            defaultValue={data?.name}
                            label="Category name"
                            type="text"
                            register={register}
                            registerName={"name"}
                          />
                          <LeftSideLabelInput
                            label="Sort No."
                            type="text"
                            defaultValue={data?.sort}
                            register={register}
                            registerName={"sort"}
                          />
                          <Flex gap="2rem" mt="3rem" p="1rem">
                            <SaveButton />
                            {/* <CancelButton onClick={onClose} /> */}
                          </Flex>
                        </GridItem>
                      </Grid>
                    </form>
                  </ModalBody>
                </ModalContent>
              </Modal>

              <AddTable
                floor={data?.uuid}
                floorname={data?.name}
                isOpen={open}
                onClose={closed}
                refetch={refetch}
              />
              <Td width={"10rem"} textAlign={"left"}>
                <AiFillDelete
                  color={"#6ed3cf"}
                  onClick={() => handleDelete(data?.uuid)}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Table ml={"5rem"} width={"100%"}>
          <Tbody>
            <Tr>
              <Th width={"10rem"}>Sort No</Th>
              <Th width={"20rem"}>Table Name</Th>
              <Th width={"10rem"}>Make Unavailable</Th>
              <Th width={"10rem"}>Edit Table</Th>
              <Th width={"10rem"}>Delete Table</Th>
            </Tr>
            {data?.table?.map((item, index) => (
              <Tr key={index} cursor={"pointer"}>
                <Td width={"10rem"} textAlign={"left"}>
                  <PageText text={item?.table_number} />
                </Td>
                <Td width={"20rem"}>
                  <Text fontSize={"sm"}>{item?.name}</Text>
                </Td>
                <Td width={"10rem"}>
                  <Box>
                    <Text fontSize={"sm"}>
                      {item?.unavailable ? "Unavailable" : "Available"}
                    </Text>
                  </Box>
                </Td>

                <EditTable data={item} index={index} refetch={refetch} />
                <Td>
                  <AiFillDelete
                    onClick={() => handleDeleteTable(item?.uuid)}
                    color={"#6ed3cf"}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </AccordionPanel>
    </AccordionItem>
  );
}
