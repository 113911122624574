import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";
import React from "react";

export const useGetPeak = () => {
  async function getPeak() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/super-user/peak/`
    );
    return res.data.data?.map(({
      uuid: value,
      name:label,
      ...rest
    }) => ({
      value,
      label,
      ...rest
    }));;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getPeak",
    getPeak
  );

  return {
    data,
  };
};
