import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { usePostCategoriesApi } from "../api/usePostCategoriesApi";
import { useHistory } from "react-router-dom";

export const useAddCategories = () => {
  let history = useHistory();
  const toast = useToast();

  const [uploadPhotoFile, setUploadPhotoFile] = useState([]);
  const toastIdRef = useRef();
  const uploadPhotoRef = useRef(null);
  const { register, handleSubmit, control, reset } = useForm();

  const { postPostCategoriesMutation } = usePostCategoriesApi();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postPostCategoriesMutation;

  const onSubmit = (values) => {
    const formData = new FormData();

    postPostCategoriesMutation.mutate({ name: values.name });
  };

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    setUploadPhotoFile(Array.from(event.target.files));
  }

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "New Categories Added!",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      // history.push("/admin/category-list");
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: "Missing Information",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postPostCategoriesMutation.isSuccess,
    postPostCategoriesMutation.isLoading,
    postPostCategoriesMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    setUploadPhotoFile,
    uploadPhotoRef,
    uploadPhotoFile,
  };
};
