import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Text,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { globalStyles } from "theme/styles";
import Moment from "react-moment";
import { NoDataMsg } from "components/Common/NoDataMsg";
import { useGetReportApi } from "../api/useGetReportApi";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";
import axios from "axios";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useGetBrandList } from "common apis/useGetBrandList";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { PageText } from "components/Common/PageText";
import ReactToPrint from "react-to-print";
import { Loading } from "components/Common/Loading";
import PaginationButton from "components/Common/PaginationButton copy";
import { useGetUserReportApi } from "../api/useGetUserReport";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { useAddResolve } from "../api/useAddResolve";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { PageTitle } from "components/Common/PageTitle";
import { useGetKitchenReportApi } from "../api/useGetKitchenReportApi";

const KitchenReport = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [kitchenItem, setKitchenItem] = useState([]);
  const { errorNotify } = useNotify();
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  const [next, setNext] = useState(1);
  const fetchData = async () => {
    const response = await authorizationAxios.get(
      `${BASE_URL}/api/get/kitchenitem/`
    );
    setKitchenItem(response?.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const {
    data,
    refetch,
    isLoading,
    error,
    isSuccess,
    isError,
  } = useGetKitchenReportApi(
    selectedOption.value,
    document.getElementById("startdate")?.value,
    document.getElementById("enddate")?.value
  );
  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption.value);
    setSelectedOption(selectedOption);
  };
  console.log("item", isError);
  useEffect(() => {
    if (error) {
      refetch();
      errorNotify(error?.response?.data?.message);
    }
  }, [error]);
  return (
    <Box bg="white" minH="100vh">
      <Box
        display={"flex"}
        p={"1rem"}
        borderBottom={"1px solid lightgray"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          ml="1rem"
          gap={breakPoint[0] ? "0rem" : "2rem"}
          flexWrap={"wrap"}
        >
          <Box w={"12rem"}>
            <Select
              placeholder="Kitchen Item..."
              options={kitchenItem?.map((item) => {
                return {
                  value: item.uuid,
                  label: item.name,
                };
              })}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Text w={breakPoint[0] ? "3rem" : '6rem'} ml={"1rem"}>
              Start Date :{" "}
            </Text>
            <Input
              defaultValue={""}
              width={breakPoint[0] ? "9rem" : "10rem"}
              type="date"
              id="startdate"
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Text w={breakPoint[0] ? "3rem" : '5rem'} ml={"1rem"}>
              End Date :{" "}
            </Text>
            <Input defaultValue={""} width={breakPoint[0] ? "9rem" : '10rem'} type="date" id="enddate" />
          </Box>
          <Button
            width={"5rem"}
            onClick={() => refetch()}
            bg={"#3ab689"}
            color={"white"}
            ml={"1rem"}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Box
        display={"flex"}
        mt={"2rem"}
        mb={"1rem"}
        gap={"1rem"}
        ml="1rem"
        flexWrap={"wrap"}
      >
        <Box
          p={"1rem"}
          gap={"1rem"}
          borderRadius={"0.5rem"}
          boxShadow={"md"}
          minW={"14rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mr={breakPoint[0] ? "1rem" : "2rem"}

        >
          <Text fontSize={"xl"}>
            Current<br></br>Stock
          </Text>
          <Text color={"#3AB689"} fontSize={"5xl"}>
            {data?.current_stock}
          </Text>
        </Box>
        <Box
          p={"1rem"}
          gap={"1rem"}
          borderRadius={"0.5rem"}
          boxShadow={"md"}
          minW={"14rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mr={breakPoint[0] ? "1rem" : "2rem"}
        >
          <Text fontSize={"xl"}>
            Last Restock <br></br>Quantity
          </Text>
          <Text color={"#3AB689"} fontSize={"5xl"}>
            {data?.last_restock_quantity}
          </Text>
        </Box>
        <Box
          p={"1rem"}
          gap={"1rem"}
          borderRadius={"0.5rem"}
          boxShadow={"md"}
          minW={"14rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mr={breakPoint[0] ? "1rem" : "2rem"}
        >
          <Text fontSize={"xl"}>
            Total<br></br>Waste/Damage
          </Text>
          <Text color={"#3AB689"} fontSize={"5xl"}>
            {data?.total_waste_demage}
          </Text>
        </Box>
        <Box
          p={"1rem"}
          gap={"1rem"}
          borderRadius={"0.5rem"}
          boxShadow={"md"}
          minW={"14rem"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mr={breakPoint[0] ? "1rem" : "2rem"}

        >
          <Text fontSize={"xl"}>
            Total<br></br>Orders
          </Text>
          <Text color={"#3AB689"} fontSize={"5xl"}>
            {data?.total_order}
          </Text>
        </Box>
      </Box>
      <Box
        display={"flex"}
        className="report-table"
        alignItems={"baseline"}
        flexWrap={"wrap"}
        gap={"2rem"}
      >
        <Box w={breakPoint[0] ? "100%" : "40%"}>
          <PageTitle p="0.5rem" title={"Orders:"} fontW="500" pl={"1rem"} />
          <Table
            width={"100%"}
            border={"1px solid lightgray"}
            variant="striped"
          >
            <Tr>
              <Th style={globalStyles.customStyle.tableHeading}> Order</Th>
              <Th style={globalStyles.customStyle.tableHeading}> Quantity</Th>
              <Th style={globalStyles.customStyle.tableHeading}> Order Date</Th>
            </Tr>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Tbody>
                  {data?.data?.map((item, index) => (
                    <>
                      <Tr
                        cursor={"pointer"}
                        key={index}
                      // onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
                      >
                        <Td>
                          <Text>{item?.invoiceNumber ?? "-"}</Text>
                        </Td>
                        <Td>
                          <Text>{item?.unit_used ?? "-"}</Text>
                        </Td>

                        <Td>
                          <Moment format="DD-MM-YYYY">
                            {item?.invoicedate}
                          </Moment>
                        </Td>
                      </Tr>
                    </>
                  ))}
                  {data?.data === undefined ||
                    (data?.data?.length == 0 && (
                      <Tr cursor={"pointer"}>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <NoDataMsg msg="No Data Found" pos="relative" />
                        </Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                    ))}
                </Tbody>

                <PaginationButton
                  isPrevious={data?.previous}
                  isNext={data?.next}
                  next={next}
                  setNext={setNext}
                />
              </>
            )}
          </Table>
        </Box>
        <Box mb="2rem" w={breakPoint[0] ? "100%" : '50%'}>
          <PageTitle
            p="0.5rem"
            title={"Adjustments:"}
            fontW="500"
            pl={"1rem"}
          />
          <Table border={"1px solid lightgray"} variant="striped">
            <Tr>
              <Th style={globalStyles.customStyle.tableHeading}> Date</Th>
              <Th style={globalStyles.customStyle.tableHeading}> Reason</Th>
              <Th style={globalStyles.customStyle.tableHeading}>
                {" "}
                Opening Stock
              </Th>
              <Th style={globalStyles.customStyle.tableHeading}>
                {" "}
                Closing Stock
              </Th>
              <Th style={globalStyles.customStyle.tableHeading}> Adjusted</Th>
            </Tr>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Tbody>
                  {data?.adjustment?.map((item, index) => (
                    <>
                      <Tr
                        cursor={"pointer"}
                        key={index}
                      // onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
                      >
                        <Td>
                          <Moment format="DD-MM-YYYY">{item?.date}</Moment>
                        </Td>
                        <Td>
                          <Text>{item?.reason ?? "-"}</Text>
                        </Td>

                        <Td>{item?.opening_stock}</Td>
                        <Td>{item?.closing_stock}</Td>

                        <Td>{item?.quantity_adjustment}</Td>
                      </Tr>
                    </>
                  ))}
                  {data?.data === undefined ||
                    (data?.data?.length == 0 && (
                      <Tr cursor={"pointer"}>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          <NoDataMsg msg="No Data Found" pos="relative" />
                        </Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                    ))}
                </Tbody>

                <PaginationButton
                  isPrevious={data?.previous}
                  isNext={data?.next}
                  next={next}
                  setNext={setNext}
                />
              </>
            )}
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
export default KitchenReport;

function InvoiceModal({ isOpen, modalData, handleCloseModal, handleVoid }) {
  const componentRef = useRef();
  console.log("xx", modalData);
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ComponentToPrint data={modalData} ref={componentRef} />
          </ModalBody>

          <ModalFooter>
            <ReactToPrint
              trigger={() => <Button colorScheme="green">Print Order</Button>}
              content={() => componentRef.current}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log("pp", props);
  return (
    <div ref={ref}>
      <ModalHeader textAlign={"center"}>Order Details</ModalHeader>
      <Text textAlign={"center"} size="lg" mt={"-1rem"}>
        Order #:{props.data.invoiceNumber}
      </Text>
      {/* <Text textAlign={"center"} size="lg" mb={"1rem"}>
        Order Date#:{new Date()}
      </Text> */}
      <table>
        <tr
          style={{
            borderBottom: "1px dotted gray",
            textAlign: "left",
          }}
        >
          <th style={{ width: "150px", fontSize: "14px" }}>Descriptions</th>
          <th style={{ width: "50px", fontSize: "14px" }}>Qty</th>
          <th style={{ width: "30px", fontSize: "14px" }}>Price</th>
          <th style={{ width: "100px", fontSize: "14px" }}>Subtotal</th>
        </tr>
        {props?.data?.items?.map((item, index) => {
          return (
            <tr key={index}>
              <td style={{ width: "150px", fontSize: "12px" }}>
                {item?.product__name}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {item?.quantity}
              </td>
              <td style={{ width: "50px", fontSize: "12px" }}>
                {props.data.currency}
                {item?.unitprice}
              </td>

              <td
                style={{
                  width: "100px",
                  fontSize: "12px",
                  marginRight: "10px",
                }}
              >
                {props.data.currency}
                {item?.billAmount}
              </td>
            </tr>
          );
        })}
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Subtotal</td>
          <th style={{ width: "50px", fontSize: "12px" }}></th>
          <th style={{ width: "30px" }}></th>

          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_bill_amount}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Discount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.discount_amount}
          </th>
        </tr>

        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Service charge</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}{" "}
            {((props.data.total_bill_amount - props.data.discount_amount) *
              props.data.tax) /
              100}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Adjustment</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.adjustment_amount ?? 0}
          </th>
        </tr>
        <tr style={{ textAlign: "left" }}>
          <td style={{ width: "150px", fontSize: "12px" }}>Due Amount</td>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency} {props.data.dueAmount ?? 0}
          </th>
        </tr>
        <tr style={{ borderTop: "1px dotted gray", textAlign: "left" }}>
          <th style={{ width: "150px", fontSize: "12px" }}>Total</th>
          <th style={{ width: "50px" }}></th>
          <th style={{ width: "30px" }}></th>
          <th style={{ width: "100px", textAlign: "right", fontSize: "12px" }}>
            {props.data.currency}
            {props.data.total_amount}
          </th>
        </tr>
      </table>
    </div>
  );
});
