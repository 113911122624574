import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { usePostGroupItemApi } from "./api/usePostGroupItemApi";
import { useHistory } from "react-router-dom";
import useMultipleImage from "../Inventory/Add/component/useMultipleImage";

export const useAddGroupItem = () => {
	let history = useHistory();
	const toast = useToast();

	const [uploadPhotoFile, setUploadPhotoFile] = useState([]);
	const toastIdRef = useRef();
	const uploadPhotoRef = useRef(null);
	const { register, handleSubmit, control, reset } = useForm();

	const { postPostGroupItemMutation } = usePostGroupItemApi();
	const { isLoading, isError, data, error, isSuccess } = postPostGroupItemMutation;
    const {
        file,
        handleUpload,
        handleClick,
        hiddenFileInput,
        countIndex,
      } = useMultipleImage();

	const onSubmit = (values) => {
		const formData = new FormData();

		formData.append("inventory_group_uuid", values.inventory_group_uuid);
		formData.append("name", values.name);
		formData.append("sku", values.sku);
		formData.append("cost_price", values.cost_value?values.cost_value:0);
		formData.append("selling_price", values.selling_price?values.selling_price:0);
		formData.append("closing_stock", values.closing_stock?values.closing_stock:0);
		formData.append("is_unlimited", values.is_unlimited);

		file?.forEach((image) => {
            formData.append("image", image);
          });

		postPostGroupItemMutation.mutate(formData);
	};

	function uploadPhotoOnClickHandler() {
		uploadPhotoRef.current.click();
	}

	function uploadPhotoChangeHandler(event) {
		setUploadPhotoFile(Array.from(event.target.files));
	}

	useEffect(() => {
		if (isSuccess) {
			toastIdRef.current = toast({
				title: "New Group Item Added!",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
            location.reload();

			// history.push("/admin/GroupItem-list");
		}

		if (isLoading) {
			toastIdRef.current = toast({
				title: "loading.....",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		}
		if (isError) {
			toast({
				title: "Missing Information",
				position: "top-right",
				// description: "You will shortly contact you.",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [
		postPostGroupItemMutation.isSuccess,
		postPostGroupItemMutation.isLoading,
		postPostGroupItemMutation.isError,
	]);

	return {
		handleSubmit,
		register,
		control,
		reset,
		toast,
		onSubmit,
		isSuccess,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		setUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoFile,
        file,
        handleUpload,
        handleClick,
        hiddenFileInput,
        countIndex,
	};
};
