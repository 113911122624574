import {
  Box,
  HStack,
  Image,
  Input,
  Flex,
  ButtonGroup,
  Button,
  Spacer,
  Grid,
  GridItem,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Card from "./component/Card";
import ItemList from "./component/ItemList";
import Payment from "./component/Payment";
import noItem from "../../../../assets/img/no-item.svg";
import Shirt from "../../../../assets/img/shirt.svg";
import { useGetBrand } from "./apis/useGetBrandList";
import { useGetBrandItemList } from "./apis/useGetBrandItemList";
import { useGeneateInvoice } from "./useGenerateInvoice";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useFieldArray } from "react-hook-form";
import { useGetSearchItems } from "views/Pages/CommonApi/useGetSearchItems";
import Invoice from "components/Common/Invoice";
import { useReactToPrint } from "react-to-print";
import { globalStyles } from "theme/styles";
import { NoResultUI } from "components/Common/NoResultUI";

function Sales() {
  const toast = useToast();
  const [brand, setBrand] = useState("all");
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0); // total discount of item
  const [totalTaxAmount, settTotalTaxAmount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [finalItemList, setFinalItemList] = useState([]);

  const { data } = useGetBrand();
  const { data: itemList, refetch } = useGetBrandItemList(brand);
  const {
    data: searchItemList,
    refetch: refetchSearch,
    isLoading: isLoadingSearch,
  } = useGetSearchItems(searchText);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };
  // console.log("final item list", finalItemList)

  const {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    isSuccess,
    data: invoiceData,
  } = useGeneateInvoice();

  useEffect(() => {
    if (itemList && searchText.length === 0) {
      setFinalItemList(itemList);
    }
    if (searchText.length) {
      setFinalItemList(searchItemList?.items);
    }
  }, [itemList, searchItemList]);

  useEffect(() => {
    if (brand !== "all") {
      setSearchText("");
    }
    const timer = setTimeout(() => {
      refetch();
    }, 250);

    return () => clearTimeout(timer);
  }, [brand]);

  useEffect(() => {
    if (searchText.length) {
      setBrand("all");
    }
    const timer = setTimeout(() => {
      refetchSearch();
    }, 250);

    if (searchText.length === 0 && itemList) {
      //loading all data after search text is cleared
      setFinalItemList(itemList);
    }

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    if (isSuccess) {
      onOpen();
      remove();
      refetch();
      setSubTotalAmount(0);
    }
  }, [isSuccess]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "items",
  });

  const handlePush = (item) => {
    const isFound = fields.some((element) => element.uuid === item.uuid);

    if (!isFound) {
      if (item.closing_stock == 0) {
        toast({
          title: "Stock Unavailable!",
          description: "Add more stock items.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      } else {
        append(item);
        setSubTotalAmount(subTotalAmount + item.selling_price);
      }
    }
  };

  const handleRemove = (uuid, price, count, index) => {
    remove(index);
    setSubTotalAmount(subTotalAmount - price * count);
  };

  const handleRemoveAll = () => {
    remove();
    setSubTotalAmount(0);
  };

  async function handleScan(e) {
    if (e.target.value != "") {
      const res = await authorizationAxios.get(
        `${BASE_URL}/api/itemsbysku/?sku_upc=${e.target.value}`
      );

      handlePush(res.data.data);
      document.getElementById("scanInput").value = "";
    }
  }

  let totalRef = useRef(0);
  getValues("items")?.forEach((list) => {
    totalRef.current = totalRef.current + list.price * list.quantity;
    // setSubTotal((prevState) => prevState + list.price )
    // subTotal.current = +subTotal.current + +(list.price * list.quantity);
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function sortByName(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Box direction="column">
        <Box bg={"white"}>
          <HStack>
            {/* First Column */}
            <Flex
              direction="column"
              px={10}
              py={5}
              h="100vh"
              flex="auto"
              w={"65%"}
            >
              <Flex
                align="center"
                justify="space-between"
                mb=".5rem"
                gap="1rem"
              >
                <Text
                  fontSize="2rem"
                  fontWeight="semibold"
                  textShadow="1px 1px  #f4b289" // set the text shadow
                  color={globalStyles.colors.orange[600]}
                  whiteSpace="nowrap"
                >
                  {brand.toUpperCase()}
                </Text>
                <Input
                  maxW="25rem"
                  placeholder="Search"
                  value={searchText}
                  size="lg"
                  p={4}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Flex>
              <Box flexGrow={1} scrollBehavior="smooth" overflowY="scroll">
                <Flex
                  flexWrap="wrap"
                  gap="1rem"
                  rowGap="1rem"
                  pt={5}
                  zIndex={1}
                >
                  {finalItemList?.length ? (
                    finalItemList?.sort(sortByName)?.map(
                      (item, idx) =>
                        item?.closing_stock > 0 && (
                          <Box
                            key={idx}
                            h="fit-content"
                            onClick={() => handlePush(item)}
                          >
                            <Card
                              brand={item?.inventory_group?.brand}
                              closing_stock={item?.closing_stock}
                              photo={
                                item?.image?.length > 0
                                  ? item?.image[0].image
                                  : Shirt
                              }
                              description={item.name}
                            />
                          </Box>
                        )
                    )
                  ) : (
                    <NoResultUI left="30%" />
                  )}

                  {/* {searchText?.length === 0 &&
										itemList
											?.sort(function (a, b) {
												if (a.name < b.name) {
													return -1;
												}
												if (a.name > b.name) {
													return 1;
												}
												return 0;
											})
											?.map(
												(item, idx) =>
													item?.closing_stock > 0 && (
														<Box
															key={idx}
															h='fit-content'
															onClick={() => handlePush(item)}
														>
															<Card
																brand={item?.inventory_group?.brand}
																closing_stock={item?.closing_stock}
																photo={
																	item?.image?.length > 0
																		? item?.image[0].image
																		: Shirt
																}
																description={item.name}
															/>
														</Box>
													)
											)
									}

									{searchText.length > 0 &&
										searchItemList?.items?.map((item, idx) => (
											<Box key={idx} onClick={() => handlePush(item)}>
												<Card
													brand={item?.inventory_group?.brand}
													closing_stock={item?.closing_stock}
													photo={
														item?.image?.length > 0 ? item?.image[0].image : Shirt
													}
													description={item.name}
												/>
											</Box>
										))
									} */}
                </Flex>
              </Box>
              <Flex
                width="100%"
                borderTop="1px solid"
                borderColor={globalStyles.colors.mediumDark}
              >
                <Box overflow="scroll" p="1rem">
                  <ButtonGroup gap={2} alignItems="center">
                    <Button
                      p="1.5rem"
                      minW="8rem"
                      color="white"
                      bg="#3AB689"
                      // shadow='base'
                      boxShadow=" 0px 2px 8px 1px rgba(99, 99, 99, 0.4)"
                      _hover={{
                        // bg: '#e5d6b9',
                        transform: "scale(1.05)",
                      }}
                      onClick={() => {
                        setBrand("all");
                        setSearchText("");
                      }}
                    >
                      All
                    </Button>
                    {data?.map((item, idx) => (
                      <Button
                        key={idx}
                        p="1.5rem"
                        color="white"
                        bgColor={item?.color_code}
                        shadow="base"
                        onClick={() => setBrand(item?.name)}
                        _hover={{
                          bg: item?.color_code,
                          transform: "scale(1.05)",
                        }}
                      >
                        {item?.name}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              </Flex>
            </Flex>

            {/* Second Column */}
            <Box w={"35%"}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => checkKeyDown(e)}
              >
                <Flex
                  direction="column"
                  // w={{ base: "35rem", lg: "35rem", md: '30rem', sm: "30rem" }}
                  w={"100%"}
                  h="100vh"
                  gap="5"
                  borderLeft="2px solid #F4F4F4"
                >
                  <HStack direction="row" px={3}>
                    <Button variant="ghost" w="fit-content">
                      Add Customer Name
                    </Button>
                    <Spacer />
                    {/* <Text>Transaction #NS001</Text> */}
                  </HStack>
                  <Grid templateColumns="repeat(2, 1fr)" px={6}>
                    <GridItem>
                      <Input
                        onChange={(e) => handleScan(e)}
                        float={"left"}
                        label={"QR"}
                        size="sm"
                        placeholder="scan here"
                        id="scanInput"
                      />
                    </GridItem>
                    <GridItem>
                      {fields.length != 0 && (
                        <Button
                          float={"right"}
                          color="red"
                          variant="ghost"
                          w="fit-content"
                          onClick={() => handleRemoveAll()}
                        >
                          Clear items
                        </Button>
                      )}
                    </GridItem>
                  </Grid>
                  <div style={{ borderBottom: "2px solid #F4F4F4" }}></div>
                  <Flex
                    direction="column"
                    flex="auto"
                    overflowY="scroll"
                    scrollBehavior="smooth"
                    gap={4}
                    px={6}
                  >
                    {!fields.length && <Image src={noItem} opacity="0.6" />}

                    {fields.map((item, index) => {
                      return (
                        <Box key={index}>
                          <input
                            {...register("items." + index + ".id")}
                            type="hidden"
                            value={item.uuid}
                          />
                          <ItemList
                            register={register}
                            setValue={setValue}
                            price={item.selling_price}
                            index={index}
                            max={item.closing_stock}
                            image={
                              item?.image?.length > 0
                                ? item.image[0].image
                                : Shirt
                            }
                            description={item.name}
                            handleRemove={handleRemove}
                            uuid={item.uuid}
                            subTotalAmount={subTotalAmount}
                            setSubTotalAmount={setSubTotalAmount}
                            fields={fields}
                            getValues={getValues}
                            control={control}
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                  <Payment
                    register={register}
                    getValues={getValues}
                    watch={watch}
                    subTotalAmount={subTotalAmount}
                    control={control}
                    total={total}
                    setTotal={setTotal}
                    discount={discount}
                    setDiscount={setDiscount}
                    totalTaxAmount={totalTaxAmount}
                    settTotalTaxAmount={settTotalTaxAmount}
                  />
                </Flex>
              </form>
            </Box>
          </HStack>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {invoiceData && (
              <Invoice invoiceData={invoiceData} ref={componentRef} />
            )}
          </ModalBody>

          <ModalFooter mt="2rem">
            <Flex gap="1.5rem">
              <Button
                colorScheme="green"
                minW="6rem"
                shadow="lg"
                onClick={handlePrint}
              >
                Print
              </Button>
              <Button
                minW="6rem"
                shadow="base"
                border="1px solid"
                borderColor={globalStyles.colors.mediumDark}
                onClick={onClose}
              >
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Sales;
