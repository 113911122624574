import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetUserReportApi = (
  username,
  payment,
  startdate,
  enddate,
  next
) => {
  async function getUserReport() {
    if (payment || username || startdate || enddate || next) {
      const res = await authorizationAxios.get(
        `${BASE_URL}/api/restaurant/resolve/report/?customer=${username}&status=${
          payment ?? ""
        }&start_date=${startdate ?? ""}&end_date=${enddate ?? ""}&next=${next}`
      );
      return res.data;
    } else {
      return "Select Parameters";
    }
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getUserReport", next],
    () => getUserReport(next)
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
