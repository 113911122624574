import { Box, Button, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
export default function MenuItems({
  data,
  setMenuUuid,
  setMenuCategory,
  setCategoryActive,
  setSubCategoryActive,
  setAllMenu,
}) {
  const history = useHistory();

  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const handleSubCategory = (val) => {
    setMenuUuid(val);
    setSubCategoryActive(true);
    setCategoryActive(false);
  };
  const handleMenu = (val) => {
    setMenuCategory(val);
    setCategoryActive(true);
    setSubCategoryActive(false);
  };
  const handleAll = (val) => {
    setMenuCategory("");
    setAllMenu(true);
    setSubCategoryActive(false);
  };

  return (
    <>
      <Box
        display={breakPoint[0] ? "none" : "block"}
        borderRight={"1px solid #ECEDED"}
        position={"absolute"}
        left={"0px"}
        minH={"-webkit-fill-available"}
        width={breakPoint[0] ? "0" : "280px"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton
                _expanded={{ bg: "buttongreen.400", color: "white" }}
                onClick={() => handleAll()}
              >
                <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                  All
                </Box>
              </AccordionButton>
            </h2>
          </AccordionItem>
          {data?.map((item, index) => {
            return (
              <>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => handleMenu(item?.uuid)}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        {item?.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  {item?.sub_category.map((val, index) => {
                    return (
                      <>
                        <AccordionPanel
                          cursor={"pointer"}
                          pb={4}
                          borderBottom={"1px solid #EBEBEB"}
                          borderTop={"1px solid #EBEBEB"}
                          fontWeight={"600"}
                          onClick={() => handleSubCategory(val?.uuid)}
                        >
                          {val?.name}
                        </AccordionPanel>
                      </>
                    );
                  })}
                </AccordionItem>
              </>
            );
          })}
        </Accordion>
      </Box>
    </>
  );
}
