import axios from "axios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useNewPasswordApi = () => {

  async function postNewPassword(values) {
    const res = await axios.post(
      `${BASE_URL}/api/forget-password/`,
      values
    );
    return res;
  }

  const postNewPasswordMutation = useMutation(postNewPassword);

  return {
    postNewPasswordMutation,
  };
};
