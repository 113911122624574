import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditRetailerPaymentApi = () => {
  async function postEditRetailer(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/retailer/order/update/`,
      values
    );
    return res;
  }

  const postEditRetailerMutation = useMutation(postEditRetailer);

  return {
    postEditRetailerMutation,
  };
};
