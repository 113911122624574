import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostGroupItemApi = () => {

  async function postPostGroupItem(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/add-inventoryitem/`,
      values
    );
    return res;
  }

  const postPostGroupItemMutation = useMutation(postPostGroupItem);

  return {
    postPostGroupItemMutation,
  };
};
