import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetSearchItems = (query) => {
	async function GetSearchItems() {
		const res = await authorizationAxios.get(
			`${BASE_URL}/api/search-item/?q=${query}`
		);
		return res.data;
	}

	const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
		"GetSearchItems",
		GetSearchItems,
		{
			retry: false
		}
	);

	return {
		data,
		refetch,
		isLoading
	};
};
