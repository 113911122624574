import React, { useEffect, useRef, useState } from "react";
import {
	Flex,
	Grid,
	GridItem,
	HStack,
	Image,
	Text,
	VStack,
} from "@chakra-ui/react";
import {
	Box,
	Center,
	Tr,
	Td,
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { PageText } from "components/Common/PageText";
import ImageIcon from "components/Common/ImageIcon";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { CancelButton } from "components/Common/CancelButton";
import useEditManufacturer from "./useEditManufacturer";

export default function EditManufacturer({ data, index, refetch }) {

	const {
		isOpen,
		onOpen,
		onClose,
		isLoading,
		isError,
		error,
		isSuccess,
		register,
		handleSubmit,
		uploadPhotoFile,
		SetUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		onFinalSubmit,
	} = useEditManufacturer(data);

	useEffect(() => {
		if (isSuccess) {
			onClose();
			refetch();
		}
	}, [isSuccess]);

	return (
		<Tr key={index} cursor="pointer" onClick={onOpen}>
			<Td width={'20rem'}>
				<HStack>
					<ImageIcon src={data?.logo} person />
					<Box textAlign={"left"}>
						<VStack>
							<Text fontSize={"sm"}>{data?.name}</Text>
						</VStack>
					</Box>
				</HStack>
			</Td>
			{/* <Td width={'20rem'}>
				<PageText text={data?.color_code ? data?.color_code : "N/A"} />
			</Td>
			<Td>
				<PageText text={data?.slogan} />
			</Td> */}
			<Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Edit Manufacturer</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<form onSubmit={handleSubmit(onFinalSubmit)}>
								<input
									type={"hidden"}
									{...register("uuid")}
									value={data?.uuid}
								/>
								<Grid templateColumns={"1fr 1fr"}>
									<GridItem>
										<LeftSideLabelInput
											defaultValue={data?.name}
											label="Manufacturer name"
											type="text"
											register={register}
											registerName={"name"}
										/>
										{/* <LeftSideLabelInput
											label="Color Code"
											type="text"
											defaultValue={data?.color_code}
											register={register}
											registerName={"color_code"}
										/>
										<LeftSideLabelTextArea
											defaultValue={data?.slogan}
											label="Slogan"
											register={register}
											registerName={"slogan"}
										/> */}
										<Flex gap="2rem" mt="3rem" p="1rem">
											<SaveButton />
											{/* <CancelButton onClick={onClose} /> */}
										</Flex>
									</GridItem>
									<GridItem>
										<Box onClick={uploadPhotoOnClickHandler}>
											<input
												type="file"
												hidden
												ref={uploadPhotoRef}
												onChange={uploadPhotoChangeHandler}
											/>
											<Center
												w="12rem"
												h="8rem"
												borderTop={"1px dashed #808080"}
												borderLeft={"1px dashed #808080"}
												borderRight={"1px dashed #808080"}
												borderTopRadius="8px"
											>
												{uploadPhotoFile.length == 0 ? (
													<Image
														src={data?.logo}
														alt={"upload image"}
														w="13rem"
														h="7.8rem"
														borderTopRadius="8px"
														objectFit={"cover"}
													/>
												) : (
													<Image
														src={
															uploadPhotoFile.length !== 0
																? URL.createObjectURL(uploadPhotoFile[0])
																: ""
														}
														w="13rem"
														h="7.8rem"
														borderTopRadius="8px"
														objectFit={"cover"}
													/>
												)}
											</Center>
											<Center
												w="12rem"
												h="2rem"
												border={"1px solid #808080"}
												borderBottomRadius="8px"
											>
												Upload Logo
											</Center>
										</Box>
									</GridItem>
								</Grid>
							</form>
						</ModalBody>
					</ModalContent>
			</Modal>
		</Tr>
	);
}
