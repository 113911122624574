import { useForm } from "react-hook-form";
import { useEditKitchenDetails } from "../../api/useEditKitchenDetailsApi";
import { useRef, useEffect } from "react";
import { useToast } from "@chakra-ui/react";

export const useEditkitchenDetails = () => {
  const { editKitchenDetailsMutation } = useEditKitchenDetails();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = editKitchenDetailsMutation;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    reset,
    control,
  } = useForm();
  const toastIdRef = useRef();
  const toast = useToast();

  const onSubmit = (values) => {
    editKitchenDetailsMutation.mutate(values);
  };
  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Updated",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      const reloadTimeout = setTimeout(() => {
        location.reload();
      }, 1000);

      return () => clearTimeout(reloadTimeout);
    }

    if (isError) {
      toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    editKitchenDetailsMutation.isSuccess,
    editKitchenDetailsMutation.isLoading,
    editKitchenDetailsMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    onSubmit,
    control,
    onSubmit,
    setValue,
  };
};
