import React, { useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import Moment from "react-moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaginationButton from "components/Common/PaginationButton copy";
import { Loading } from "components/Common/Loading";
import { useGetUnpaidSalesReportApi } from "../api/useGetUnpaidSalesReport";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useAddResolve } from "../api/useAddResolve";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { NoDataMsg } from "components/Common/NoDataMsg";

export default function UnpaidSalesReport() {
  const [next, setNext] = useState(1);
  const [resolveOpen, setResolveOpen] = useState(false);
  const { data, refetch, isLoading } = useGetUnpaidSalesReportApi(next);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customerNumber, setCustomerNumber] = useState("");
  const [customeId, setCustomerId] = useState("");
  const [id, setId] = useState("");

  const resolveType = [
    { value: "pay later", label: "Pay Later", code: "pay later" },
    { value: "to owner", label: "To owner", code: "to owner" },
  ];

  const history = useHistory();
  const handleResolveOpen = (val) => {
    setId(val.uuid);
    setResolveOpen(true);
    setCustomerNumber(val.customer.customer_number);
  };
  console.log("check", id);

  const {
    handleSubmit: handleResolveSubmit,
    onSubmit: onResolveSubmit,
    register,
    control,
    data: resolveData,
    isSuccess,
  } = useAddResolve();
  return (
    <Table variant="striped">
      <Modal isOpen={resolveOpen} onClose={() => setResolveOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resolve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleResolveSubmit(onResolveSubmit)}>
              <input type={"hidden"} value={id} {...register("uuid")} />

              <LeftSideLabelSelect
                ml="-2rem"
                label="Resolve Type"
                data={resolveType}
                register={register}
                registerName={"resolve_type"}
                control={control}
              />

              <LeftSideLabelInput
                label="Customer"
                type="text"
                register={register}
                defaultValue={customerNumber}
                registerName={"customer"}
              />
              <LeftSideLabelTextArea
                w="15.5rem"
                label="Remarks"
                type="text"
                register={register}
                registerName={"remarks"}
              />
              <Flex gap="1rem" mt="3rem">
                <SaveButton />
                <CancelButton onClose={() => setResolveOpen(false)} />
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Tr>
        <Th style={globalStyles.customStyle.tableHeading}> Order Id</Th>
        <Th style={globalStyles.customStyle.tableHeading}>Order Date</Th>
        <Th style={globalStyles.customStyle.tableHeading}> Order Amount </Th>

        <Th style={globalStyles.customStyle.tableHeading}> Due Amount</Th>
        {/* <Th style={globalStyles.customStyle.tableHeading}> Invoice Amount</Th> */}
      </Tr>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Tbody>
            {data?.data?.map((item, index) => (
              <>
                <Tr
                  cursor={"pointer"}
                  key={index}
                  // onClick={() => history.push(`/admin/edit/${item?.uuid}`)}
                >
                  <Td>
                    <Text>{item?.invoiceNumber}</Text>
                  </Td>
                  <Td>
                    <Moment format="DD-MM-YYYY">{item?.invoicedate}</Moment>
                  </Td>
                  <Td>
                    Rs.
                    {/* {item?.currency} */}
                    {item?.total_amount}
                  </Td>

                  <Td>{item?.dueAmount}</Td>
                  <Td>
                    <Flex gap="0.5rem">
                      <Button
                        onClick={
                          () =>
                            history.push({
                              pathname: `/admin/order-item/${item?.invoiceNumber}`,
                              state: { unpaid: "unpaid" },
                            })
                          // history.push(`/admin/payment/${item?.uuid}`)
                        }
                        colorScheme="green"
                      >
                        Pay
                      </Button>

                      <Button onClick={() => handleResolveOpen(item)}>
                        Resolve
                      </Button>
                      {/* Resolve Modal */}

                      {/* Resolve Modal */}
                    </Flex>
                  </Td>
                </Tr>
              </>
            ))}
            {data?.data === undefined ||
              (data?.data?.length == 0 && (
                <Tr cursor={"pointer"}>
                  <Td></Td>
                  <Td></Td>
                  <Td>
                    <NoDataMsg msg="No Data Found" pos="relative" />
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              ))}
          </Tbody>
          <PaginationButton
            isPrevious={data?.previous}
            isNext={data?.next}
            next={next}
            setNext={setNext}
          />
        </>
      )}
    </Table>
  );
}
