import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetRetailerInsightsApi = () => {
	async function getRetailerInsights() {
		const res = await authorizationAxios.get(
			`${BASE_URL}/api/retailer/dashboard/`
		);
		return res?.data?.data;
	}

	const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
		"getRetailerInsights",
		getRetailerInsights
	);

	return {
		data,
		refetch,
		isLoading
	};
};
