import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useAddReceipeApi } from "views/Pages/Inventory/api/useAddReceipeApi";
import { useNotify } from "common hooks/useNotify";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

export const usePostRecipe = () => {
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    reset,
  } = useForm();
  const [receipeData, setreceipeData] = useState("");
  const { postAddReceipeGroupMutation } = useAddReceipeApi();
  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postAddReceipeGroupMutation;
  useEffect(() => {
    watch();
  }, [watch]);
  const watchUuid = watch().sales_inventory;
  console.log(watchUuid);
  const fetchData = async () => {
    if (watchUuid) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/get-add/receipe/?uuid=${watchUuid}`
      );
      setreceipeData(response?.data?.data);
      console.log("rr", response);
    }
  };
  useEffect(() => {
    fetchData();
  }, [watchUuid]);

  const onSubmit = (values) => {
    // const updatedArray1 = values?.item?.filter((item1) =>
    //   receipeData.every((item2) => item2.uuid !== item1.uuid)
    // );
    const data = {
      sales_inventory: values.sales_inventory,
      kitchen_item: values.item?.map((item) => {
        return {
          uuid: item?.uuid ? item?.uuid : "",
          kitchen_inventory: item.kitchen_inventory,
          quantity: parseInt(item.name),
        };
      }),
    };

    postAddReceipeGroupMutation.mutate(data);
  };
  useEffect(() => {
    if (isSuccess) {
      successNotify("Receipe Added Successfully");
      location.reload();
    }

    if (isError) {
      errorNotify(
        error?.response?.data.message
          ? error?.response?.data.message
          : error.message
      );
    }
  }, [
    postAddReceipeGroupMutation.isSuccess,
    postAddReceipeGroupMutation.isError,
  ]);
  return {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    reset,
  };
};
