import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import SearchUI from "components/Common/SearchUI";
import { globalStyles } from "theme/styles";
import React, { useState } from "react";
import AddUI from "components/Common/AddUI";
import SortIcon from "components/Common/SortIcon";
import { PageText } from "components/Common/PageText";
import { NavLink, useHistory } from "react-router-dom";
import useGroupList from "./useGroupList";
import PaginationButton from "components/Common/PaginationButton copy";

const InventoryList = ({ searchQuery }) => {
  const [next, setNext] = useState(1);

  const { history, data } = useGroupList(next, searchQuery);
  function logoutHandler() {
    history.push("/signin");
  }
  console.log(data);
  const handleInventoryDetail = (id) => {
    // history.push('/admin/inventory/detail', { id: 7, color: 'green' })
    history.push({
      pathname: "/admin/inventory-detail",
      search: "?id=" + id,
      state: { id: id },
    });
  };

  return (
    <>
      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}>Group Name</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Category</Th>
          <Th style={globalStyles.customStyle.tableHeading}>Stock on hand</Th>
        </Tr>
        <Tbody>
          {data?.data?.map((item, index) => {
            return (
              <Tr
                key={index}
                cursor="pointer"
                onClick={() => handleInventoryDetail(item.uuid)}
              >
                <Td>
                  <HStack>
                    <Image
                      src={item?.image_url}
                      height={"2.5rem"}
                      width={"2.5rem"}
                      borderRadius={"0.5rem"}
                    />

                    <Box textAlign={"left"}>
                      <VStack>
                        <Text fontSize={"sm"}>
                          {item?.name ? item.name : item?.description}
                        </Text>
                        <Text
                          fontSize={"sm"}
                          width="100%"
                          textAlign={"left"}
                          marginTop={"0rem!important"}
                          style={globalStyles.customStyle.fontColor}
                        >
                          ({item?.item_count} items)
                        </Text>
                      </VStack>
                    </Box>
                  </HStack>
                </Td>
                <Td>
                  <PageText text={item?.category?.name} />
                </Td>
                <Td textOverflow={"ellipsis"} text>
                  {item?.item_in_hand}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <PaginationButton
          isPrevious={data?.previous}
          isNext={data?.next}
          next={next}
          setNext={setNext}
        />
      </Table>{" "}
    </>
    // <Box bg={"white"} minH="100vh" pos="relative">
    //   <Tabs
    //     // w="vw"
    //     borderColor={globalStyles.customStyle.borderColor.borderColor}
    //   >
    //     <TabList>
    //       <Tab
    //         style={globalStyles.customStyle.tab}
    //         _selected={globalStyles.customStyle.selectedTab}
    //         _focus={"none"}
    //         p="1.5rem"
    //       >
    //         All
    //       </Tab>
    //     </TabList>

    //     <TabPanels>
    //       <TabPanel>
    //      <Table variant="striped">
    //           <Tr>
    //             <Th style={globalStyles.customStyle.tableHeading}>
    //               Group Name
    //             </Th>
    //             <Th style={globalStyles.customStyle.tableHeading}>Brand</Th>
    //             <Th style={globalStyles.customStyle.tableHeading}>
    //               Manufactured By
    //             </Th>
    //             <Th style={globalStyles.customStyle.tableHeading}>
    //               Stock on hand
    //             </Th>
    //           </Tr>
    //           <Tbody>
    //             {data?.map((item, index) => {
    //               return (
    //                 <Tr
    //                   key={index}
    //                   cursor="pointer"
    //                   onClick={() => handleInventoryDetail(item.uuid)}
    //                 >
    //                   <Td>
    //                     <HStack>
    //                       <Image
    //                         src={item?.images?.image}
    //                         height={"2.5rem"}
    //                         width={"2.5rem"}
    //                         borderRadius={"0.5rem"}
    //                       />

    //                       <Box textAlign={"left"}>
    //                         <VStack>
    //                           <Text fontSize={"sm"}>
    //                             {item.name ? item.name : item.description}
    //                           </Text>
    //                           <Text
    //                             fontSize={"sm"}
    //                             width="100%"
    //                             textAlign={"left"}
    //                             marginTop={"0rem!important"}
    //                             style={globalStyles.customStyle.fontColor}
    //                           >
    //                             ({item.item_count} items)
    //                           </Text>
    //                         </VStack>
    //                       </Box>
    //                     </HStack>
    //                   </Td>
    //                   <Td>
    //                     <PageText text={item.brand} />
    //                   </Td>
    //                   <Td>
    //                     <PageText text={item.manufactured_by} />
    //                   </Td>
    //                   <Td textOverflow={"ellipsis"} text>
    //                     {item.item_in_hand}
    //                   </Td>
    //                 </Tr>
    //               );
    //             })}
    //           </Tbody>
    //         </Table>
    //       </TabPanel>
    //     </TabPanels>
    //   </Tabs>

    //   <HStack style={globalStyles.customStyle.customSearchNav}>
    //     <Box style={globalStyles.customStyle.customSearchNav_sec1}>
    //       <SearchUI label={"Search Group"} />
    //     </Box>
    //     <Box style={globalStyles.customStyle.customSearchNav_sec2}>
    //       <NavLink to={"/admin/inventory/bulk-add"}>
    //         <AddUI label={"Add Item Group"} />
    //       </NavLink>
    //     </Box>
    //     <Box
    //       style={globalStyles.customStyle.customSearchNav_sec3}
    //       color="orange.500"
    //       onClick={logoutHandler}
    //     >
    //       {/* <SortIcon /> */}
    //       Logout
    //     </Box>
    //   </HStack>
    // </Box>
  );
};

export default InventoryList;
