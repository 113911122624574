import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostMenuItem = () => {
  async function postMenu(values) {
    const res = await authorizationAxios.post(`${BASE_URL}/api/order/`, values);
    return res;
  }

  const postMenuMutation = useMutation(postMenu);

  return {
    postMenuMutation,
  };
};
