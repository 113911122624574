import {
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Input,
  Textarea,
} from "@chakra-ui/react";
import {
  AiOutlineArrowLeft,
  AiOutlineCheck,
  AiOutlineDelete,
} from "react-icons/ai";

const ThreeProperty = ({
  itemgroupdata,
  countIndex,
  getValues,
  itemRemove,
  register,
  seconds,
  setValue,
}) => {
  let count = -1;

  return (
    <>
      {/* when size is 3 */}

      {itemgroupdata &&
        itemgroupdata.length == 3 &&
        itemgroupdata[0].value.split(",").map((color, index) => {
          return itemgroupdata[1]?.value.split(",").map((size, i) => {
            return itemgroupdata[2]?.value.split(",").map((row3, rowIndex) => {
              count = count + 1;
              countIndex.current = countIndex.current + 1;
              setValue(
                "item." + count + ".name",
                localStorage.getItem("company_type") === "Retailer"
                  ? getValues("groupname") +
                      "-" +
                      color +
                      "-" +
                      size +
                      "-" +
                      row3
                  : color + " " + size + " " + row3
              );
              return (
                <Grid
                  gap={"1rem"}
                  templateColumns={"repeat(9, 1fr)"}
                  mt="1.5rem"
                  mb="1.5rem"
                  key={count}
                >
                  <GridItem>
                    <Textarea
                      width="7.5rem"
                      type="text"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".name")}
                      //   defaultValue={
                      //     getValues("groupname") +
                      //     "-" +
                      //     color +
                      //     "-" +
                      //     size +
                      //     "-" +
                      //     row3
                      //   }
                      required
                      rows="2"
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      type="text"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".sku")}
                      required
                      defaultValue={seconds + "" + count}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      // id={"item." + rowIndex + ".cost_price"}
                      type="number"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".cost_price")}
                      defaultValue={0}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      type="number"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".selling_price")}
                      defaultValue={0}
                      required
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      type="text"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".upc")}
                    />
                  </GridItem>
                  <GridItem>
                    <Input
                      type="text"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".isbn")}
                    />
                  </GridItem>
                  <GridItem>
                    <HStack>
                      <Input
                        width="7.5rem"
                        type="number"
                        borderColor="#D3D3D3"
                        borderRadius={"0.3rem"}
                        size="sm"
                        {...register("item." + count + ".opening_stock")}
                        defaultValue={0}
                        required
                      />
                    </HStack>
                  </GridItem>
                  <GridItem width={"5rem"}>
                    <Checkbox
                      {...register("item." + count + ".is_unlimited")}
                      size={"2xl"}
                      icon={<AiOutlineCheck />}
                    ></Checkbox>
                  </GridItem>
                  <GridItem width={"5rem"}>
                    <Checkbox
                      {...register("item." + count + ".delete")}
                      size={"2xl"}
                      icon={<AiOutlineDelete />}
                      colorScheme={"red"}
                    ></Checkbox>
                  </GridItem>
                </Grid>
              );
            });
          });
        })}
    </>
  );
};

export default ThreeProperty;
