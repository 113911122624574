import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetInventoryItemApi = (sku_upc) => {

  async function getInventoryItemApi() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/itemsbysku/?sku_upc=${sku_upc}&`
    );
    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getInventoryItemApi",
    getInventoryItemApi
  );

  return {
    data,
    refetch,
  };
};
