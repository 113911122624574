import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import food1 from "../../../assets/img/Food1.png";
import "@splidejs/splide/dist/css/splide.min.css";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
// or only core styles
export default function Favorites({ responseData }) {
  const splideOptions = {
    type: "loop", // Infinite loop
    perPage: 6, // Number of slides to display at once
    perMove: 1, // Number of slides to move when navigating
    pagination: false, // Show pagination dots
    breakpoints: {
      370: {
        perPage: 2,
      },
      550: {
        perPage: 3, // Adjust the number of slides for smaller screens
      },
      700: {
        perPage: 4, // Adjust the number of slides for smaller screens
      },
      1000: {
        perPage: 4, // Adjust the number of slides for smaller screens
      },
      1300: {
        perPage: 5, // Adjust the number of slides for smaller screens
      },
    },
  };
  console.log(responseData);
  return (
    <Box mt="2rem" className="responsive-title">
      <Text className="food-title">Our Favorites</Text>
      <Box className="splide-container" ml="3rem">
        <Splide options={splideOptions}>
          {responseData?.map((item, index) => {
            return (
              <SplideSlide>
                <Box className="slider-favorite">
                  <Image
                    className="slider-favorite-img"
                    objectFit={"cover"}
                    src={item?.image}
                    height={"130px"}
                    width={"250px"}
                  />

                  <Box p="1" textAlign={"center"}>
                    <Text
                      className="responsive-subtitle"
                      lineHeight={"18px"}
                      fontWeight={"bold"}
                      fontSize={"15px"}
                      color={"#000000"}
                    >
                      {item?.name}
                    </Text>
                    <Text
                      className="responsive-text"
                      fontWeight={"bold"}
                      fontSize={"14px"}
                      color={"#000000"}
                    >
                      Rs.{item?.selling_price}
                    </Text>
                  </Box>
                </Box>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </Box>
  );
}
