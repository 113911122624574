import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostInventoryItemApi = () => {
    async function postInventoryItem(values) {
        const res = await authorizationAxios.patch(
            `${BASE_URL}/api/group-item/update/`,
            values
        );
        return res;
    }

    const postInventoryItemMutation = useMutation(postInventoryItem);

    return {
        postInventoryItemMutation,
    };
};
