import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { PageTitle } from "components/Common/PageTitle";
import { PageText } from "components/Common/PageText";
import { globalStyles } from "theme/styles";
import { LeftSideLabelText } from "components/Common/LeftSideLabelText";
import { NavLink } from "react-router-dom";
import { useProfile } from "./useProfile";
import { useEffect } from "react";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { PageSubTitle } from "components/Common/PageSubTitle";

const Profile = () => {
  const { dataGetProfile, toast } = useProfile();
  // console.log("getProfileData", dataGetProfile);
  // useEffect(()=>{

  // },[])
  console.log("dd", dataGetProfile);
  return (
    <Box direction="column">
      <Box h="97vh" bg={"white"}>
        {/* header */}
        <Box p="1rem" pb={"0"}>
          <Grid templateColumns="repeat(7, 1fr)" p="2rem">
            <GridItem colSpan={5}>
              <HStack gap={"2rem"}>
                <Image
                  src={dataGetProfile?.profile}
                  style={globalStyles.customStyle.bigProfileImage}
                />

                <Box ml={"5rem"}>
                  <PageSubTitle
                    title={`${dataGetProfile?.first_name} ${dataGetProfile?.last_name}`}
                  />
                  {/* <PageText text={dataGetProfile?.experience[0]?.job} /> */}
                  <LeftSideLabelText
                    label={"Phone"}
                    value={dataGetProfile?.contact[0]?.value}
                  />
                  <LeftSideLabelText
                    label={"Email"}
                    value={dataGetProfile?.email}
                    mt={"0.2rem"}
                  />
                </Box>
              </HStack>
            </GridItem>

            <GridItem colSpan={2}>
              <HStack float={"right"} gap={"1rem"}>
                <NavLink to={"/admin/profile-update"}>
                  <Button
                    style={globalStyles.customStyle.editButton}
                    rightIcon={<EditIcon />}
                  >
                    Edit{" "}
                  </Button>
                </NavLink>
                {/* <Button
                  style={globalStyles.customStyle.editButton}
                  rightIcon={<ChevronDownIcon w={6} h={6} />}
                >
                  More{" "}
                </Button> */}
              </HStack>
            </GridItem>
          </Grid>
        </Box>

        {/* body and tabs */}
        <Tabs overflow={"hidden"}>
          <TabList
            id="scroll"
            variant="unstyled"
            gap={{ sm: "1rem", md: "6rem" }}
            overflowX={{ sm: "scroll", xl: "visible" }}
            overflowY="hidden"
            scrollBehavior="smooth"
            // onClick={tabHandler}
            p="1rem"
            bg={"#F8F8F8"}
            borderTop="2px solid #EAEAEA"
            borderBottom="2px solid #EAEAEA"
          >
            <Tab
              _selected={globalStyles.customStyle.unStyledTab}
              pr="2rem"
              pl={"2rem"}
              _hover={globalStyles.customStyle.tabHover}
            >
              Personal
            </Tab>

            <Tab
              _selected={globalStyles.customStyle.unStyledTab}
              fontSize={{ base: "md", md: "md", sm: "sm" }}
              pr="2rem"
              pl={"2rem"}
              _hover={globalStyles.customStyle.tabHover}
            >
              Address
            </Tab>
            {/* <Tab
              _selected={globalStyles.customStyle.unStyledTab}
              fontSize={{ base: "md", md: "md", sm: "sm" }}
              pr="2rem"
              pl={"2rem"}
              _hover={globalStyles.customStyle.tabHover}
            >
              Job Details
            </Tab>
            <Tab
              _selected={globalStyles.customStyle.unStyledTab}
              fontSize={{ base: "md", md: "md", sm: "sm" }}
              pr="2rem"
              pl={"2rem"}
              _hover={globalStyles.customStyle.tabHover}
            >
              Documents
            </Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel p="2rem">
              <LeftSideLabelText
                label={"Birth Date"}
                value={dataGetProfile?.dob ? dataGetProfile?.dob : "N/A"}
              />
              <LeftSideLabelText
                label={"Gender"}
                value={dataGetProfile?.gender ? dataGetProfile?.gender : "N/A"}
                mt={"0.2rem"}
              />
              {/* <LeftSideLabelText
                label={"Address"}
                mt={"0.2rem"}
                value={`${dataGetProfile?.address[0].country},${dataGetProfile?.address[0].district}`}
              /> */}
            </TabPanel>
            <TabPanel p="2rem">
              <Grid templateColumns="repeat(2, 1fr)">
                <GridItem>
                  <PageText text="Permanent Address" />
                  <LeftSideLabelText
                    label={"Country"}
                    value={dataGetProfile?.address?.map((item, index) => {
                      if (item?.address_type === "Permanent") {
                        return item?.country;
                      }
                    })}
                  />
                  <LeftSideLabelText
                    label={"City"}
                    value={dataGetProfile?.address?.map((item, index) => {
                      if (item?.address_type === "Permanent") {
                        return item?.city;
                      }
                    })}
                  />
                  <LeftSideLabelText
                    label={"State/Provience"}
                    value={dataGetProfile?.address?.map((item, index) => {
                      if (item?.address_type === "Permanent") {
                        return item?.state;
                      }
                    })}
                  />
                </GridItem>

                {/* <GridItem>
                  <PageText text="Temporary Address" />
                  <LeftSideLabelText
                    label={"Country"}
                    value={dataGetProfile?.address[1]?.country}
                  />
                  <LeftSideLabelText
                    label={"City"}
                    value={dataGetProfile?.address[1]?.city}
                  />
                  <LeftSideLabelText
                    label={"State/Provience"}
                    value={dataGetProfile?.address[1]?.state}
                  />
                </GridItem> */}
              </Grid>
            </TabPanel>
            <TabPanel p="2rem" pt={"0"}>
              <LeftSideLabelText
                label={"Job Started Date"}
                // value={dataGetProfile?.experience[0]?.start_date}
              />
              <LeftSideLabelText
                label={"Position"}
                // value={dataGetProfile?.experience[0]?.job}
              />
            </TabPanel>
            <TabPanel>asdf</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Profile;
