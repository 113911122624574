import { Button } from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const MoreButton = () => {
  return (
    <Button
      style={globalStyles.customStyle.editButton}
      rightIcon={<ChevronDownIcon w={6} h={6} />}
    >
      More
    </Button>
  );
};

export default MoreButton;
