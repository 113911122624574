import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useDeleteTable = () => {
  async function deleteTable(floor) {
    const res = await authorizationAxios.delete(
      `${BASE_URL}/api/floor-table/${floor}/`
    );
    return res;
  }

  const deleteTableMutation = useMutation(deleteTable);

  return {
    deleteTableMutation,
  };
};
