import { Box, Button, Text, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableImage from "../../../../assets/icons/table.png";
import { BsPeople } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { usePostTable } from "./api/usePostTable";
import { usePostBookedTable } from "views/Pages/Menu/List/api/usePostBookedTable";
import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { useNotify } from "common hooks/useNotify";

export default function AvailaibleTable({ tableData }) {
  const history = useHistory();
  const { postTableMutation } = usePostTable();
  const { postBookedTableMutation } = usePostBookedTable();
  const [tableStatus, setTableStatus] = useState(false);
  const [matchTable, setMatchTable] = useState(false);
  const { data } = postTableMutation;
  const [selectedTables, setSelectedTables] = useState([]);
  const { errorNotify } = useNotify();
  const breakPoint2 = useMediaQuery("(max-width: 1200px)");

  function handleTableChange(item) {
    const index = selectedTables?.findIndex(
      (selectedTables) => selectedTables.uuid === item.uuid
    );

    if (index !== -1) {
      setTableStatus(false);

      setSelectedTables([]);
    } else {
      setTableStatus(true);

      setSelectedTables([item]);
    }
  }

  const postTableOrder = () => {
    if (tableStatus) {
      localStorage.removeItem("customer_number");
      localStorage.removeItem("customer_name");
      localStorage.removeItem("customer_uuid");
      history.push({
        pathname: "/admin/menu-item/",
        state: selectedTables[0]?.uuid,
      });
    } else {
      history.push({
        pathname: "/admin/menu-item/",
        state: 0,
      });
    }
  };

  const postViewTableOrder = async (val) => {
    if (val) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?uuid=${val?.uuid}`
      );
      if (response?.status === 200) {
        history.push({
          pathname: `/admin/order/${response?.data[0]?.table}/`,
          state: response?.data,
        });
      } else {
        errorNotify(response?.message);
      }
    }
  };
  useEffect(() => {
    if (data?.statusText === "Created") {
      history.push({
        pathname: "/admin/menu-item/",
        state: data,
      });
    }
  }, [data]);

  return (
    <Box bg={"white"} height={"100%"} position={"fixed"} width={"100%"}>
      <Box
        display={"flex"}
        gap={"2rem"}
        flexWrap={"wrap"}
        paddingBottom={"2rem "}
        // bg={'white'}
        p={"2rem"}
        pl={"3rem"}
      >
        {tableData && (
          <>
            {tableData.map((item, index) => {
              return (
                <Box
                  width="127px"
                  height="116px"
                  bg={
                    selectedTables?.find(
                      (selectedItem) => selectedItem.uuid === item.uuid
                    ) || item?.reserved === true
                      ? "#3AB689"
                      : "#F8F8F8"
                  }
                  display="flex"
                  justifyContent="center"
                  cursor={"pointer"}
                  alignItems="center"
                  key={item.id}
                  onClick={() => {
                    item?.reserved === true
                      ? postViewTableOrder(item)
                      : handleTableChange(item);
                  }}
                >
                  <Text
                    fontSize={"20px"}
                    fontWeight={"510"}
                    color={
                      selectedTables?.find(
                        (selectedItem) => selectedItem.uuid === item.uuid
                      ) || item?.reserved === true
                        ? "#fff"
                        : "#000"
                    }
                  >
                    {item?.name}
                  </Text>
                </Box>
              );
            })}
          </>
        )}
      </Box>
      <Box
        position={"fixed"}
        bottom={"0rem"}
        p={"2rem"}
        display={"flex"}
        justifyContent="space-between"
        w={"100%"}
        borderTop={"1px solid #ECEDED"}
        alignItems={"center"}
        bg={"white"}
      >
        <Box display={"flex"} gap="1rem" width={"100%"}>
          <Text
            display={"flex"}
            alignItems={"center"}
            color={"#2C3832"}
            fontSize={"13px"}
            fontWeight={"500"}
          >
            <Image
              width={"10px"}
              height={"10px"}
              src={TableImage}
              mr={"0.5rem"}
            />
            TABLE:
            <span style={{ color: "#D89F65" }}>
              {selectedTables ? selectedTables[0]?.name : "Not Selected"}
            </span>
          </Text>
          <Text
            display={"flex"}
            alignItems={"center"}
            gap={"3px"}
            color={"#2C3832"}
            fontSize={"13px"}
            fontWeight={"500"}
          >
            <BsPeople mr={"0.5rem"} />
            GUEST:
            <span style={{ color: "#D89F65" }}>1</span>
          </Text>
        </Box>{" "}
        <Button
          mr={breakPoint2[0] ? "0.5rem" : "4rem"}
          variant="secondary"
          bg={"buttongreen.400"}
          color={"#D8E6E9"}
          minW={"15rem"}
          borderRadius={"4px"}
          fontSize={"13px"}
          fontWeight={"600"}
          py={"1.3rem"}
          onClick={postTableOrder}
        >
          CONTINUE
        </Button>
      </Box>
    </Box>
  );
}
