import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddInventoryGroupApi = () => {
  async function postAddInventoryGroup(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/inventory/`,
      values
    );
    return res;
  }

  const postAddInventoryGroupMutation = useMutation(postAddInventoryGroup);

  return {
    postAddInventoryGroupMutation,
  };
};
