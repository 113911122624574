import { Box, Flex } from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideDatePicker from "components/LeftSideDatePicker";
import React, { useEffect } from "react";
import { GENDER } from "constants/CONSTANTS";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";

const Personal = ({
  dataGetProfile,
  register,
  setBirthDate,
  birthDate,
  control,
  setValue,
}) => {
  console.log("dd", dataGetProfile);
  return (
    <Box>
      {/* <LeftSideDatePicker
        label="Birth Date"
        date={birthDate}
        setDate={setBirthDate}
        registerName={"dob"}
      /> */}
      <LeftSideLabelInput
        label="Birthdate"
        defaultValue={dataGetProfile?.dob}
        type="date"
        register={register}
        registerName={"dob"}
      />
      <LeftSideLabelSelect
        gap="0rem"
        label="Gender"
        // defaultValue={dataGetProfile?.gender}
        data={[
          { label: "Male", value: "Male" },
          { label: "Female", value: "Female" },
        ]}
        control={control}
        register={register}
        registerName={"gender"}
      />
      <Flex gap="2rem" mt="3rem">
        <SaveButton />
        <CancelButton />
      </Flex>
    </Box>
  );
};

export default Personal;
