import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { PageSubTitle } from "components/Common/PageSubTitle";
import { useGetRetailerInsightsApi } from "./useGetRetailerInsightsApi";

export default function RetailerDashboard() {
  const { data } = useGetRetailerInsightsApi();
  console.log("xx", data);

  return (
    <Box>
      <TopSearch />
      <Box p={"1rem"} pt={"5rem"}>
        <PageSubTitle title={"Insights"} />
        <Box display={"flex"} mt={"1rem"} mb={"1rem"}>
          <Box
            p={"1rem"}
            gap={"1rem"}
            borderRadius={"0.5rem"}
            boxShadow={"md"}
            minW={"15rem"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mr={"2rem"}
          >
            <Text fontSize={"xl"}>
              Total<br></br>Orders
            </Text>
            <Text color={"#3AB689"} fontSize={"4xl"}>
              {data?.todays_orders}
            </Text>
          </Box>

          <Box
            p={"1rem"}
            gap={"1rem"}
            borderRadius={"0.5rem"}
            boxShadow={"md"}
            minW={"18rem"}
            display={"flex"}
            alignItems={"center"}
            mr={"2rem"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"xl"}>
              Total<br></br>Sales
            </Text>
            <Text color={"#3AB689"} fontSize={"4xl"}>
              Rs{data?.todays_sales["RS"]}
            </Text>
          </Box>

          <Box
            p={"1rem"}
            gap={"1rem"}
            borderRadius={"0.5rem"}
            boxShadow={"md"}
            minW={"18rem"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"xl"}>
              Total<br></br>Sales
            </Text>
            <Text color={"#3AB689"} fontSize={"4xl"}>
              ${data?.todays_sales["$"]}
            </Text>
          </Box>
        </Box>

        <PageSubTitle title={"Top 5 Most Sold Today"} mt="2rem" />
        <Box mt={"1rem"} mb={"1rem"}>
          <TableContainer width={"50%"} border={"1px solid lightgray"}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>NAME</Th>
                  <Th>QUANTITY</Th>
                  {/* <Th>SALES</Th> */}
                  {/* <Th>SALES($)</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {data?.top_selling_items_today?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.name}</Td>
                    <Td>{item.quantity}</Td>
                    {/* <Td>{item.rs_amount}</Td> */}
                    {/* <Td>{item.dollar_amount}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <PageSubTitle title={"Top 5 Most Sold this week"} />
        <Box mt={"1rem"} mb={"1rem"}>
          <TableContainer width={"50%"} border={"1px solid lightgray"}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>NAME</Th>
                  <Th>QUANTITY</Th>
                  {/* <Th>SALES</Th> */}
                  {/* <Th>SALES($)</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {data?.top_selling_items_this_week?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.name}</Td>
                    <Td>{item.quantity}</Td>
                    {/* <Td>{item.rs_amount}</Td> */}
                    {/* <Td>{item.dollar_amount}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box>
          <Box mt={"2rem"} mb={"1rem"}>
            <PageSubTitle title={"Top 5 Low on Stock"} />
            <TableContainer
              width={"50%"}
              border={"1px solid lightgray"}
              mb="1rem"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>NAME</Th>
                    <Th>STOCKS</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.low_on_stock?.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.available_stock}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
