import React, { useEffect, useRef, useState } from "react";
import {
	Flex,
	Grid,
	GridItem,
	HStack,
	Image,
	Text,
	VStack,
} from "@chakra-ui/react";
import {
	Box,
	Center,
	Tr,
	Td,
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { PageText } from "components/Common/PageText";
import ImageIcon from "components/Common/ImageIcon";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { CancelButton } from "components/Common/CancelButton";
import useEditCategories from "./useEditCategories";

export default function EditCategories({ data, index, refetch }) {

	const {
		isOpen,
		onOpen,
		onClose,
		isLoading,
		isError,
		error,
		isSuccess,
		register,
		handleSubmit,
		uploadPhotoFile,
		SetUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		onFinalSubmit,
	} = useEditCategories(data);

	useEffect(() => {
		if (isSuccess) {
			onClose();
			refetch();
		}
	}, [isSuccess]);

	return (
		<Tr key={index} cursor="pointer" onClick={onOpen}>
			<Td width={'10rem'}>
				<PageText text={data?.sort ? data?.sort : "N/A"} />
			</Td>
			<Td width={'20rem'}>
				<HStack>
					{/* <ImageIcon src={data?.logo} person /> */}
					<Box textAlign={"left"}>
						<VStack>
							<Text fontSize={"sm"}>{data?.name}</Text>
						</VStack>
					</Box>
				</HStack>
			</Td>
			<Td>
				<PageText text={data?.slogan} />
			</Td>
			<Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Category</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onFinalSubmit)}>
							<input
								type={"hidden"}
								{...register("uuid")}
								value={data?.uuid}
							/>
							<Grid templateColumns={"1fr 1fr"}>
								<GridItem>
									<LeftSideLabelInput
										defaultValue={data?.name}
										label="Category name"
										type="text"
										register={register}
										registerName={"name"}
									/>
									<LeftSideLabelInput
										label="Sort No."
										type="text"
										defaultValue={data?.sort}
										register={register}
										registerName={"sort"}
									/>
									<Flex gap="2rem" mt="3rem" p="1rem">
										<SaveButton />
										{/* <CancelButton onClick={onClose} /> */}
									</Flex>
								</GridItem>
							</Grid>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Tr>
	);
}
