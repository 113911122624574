import React from "react";
import { Box, Center, Input } from "@chakra-ui/react";

import { IoCloudUploadOutline, IoCloseCircleOutline } from "react-icons/io5";
import useUpload from "./useUpload";

const Upload = ({
  name,
  register,
  index,
  tabIndex,
  setSelectedImageForPreview,
}) => {
  const { onChangeHandler, selectedImage } = useUpload();
  if (index === tabIndex) {
    // console.log("index", index, tabIndex);
    setSelectedImageForPreview(selectedImage);
  }
  // console.log("selectedImage", selectedImage);
  return (
    <label for={`image${name}`}>
      <Input
        id={`image${name}`}
        {...register(name)}
        type={"file"}
        multiple
        style={{ display: "none" }}
        onChange={onChangeHandler}
      />
      <Center
        w="12rem"
        h="8rem"
        borderTop={"1px dashed #808080"}
        borderLeft={"1px dashed #808080"}
        borderRight={"1px dashed #808080"}
        borderTopRadius="8px"
      >
        <IoCloudUploadOutline fontSize={"3rem"} color="lightgrey" />
      </Center>
      <Center
        w="12rem"
        h="2rem"
        border={"1px solid #808080"}
        borderBottomRadius="8px"
      >
        Upload Photo
      </Center>
    </label>
  );
};

export default Upload;
