import { Box, Flex, VStack, Text } from "@chakra-ui/react";
import React from "react";
// import { LeftSideLabelText } from "./LeftSideLabelText";
import { RightSideLabelText } from "components/Common/RightSideLabelText";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { LeftSideLabelText } from "components/Common/LeftSideLabelText";

const Invoice = React.forwardRef(({ invoiceData }, ref) => {
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0); // total discount of item
  const [totalTaxAmount, settTotalTaxAmount] = useState(0);

  // console.log("invoice1", invoiceData);
  useEffect(() => {
    if (!invoiceData?.complimentry) {
      let bill = 0;
      let totalTemp = 0;
      let disountTotalTemp = (
        (invoiceData?.discount / 100) *
        invoiceData?.unitprice *
        invoiceData?.quantity
      ).toFixed(2);

      let taxTotalTemp = (
        (invoiceData?.tax / 100) *
        (invoiceData?.unitprice * invoiceData?.quantity - disountTotalTemp)
      ).toFixed(2);

      invoiceData?.data?.items.forEach((item) => {
        bill += item?.billAmount;
      });
      totalTemp = (
        invoiceData?.unitprice * invoiceData?.quantity +
        +taxTotalTemp -
        +disountTotalTemp +
        invoiceData?.adjustment_amount
      ).toFixed(2);

      setTotal(totalTemp);
      setTotalDiscount(disountTotalTemp);
      settTotalTaxAmount(taxTotalTemp);
      setSubTotalAmount(bill);
    }
  }, [invoiceData]);

  return (
    <div ref={ref}>
      <Flex direction="column" mt="3rem" mx="1rem" gap="1rem">
        <VStack spacing="0">
          <span>Aalaya STORE</span>
          <span>KATHMANDU, NEPAL</span>
          <span>INVOICE</span>
        </VStack>

        <Flex direction="column">
          <LeftSideLabelText label="Bill No" value="-----" mt="0" />
          <LeftSideLabelText
            label="Date"
            value={invoiceData?.invoiceDate.split(" ")[0]}
            mt="0"
          />
          <LeftSideLabelText label="Name" value="-----" mt="0" />
          <LeftSideLabelText label="Address" value="-----" mt="0" />
          <LeftSideLabelText label="Pan No" value="-----" mt="0" />
          <LeftSideLabelText label="Payment Mode" value="Cash" mt="0" />
        </Flex>

        <TableContainer>
          <Table
            variant="unstyled"
            fontSize="xs"
            size="xs"
            borderTop="1px dashed black"
            borderBottom="1px dashed black"
          >
            <Thead borderBottom="1px dashed black">
              <Tr>
                <Th>Sn</Th>
                <Th>Particulars</Th>
                <Th>Qty</Th>
                <Th>Rate</Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td> 1</Td>
                <Td>
                  <Box w="10rem" whiteSpace="pre-wrap">
                    {invoiceData?.productName}
                    {invoiceData?.complimentry ? " [C]" : ""}
                  </Box>
                </Td>
                <Td>{invoiceData?.quantity}</Td>
                <Td>{invoiceData?.unitprice}</Td>
                <Td isNumeric>{invoiceData?.billAmount}</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Flex direction="column">
          <RightSideLabelText
            label="Complimentry Total"
            value={invoiceData?.complimentry ? invoiceData?.billAmount : 0}
            mt="0"
          />
          <RightSideLabelText
            label="Sub Total"
            value={!invoiceData?.complimentry ? invoiceData?.billAmount : 0}
            mt="0"
          />
          <RightSideLabelText label="Discount" value={totalDiscount} mt="0" />

          {/* <RightSideLabelText
						label="Promo"
						value={invoiceData?.data?.invoice?.promoAmount}
						mt="0"
					/> */}
          <RightSideLabelText
            label={`Tax amount `}
            value={totalTaxAmount}
            mt="0"
          />

          <RightSideLabelText
            label="Adjustment"
            value={invoiceData?.adjustment_amount}
            mt="0"
          />
          <RightSideLabelText label="Total" value={total} mt="0" />
        </Flex>

        <Text
          textAlign="center"
          borderTop="1px dashed black "
          borderBottom="1px dashed black "
          mt="1rem"
        >
          {invoiceData?.data?.invoice?.footerNote}
        </Text>
      </Flex>
    </div>
  );
});

export default Invoice;
