import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostInventoryDetailApi = () => {
  async function postInventoryDetail(values) {
    const res = await authorizationAxios.patch(
      `${BASE_URL}/api/group-detail/update/`,
      values
    );
    return res;
  }

  const postInventoryDetailMutation = useMutation(postInventoryDetail);

  return {
    postInventoryDetailMutation,
  };
};
