import {
  Box,
  Button,
  Grid,
  MenuItem,
  useDisclosure,
  useMediaQuery,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  SlideIn,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MenuCategory from "./MenuCategory";
import MenuBill from "./MenuBill";
import MenuItems from "./MenuItems";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import { usePostMenuItem } from "./api/usePostMenuItem";
import { useHistory, useLocation } from "react-router-dom";
import { useNotify } from "common hooks/useNotify";
import { useGetMenuItems } from "./api/useGetMenuItems";
import { Loading } from "components/Common/Loading";
import { popup } from "leaflet";
import TabMenu from "./TabMenu";
import { CloseIcon } from "@chakra-ui/icons";

const MenuList = () => {
  const toast = useToast();
  const { onClose } = useDisclosure();
  const { errorNotify } = useNotify();
  const [selectedItems, setSelectedItems] = useState([]);
  const [menuUuid, setMenuUuid] = useState("");
  const [menuCategory, setMenuCategory] = useState("");
  const [tableId, setTableId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  const [allMenu, setAllMenu] = useState(false);
  const [idInvoice, setIdInvoice] = useState("");
  const { postMenuMutation, data: menuData } = usePostMenuItem();
  const {
    isError,
    isSuccess,
    isLoading,
    data: responseData,
  } = postMenuMutation;
  const { data } = useGetMenuItems();
  const location = useLocation();
  const [stock, setStock] = useState(false);
  const [guest, setGuest] = useState(1);
  const [categoryActive, setCategoryActive] = useState(false);
  const [subCategoryActive, setSubCategoryActive] = useState(true);
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const breakPoint2 = useMediaQuery("(max-width: 1200px)");
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const history = useHistory();
  console.log(isMenuModalOpen);
  //to change item from list of menus
  function handleItemChange(item) {
    if (item?.is_unlimited === false && item?.available_stock === 0) {
      setStock(true);
    } else {
      const itemIndex = selectedItems.findIndex(
        (selectedItem) => selectedItem.uuid === item.uuid
      );

      if (itemIndex !== -1) {
        // Item already exists, increase the quantity
        const updatedItems = selectedItems.map((selectedItem) =>
          selectedItem.uuid === item.uuid ? { ...selectedItem } : selectedItem
        );
        setSelectedItems(updatedItems);
      } else {
        // Item doesn't exist, add it to the cart with quantity 1
        setSelectedItems([...selectedItems, { ...item, qty: 1 }]);
      }
    }
  }
  useEffect(() => {
    if (stock) {
      setStock(false);
      errorNotify("Item out of stock");
    }
  }, [stock]);
  //function to add order
  const submitValues = () => {
    const Data = selectedItems.map((item) => {
      return {
        product: item?.uuid,
        quantity: item?.qty,
        unitprice: item?.selling_price,
        billAmount: item?.selling_price * item?.qty,
      };
    });
    const finalData = {
      customer_number: localStorage.getItem("customer_number") ?? "",
      customer_name: localStorage.getItem("customer_name") ?? "",
      customer_uuid: localStorage.getItem("customer_uuid") ?? "",

      table: location?.state,
      guest: guest,
      items: Data,
    };

    postMenuMutation.mutate(finalData);
  };
  console.log("data", location);
  // if (location?.state === undefined) {
  //   history.push("/admin/table-list");
  // }
  //to display success message after add order
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "New Item Added successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });

      localStorage.removeItem("customer_number");
      localStorage.removeItem("customer_name");
      localStorage.removeItem("customer_uuid");
      {
        location?.state == 0
          ? history.push({
              pathname: `/admin/order/${responseData?.data?.invoice_number}`,
              state: 0,
            })
          : history.push(`/admin/order/${location?.state}/`);
      }
      onClose();
    }
    if (isError) {
      toast({
        title: postMenuMutation?.error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [postMenuMutation.isSuccess, postMenuMutation.isError]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  return (
    <Box minH={"100vh"} bg={"white"}>
      <TopSearch
        selectedItems={selectedItems}
        submitValues={submitValues}
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Box pt={"4.5rem"}>
        {breakPoint[0] && (
          <Box
            p="1rem"
            borderBottom="1px solid lightgray"
            bg="white"
            width="100%"
            zIndex={99}
          >
            <Button
              // key={item.uuid}
              height="29px"
              fontSize="14px"
              fontWeight="normal"
              minWidth="90px" // Set a minimum width for the buttons
              color="#fff"
              borderRadius="4px"
              mb="0.5rem"
              mr="0.5rem"
              colorScheme={activeIndex === "all" ? "red" : "green"}
              size="md"
              onClick={() => {
                setMenuCategory("");
                setActiveIndex("all");
              }}
            >
              All
            </Button>
            {data?.map((item, index) => (
              <Button
                key={item.uuid}
                height="29px"
                fontSize="14px"
                fontWeight="normal"
                minWidth="90px" // Set a minimum width for the buttons
                color="#fff"
                borderRadius="4px"
                mb="0.5rem"
                mr="0.5rem"
                colorScheme={activeIndex === index ? "red" : "green"}
                size="md"
                onClick={() => {
                  setMenuCategory(item.uuid);
                  setActiveIndex(index);
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <Box display={"flex"} width={"100%"} minH={"90vh"}>
            <Box width={breakPoint[0] ? "0" : "280px"}>
              <MenuItems
                allMenu={allMenu}
                setAllMenu={setAllMenu}
                data={data}
                setMenuUuid={setMenuUuid}
                setMenuCategory={setMenuCategory}
                categoryActive={categoryActive}
                setCategoryActive={setCategoryActive}
                subCategoryActive={subCategoryActive}
                setSubCategoryActive={setSubCategoryActive}
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              // flexWrap={"wrap"}
              width={"100%"}
              height={"-webkit-fit-content"}
            >
              <Box className="menucategory-wrapper">
                <MenuCategory
                  allMenu={allMenu}
                  setAllMenu={setAllMenu}
                  isMenuModalOpen={isMenuModalOpen}
                  setIsMenuModalOpen={setIsMenuModalOpen}
                  showPopup={showPopup}
                  setShowPopup={setShowPopup}
                  categoryActive={categoryActive}
                  setCategoryActive={setCategoryActive}
                  subCategoryActive={subCategoryActive}
                  setSubCategoryActive={setSubCategoryActive}
                  tableNumber={location?.state?.data?.data}
                  data={data}
                  tableId={tableId}
                  searchQuery={searchQuery}
                  handleItemChange={handleItemChange}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  menuUuid={menuUuid}
                  menuCategory={menuCategory}
                />
              </Box>

              {/* show when big screen */}

              <Box className="menubill-container">
                <MenuBill
                  menuData={postMenuMutation}
                  bookedItem={location?.state}
                  errorNotify={errorNotify}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  tableNumber={location?.state?.data?.data}
                  tableId={idInvoice}
                  invoiceName={invoiceName}
                  submitValues={submitValues}
                  guest={guest}
                  setGuest={setGuest}
                />
              </Box>

              {isMenuModalOpen && (
                <Drawer
                  placement={"bottom"}
                  onClose={() => setIsMenuModalOpen(false)}
                  isOpen={isMenuModalOpen}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerHeader
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {""}
                      Total Orders
                      <IconButton
                        icon={<CloseIcon />}
                        size="md"
                        aria-label="Close"
                        onClick={() => setIsMenuModalOpen(false)}
                      />
                    </DrawerHeader>
                    <DrawerBody overflow={"none"}>
                      <Box>
                        {" "}
                        <TabMenu
                          menuData={postMenuMutation}
                          bookedItem={location?.state}
                          errorNotify={errorNotify}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          tableNumber={location?.state?.data?.data}
                          tableId={idInvoice}
                          invoiceName={invoiceName}
                          submitValues={submitValues}
                          guest={guest}
                          setGuest={setGuest}
                        />
                      </Box>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              )}

              {/* show when small sreen */}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default MenuList;
