import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import SearchUI from "components/Common/SearchUI";
import { globalStyles } from "theme/styles";
import React, { useState } from "react";
import AddUI from "components/Common/AddUI";
import SortIcon from "components/Common/SortIcon";
import { PageText } from "components/Common/PageText";
import { NavLink, useHistory } from "react-router-dom";
import useGroupList from "./useGroupList";
import PaginationButton from "components/Common/PaginationButton copy";
import { useKitchenList } from "./useKitchenList";

const KitchenInventoryList = () => {
  const [next, setNext] = useState(1);

  const { data } = useKitchenList(next);
  const history = useHistory();

  const handleKitchenDetail = (id) => {
    history.push(`/admin/kitchen-detail/` + id);
  };

  return (
    <>
      <Table variant="striped">
        <Tr>
          <Th style={globalStyles.customStyle.tableHeading}>Kitchen Item</Th>
          {/* <Th style={globalStyles.customStyle.tableHeading}>Unit</Th> */}
          <Th style={globalStyles.customStyle.tableHeading}>Stock on hand</Th>
        </Tr>
        <Tbody>
          {data?.data?.map((item, index) => {
            return (
              <Tr
                key={index}
                cursor="pointer"
                onClick={() => handleKitchenDetail(item.uuid)}
              >
                <Td>
                  <HStack>
                    <Box textAlign={"left"}>
                      <VStack>
                        <Text fontSize={"sm"}>{item.name}</Text>
                      </VStack>
                    </Box>
                  </HStack>
                </Td>
                {/* <Td>{item?.unit_name}</Td> */}
                <Td>{item?.available_stock + item?.unit_name}</Td>
              </Tr>
            );
          })}
        </Tbody>{" "}
        <PaginationButton
          isPrevious={data?.previous}
          isNext={data?.next}
          next={next}
          setNext={setNext}
        />
      </Table>{" "}
    </>
  );
};

export default KitchenInventoryList;
