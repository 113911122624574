import React from "react";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { WithContext as ReactTags } from "react-tag-input";

export const TagInput = ({
  index,
  field,
  register,
  setValue,
  count,
  setCount,
}) => {
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    setCount(count + 1);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    setCount(count + 1);
  };

  const [str, setStr] = useState("");

  let strTemp = useRef("");

  useEffect(() => {
    strTemp.current = "";
    // setStr('');
    tags.map((item, index) => {
      strTemp.current = strTemp.current + item.text + ",";
      setStr(strTemp.current);

      // setStr(str+ item.text+',');
    });
  }, [tags]);

  useEffect(() => {
    setValue("cat." + index + ".value", str.slice(0, -1));
  }, [str]);

  return (
    <>
      <input
        type={"hidden"}
        value={str.slice(0, -1)}
        {...register(`cat.${index}.value`)}
      />
      <ReactTags
        delimiters={delimiters}
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="inline"
        placeholder="attributes"
      />
    </>
  );
};
