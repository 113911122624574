import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditCategoriesApi = (categories) => {

    async function editCategories(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/category/${categories}/`,
            values
        );
        return res;
    }

    const editCategoriesMutation = useMutation(editCategories);

    return {
        editCategoriesMutation,
    };
};
