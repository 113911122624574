import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAddResolveApi } from "./useAddResolveApi";

export const useAddResolve = () => {
  let history = useHistory();
  const toast = useToast();
  const toastIdRef = useRef();
  const { register, handleSubmit, control, reset } = useForm();
  const { postResolveMutation } = useAddResolveApi();
  const { isLoading, isError, data, error, isSuccess } = postResolveMutation;
  const onSubmit = (values) => {
    const formData = new FormData();
    postResolveMutation.mutate({
      uuid: values.uuid,
      resolve_type: values.resolve_type,
      remarks: values.remarks,
      customer: values.customer,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Resolved",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      const timer = setTimeout(() => {
        location.reload();
      }, 250);

      return () => clearTimeout(timer);

      // history.push("/admin/category-list");
    }

    if (isError) {
      toast({
        title: "Missing Information",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postResolveMutation.isSuccess,
    postResolveMutation.isLoading,
    postResolveMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    data,
  };
};
