import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditChangeEmailApi = () => {

    async function editChangeEmail(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/update-email/`,
            values
        );
        return res;
    }

    const editChangeEmailMutation = useMutation(editChangeEmail);

    return {
        editChangeEmailMutation,
    };
};
