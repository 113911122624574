import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddPeakApi = () => {

  async function postAddPeak(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/super-user/peak/`,
      values
    );
    return res;
  }

  const postAddPeakMutation = useMutation(postAddPeak);

  return {
    postAddPeakMutation,
  };
};
