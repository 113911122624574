import { Box, Button, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

function RetailerCategory({ data, setMenuCategory }) {
  const handleMenu = (val) => {
    setMenuCategory(val);
  };
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  return (
    <>
      <Box
        display={breakPoint[0] ? "none" : "block"}
        borderRight={"1px solid #ECEDED"}
        // position={"absolute"}
        left={"0px"}
        minW={'10rem'}
        minH={"-webkit-fill-available"}
        position="fixed"
        marginLeft={"70px"}
      >
        <Accordion>
          <AccordionItem>
            <h2>
              <AccordionButton
                _expanded={{ bg: "buttongreen.400", color: "white" }}
                onClick={() => handleMenu("")}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"600"}
                >
                  All
                </Box>
                {/* <AccordionIcon /> */}
              </AccordionButton>
            </h2>
          </AccordionItem>
          {data?.data?.map((item, index) => {
            return (
              <>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => handleMenu(item?.uuid)}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        {item?.name}
                      </Box>
                      {/* <AccordionIcon /> */}
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              </>
            );
          })}
        </Accordion>
      </Box>
    </>
  );
}

export default RetailerCategory;
