import { globalStyles } from "theme/styles";
import { Tab,Tabs,TabList,TabPanel,TabPanels } from "@chakra-ui/react";
import { useGetConfigChoiceList } from "views/Pages/CommonApi/useGetConfigChoiceList";

const Additional = () => {

    const {data} = useGetConfigChoiceList('ExpeditionAdditional')

    return (
        <Tabs mt="1rem" isLazy={true}>
            <TabList>
                {
                    data?.map((item,idx)=>(
                        <Tab
                            style={globalStyles.customStyle.tab}
                            _selected={globalStyles.customStyle.selectedTab}
                            _focus={"none"}
                            key={idx}
                        >
                            {item.label}
                        </Tab>
                    ))
                }

                <Tab
                    style={globalStyles.customStyle.tab}
                    _selected={globalStyles.customStyle.selectedTab}
                    _focus={"none"}
                    color={globalStyles.customStyle.fontColor.color}
                >
                    +Add
                </Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    asdf
                </TabPanel>
                <TabPanel>
                    asdf
                </TabPanel>
                <TabPanel>
                    asdf
                </TabPanel>
                <TabPanel>
                   asdf
                </TabPanel>
            </TabPanels>
        </Tabs>
        );
}

export default Additional;