import { Box } from "@chakra-ui/react";
import React from "react";
import { PageTitle } from "components/Common/PageTitle";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import ItemDetailListAdjustment from "./component/ItemDetailListAdjustment";

export default function NewAdjustment() {
  return (
    <Box bg={"white"} minH="100vh" pos="relative">
      <Box px="3rem">
        <ItemDetailListAdjustment />
      </Box>
    </Box>
  );
}
