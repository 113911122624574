import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useQuery, useMutation } from "react-query";

export const usePutProfileApi = () => {
  async function putProfile(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/profile/web/update/`,
      values
    );
    return res;
  }
  const putProfileMutation = useMutation(putProfile);

  return {
    putProfileMutation,
  };
};
