import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddReceipeApi = () => {
  async function postAddReceipeGroup(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/get-add/receipe/`,
      values
    );
    return res;
  }

  const postAddReceipeGroupMutation = useMutation(postAddReceipeGroup);

  return {
    postAddReceipeGroupMutation,
  };
};
