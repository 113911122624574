import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  Input,
  Button,
  Center,
  Textarea,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { PageTitle } from "components/Common/PageTitle";
import { SaveButton } from "components/Common/SaveButton";
import { TagInput } from "components/Common/TagInput";
import { useEffect, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import MultipleImage from "./component/MultipleImage";
import useMultipleImage from "./component/useMultipleImage";
import GroupItem from "./GroupItem";
import { useAddInventoryGroup } from "./useAddInventoryGroup";
import { AiOutlineArrowLeft, AiOutlineCloseCircle } from "react-icons/ai";
import OneProperty from "./component/OneProperty";
import TwoProperty from "./component/TwoProperty";
import ThreeProperty from "./component/ThreeProperty";
import { globalStyles } from "theme/styles";
import { useGetBrandList } from "../api/useGetBrandList";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useGetManufacturer } from "../api/useGetManufacturer";
import { useGetCategoriesApi } from "../api/useGetCategoriesApi";
import { Radio, RadioGroup } from "@chakra-ui/react";
const AddInventory = () => {
  const {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoRef,
    uploadPhotoFile,
    newInclude,
    setNewInclude,
    newItinerary,
    setNewItinerary,
    getValues,
    setValue,
    watch,
    unregister,
    resetField,
    reset,
    file,
    handleUpload,
    handleClick,
    hiddenFileInput,
    countIndex,
  } = useAddInventoryGroup();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "cat",
  });
  const {
    fields: itemField,
    remove: itemRemove,
    append: itemAppend,
  } = useFieldArray({
    control,
    name: "item",
  });
  const [count, setCount] = useState(1);
  const [itemgroupdata, setItemgroupdata] = useState();
  const [typeValue, setTypeValue] = useState("1");
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  const [scrollHeight, setScrollHeight] = useState();
  const { data: getBrandList } = useGetBrandList();
  const { data: getManufacturer } = useGetManufacturer();
  const { data: getCategories } = useGetCategoriesApi();
  const [manufacturedBy, setManufacturedBy] = useState("");
  const [brandFk, setBrandFk] = useState("");

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  useEffect(() => {
    unregister("manufacturedBy");
    unregister("brand");
    register("manufacturedBy", {
      value: getManufacturer
        ? getManufacturer[
          getManufacturer?.findIndex((x) => x.value === manufacturedBy)
        ]?.label
        : "",
    });
    register("brand", {
      value: getBrandList
        ? getBrandList[getBrandList?.findIndex((x) => x.value === brandFk)]
          ?.label
        : "",
    });
  }, [manufacturedBy, brandFk]);
  if (localStorage.getItem("company_type") === "Retailer") {
    useEffect(() => {
      document.querySelector("textarea").addEventListener("input", function () {
        this.style.height = "0px";
        this.style.height = this.scrollHeight + "px";
      });
      setScrollHeight();
    }, [document.querySelector("textarea")?.scrollHeight]);

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        setManufacturedBy(value.manufacture_fk);
        setBrandFk(value.brand_fk);
      });

      return () => subscription.unsubscribe();
    }, [watch]);
  }

  useEffect(() => {
    if (fields.length == 0) {
      append([
        {
          name:
            localStorage.getItem("company_type") === "Retailer"
              ? "Size"
              : "Type",
          value: "",
        },
      ]);
    }
  }, []);

  let today = new Date().getTime();
  const date = new Date(today);
  const seconds =
    Math.floor(date.getTime() / 1000) +
    "-" +
    Math.floor(Math.random() * (100 - 1 + 1) + 1);
  const watchMultipleItems = watch("cat");

  const costPriceRef = useRef();

  useEffect(() => {
    unregister("item");

    countIndex.current = -1;
    setItemgroupdata(watchMultipleItems);
  }, [count]);

  function handelCopy() {
    for (let i = 0; i <= countIndex.current; i++) {
      setValue(
        "item." + i + ".cost_price",
        getValues("item").filter((list) => {
          return list.cost_price !== "";
        })[0]?.cost_price
      );
    }
  }

  function handelCopySellingPrice() {
    for (let i = 0; i <= countIndex.current; i++) {
      setValue(
        "item." + i + ".selling_price",
        getValues("item").filter((list) => {
          return list.selling_price !== "";
        })[0]?.selling_price
        // getValues("item").filter((list) => {
        //   return list.sku !== "";
        // })[0].cost_price
      );
    }
  }
  // console.log("getValues", itemgroupdata);

  return (
    <Box direction="column">
      <Box bg={"white"}>
        {/* header */}

        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <Box p="1rem" pl={"3rem"} pb={"0"}>
            <Grid templateColumns={"repeat(2, 1fr)"} gap="10rem">
              <GridItem>
                {getCategories && (
                  <>
                    <LeftSideLabelSelect
                      register={register}
                      control={control}
                      registerName="category"
                      label="Category"
                      labelW="7rem"
                      data={getCategories}
                    />
                  </>
                )}
                <LeftSideLabelInput
                  type="text"
                  labelW={breakPoint[0] ? "9rem" : "8rem"}
                  label="Item Group Name"
                  w="100%"
                  register={register}
                  registerName={"groupname"}
                  required={true}
                />

                <LeftSideLabelTextArea
                  labelW={breakPoint[0] ? "10rem" : "9rem"}
                  label="Item Description"
                  w={"100%"}
                  register={register}
                  registerName={"description"}
                  required={true}
                />
                {/* <Flex mt="2rem" gap={"2rem"}>
                  <Text>Select Type</Text>
                  <RadioGroup
                    onChange={setTypeValue}
                    value={typeValue}
                    colorScheme="green"
                  ></RadioGroup>
                </Flex> */}
                <Grid templateColumns={"repeat(2, 1fr)"} gap={2} mt="1.5rem">
                  {localStorage.getItem("company_type") === "Retailer" && (
                    <GridItem>
                      {getManufacturer && (
                        <>
                          <LeftSideLabelSelect
                            register={register}
                            control={control}
                            registerName="manufacture_fk"
                            label="Manufactured By"
                            data={getManufacturer}
                          />
                        </>
                      )}
                    </GridItem>
                  )}
                  {localStorage.getItem("company_type") === "Retailer" && (
                    <GridItem>
                      {getBrandList && (
                        <LeftSideLabelSelect
                          register={register}
                          control={control}
                          registerName="brand_fk"
                          label="Brand"
                          data={getBrandList}
                        />
                      )}
                    </GridItem>
                  )}
                </Grid>
                <Text fontSize={"sm"}>
                  Multiple Items
                </Text>
                {fields.map((field, index) => (
                  <Grid
                    key={field.id}
                    gap={"2rem"}
                    templateColumns={"repeat(3, 1fr)"}
                    mt="1.5rem"
                    ref={costPriceRef}
                  >
                    <GridItem>
                      <Input
                        type="text"
                        borderColor="#D3D3D3"
                        borderRadius={"0.3rem"}
                        size="sm"
                        placeholder="Category"
                        {...register(`cat.${index}.name`)}
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Box border={"1px solid lightgray"} borderRadius={"5px"}>
                        <TagInput
                          index={index}
                          field={field}
                          register={register}
                          setValue={setValue}
                          count={count}
                          setCount={setCount}
                        />
                      </Box>
                    </GridItem>
                  </Grid>
                ))}
                {watchMultipleItems?.length <= 2 && (
                  <Text
                    color={globalStyles.customStyle.fontColor.color}
                    cursor="pointer"
                    mt={"1.5rem"}
                    onClick={() => append()}
                  >
                    + Add Another
                  </Text>
                )}
              </GridItem>
              {/* <GridItem mt="4rem">
                {" "}
                <MultipleImage
                  file={file}
                  handleUpload={handleUpload}
                  handleClick={handleClick}
                  hiddenFileInput={hiddenFileInput}
                />
              </GridItem> */}
            </Grid>
            {/* <GroupItem groupitem={groupitem} register={register} data={data}/> */}
            <Grid gap={"1rem"} templateColumns={"repeat(9, 1fr)"} mt="1.5rem">
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  ITEM NAME
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  SKU
                </Text>
              </GridItem>
              <GridItem w={["100%", "7.5rem"]}>
                <Text style={globalStyles.customStyle.tableHeading}>
                  COST PRICE
                </Text>
                <Text
                  onClick={handelCopy}
                  fontSize="xs"
                  color={globalStyles.customStyle.fontColor.color}
                  cursor="pointer"
                >
                  COPY TO ALL
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  SELLING PRICE
                </Text>
                <Text
                  onClick={handelCopySellingPrice}
                  fontSize="xs"
                  color={globalStyles.customStyle.fontColor.color}
                  cursor="pointer"
                >
                  COPY TO ALL
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  UPC
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  ISBN
                </Text>
              </GridItem>
              <GridItem>
                <Text
                  style={globalStyles.customStyle.tableHeading}
                  width="7.5rem"
                >
                  OPENING STOCK
                </Text>
              </GridItem>
              <GridItem w={"5rem"}>
                <Text style={globalStyles.customStyle.tableHeading}>
                  Is Unlimited
                </Text>
              </GridItem>
              <GridItem w={"5rem"}>
                <Text style={globalStyles.customStyle.tableHeading}>
                  Exclude
                </Text>
              </GridItem>
            </Grid>

            <OneProperty
              itemgroupdata={itemgroupdata}
              countIndex={countIndex}
              getValues={getValues}
              register={register}
              itemRemove={itemRemove}
              seconds={seconds}
              setValue={setValue}
            />
            <TwoProperty
              itemgroupdata={itemgroupdata}
              countIndex={countIndex}
              getValues={getValues}
              register={register}
              itemRemove={itemRemove}
              seconds={seconds}
              setValue={setValue}
            />
            <ThreeProperty
              itemgroupdata={itemgroupdata}
              countIndex={countIndex}
              getValues={getValues}
              register={register}
              itemRemove={itemRemove}
              seconds={seconds}
              setValue={setValue}
            />

            {itemgroupdata ? (
              ""
            ) : (
              <Center mt={"1.5rem"}>
                <Text fontSize="sm">Please enter attributes.</Text>
              </Center>
            )}
            <Box mb="1rem" p="1rem">
              {" "}
              <SaveButton />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default AddInventory;
