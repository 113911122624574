import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useUpdateEmailApi } from "../api/useUpdateEmailApi";

export const useUpdateEmail = () => {
  const { postUpdateEmailMutation } = useUpdateEmailApi();
  const { isLoading, isError, data, error, isSuccess } = postUpdateEmailMutation;
  const toast = useToast();
  const toastIdRef = useRef();

  const { register, handleSubmit } = useForm();
  const onSubmit = (values) => {
    postUpdateEmailMutation.mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "Email changed successfully!",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data.message ? error?.response?.data.message : error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postUpdateEmailMutation.isSuccess,
    postUpdateEmailMutation.isLoading,
    postUpdateEmailMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    toast,
    onSubmit,
    isSuccess
  };
};
