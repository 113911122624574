import {
  Box,
  Grid,
  GridItem,
  HStack,
  Button,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { PageTitle } from "components/Common/PageTitle";
import { ChevronDownIcon, EditIcon } from "@chakra-ui/icons";
import { globalStyles } from "theme/styles";
import { LeftSideLabelText } from "components/Common/LeftSideLabelText";
import EditButton from "components/Common/EditButton";
import MoreButton from "components/Common/MoreButton";
import { useGetPeak } from "../api/useGetPeak";
import usePeakList from "../List/usePeakList";
import usePeak from "./usePeak";

const Peak = () => {
  const { specificPeak } = usePeak();
  const { data } = useGetPeak();

  return (
    <Box direction="column">
      <Box h="97vh" bg={"white"}>
        {/* header */}
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem>
            <PageTitle title={"Peak Details"} />
          </GridItem>

          <GridItem p={"2rem"}>
            <HStack float={"right"} gap={"1rem"}>
              <NavLink to={"/admin/peak-update"}>
                <EditButton />
              </NavLink>
              <MoreButton />
            </HStack>
          </GridItem>
        </Grid>

        {/* Body */}
        <Box p={"2rem"} pt={0}>
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={2}>
              <LeftSideLabelText
                label={"Peak Name"}
                value={specificPeak?.label}
              />
              <LeftSideLabelText
                label={"Country"}
                value={specificPeak?.country}
              />
              <LeftSideLabelText
                label={"Altitude"}
                value={specificPeak?.altitude}
              />
              <Flex gap="1.2rem" alignItems="center" mt={"1.5rem"}>
                <Box w="8rem" fontSize={"sm"}>
                  Category
                </Box>
                <HStack>
                  {specificPeak?.category.map((list, indx) => {
                    return (
                      <Text fontSize="sm">
                        {indx === 0 ? ": " : ""}
                        {list.name}
                        {indx === specificPeak?.category.length - 1 ? "" : ","}
                      </Text>
                    );
                  })}
                  {/* <Text fontSize="sm">: 14 Peak, 7 Summit, 8000 Above </Text> */}
                </HStack>
              </Flex>
              {/* <LeftSideLabelText
                label={"Category"}
                value={specificPeak?.category.map((list) => {
                  return list.name;
                })}
              /> */}
              <LeftSideLabelText
                label={"Difficulty"}
                value={specificPeak?.difficulty.name}
              />
              <LeftSideLabelText
                label={"Lat,Lon"}
                value={`${specificPeak?.latitude},${specificPeak?.longitude}`}
              />
              <LeftSideLabelText
                label={"Description"}
                value={specificPeak?.description}
              />
            </GridItem>
            <GridItem>
              <Image
                src={specificPeak?.cover_image}
                width={"15rem"}
                height={"15rem"}
                objectFit={"cover"}
                borderRadius={"1rem"}
                float={"left"}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Peak;
