import { Flex, Box, Textarea } from "@chakra-ui/react";
import React from "react";

const LeftSideLabelTextArea = ({
  label,
  register,
  registerName,
  value,
  w,
  required,
  defaultValue,
  labelW,
  gap,
}) => {
  return (
    <Flex gap={gap ?? "1.2rem"} alignItems="center" mt="1.5rem">
      <Box w={labelW ?? "8rem"} fontSize={"sm"}>
        {label}
      </Box>
      <Box w={"100%"}>
        {registerName ? (
          <Textarea
            borderColor="#D3D3D3"
            w={w ? w : "30.5rem"}
            {...register(registerName)}
            defaultValue={defaultValue}
            value={value}
            required={required ? true : false}
          />
        ) : (
          <Textarea borderColor="#D3D3D3" w={w ? w : "30.5rem"} value={value} />
        )}
      </Box>
    </Flex>
  );
};

export default LeftSideLabelTextArea;
