import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import OrderRetailItem from "./OrderRetailItem";
import { Box, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import OrderRetailPayment from "./OrderRetailPayment";
import { useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import EditRetailerPayment from "./EditRetailerPayment";

export default function OrderRetail() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableInfo, setTableInfo] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [orderData, setOrderData] = useState("");
  const param = useParams();
  console.log(param);
  const fetchData = async () => {
    if (param.uuid) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/order/?invoice_uuid=${param.uuid}`
      );
      console.log(response);
      setOrderData(response?.data[0]);
      setTableInfo(response?.data[0]);
      setInvoiceNumber(response?.data[0]?.invoiceNumber);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <TopSearch />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box width={"100%"}>
          <OrderRetailItem
            orderData={orderData}
            invId={param.uuid}
            invoiceNumber={invoiceNumber}
          />
        </Box>
        <Box>
          <OrderRetailPayment
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            invId={param.uuid}
            invoiceNumber={invoiceNumber}
          />
        </Box>
      </Box>
      <EditRetailerPayment
        tableInfo={tableInfo}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        menuData={param.uuid}
        invId={param.uuid}
      />
    </Box>
  );
}
