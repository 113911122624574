import { Box, Button, HStack, Input, Text } from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import useEditChangepassword from "./useEditChangepassword";
import { PageSubTitle } from "components/Common/PageSubTitle";
import useEditChangeEmail from "./useEditChangeEmail";
import { useSendCode } from "views/Pages/signin/useSendCode";
import { useEffect, useState } from "react";

const Changepassword = ()=>{
    const [isSent,setIsSent] = useState(false);
    const [email, setEmail] = useState('');
    const {
		register,
		handleSubmit,
		onFinalSubmit
	} = useEditChangepassword();

    const { handleSubmit:h, register:r, onSubmit:onSub,isSuccess:success } = useSendCode();

    const {
		register:reg,
		handleSubmit:handleSub,
		onFinalSubmit:onFinalSub
	} = useEditChangeEmail();

    useEffect(() => {
        if(success){
            setIsSent(true);
        }
    },[success])

    return (
        <Box minH={'95vh'}>
            <Box display={'flex'} p={'1rem'} gap={'2rem'} flexWrap={'wrap'} >
                <Box borderRadius={'1rem'} boxShadow={'md'} height={'auto'} p={'1rem'} bg={"white"}  pos="relative" >
                    <PageSubTitle title="Change Password" />
                    <form onSubmit={handleSubmit(onFinalSubmit)}>
                        <LeftSideLabelInput label="Old Password" register={register} registerName={"old_password"} type="password"/>
                        <LeftSideLabelInput label="New Password" register={register} registerName={"new_password"} type="password"/>
                        <Box mt={'1rem'}>
                            <SaveButton />
                        </Box>
                    </form>
                </Box>
                <Box borderRadius={'1rem'} boxShadow={'md'} height={'auto'} p={'1rem'} bg={"white"}  pos="relative">
                    <PageSubTitle title="Change Email" />
                    <Box mt={'1rem'}>
                    {
                        !isSent ? 
                        <form onSubmit={h(onSub)}>
                            {/* <LeftSideLabelInput label="New Email" register={r} registerName={"email"} type="email"/> */}
                            <Text w={'6.5rem'}>New Email</Text> <Input minW={'20rem'} value={email} type="email" {...r('email')} onChange={(e)=>setEmail(e.target.value)}/>
                            <Box mt={'1rem'}>
                                <SaveButton />
                            </Box>
                        </form>
                        :
                        <form onSubmit={handleSub(onFinalSub)}>
                            <LeftSideLabelInput label="New Email" register={reg} registerName={"email"} type="email" value={email}/>
                            <LeftSideLabelInput label="OTP" register={reg} registerName={"otp"} type="otp"/>
                            <Box mt={'1rem'}>
                                <SaveButton />
                            </Box>
                        </form>
                    }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Changepassword;