import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostAmount = () => {
  async function postAmount(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/payment/`,
      values
    );
    return res;
  }

  const postAmountMutation = useMutation(postAmount);

  return {
    postAmountMutation,
  };
};
