import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Center,
  Image,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { PageTitle } from "components/Common/PageTitle";
import { useGetPeakType } from "../api/useGetPeakType";
import { LeftSideLabelMultiSelect } from "components/Common/LeftSideLabelMultiSelect";
import useEditPeak from "./useEditPeak";

const EditPeak = () => {
  const { data } = useGetPeakType();
  const {
    specificPeak,
    selectedPeakCategory,
    register,
    handleSubmit,
    onSubmit,
    handleCategoryChange,
    control
  } = useEditPeak();

  return (
    <Box direction="column">
      <Box h="97vh" bg={"white"}>
        {/* header */}

        <PageTitle title={"Edit Peak"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {" "}
          <Box p="1rem" pb={"0"}>
            <Grid templateColumns={"repeat(2, 1fr)"}>
              <GridItem>
                <Flex pl="2rem" flexDirection={"column"}>
                  <LeftSideLabelInput
                    type="text"
                    label="Peak Name"
                    // value={specificPeak?.label}
                    register={register}
                    registerName={"peakName"}
                  />
                  <LeftSideLabelInput
                    type="text"
                    label="Country"
                    // value={specificPeak?.country}
                    register={register}
                    registerName={"country"}
                  />
                  <LeftSideLabelInput
                    type="text"
                    label="Altitude"
                    // value={specificPeak?.altitude}
                    register={register}
                    registerName={"altitude"}
                  />
                  <LeftSideLabelMultiSelect
                    label="Category"
                    register={() => {}}
                    data={data}
                    onChange={handleCategoryChange}
                    value={selectedPeakCategory}
                    registerName={"nicee"}
                    control={control}
                  />
                  <LeftSideLabelInput
                    type="text"
                    label="Grade"
                    // value={specificPeak?.difficulty.name}
                    register={register}
                    registerName={"difficulty"}
                  />
                  <HStack>
                    <LeftSideLabelInput
                      type="text"
                      label="Lat,Lon"
                      // value={specificPeak?.latitude}
                      register={register}
                      registerName={"latitude"}
                    />
                    <Input
                      type={"text"}
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                      size="sm"
                      mt="1.5rem!important"
                      // value={specificPeak?.longitude}
                      register={register}
                      {...register("longitude")}
                    />
                  </HStack>
                  <LeftSideLabelTextArea
                    label="Description"
                    // value={specificPeak?.description}
                    register={register}
                    registerName={"description"}
                  />
                  <HStack gap="2rem" mt="5rem">
                    <SaveButton /> <CancelButton />{" "}
                  </HStack>
                </Flex>
              </GridItem>

              <GridItem>
                <Box cursor="pointer">
                  <Center
                    w="12rem"
                    h="8rem"
                    borderTop={"1px dashed #808080"}
                    borderLeft={"1px dashed #808080"}
                    borderRight={"1px dashed #808080"}
                    borderTopRadius="8px"
                  >
                    <Image
                      src={specificPeak?.cover_image}
                      alt={"upload image"}
                      w="13rem"
                      h="7.8rem"
                      borderTopRadius="8px"
                      objectFit={"cover"}
                    />
                  </Center>
                  <Center
                    w="12rem"
                    h="2rem"
                    border={"1px solid #808080"}
                    borderBottomRadius="8px"
                  >
                    Upload Photo
                  </Center>
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EditPeak;
