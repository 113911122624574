import { Box, Button, useMediaQuery, useToast } from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import React, { useState, useEffect } from "react";
import RetailerList from "./RetailerList";
import RetailerCategory from "./RetailerCategory";
import RetailerBill from "./RetailerBill";
import { usePostClothOrder } from "../api/usePostClothOrder";
import { useGetBrand } from "../api/useGetBrand";
import { useParams } from "react-router-dom";
import { useNotify } from "common hooks/useNotify";

export default function RetaiItem() {
  const toast = useToast();
  const [invoiceId, setInvoiceId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { errorNotify } = useNotify();

  const { data } = useGetBrand();
  const { postClothMutation } = usePostClothOrder();
  const { data: clothData, isSuccess, isError } = postClothMutation;
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [menuCategory, setMenuCategory] = useState("");
  const param = useParams();
  const [stock, setStock] = useState(false);

  console.log(param.uuid);
  function handleItemChange(item) {
    if (item?.is_unlimited === false && item?.closing_stock === 0) {
      setStock(true);
    } else {
      const itemIndex = selectedItems.findIndex(
        (selectedItem) => selectedItem.product === item.uuid
      );

      if (itemIndex !== -1) {
        setSelectedItems(selectedItems);
      } else {
        setSelectedItems([
          ...selectedItems,
          { ...item, quantity: 1, product: item.uuid, uuid: "" },
        ]);
      }
    }
  }
  //post order of cloth
  const submitValues = () => {
    const Data = selectedItems.map((item) => {
      return {
        product: item?.product,
        quantity: item?.quantity,
        unitprice: item?.selling_price,
        billAmount: item?.selling_price * item?.quantity ?? item.qty,
        discount_percentage:
          parseFloat(document.getElementById(`dis-${item?.uuid}`).value) ?? 0,
        complimentry: document.getElementById(`com-${item?.uuid}`).checked,
      };
    });
    const finalData = {
      invoice_uuid: param.uuid,

      items: Data,
    };

    postClothMutation.mutate(finalData);
  };
  useEffect(() => {
    if (isSuccess) {
      toast({
        title: " Item Updated successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
    }
    if (isError) {
      toast({
        title: postClothMutation?.error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [postClothMutation.isSuccess, postClothMutation.isError]);
  const handleSearch = (e) => {
    const query = e.target.value;
    console.log(query);
    setSearchQuery(query);
  };
  useEffect(() => {
    if (stock) {
      setStock(false);
      errorNotify("Item out of stock");
    }
  }, [stock]);
  const handleMenu = (val) => {
    setMenuCategory(val);
  };
  useEffect(() => {
    if (searchQuery) {
      setMenuCategory("");
    }
  }, [searchQuery]);
  return (
    <Box minH={"100vh"} bg={"white"}>
      <TopSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        selectedItems={selectedItems}
        submitValues={submitValues}
      />
      <Box paddingTop={"4.5rem"}>
        {breakPoint[0] && (
          <Box p={"1rem"} borderBottom={"1px solid lightgray"} width={"100%"}>
            {data?.data?.map((item, index) => {
              return (
                <Button
                  height={"29px"}
                  fontSize={"14px"}
                  fontWeight={"normal"}
                  width={"90px"}
                  color={"#fff"}
                  borderRadius={"4px"}
                  // bg="#3AB689"
                  mb={"0.5rem"}
                  mr={"0.5rem"}
                  colorScheme={activeIndex === index ? "red" : "green"}
                  size="md"
                  // onClick={() => {
                  //   setMenuCategory(item?.uuid);
                  //   setActiveIndex(index);
                  // }}
                >
                  {item?.name}
                </Button>
              );
            })}
          </Box>
        )}
        <Box display={"flex"} width={"100%"} minH={"90vh"} gap={"1rem"}>
          <Box>
            <RetailerCategory
              data={data}
              setMenuCategory={setMenuCategory}
              handleMenu={handleMenu}
            />
          </Box>
          <Box
            display={"block"}
            justifyContent={"space-between"}
            // flexWrap={"wrap"}
            width={"100%"}
            height={"-webkit-fit-content"}
            ml={"3rem"}
          >
            {" "}
            <RetailerList
              handleItemChange={handleItemChange}
              menuCategory={menuCategory}
              searchQuery={searchQuery}
            />
          </Box>
          <Box
            minW={"25rem"}
            borderLeft={"1px solid lightgray"}
            height={"90vh"}
          >
            <RetailerBill
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              submitValues={submitValues}
              invoiceId={invoiceId}
              invId={param.uuid}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
