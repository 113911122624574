import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNotify } from "common hooks/useNotify";
import { useAddWasteApi } from "./useAddWasteApi";

export const useAddWaste = () => {
  const { postAddWasteMutation } = useAddWasteApi();

  const { isLoading, isError, data, error, isSuccess } = postAddWasteMutation;
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    resetField,
    reset,
  } = useForm();

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("quantity", values.quantity);
    formData.append("inventory", values.inventory);
    formData.append("datetime", values.datetime);

    console.log(values);
    postAddWasteMutation.mutate(formData);
  };
  console.log("succ", isSuccess);
  useEffect(() => {
    if (isSuccess) {
      successNotify("Waste Added Successfully");
      reset();
      location.reload();
    }

    if (isError) {
      errorNotify(
        error?.response?.data.message
          ? error?.response?.data.message
          : error.message
      );
    }
  }, [
    postAddWasteMutation.isSuccess,
    postAddWasteMutation.isLoading,
    postAddWasteMutation.isError,
  ]);
  return {
    handleSubmit,
    register,
    control,
    onSubmit,
    isSuccess,
    getValues,
    setValue,
    watch,
    unregister,
    resetField,
    reset,
  };
};
