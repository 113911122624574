import {
  Box,
  Button,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import TableImage from "../../../../assets/icons/table.png";
import { BsClock } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import Delete from "../../../../assets/icons/remove.png";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useDeleteItem } from "./api/useDeleteItem";
import { useGetTableItem } from "./api/useGetTableItem";
import { useNotify } from "common hooks/useNotify";
import CancelModal from "components/Common/CancelModal";
export default function OrderItem({
  menuData,
  isCancelOpen,
  onCancelOpen,
  onCancelClose,
  cancelOrder,
  isMenuModalOpen,
  setIsMenuModalOpen,
}) {
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [orderId, setOrderId] = useState("");
  const [remainingData, setRemainingData] = useState([]);
  const [tableName, setTableName] = useState("");
  const [listData, setListData] = useState("");
  const [invoiceUid, setInvoiceUid] = useState("");
  const [currentTime, setCurrentTime] = useState(new Date());
  const fetchData = async () => {
    if (menuData) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?invoice_number=${menuData}`
      );
      setOrderId(response?.data[0]?.invoiceNumber);
      setInvoiceUid(response?.data[0]?.uuid);
      setRemainingData(response?.data[0]?.invoice_item);
      setTableName(response?.data[0]?.table_name);
      setListData(response?.data[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [menuData]);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    // Cleanup function
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Box borderRight="1px solid #ECEDED" height={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #ECEDED"}
        p={"1rem"}
        mt="5rem"
      >
        <Text color={"#000000"} fontSize={"23px"} fontWeight={"600"}>
          ORDER #: {orderId}
        </Text>
        <Box display={"flex"} gap={"3rem"}>
          <Text
            display={"flex"}
            alignItems={"center"}
            gap={"3px"}
            color={"#2C3832"}
            fontSize={"13px"}
            fontWeight={"500"}
          >
            <Image width={"10px"} height={"10px"} src={TableImage} /> TABLE:
            <span style={{ color: "#D89F65" }}>{tableName}</span>
          </Text>
        </Box>
      </Box>
      <ItemTable
        isCancelOpen={isCancelOpen}
        onCancelOpen={onCancelOpen}
        onCancelClose={onCancelClose}
        menuData={menuData}
        remainingData={remainingData}
        setRemainingData={setRemainingData}
        invoiceUid={invoiceUid}
        listData={listData}
        breakPoint={breakPoint}
        cancelOrder={cancelOrder}
      />
      {/* <Button
        className="button-bottom"
        onClick={() => setIsMenuModalOpen(true)}
      >
        Payable Amount
      </Button> */}
    </Box>
  );
}

function ItemTable({
  breakPoint,
  menuData,
  setRemainingData,
  remainingData,
  orderId,
  invoiceUid,
  listData,
  isCancelOpen,
  onCancelOpen,
  onCancelClose,
  cancelOrder,
}) {
  const { deleteItemMutation } = useDeleteItem();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successNotify } = useNotify();
  const history = useHistory();
  const { isLoading, isError, error, isSuccess, data } = deleteItemMutation;
  const toast = useToast();
  const handleDelete = (val) => {
    deleteItemMutation.mutate(val?.uuid);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Item deleted successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
      setRemainingData(data?.data?.data);
    }

    if (isError) {
      toast({
        title: error?.response?.data.message
          ? error?.response?.data.message
          : error.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    menuData,
    remainingData,
    deleteItemMutation.isSuccess,
    deleteItemMutation.isLoading,
    deleteItemMutation.isError,
  ]);
  // const cancelOrder = async () => {
  //   const response = await authorizationAxios.post(
  //     `${BASE_URL}/api/order/cancel_order/?uuid=${invoiceUid}`
  //   );
  //   if (response?.status === 200) {
  //     successNotify("Order canceled successfully");
  //     localStorage.removeItem("customer_name");
  //     localStorage.removeItem("customer_number");
  //     localStorage.removeItem("customer_uuid");

  //     const reloadTimeout = setTimeout(() => {
  //       history.push("/admin/table-list");
  //     }, 2000);

  //     return () => clearTimeout(reloadTimeout);
  //   }
  // };
  return (
    <Box
      p={"1.5rem"}
      // height={"100%"}
      width={"100%"}
      display={"flex"}
      flexFlow={"column"}
      justifyContent={"space-between"}
    >
      <CancelModal
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        onOpen={onCancelOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      />
      <TableContainer height={"100%"} width={"100%"} border="1px solid #ECEDED">
        <Table variant="simple" bg="#FAFAFA">
          <Thead bg={"#EAF0F0"}>
            <Tr>
              <Th>Item</Th>
              <Th>Price</Th>
              <Th>Qty</Th>
              <Th>SUBTOTAL</Th>
            </Tr>
          </Thead>
          <Tbody>
            {remainingData?.map((item, index) => {
              return (
                <Tr>
                  <Td>{item?.name}</Td>
                  <Td>
                    {listData?.currency}
                    {item?.selling_price}
                  </Td>
                  <Td>{item?.qty ?? item?.quantity}</Td>
                  {item?.quantity && (
                    <Td display={"flex"} alignItems={"center"} gap={"5px"}>
                      Rs.
                      {item?.selling_price * item?.quantity}{" "}
                      <Image
                        cursor={"pointer"}
                        height={"15px"}
                        src={Delete}
                        onClick={() => handleDelete(item)}
                      />
                    </Td>
                  )}
                  {item?.qty && (
                    <Td display={"flex"} alignItems={"center"} gap={"5px"}>
                      {item?.selling_price * item?.qty}{" "}
                      <Image
                        cursor={"pointer"}
                        height={"15px"}
                        src={Delete}
                        onClick={() => handleDelete(item)}
                      />
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!breakPoint[0] && (
        <Box mt={"2rem"} px={"2rem"}>
          <Button
            _hover={{ backgroundColor: "buttonred.500", color: "black" }}
            width={"100%"}
            height={"70px"}
            bg="buttonred.400"
            variant="secondary"
            color={"#fff"}
            onClick={onCancelOpen}
          >
            CANCEL ORDER
          </Button>
        </Box>
      )}
    </Box>
  );
}
