import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostTableApi = () => {

  async function postPostTable(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/floor-table/post-floor-table/`,
      values
    );
    return res;
  }

  const postPostTableMutation = useMutation(postPostTable);

  return {
    postPostTableMutation,
  };
};
