import {
  Select,
  Text,
  Flex,
  Grid,
  GridItem,
  Box,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { globalStyles } from "theme/styles";
import React from "react";
import Personal from "./component/Personal";
import Contact from "./component/Contact";

const OverView = () => {
  return (
    <>
      <Grid templateColumns={"repeat(2,1fr)"} p="1rem">
        <GridItem lineHeight={"1.5rem"}>
          <Flex gap="2rem">
            <Text fontSize={"xl"}>Booking Information</Text>
            <Select w="8rem" size="sm" cursor={"pointer"}>
              <option>Pending</option>
            </Select>
          </Flex>
          <Box mt="1rem">
            {" "}
            <Text>Lobuche Peak Expedition 2022</Text>
            <Text
              fontSize={"xs"}
              color={globalStyles.customStyle.fontColor.color}
            >
              VVIP Package
            </Text>
            <Text fontSize={"xs"}>April - May | April 6, 2022</Text>
            <Text
              fontSize={"xs"}
              color={globalStyles.customStyle.fontColor.color}
            >
              5 Special Request |10 Equipments Hire
            </Text>
          </Box>
        </GridItem>
        <GridItem justifySelf={"center"}>
          <Button color={globalStyles.customStyle.fontColor.color}>
            Management Notes
          </Button>
        </GridItem>
      </Grid>
      <Tabs overflow={"hidden"}>
        <TabList
          id="scroll"
          variant="unstyled"
          gap={{ sm: "1rem", md: "2rem" }}
          overflowX={{ sm: "scroll", xl: "visible" }}
          overflowY="hidden"
          scrollBehavior="smooth"
          // onClick={tabHandler}
          p="1rem"
          bg={"#F8F8F8"}
          borderTop="2px solid #EAEAEA"
          borderBottom="2px solid #EAEAEA"
        >
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Personal
          </Tab>

          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Contact
          </Tab>
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Insurance
          </Tab>
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Medical
          </Tab>
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Food
          </Tab>
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Additional
          </Tab>
          <Tab
            _selected={globalStyles.customStyle.unStyledTab}
            fontSize={{ base: "md", md: "md", sm: "sm" }}
            pr="2rem"
            pl={"2rem"}
            _hover={globalStyles.customStyle.tabHover}
          >
            Documents
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p="2rem" pt={"0"}>
            <Personal />
          </TabPanel>
          <TabPanel p="2rem">
            <Contact />
          </TabPanel>
          <TabPanel p="2rem" pt={"0"}>
            jk
          </TabPanel>
          <TabPanel>kjhk</TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default OverView;
