import {
  Box,
  Button,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { BsPeople } from "react-icons/bs";
import TableIcon from "../../../../../assets/icons/table.png";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useNotify } from "common hooks/useNotify";
import { authorizationAxios } from "axios/authorizationAxios";
import { globalStyles } from "theme/styles";

import { BASE_URL } from "constants/BASE_URL";
import CancelModal from "components/Common/CancelModal";
import { useAddCustomer } from "views/Pages/Order/List/api/useAddCustomer";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export default function TabMenu({
  selectedItems,
  setSelectedItems,
  tableNumber,
  guest,
  setGuest,
  bookedItem,
  tableId,
}) {
  const location = useLocation();
  const history = useHistory();
  const [subtotal, setSubtotal] = useState(0);
  const [tableName, setTableName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [orderName, setOrderName] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const { successNotify } = useNotify();
  const { errorNotify } = useNotify();
  const {
    isOpen: isOpened,
    onOpen: onOpened,
    onClose: onClosed,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const breakPoint = useMediaQuery("(max-width: 1200px)");
  const {
    handleSubmit,
    onSubmit,
    register,
    data,
    isSuccess,
  } = useAddCustomer();

  const handleDelete = (itemId) => {
    console.log(itemId, selectedItems);
    if (itemId?.uuid) {
      const updatedItems = selectedItems.filter(
        (item) => item.uuid !== itemId?.uuid
      );
      setSelectedItems(updatedItems);
    } else {
      const updatedItems = selectedItems.filter(
        (item) => item.product !== itemId?.product
      );
      setSelectedItems(updatedItems);
    }
  };
  console.log("xxyy", tableId);
  const fetchData = async () => {
    if (location?.state == 0) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?invoice_uuid=${tableId}`
      );
      setCustomerNumber(response?.data[0]?.customer_number);
      setCustomerName(response?.data[0]?.customer_name);
      setSelectedItems(response?.data[0]?.invoice_item);
      setTableName(response?.data[0]?.table_name);
      setOrderName(response?.data[0]?.invoiceNumber);
      setInvoiceId(response?.data[0]?.uuid);
      setGuest(response?.data[0]?.guest);
    } else {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/order/?uuid=${tableId}`
      );
      setCustomerNumber(response?.data[0]?.customer_number);
      setCustomerName(response?.data[0]?.customer_name);
      setSelectedItems(response?.data[0]?.invoice_item);
      setTableName(response?.data[0]?.table_name);
      setOrderName(response?.data[0]?.invoiceNumber);
      setInvoiceId(response?.data[0]?.uuid);
      setGuest(response?.data[0]?.guest);
    }
  };
  useEffect(() => {
    fetchData();
  }, [tableId]);

  const handleQuantityChange = (productId, action) => {
    setSelectedItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product?.uuid) {
          if (product.uuid === productId) {
            const newQuantity =
              action === "increase"
                ? product.quantity + 1
                : product.quantity - 1;
            return { ...product, quantity: newQuantity };
          }
        } else {
          if (product.product === productId) {
            const newQuantity =
              action === "increase"
                ? product.quantity + 1
                : product.quantity - 1;
            return { ...product, quantity: newQuantity };
          }
        }

        return product;
      })
    );
  };
  const calculateSubtotal = () => {
    const total = selectedItems?.reduce((accumulator, product) => {
      return accumulator + product.quantity * product.selling_price;
    }, 0);
    setSubtotal(total);
  };
  useEffect(() => {
    calculateSubtotal();
  }, [selectedItems]);

  const paymentOrder = () => {
    history.push(`/admin/order-item/${orderName}`);
  };
  const cancelOrder = async () => {
    if (invoiceId) {
      const response = await authorizationAxios.post(
        `${BASE_URL}/api/order/cancel_order/?uuid=${invoiceId}`
      );
      if (response?.status === 200) {
        successNotify("Order canceled successfully");
        localStorage.removeItem("customer_name");
        localStorage.removeItem("customer_number");
        localStorage.removeItem("customer_uuid");

        const reloadTimeout = setTimeout(() => {
          history.push("/admin/table-list");
        }, 1000);

        return () => clearTimeout(reloadTimeout);
      }
    }
  };
  const cancelWasteOrder = async () => {
    if (invoiceId) {
      try {
        const data = { uuid: invoiceId };
        const response = await authorizationAxios.post(
          `${BASE_URL}/api/inventory/cancel-as-waste/`,
          data
        );

        if (response?.status === 200) {
          successNotify("Order canceled successfully");
          localStorage.removeItem("customer_name");
          localStorage.removeItem("customer_number");
          localStorage.removeItem("customer_uuid");

          const reloadTimeout = setTimeout(() => {
            history.push("/admin/table-list");
          }, 1000);

          return () => clearTimeout(reloadTimeout);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (isSuccess) {
      onClosed();
    }
  }, [isSuccess]);
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexFlow={"column"}
      justifyContent={"space-between"}
    >
      <Modal isOpen={isOpened} onClose={onClosed}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LeftSideLabelInput
                defaultValue={customerNumber}
                label="Phone number"
                type="number"
                register={register}
                registerName={"number"}
              />
              <LeftSideLabelInput
                label="Customer Name"
                defaultValue={customerName}
                type="text"
                register={register}
                registerName={"customername"}
              />
              <Flex gap="1rem" mt="3rem">
                <SaveButton />
                <CancelButton onClose={onClosed} />
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px dashed #A9A9A9"}
          paddingBottom={"1rem"}
          width={"100%"}
          p={"1rem"}
        >
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"590"}
            >
              ORDER #
            </Text>
            <Text
              display={"flex"}
              alignItems={"baseline"}
              gap="5px"
              mt="5px"
              color={"#2C3832"}
              fontSize={breakPoint[0] ? "10px" : "12px"}
              fontWeight={"600"}
            >
              <BsPeople /> GUEST:{" "}
              <span style={{ color: "#D89F65" }}>
                <Button
                  size="xs"
                  mr="5px"
                  bg="none"
                  onClick={() => setGuest(guest - 1)}
                  disabled={guest <= 1}
                >
                  -
                </Button>
                {guest}
                <Button
                  size="xs"
                  bg={"none"}
                  onClick={() => setGuest(guest + 1)}
                >
                  +
                </Button>
              </span>
            </Text>
          </Box>
          <Box>
            <Text fontSize={"20px"} color={"#2C3832"} fontWeight={"600"}>
              {orderName}
            </Text>
            <Box display={"flex"} alignItems={"baseline"}>
              <Text
                display={"flex"}
                gap="5px"
                alignItems={"baseline"}
                mt="5px"
                color={"#2C3832"}
                fontSize={"12px"}
                fontWeight={"600"}
              >
                {" "}
                <Image src={TableIcon} width={"10px"} marginRight={"3px"} />
                TABLE:
                <span style={{ color: "#D89F65" }}>{tableName}</span>
                <span style={{ color: "#D89F65" }}>{tableNumber?.name}</span>
              </Text>
              <Box>
                {(customerName && localStorage.getItem("customer_name")) ||
                localStorage.getItem("customer_name") ? (
                  <>
                    <Text
                      marginLeft={"1rem"}
                      color={globalStyles.customStyle.fontColor.color}
                      fontSize={"14px"}
                      onClick={onOpened}
                      cursor={"pointer"}
                    >
                      {localStorage.getItem("customer_name")}
                    </Text>
                  </>
                ) : (customerNumber &&
                    localStorage.getItem("customer_number")) ||
                  localStorage.getItem("customer_number") ? (
                  <>
                    <Text
                      marginLeft={"1rem"}
                      color={globalStyles.customStyle.fontColor.color}
                      fontSize={"14px"}
                      onClick={onOpened}
                      cursor={"pointer"}
                    >
                      {localStorage.getItem("customer_number")}
                    </Text>
                  </>
                ) : customerName ? (
                  <Text
                    marginLeft={"1rem"}
                    color={globalStyles.customStyle.fontColor.color}
                    fontSize={"14px"}
                    onClick={onOpened}
                    cursor={"pointer"}
                  >
                    {customerName}
                  </Text>
                ) : customerNumber ? (
                  <Text
                    marginLeft={"1rem"}
                    color={globalStyles.customStyle.fontColor.color}
                    fontSize={"14px"}
                    onClick={onOpened}
                    cursor={"pointer"}
                  >
                    {customerNumber}
                  </Text>
                ) : (
                  <>
                    <Text
                      marginLeft={"1rem"}
                      color={globalStyles.customStyle.fontColor.color}
                      fontSize={"14px"}
                      onClick={onOpened}
                    >
                      +Add Customer
                    </Text>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width={"129%"}>
          <Table variant="unstyled" size="md" w={"100%"}>
            <Tbody>
              <Tr>
                <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                  Items
                </Td>
                <Td
                  color={"#828282"}
                  fontSize={"12px"}
                  fontWeight="600"
                  pl={"2.85rem"}
                >
                  Qty
                </Td>
                <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                  Amnt
                </Td>
              </Tr>

              {selectedItems &&
                selectedItems?.map((item, index) => {
                  return (
                    <Tr>
                      <Td
                        color={"#000000"}
                        fontSize={"12px"}
                        lineHeight={"14px"}
                        fontWeight={"600"}
                      >
                        <div className="twolines">{item?.name}</div>
                      </Td>
                      <Td
                        color={"#000000"}
                        fontSize={"12px"}
                        lineHeight={"14px"}
                        fontWeight={"600"}
                      >
                        <Button
                          size="xs"
                          mr="5px"
                          bg="none"
                          onClick={() =>
                            handleQuantityChange(
                              item?.uuid ? item?.uuid : item?.product,
                              "decrease"
                            )
                          }
                          disabled={item?.quantity <= 1}
                        >
                          -
                        </Button>
                        {item?.quantity}
                        <Button
                          size="xs"
                          bg={"none"}
                          onClick={
                            item?.is_unlimited === false &&
                            item?.available_stock === item?.quantity
                              ? () =>
                                  toast({
                                    title: "Inventory unavailable",
                                    position: "top-right",
                                    status: "error",
                                    duration: 1000,
                                    isClosable: true,
                                  })
                              : () =>
                                  handleQuantityChange(
                                    item?.uuid ? item?.uuid : item?.product,
                                    "increase"
                                  )
                          }
                        >
                          +
                        </Button>
                      </Td>
                      <Td
                        color={"#000000"}
                        fontSize={"12px"}
                        lineHeight={"14px"}
                        fontWeight={"600"}
                        display={"flex"}
                        gap="3px"
                      >
                        {item?.selling_price * item?.quantity}

                        <AiFillDelete
                          color="#EC5A5A"
                          size={13}
                          cursor={"pointer"}
                          onClick={() => handleDelete(item)}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <Box px={"1rem"}>
        <TotalAmount
          breakPoint={breakPoint}
          subtotal={subtotal}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          paymentOrder={paymentOrder}
          cancelOrder={cancelOrder}
          cancelWasteOrder={cancelWasteOrder}
        />
      </Box>
    </Box>
  );
}

function TotalAmount({
  subtotal,
  paymentOrder,
  cancelOrder,
  breakPoint,
  onOpen,
  onClose,
  isOpen,
  cancelWasteOrder,
}) {
  return (
    <Box
      border={"1px dashed #A9A9A9"}
      borderRadius={"14px 14px 0px 0px"}
      padding={"1rem"}
      bottom={"1rem"}
      right={"1rem"}
      width={"100%"}
      backgroundColor={"#FAFAFA"}
    >
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      />
      {/* TOTAL */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Text fontWeight={"600"} fontSize={"13px"}>
            SUBTOTAL
          </Text>
        </Box>
        <Box>
          <Text fontWeight={"600"}>Rs.{subtotal}</Text>
        </Box>
      </Box>
      <Box
        borderTop={"1px dashed #A9A9A9 "}
        paddingBlock={"1rem"}
        display={"flex"}
        mt="2rem"
        justifyContent={"space-between"}
      >
        <Text fontSize={"20px"} fontWeight={"600"}>
          TOTAL
        </Text>
        <Text fontSize={"20px"} fontWeight={"600"}>
          Rs.{parseFloat(subtotal)}
        </Text>
      </Box>
      {/* BUTTON */}
      <Box display={"flex"} gap={"0.5rem"} mt="1rem" mb={"1rem"}>
        <Button
          height={"50px"}
          width={"100%"}
          size="sm"
          color="#fff"
          bg="orange.400"
          // onClick={cancelOrder}
          borderRadius={"0rem"}
          onClick={cancelWasteOrder}
          _hover={{ backgroundColor: "orange.500", color: "black" }}
          variant="secondary"
        >
          WASTE
        </Button>

        <Button
          height={"50px"}
          width={"100%"}
          _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
          size="sm"
          color="#ffff"
          bg={"buttongreen.400"}
          borderRadius={"0rem"}
          variant="secondary"
          onClick={paymentOrder}
        >
          PAY
        </Button>
      </Box>
    </Box>
  );
}
