import {
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineCloseCircle,
  AiOutlineCheck,
} from "react-icons/ai";

const OneProperty = ({
  itemgroupdata,
  countIndex,
  getValues,
  itemRemove,
  register,
  seconds,
  setValue,
}) => {
  return (
    <>
      {/* when size is 1 */}
      {itemgroupdata &&
        itemgroupdata.length == 1 &&
        itemgroupdata[0].value.split(",").map((color, index) => {
          // console.log("index", countIndex.current, index,getValues("groupname") + "-" + color);
          countIndex.current = countIndex.current + 1;
          setValue(
            "item." + index + ".name",
            localStorage.getItem("company_type") === "Retailer"
              ? getValues("groupname") + "-" + color
              : color
          );
          return (
            <Grid
              gap={"1rem"}
              templateColumns={"repeat(9, 1fr)"}
              mt="1.5rem"
              key={index}
            >
              <GridItem>
                <Textarea
                  width="7.5rem"
                  type="text"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  size="sm"
                  height={"auto"}
                  className="autosize"
                  // value={getValues("groupname") + "-" + color}
                  {...register("item." + index + ".name")}
                  required
                  rows={"2"}
                  // onInput={(e) => {
                  //   setScrollHeight(e.target.scrollHeight);
                  //   console.log("scrollheight ", scrollHeight);
                  // }}
                />
              </GridItem>
              <GridItem>
                <Input
                  width="7.5rem"
                  type="text"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  size="sm"
                  {...register("item." + index + ".sku")}
                  required
                  defaultValue={seconds + "" + index}
                />
              </GridItem>
              <GridItem>
                <Input
                  width="7.5rem"
                  // id={"item." + index + ".cost_price"}
                  type="number"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  size="sm"
                  defaultValue={0}
                  required
                  {...register("item." + index + ".cost_price")}
                />
              </GridItem>
              <GridItem>
                <Input
                  width="7.5rem"
                  type="number"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  defaultValue={0}
                  size="sm"
                  {...register("item." + index + ".selling_price")}
                  required
                />
              </GridItem>
              <GridItem>
                <Input
                  width="7.5rem"
                  type="text"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  size="sm"
                  {...register("item." + index + ".upc")}
                />
              </GridItem>
              <GridItem>
                <Input
                  width="7.5rem"
                  type="text"
                  borderColor="#D3D3D3"
                  borderRadius={"0.3rem"}
                  size="sm"
                  {...register("item." + index + ".isbn")}
                />
              </GridItem>
              <GridItem>
                <HStack>
                  <Input
                    width="7.5rem"
                    type="number"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    defaultValue={0}
                    size="sm"
                    {...register("item." + index + ".opening_stock")}
                    required
                  />
                </HStack>
              </GridItem>
              <GridItem width={"5rem"}>
                <Checkbox
                  {...register("item." + index + ".is_unlimited")}
                  size={"2xl"}
                  icon={<AiOutlineCheck />}
                ></Checkbox>
              </GridItem>
              <GridItem width={"5rem"}>
                <Checkbox
                  {...register("item." + index + ".delete")}
                  size={"2xl"}
                  icon={<AiOutlineDelete />}
                  colorScheme={"red"}
                ></Checkbox>
              </GridItem>
            </Grid>
          );
        })}
    </>
  );
};

export default OneProperty;
