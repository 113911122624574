import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { usePostInventoryDetailApi } from "../api/usePostInventoryDetailApi";

export const usePostInventoryDetail = () => {
  const { postInventoryDetailMutation } = usePostInventoryDetailApi();

  const {
    isLoading,
    isError,
    data,
    error,
    isSuccess,
  } = postInventoryDetailMutation;

  const toast = useToast();
  const toastIdRef = useRef();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control
  } = useForm();

  const onSubmit = (values) => {
    postInventoryDetailMutation.mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
      toastIdRef.current = toast({
        title: "Updated",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postInventoryDetailMutation.isSuccess,
    postInventoryDetailMutation.isLoading,
    postInventoryDetailMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    getValues,
    watch,
    unregister,
    control,
    isSuccess
  };
};
