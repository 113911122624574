import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";

import { useMutation } from "react-query";

export const useUpdateEmailApi = () => {

  async function postUpdateEmail(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/update-email/`,
      values
    );
    return res;
  }

  const postUpdateEmailMutation = useMutation(postUpdateEmail);

  return {
    postUpdateEmailMutation,
  };
};
