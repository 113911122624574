import { configureStore } from "@reduxjs/toolkit";
// import { recordFlightReducer } from "views/Pages/Record Flights/slice/recordFlightSlice";
// import { editFlightReducer } from "views/Pages/Reports/slice/editFlightSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { signInReducer } from "views/Pages/signin/slice/signInSlice";
import { profileSlice } from "views/Pages/UserProfile/slice/profileSlice";
import { profileReducer } from "views/Pages/UserProfile/slice/profileSlice";
import { peakReducer } from "views/Pages/ExpeditionAndPackage/Peak/slice/peakSlice";
const persistConfigLogin = {
  key: "root",
  storage,
  whitelist: ["signIn"],
};

const persistConfigProfile = {
  key: "root2",
  storage,
  whitelist: ["profile"],
};

const persistConfigPeak = {
  key: "peak",
  storage,
  whitelist: ["specificPeak"],
};

const persistedSignIn = persistReducer(persistConfigLogin, signInReducer);
const persistedProfile = persistReducer(persistConfigProfile, profileReducer);
const persistedPeak = persistReducer(persistConfigPeak, peakReducer);
// const persistReportInfoForEdit = persistReducer(
//   persistConfigReportOfEdit,
//   editFlightReducer
// );

export default configureStore({
  reducer: {
    signIn: persistedSignIn,
    profile: persistedProfile,
    peak: persistedPeak,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
