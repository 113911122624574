import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetSalesReportApi = (next) => {
  async function getSales() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/retailer/unpaidsales/report/?page_number=${next}`
    );
    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getSales", next],
    () => getSales(next)
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
