import { useEffect, useRef, useState } from 'react'
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { useEditBrandApi } from '../api/useEditBrandApi';

export default function useEditBrand(data) {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure()
    const [uuid, setUuid] = useState('');

	const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
	const toastIdRef = useRef();
	const uploadPhotoRef = useRef(null);
	const { register, handleSubmit, reset } = useForm()

	const { editBrandMutation } = useEditBrandApi(uuid)
	const { isLoading, isError, error, isSuccess } = editBrandMutation;

	function uploadPhotoOnClickHandler() {
		uploadPhotoRef.current.click();
	}

	function uploadPhotoChangeHandler(event) {
		SetUploadPhotoFile(Array.from(event.target.files));
	}

	function onFinalSubmit(data) {
		const formData = new FormData();

		formData.append("name", data.name);
		formData.append("color_code", data.color_code);
		formData.append("slogan", data.slogan);
		formData.append("uuid", data.uuid);
        setUuid(data.uuid)
		uploadPhotoFile.length !== 0
			? formData.append("logo", uploadPhotoFile[0])
			: ''

		editBrandMutation.mutate(formData)
	}

	useEffect(() => {
		if (isOpen) {
			reset({
				name: data?.name,
			})
		}
	}, [isOpen])

	useEffect(() => {
		if (isSuccess) {
			toastIdRef.current = toast({
				title: "Brand Updated!",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});

		}

		if (isLoading) {
			toastIdRef.current = toast({
				title: "loading.....",
				position: "top-right",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		}
		if (isError) {
			toast({
				title: "Missing Information",
				position: "top-right",
				// description: "You will shortly contact you.",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [
		editBrandMutation.isSuccess,
		editBrandMutation.isLoading,
		editBrandMutation.isError,
	]);

	return {
		isOpen,
		onOpen,
		onClose,
		isLoading,
		isError,
		error,
		isSuccess,
		register,
		handleSubmit,
		uploadPhotoFile,
		SetUploadPhotoFile,
		uploadPhotoRef,
		uploadPhotoOnClickHandler,
		uploadPhotoChangeHandler,
		onFinalSubmit
	}
}
