import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetSalesList = (params, next) => {
  async function getSalesList() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/brand/sales/details/v2/?uuid=${params}&page=${
        next ? next : 1
      }`
    );
    return res.data;
  }

  const {
    data,
    error,
    isError,
    isLoading,
    refetch,
    isSuccess,
    remove,
  } = useQuery("getSalesList", getSalesList);

  return {
    data,
    refetch,
    isLoading,
    remove,
  };
};
