import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Text,
  VStack,
  others,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useAddWaste } from "../../api/useAddWaste";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
export default function AddWaste({ isOpen, onOpen, onClose, uuid }) {
  const { handleSubmit, onSubmit, register, control } = useAddWaste();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  console.log("Current Date:", formattedDate);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Waste</ModalHeader>
            <ModalBody>
              <>
                <input
                  {...register("inventory")}
                  type={"hidden"}
                  value={uuid}
                />

                <LeftSideLabelInput
                  type="text"
                  label="Quantity"
                  register={register}
                  registerName={"quantity"}
                />
              </>
              {/* <LeftSideLabelInput
                type="text"
                label="Date"
                register={register}
                defaultValue={formattedDate}
                registerName={"quantity"}
              /> */}
              <Box display={"flex"} alignItems={"center"} mt="1rem">
                <Text w={"10rem"}> Date </Text>
                <Input type="date" id="startdate" {...register("datetime")} />
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button onClick={handleSubmit(onSubmit)} variant="ghost">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
    </div>
  );
}
