import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useEditManufacturerApi = (manufacturer) => {

    async function editManufacturer(values) {
        const res = await authorizationAxios.put(
            `${BASE_URL}/api/manufacturers/${manufacturer}/`,
            values
        );
        return res;
    }

    const editManufacturerMutation = useMutation(editManufacturer);

    return {
        editManufacturerMutation,
    };
};
