import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Select from "react-select";
import { Controller } from "react-hook-form";

export const LeftSideLabelSelect = ({
  label,
  data,
  onSelect,
  value,
  forPaymentDetails,
  register,
  registerName,
  control,
  alignment,
  labelWidth,
  fontWeight,
  labelW,
  bottomMargin,
  mt,
  mr,
  ml,
  gap,
}) => {
  return (
    <Flex
      display={alignment ? "block" : "flex"}
      alignItems={"center"}
      gap={gap ?? "1.2rem"}
      mt={mt ?? "1.5rem"}
      mr={mr ?? "0rem"}

    >
      {registerName ? (
        <>
          {" "}
          <Box
            mb={bottomMargin ? "0.5rem" : ""}
            w={labelW ? labelW : "8rem"}
            fontSize={"sm"}

          >
            {label}
          </Box>
          <Box ml={ml ?? "0rem"} w={labelWidth ?? "15rem"}>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  fontWeight={fontWeight ?? ""}
                  options={data}
                  value={data?.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                // isMulti
                />
              )}
              control={control}
              name={registerName}
              defaultValue={value}
              register={registerName}
            />
          </Box>
        </>
      ) : (
        <>
          {" "}
          <Box w="8rem" fontSize={"sm"}>
            {label}
          </Box>
          <Box w="15rem">
            <Select options={data} />
          </Box>
        </>
      )}
    </Flex>
  );
};
