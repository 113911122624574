import { Box,Text, Textarea,Tabs,TabList,Tab,TabPanels,TabPanel, HStack } from "@chakra-ui/react";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useState } from "react";
import { globalStyles } from "theme/styles";
import {AiOutlineArrowLeft} from "react-icons/ai";

const Include = ({register,control,newInclude,setNewInclude}) => {

    return (<>
        {
            !newInclude?
            <Box>
                <LeftSideLabelSelect label="Assign Include/Exclude" data={[{label:'xyz',value:'asdf'}]}  register={register} registerName={'selected_include'} control={control}/>
                <Text
                  marginLeft={'9rem'}
                  color={globalStyles.customStyle.fontColor.color}
                  cursor="pointer"
                  mt={'1.5rem'}
                  onClick={()=>setNewInclude(true)}
                >
                  + Create New Include & Exclude
                </Text>
            </Box>
            :
            <Box>
                <HStack mb={'1rem'} mt={'2rem'} color={globalStyles.customStyle.fontColor.color} cursor="pointer" onClick={()=>setNewInclude(false)}>
                    <AiOutlineArrowLeft />
                    <Text> Select From Dropdown 
                    </Text>
                </HStack>
                <Tabs>
                    <TabList>
                        <Tab
                            style={globalStyles.customStyle.tab}
                            _selected={globalStyles.customStyle.selectedTab}
                            _focus={"none"}
                        >
                            Included
                        </Tab>
                        <Tab
                            style={globalStyles.customStyle.tab}
                            _selected={globalStyles.customStyle.selectedTab}
                            _focus={"none"}
                        >
                            Excluded
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <Textarea height={'10rem'} placeholder={'write something'} {...register('include')}/>
                        </TabPanel>
                        <TabPanel>
                            <Textarea height={'10rem'} placeholder={'write something'} {...register('exclude')}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>

        }</>
    )
}
export default Include;