import axios from "axios";
import { BASE_URL } from "constants/BASE_URL";

import { useQuery, useMutation } from "react-query";

export const useSignInApi = () => {
  // async function getContact() {
  //   const res = await axios.get(`${BASE_URL}/api/about/`);
  //   return res.data.data;
  // }
  // const {
  //   data: getContactData,
  //   error,
  //   isError: isErrorGetContact,
  //   isLoading: isLoadingGetContact,
  // } = useQuery("getContact", getContact);

  async function postSignIn(values) {
    const res = await axios.post(`${BASE_URL}/api/login-user/`, values);
    return res;
  }

  const postSignInMutation = useMutation(postSignIn);

  return {
    postSignInMutation,
  };
};
