import { useToast } from "@chakra-ui/react";

import { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNewEmailApi } from "../api/useNewEmailApi";

export const useNewEmail = () => {
  const { postNewEmailMutation } = useNewEmailApi();
  const { isLoading, isError, data, error, isSuccess } = postNewEmailMutation;
  const toast = useToast();
  const toastIdRef = useRef();

  const { register, handleSubmit } = useForm();
  const onSubmit = (values) => {
    postNewEmailMutation.mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "OTP sent to the email!",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postNewEmailMutation.isSuccess,
    postNewEmailMutation.isLoading,
    postNewEmailMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    toast,
    onSubmit,
    isSuccess
  };
};
