import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useAddGalleryCategoryApi } from "../../../api/useAddGalleryCategoryApi";

export const useAddGalleryCategory = () => {
  const { postAddGalleryCategoryMutation } = useAddGalleryCategoryApi();
  const { isLoading, isError, data, error, isSuccess } = postAddGalleryCategoryMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);

  const { register, handleSubmit,control } = useForm();
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append(
      "image",
      uploadPhotoFile.length !== 0
        ? uploadPhotoFile[0]
        : ''
    );
    postAddGalleryCategoryMutation.mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "Gallery Category Added successfully!",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error?.response?.data.message ? error?.response?.data.message : error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postAddGalleryCategoryMutation.isSuccess,
    postAddGalleryCategoryMutation.isLoading,
    postAddGalleryCategoryMutation.isError,
  ]);

  const uploadPhotoRef = useRef(null);

  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    SetUploadPhotoFile(Array.from(event.target.files));
  }

  return {
    handleSubmit,
    register,
    control,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoRef,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoFile,
  };
};
