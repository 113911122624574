import { useToast } from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSendCodeApi } from "./api/useSendCodeApi";

export const useSendCode = () => {
  const { postSendCodeMutation } = useSendCodeApi();
  const { isLoading, isError, data, error, isSuccess } = postSendCodeMutation;
  const toast = useToast();
  const toastIdRef = useRef();

  const { register, handleSubmit } = useForm();
  const onSubmit = (values) => {
    postSendCodeMutation.mutate(values);
  };

  useEffect(() => {
    if (isSuccess) {
        toastIdRef.current = toast({
            title: "Code sent to email.",
            position: "top-right",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postSendCodeMutation.isSuccess,
    postSendCodeMutation.isLoading,
    postSendCodeMutation.isError,
  ]);

  return {
    handleSubmit,
    register,
    toast,
    onSubmit,
    isSuccess
  };
};
