import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Grid,
  GridItem,
  Center,
  Image,
} from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import React, { useEffect } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useAddCategories } from "./useAddCategories";

const AddCategories = ({ isOpen, onClose, refetch }) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    setUploadPhotoFile,
    uploadPhotoRef,
    uploadPhotoFile,
  } = useAddCategories();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setUploadPhotoFile([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Categories</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns={"1fr 1fr"} gap="3rem">
              <GridItem>
                <LeftSideLabelInput
                  label="Categories name"
                  type="text"
                  register={register}
                  registerName={"name"}
                />
                {/* <LeftSideLabelInput
                    label="Sort No."
                    type="text"
                    register={register}
                    registerName={"sort"}
                  /> */}
                <Flex gap="1rem" mt="3rem">
                  <SaveButton />
                  <CancelButton onClose={onClose} />
                </Flex>
              </GridItem>
            </Grid>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCategories;
