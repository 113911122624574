import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Grid,
  GridItem,
  Center,
  Image,
} from "@chakra-ui/react";
import { CancelButton } from "components/Common/CancelButton";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
import { SaveButton } from "components/Common/SaveButton";
import React, { useEffect } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useAddBrand } from "./useAddBrand";

const AddBrand = ({ isOpen, onClose, refetch }) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    toast,
    onSubmit,
    isSuccess,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    setUploadPhotoFile,
    uploadPhotoRef,
    uploadPhotoFile,
  } = useAddBrand();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isOpen) {
      reset();
      setUploadPhotoFile([]);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Brand</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns={"1fr 1fr"} gap="3rem">
              <GridItem>
                <LeftSideLabelInput
                  label="Brand name"
                  type="text"
                  register={register}
                  registerName={"name"}
                />
                <LeftSideLabelInput
                  label="Color Code"
                  type="text"
                  register={register}
                  registerName={"color_code"}
                />
                <LeftSideLabelTextArea
                  label="Slogan"
                  register={register}
                  registerName={"slogan"}
                />
                <Flex gap="2rem" mt="3rem" p="1rem 0rem">
                  <SaveButton />
                  <CancelButton onClose={onClose} />
                </Flex>
              </GridItem>
              <GridItem>
                <Box onClick={uploadPhotoOnClickHandler}>
                  <input
                    type="file"
                    hidden
                    ref={uploadPhotoRef}
                    onChange={uploadPhotoChangeHandler}
                  />
                  <Center
                    w="12rem"
                    h="8rem"
                    borderTop={"1px dashed #808080"}
                    borderLeft={"1px dashed #808080"}
                    borderRight={"1px dashed #808080"}
                    borderTopRadius="8px"
                  >
                    {uploadPhotoFile.length == 0 ? (
                      <IoCloudUploadOutline
                        fontSize={"3rem"}
                        color="lightgrey"
                      />
                    ) : (
                      <Image
                        src={
                          uploadPhotoFile.length !== 0
                            ? URL.createObjectURL(uploadPhotoFile[0])
                            : ""
                        }
                        w="13rem"
                        h="7.8rem"
                        borderTopRadius="8px"
                        objectFit={"cover"}
                      />
                    )}
                  </Center>
                  <Center
                    w="12rem"
                    h="2rem"
                    border={"1px solid #808080"}
                    borderBottomRadius="8px"
                  >
                    Upload Cover
                  </Center>
                </Box>
              </GridItem>
            </Grid>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddBrand;
