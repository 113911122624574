import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddResolveApi = () => {
  async function postResolve(values) {
    const data = {
      resolve_type: values.resolve_type,
      remarks: values.remarks,
      customer: values.customer,
    };
    console.log("val", values);
    const res = await authorizationAxios.patch(
      `${BASE_URL}/api/restaurant/invoice/resolve/${values.uuid}/`,
      data
    );
    return res;
  }

  const postResolveMutation = useMutation(postResolve);

  return {
    postResolveMutation,
  };
};
