import React from "react";
import { useSelector, useDispatch } from "react-redux";

const usePeak = () => {
  const specificPeak = useSelector((state) => state.peak.specificPeak);
  // console.log("getPeak", specificPeak);
  return {
    specificPeak,
  };
};

export default usePeak;
