import {
  Checkbox,
  Grid,
  GridItem,
  HStack,
  Input,
  Textarea,
} from "@chakra-ui/react";
import {
  AiOutlineArrowLeft,
  AiOutlineCheck,
  AiOutlineDelete,
} from "react-icons/ai";

const TwoProperty = ({
  itemgroupdata,
  countIndex,
  getValues,
  itemRemove,
  register,
  seconds,
  setValue,
}) => {
  let count = -1;

  return (
    <>
      {/* when size is 2 */}
      {itemgroupdata &&
        itemgroupdata.length == 2 &&
        itemgroupdata[0].value.split(",").map((color, index) => {
          return itemgroupdata[1]?.value.split(",").map((size, i) => {
            count = count + 1;
            // console.log(getValues("groupname") + "-" + color + "-" + size)

            setValue(
              "item." + count + ".name",
              localStorage.getItem("company_type") === "Retailer"
                ? getValues("groupname") + "-" + color + "-" + size
                : color + " " + size
            );

            countIndex.current = countIndex.current + 1;
            return (
              <Grid
                gap={"1rem"}
                templateColumns={"repeat(9, 1fr)"}
                mt="1.5rem"
                key={count}
              >
                <GridItem>
                  <Textarea
                    width="7.5rem"
                    type="text"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    size="sm"
                    {...register("item." + count + ".name")}
                    rows="2"
                    required
                  />
                </GridItem>
                <GridItem>
                  <Input
                    width="7.5rem"
                    type="text"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    size="sm"
                    {...register("item." + count + ".sku")}
                    required
                    defaultValue={seconds + "" + count}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    width="7.5rem"
                    // id={"item." + i + ".cost_price"}
                    type="number"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    defaultValue={0}
                    size="sm"
                    {...register("item." + count + ".cost_price")}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    type="number"
                    width="7.5rem"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    size="sm"
                    {...register("item." + count + ".selling_price")}
                    defaultValue={0}
                    required
                  />
                </GridItem>
                <GridItem>
                  <Input
                    type="text"
                    width="7.5rem"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    size="sm"
                    {...register("item." + count + ".upc")}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    type="text"
                    width="7.5rem"
                    borderColor="#D3D3D3"
                    borderRadius={"0.3rem"}
                    size="sm"
                    {...register("item." + count + ".isbn")}
                  />
                </GridItem>
                <GridItem>
                  <HStack>
                    <Input
                      type="number"
                      width="7.5rem"
                      borderColor="#D3D3D3"
                      borderRadius={"0.3rem"}
                      size="sm"
                      {...register("item." + count + ".opening_stock")}
                      defaultValue={0}
                      required
                    />
                  </HStack>
                </GridItem>
                <GridItem width={"5rem"}>
                  <Checkbox
                    {...register("item." + count + ".is_unlimited")}
                    size={"2xl"}
                    icon={<AiOutlineCheck />}
                  ></Checkbox>
                </GridItem>
                <GridItem width={"5rem"}>
                  <Checkbox
                    {...register("item." + count + ".delete")}
                    size={"2xl"}
                    icon={<AiOutlineDelete />}
                    colorScheme={"red"}
                  ></Checkbox>
                </GridItem>
              </Grid>
            );
          });
        })}
    </>
  );
};

export default TwoProperty;
