import { useForm } from "react-hook-form";
import { useEditPaymentApi } from "./useEditPaymentApi";
import { useEffect, useRef } from "react";
import { toast } from "@chakra-ui/react";

export const useEditPayment = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
  } = useForm();
  const { postEditPeakMutation } = useEditPaymentApi();
  const { isLoading, isError, data, error, isSuccess } = postEditPeakMutation;
  const toastIdRef = useRef();
  const onSubmit = () => {
    const values = watch();
    const formData = new FormData();
    formData.append("uuid", values.uuid);
    formData.append("currency", values.currency);
    {
      values.table !== null && formData.append("table", values.table);
    }
    formData.append("discount", values.discount);
    formData.append("tax", values.tax);

    formData.append("adjustment_amount", values.adjustment);
    formData.append("guest", values.guest);

    postEditPeakMutation.mutate(formData);
  };

  return {
    register,
    control,
    onSubmit,
    handleSubmit,
    setValue,
    isSuccess,
    getValues,
    isError,
    isLoading,
    error,
    data,
  };
};
