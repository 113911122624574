import { Box } from "@chakra-ui/react";
import React from "react";

export const NoDataMsg = ({ msg, mt, ml, pos }) => {
  return (
    <Box mt={mt} ml={ml} pos={pos}>
      {msg}
    </Box>
  );
};
