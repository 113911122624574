import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Grid,
    GridItem,
    Center,
    Switch,
    HStack,
    Text,
  } from "@chakra-ui/react";
  import { CancelButton } from "components/Common/CancelButton";
  import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
  import LeftSideLabelTextArea from "components/Common/LeftSideLabelTextArea";
  import { SaveButton } from "components/Common/SaveButton";
  import React, { useEffect } from "react";
  import { IoCloudUploadOutline } from "react-icons/io5";
  import { useAddTable } from "./useAddTable";
  
  const AddTable = ({ isOpen, onClose, refetch,floor,floorname }) => {
    const {
      handleSubmit,
      register,
      control,
      reset,
      toast,
      onSubmit,
      isSuccess,
      uploadPhotoOnClickHandler,
      uploadPhotoChangeHandler,
      setUploadPhotoFile,
      uploadPhotoRef,
      uploadPhotoFile,
    } = useAddTable();
  
    useEffect(() => {
      if (isSuccess) {
        onClose();
        refetch();
      }
    }, [isSuccess]);
  
    useEffect(() => {
      if (!isOpen) {
        reset();
        setUploadPhotoFile([]);
      }
    }, [isOpen]);
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Table in {floorname}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
            <input
				type={"hidden"}
				{...register("floor")}
				value={floor}
			/>
              <Grid templateColumns={"1fr 1fr"} gap="3rem">
                <GridItem>
                  <LeftSideLabelInput
                    label="Table name"
                    type="text"
                    register={register}
                    registerName={"name"}
                  />
                  <LeftSideLabelInput
                    label="Sort No."
                    type="text"
                    register={register}
                    registerName={"table_number"}
                  />
                  <HStack mt={'1rem'}>
                    <Text fontSize={'sm'}>Make Unavaiable</Text>
                    <Switch {...register("unavailable")} />
                  </HStack>
                  <Flex gap="2rem" mt="3rem" p="1rem">
                    <SaveButton />
                    {/* <CancelButton onClick={onClose} /> */}
                  </Flex>
                </GridItem>
              </Grid>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  
  export default AddTable;
  