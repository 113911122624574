import React, { useRef, useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  useMediaQuery,
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
} from "@chakra-ui/react";
import InventoryList from "../Inventory/List/InventoryList";
import AddInventory from "../Inventory/Add/AddInventory";
import NewAdjustment from "../InventoryAdjustment/NewAdjustment";
import { Profile as SidebarProfile } from "components/Common/Profile";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import Report from "../Report/List/Report";
import AddKitchenInventory from "../Inventory/Add/AddKitchenInventory";
import KitchenInventoryList from "../Inventory/List/KitchInventoryList";
import RetailerReport from "../Report/Retailer/RetailerReport";
import { SalesReport } from "../SalesReport/list/SalesReport";

export default function InventoryMenu() {
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [activePage, setActivePage] = useState("Itemgroups");
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <div>
      <Box>
        <TopSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Box
          display={"flex"}
          gap={"0.5rem"}
          justifyContent={"space-between"}
          background={"white"}
          pt={"4.5rem"}
        >
          <Box >
            <Accordion allowToggle minW={breakPoint[0] ? "10rem" : '13rem'} boxShadow={"md"} minH={"93vh"}>
              <>
                {localStorage.getItem("company_type") === "Restaurant" && (
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        _expanded={{ bg: "buttongreen.400", color: "white" }}
                        onClick={() => setActivePage("kitchen")}
                      //     onClick={() => handleMenu(item?.uuid)}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Kitchen Inventory
                        </Box>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                )}

                {localStorage.getItem("company_type") === "Restaurant" && (
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        _expanded={{ bg: "buttongreen.400", color: "white" }}
                        onClick={() => setActivePage("addkitchen")}
                      //     onClick={() => handleMenu(item?.uuid)}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Add Kitchen Inventory
                        </Box>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                )}

                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Itemgroups")}
                    //     onClick={() => handleMenu(item?.uuid)}
                    >
                      <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                        Sales Inventory
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("AddItemgroups")}
                    >
                      <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                        Add Sales Inventory
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Adjustment")}
                    >
                      <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                        Adjustment
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>

              </>
            </Accordion>
          </Box>
          {/* DRAWER */}


          {/* DRAWER END */}
          <Box width={"100%"}>
            {activePage === "addkitchen" && (
              <AddKitchenInventory
                activePage={activePage}
                setActivePage={setActivePage}
              />
            )}

            {activePage === "kitchen" && <KitchenInventoryList />}
            {activePage === "Itemgroups" && (
              <InventoryList
                handleSearch={handleSearch}
                searchQuery={searchQuery}
              />
            )}
            {activePage === "AddItemgroups" && <AddInventory />}
            {activePage === "Adjustment" && <NewAdjustment />}

          </Box>
        </Box>


      </Box>
    </div>
  );
}
