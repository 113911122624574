import axios from "axios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useNewEmailApi = () => {

  async function postNewEmail(values) {
    const res = await axios.post(
      `${BASE_URL}/api/send-otp/`,
      values
    );
    return res;
  }

  const postNewEmailMutation = useMutation(postNewEmail);

  return {
    postNewEmailMutation,
  };
};
