import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import Categories from "./Categories/list/Categories";
import Brands from "./Brands/list/Brands";
import Manufacturer from "./Manufacturer/list/Manufacturer";
import Floor from "./Floor/list/Floor";
import Changepassword from "./Changepassword/Changepassword";
import Employee from "./Employee/Employee";

export default function Settings() {
  const [activePage, setActivePage] = useState("Category");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [settingDrop, setSettingDrop] = useState(true);
  return (
    <Box>
      <TopSearch
        activePage={activePage}
        setActivePage={setActivePage}
        add={activePage === "Security" ? false : true}
        onOpen={onOpen}
        settingDrop={settingDrop}
      />
      <Box
        display={"flex"}
        gap={"0.5rem"}
        justifyContent={"space-between"}
        background={"white"}
        pt={"4.5rem"}
      >
        <Box display={breakPoint[0] ? "none" : "block"}>
          <Accordion allowToggle minW={"13rem"} boxShadow="md" minH={"93vh"}>
            <>
              {localStorage.getItem("employee_type") !== "Employee" && (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Category")}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Group Category
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              )}

              {localStorage.getItem("company_type") === "Retailer" && (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Brand")}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Brand
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              )}
              {localStorage.getItem("company_type") === "Retailer" && (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Manufacturer")}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Manufacturer
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              )}

              <AccordionItem>
                <h2>
                  {localStorage.getItem("company_type") === "Restaurant" &&
                    localStorage.getItem("employee_type") !== "Employee" && (
                      <AccordionButton
                        _expanded={{ bg: "buttongreen.400", color: "white" }}
                        onClick={() => setActivePage("Floor")}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Floor & Tables
                        </Box>
                      </AccordionButton>
                    )}
                </h2>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "buttongreen.400", color: "white" }}
                    onClick={() => setActivePage("Security")}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Security
                    </Box>
                  </AccordionButton>
                </h2>
              </AccordionItem>
              {localStorage.getItem("employee_type") !== "Employee" && (
                <AccordionItem>
                  <h2>
                    <AccordionButton
                      _expanded={{ bg: "buttongreen.400", color: "white" }}
                      onClick={() => setActivePage("Employee")}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Employee
                      </Box>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              )}
            </>
          </Accordion>
        </Box>

        <Box width={"100%"}>
          {activePage === "Category" && (
            <Categories isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
          {activePage === "Brand" && (
            <Brands isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
          {activePage === "Manufacturer" && (
            <Manufacturer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
          {activePage === "Floor" && (
            <Floor isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
          {activePage === "Security" && <Changepassword />}
          {activePage === "Employee" && (
            <Employee isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
