import { Box, Center, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import React from "react";
import { IoCloudUploadOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FiEdit2 } from "react-icons/fi";

const MultipleImage = ({
	file,
	handleUpload,
	handleClick,
	hiddenFileInput,
}) => {
	return (
		<>
			<input
				type="file"
				multiple
				hidden
				ref={hiddenFileInput}
				onChange={handleUpload}
			/>
			<Grid templateColumns={"repeat(6,1fr)"} cursor="pointer" gap={4}>
				<GridItem colSpan={{ base: 6, md: 6, sm: 4 }} onClick={handleClick}>
					<Center
						w="12rem"
						h="8rem"
						borderTop={"1px dashed #808080"}
						borderLeft={"1px dashed #808080"}
						borderRight={"1px dashed #808080"}
						borderTopRadius="8px"
					>
						<IoCloudUploadOutline fontSize={"3rem"} color="lightgrey" />
					</Center>
					<Center
						w="12rem"
						h="2rem"
						border={"1px solid #808080"}
						borderBottomRadius="8px"
					>
						Upload Image
					</Center>
				</GridItem>
				{file
					? file.map((item, index) => (
						<GridItem key={index} colSpan={{ base: 1, md: 1, sm: 1 }}>
							<Image
								src={URL.createObjectURL(item)}
								objectFit={"cover"}
								height={"4rem"}
								width={"4rem"}
								borderRadius={"5px"}
								border={"1px solid orange"}
								onClick={handleClick}
							/>
						</GridItem>
					))
					: ""}
			</Grid>
		</>
	);
};

export default MultipleImage;
