import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { ImSad } from "react-icons/im";
import { globalStyles } from "theme/styles";

export const NoResultUI = (
	{
		position,
		iconSize,
		textSize,
		left,
		top,
		gap,
		mt,
		message,
		minW
	}
) => {
	return (
		<>
			<Flex
				position={position ?? 'absolute'}
				left={left ?? "40%"}
				top={top ?? "25%"}
				direction='column'
				justify='center'
				align='center'
				gap={gap ?? '1.5rem'}
				minW={minW ?? '13rem'}
				color={"#3ab689"}
				mt={mt}
				mb='1rem'
			>
				<ImSad size={iconSize ?? '5.5rem'} />
				<Text
					fontSize={textSize ?? '1.5rem'}
					fontWeight='bold'
				>
					{message ?? 'No result found'}
				</Text>
			</Flex>

		</>
	);
};
