/*!

=========================================================
* Argon Dashboard Chakra PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com/)

* Designed and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    gray: {
      400: "#B0BBCA",
      500: "#A7A7A7",
      700: "#1f2733",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      600: "#24388a",
      700: "#1b254b",
      800: "#111c44",
      900: "#0b1437",
    },
    orange: {
      300: "#f9c4a7",
      400: "#f4b289",
      500: "#3AB689",
      600: "#C05621",
    },
    red: {
      400: "#ff8077",
      500: "#F44336",
    },
    buttongreen: {
      400: "#3AB689",
      500: "#1f9e70",
    },
    buttonred: {
      400: "#EC5A5A",
      500: "#d93f3f",
    },
    chatBox: "#F5F7FB",
    facebook: "#3b5998",
    whatsapp: "#4fce5d",
    activeStatus: "#34A284",
    lightDark: "#F1F1F1",
    mediumDark: "#C9CCD1",
    lightDarkBackground: "#F8F8F8",
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        // bg: mode("gray.50", "#1B254B")(props),
        fontFamily: "Helvetica, sans-serif",
        // fontFamily: "SF Pro",
        background: "#FFFF",
      },
      html: {
        fontFamily: "Helvetica, sans-serif",
      },
    }),
  },
  customStyle: {
    // common

    // tab design css
    tab: {
      minWidth: "8rem",
      color: "gray",
    },
    textColor: {
      color: "#000000",
      fontFamily: "SF Pro",
    },
    tabHover: {
      bg: "#EAEAEA",
      color: "black",
    },
    selectedTab: {
      color: "#3AB689!important",
      borderBottom: "2px solid #3AB689",
      outline: "none",
    },
    unStyledTab: {
      color: "white",
      bg: "#3AB689",
    },
    fontColor: {
      color: "#3AB689",
    },
    grayFont: {
      color: "#A1A7AD",
    },
    smallFont: {
      fontSize: "10px",
    },
    mediumFont: {
      fontSize: "20px",
    },
    redFont: {
      color: "#F04444",
    },
    greenFont: {
      color: "#3EA391",
    },
    borderColor: {
      borderColor: "#E7E7F1",
    },

    // profile design css
    bigProfileImage: {
      width: "10rem",
      height: "10rem",
      objectFit: "cover",
      borderRadius: "5rem",
    },

    // Edit Button Styles
    editButton: {
      color: "white",
      borderRadius: "5rem",
      background: "#3AB689",
    },

    // default button style
    defaultBtnStyle: {
      background: "#3AB689",
      color: "white",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      minWidth: "6rem",
      alignSelf: "end",
      height: "2rem",
    },
    // nav with search and add button
    customSearchNav: {
      position: "absolute",
      top: "0rem",
      right: "6rem",
    },
    customSearchNav_sec1: {
      borderLeft: "2px solid #E7E7F1",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      height: "4.5rem",
      paddingTop: "1rem",
    },
    customSearchNav_sec2: {
      borderLeft: "2px solid #E7E7F1",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      height: "4.5rem",
      paddingTop: "1.5rem",
      cursor: "pointer",
    },
    customSearchNav_sec3: {
      borderRight: "2px solid #E7E7F1",
      borderLeft: "2px solid #E7E7F1",
      paddingTop: "1.5rem",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      height: "4.5rem",
      cursor: "pointer",
    },

    // table
    tableHeading: {
      fontWeight: "bold",
      fontSize: "0.8rem",
    },
  },
};
