import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Text,
  VStack,
  others,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { PageTitle } from "components/Common/PageTitle";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SaveButton } from "components/Common/SaveButton";

import React, { useEffect, useState } from "react";
import { useEditkitchenDetails } from "./useEditKitchenDetails";
import WasteTable from "./WasteTable";
import { useGetUnit } from "views/Pages/Settings/Categories/api/useGetUnit";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { useParams, useLocation } from "react-router-dom";

import { BASE_URL } from "constants/BASE_URL";
import { authorizationAxios } from "axios/authorizationAxios";
import { PageText } from "components/Common/PageText";
import { usegetKitchenApi } from "../../api/usegetKitchenApi";
import { useAddWasteApi } from "../../api/useAddWasteApi";
import { useAddWaste } from "../../api/useAddWaste";
import { CancelButton } from "components/Common/CancelButton";
import AddWaste from "./AddWaste";
import ReceipeTable from "./ReceipeTable";
import AuditTable from "./AuditTable";

export default function KitchenDetail() {
  const {
    handleSubmit,
    register,
    control,
    onSubmit,
    setValue,
  } = useEditkitchenDetails();
  const {
    handleSubmit: handleWasteSubmit,
    onSubmit: onWasteSubmit,
  } = useAddWaste();
  const location = useLocation();
  const param = useParams();
  const { data: getUnit } = useGetUnit();
  const { data, refetch: refetchItemDetail } = usegetKitchenApi(param.uuid);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  useEffect(() => {
    setValue("name", data?.name);
    setValue("unit", data?.unit);
  }, [data]);

  return (
    <Box h="100vh" bg="white">
      <AddWaste isOpen={isOpen} onClose={onClose} uuid={param.uuid} />

      <Box display={"flex"} alignItems={'baseline'} gap={"4rem"} maxWidth={"100%"}>
        <Box flex="1" >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ marginLeft: "2rem" }}
          >

            <PageTitle title="Edit Item" pl="0rem" p="0rem" pt="3rem" />
            <input {...register("uuid")} type={"hidden"} value={param.uuid} />
            <Box>
              <LeftSideLabelInput
                labelW="3rem"

                label=" Name"
                defaultValue={data?.name}
                register={register}
                registerName="name"
              />
            </Box>

            <LeftSideLabelSelect
              register={register}
              control={control}
              registerName="unit"
              label="Unit"
              labelW="3rem"
              data={getUnit?.map((item) => ({
                label: item?.name,
                value: item?.uuid,
              }))}
              defaultValue={data?.unit}
            />

            <Box mt="2rem">
              <SaveButton />
            </Box>
          </form>
        </Box>
        <Box flex="1" mr={"2rem"}>
          <Box>
            <Box display={"flex"} alignItems={"baseline"} >
              {" "}
              <PageTitle
                pl="0rem" p="0rem" pt="3rem" pb="0.5rem"
                title="Waste Audits"
              />
              <p
                style={{ color: "#3AB689", cursor: "pointer", marginLeft: "1rem" }}
                onClick={onOpen}
              >
                + Add Waste Item
              </p>
            </Box>

            <WasteTable uuid={param.uuid} />
          </Box>
          <Box display={breakPoint[0] ? "none" : "block"} >
            <Box display={"flex"} alignItems={"baseline"}>
              {" "}
              <PageTitle
                pl="0rem" p="0rem" pt="3rem"
                title="Adjustment Audits"

              />
            </Box>

            <AuditTable uuid={param.uuid} />
          </Box>
        </Box>

      </Box>
      <Box display={breakPoint[0] ? "block" : "none"} p={"0 0.5rem"}>
        <Box display={"flex"} alignItems={"baseline"}>
          {" "}
          <PageTitle
            style={{ marginLeft: "1rem" }}
            pl="0rem" p="0rem" pt="3rem" pb="0.5rem"
            title="Adjustment Audits"


          />
        </Box>

        <AuditTable uuid={param.uuid} />
      </Box>
    </Box>
  );
}
