import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetIndividualMenu = (uuid, searchText, next) => {
  async function getIndividualMenu() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/category-item-detail/?uuid=${uuid}&search=${searchText}&page_number=${next}`
    );

    return res.data;
  }

  // const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
  //   "getIndividualMenu",
  //   getIndividualMenu
  // );
  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getIndividualMenu", uuid, searchText],
    () => getIndividualMenu(uuid, searchText)
  );
  return {
    data,
    refetch,
    isLoading,
  };
};
