import {
  Box,
  Button,
  Image,
  Text,
  useDisclosure,
  useToast,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import Delete from "../../../../../assets/icons/remove.png";
import { useDeleteCloth } from "../api/useDeleteCloth";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import CancelModal from "components/Common/CancelModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNotify } from "common hooks/useNotify";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCrossCircled } from "react-icons/rx";

export default function OrderRetailItem({ orderData, invId, invoiceNumber }) {
  const [remainingData, setRemainingData] = useState([]);
  const [invoiceUid, setInvoiceUid] = useState("");
  const [listItem, setListItem] = useState("");
  const fetchData = async () => {
    if (invId) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/retailer/order/?invoice_uuid=${invId}`
      );
      setInvoiceUid(response?.data[0]?.uuid);

      setRemainingData(response?.data[0]?.invoice_item);
      setListItem(response?.data[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [invId]);
  return (
    <Box borderRight="1px solid #ECEDED" height={"100%"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #ECEDED"}
        p={"1rem"}
      >
        <Text color={"#000000"} fontSize={"23px"} fontWeight={"600"}>
          ORDER #:{invoiceNumber}
        </Text>
        <Box display={"flex"} gap={"3rem"}>
          <Text
            display={"flex"}
            alignItems={"center"}
            gap={"3px"}
            color={"#2C3832"}
            fontSize={"13px"}
            fontWeight={"500"}
          ></Text>
        </Box>
      </Box>
      <ItemTable
        orderData={orderData}
        remainingData={remainingData}
        listItem={listItem}
        invId={invId}
        invoiceUid={invoiceUid}
        // setRemainingData={setRemainingData}
        // invoiceUid={invoiceUid}
      />
    </Box>
  );
}
function ItemTable({
  orderData,
  remainingData,
  setRemainingData,
  invId,
  invoiceUid,
  listItem,
}) {
  const { deleteClothMutation } = useDeleteCloth();
  const { isLoading, isError, error, isSuccess, data } = deleteClothMutation;
  const { successNotify } = useNotify();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const handleDelete = (val) => {
    deleteClothMutation.mutate(val?.uuid);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Item deleted successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      location.reload();
      setRemainingData(data?.data?.data);
    }

    if (isError) {
      toast({
        title: error?.response?.data.message
          ? error?.response?.data.message
          : error.message,
        position: "top-right",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    orderData,
    remainingData,
    deleteClothMutation.isSuccess,
    deleteClothMutation.isLoading,
    deleteClothMutation.isError,
  ]);
  const cancelOrder = async () => {
    const response = await authorizationAxios.post(
      `${BASE_URL}/api/retailer/order/cancel_order/?uuid=${invoiceUid}`
    );
    console.log(response);
    if (response?.status === 200) {
      successNotify("Order canceled successfully");
      history.push("/admin/retail-item");
    }
  };
  console.log("Xx", remainingData);
  return (
    <Box
      p={"1.5rem"}
      height={"100%"}
      width={"100%"}
      display={"flex"}
      flexFlow={"column"}
      justifyContent={"space-between"}
    >
      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      />
      <TableContainer height={"100%"} width={"100%"} border="1px solid #ECEDED">
        <Table variant="simple" bg="#FAFAFA">
          <Thead bg={"#EAF0F0"}>
            <Tr>
              <Th>Item</Th>
              <Th>Price</Th>
              <Th>Qty</Th>
              <Th>SUBTOTAL</Th>
              <Th>Complimentry</Th>
            </Tr>
          </Thead>
          <Tbody>
            {remainingData?.map((item, index) => {
              return (
                <Tr>
                  <Td>{item?.name}</Td>
                  <Td>
                    {listItem?.currency}
                    {item?.billAmount}
                  </Td>
                  <Td>{item?.quantity}</Td>

                  <Td display={"flex"} alignItems={"center"} gap={"5px"}>
                    Rs.
                    {item?.billAmount}{" "}
                  </Td>
                  <Td>
                    {item?.complimentry ? (
                      <Flex alignItem="center" gap="7.5rem">
                        <AiOutlineCheckCircle color="green" size="1.2rem" />
                        <Image
                          cursor={"pointer"}
                          height={"15px"}
                          src={Delete}
                          onClick={() => handleDelete(item)}
                        />
                      </Flex>
                    ) : (
                      <Flex alignItem="center" gap="7.5rem">
                        <RxCrossCircled color="red" size="1.2rem" />
                        <Image
                          cursor={"pointer"}
                          height={"15px"}
                          src={Delete}
                          onClick={() => handleDelete(item)}
                        />{" "}
                      </Flex>
                    )}
                  </Td>
                  {/* <Td>
                    {item?.complimentry ? (
                      <AiOutlineCheckCircle color="green" size="1.2rem" />
                    ) : (
                      <RxCrossCircled color="red" size="1.2rem" />
                    )}
                  </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Box mt={"2rem"} px={"2rem"}>
        <Button
          _hover={{ backgroundColor: "buttonred.500", color: "black" }}
          width={"100%"}
          height={"70px"}
          bg="buttonred.400"
          variant="secondary"
          onClick={onOpen}
        >
          CANCEL ORDER
        </Button>
      </Box>
    </Box>
  );
}
