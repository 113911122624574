import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetUnpaidSalesReportApi = (next) => {
  async function getUnpaidSales() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/restaurant/unpaidsales/report/?page_number=${next}`
    );
    return res.data;
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getUnpaidSales", next],
    () => getUnpaidSales(next)
  );

  return {
    data,
    refetch,
    isLoading,
  };
};
