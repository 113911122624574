import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetKitchenReportApi = (item, startdate, enddate, next) => {
  async function getKitchenReport() {
    if (item || startdate || enddate) {
      const res = await authorizationAxios.get(
        `${BASE_URL}/api/restaurant/order/adjustment/report/?item=${
          item ?? ""
        }&start_date=${startdate}&end_date=${enddate}`
      );
      return res.data;
    }
  }

  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    "getKitchenReport",
    getKitchenReport
  );

  return {
    data,
    refetch,
    isLoading,
    error,
    isSuccess,
    isError,
  };
};
