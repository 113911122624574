import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const usePostBrandApi = () => {
  async function postPostBrand(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/brands/`,
      values
    );
    return res;
  }

  const postPostBrandMutation = useMutation(postPostBrand);

  return {
    postPostBrandMutation,
  };
};
