import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

import { useMutation } from "react-query";

export const useAddGalleryCategoryApi = () => {

  async function postAddGalleryCategory(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/super-user/gallery-category/`,
      values
    );
    return res;
  }

  const postAddGalleryCategoryMutation = useMutation(postAddGalleryCategory);

  return {
    postAddGalleryCategoryMutation,
  };
};
