import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import food from "../../../assets/img/hamburger 1.png";

export default function FoodMenu({
  responseData,
  handleScrollClick,
  scrollRef,
}) {
  return (
    <Box mt="2rem" className="responsive-title">
      <Text className="food-title">Food Menu</Text>

      <Box className="food-box">
        {responseData?.map((item, index) => {
          console.log(item);
          return (
            <Box id={item?.uuid} onClick={() => handleScrollClick(item?.uuid)}>
              <Box className="food-container">
                <div>
                  <Image height={"30px"} width={"30px"} src={food} />
                  <Text
                    className="responsive-text"
                    fontSize={"12px"}
                    fontWeight={"600"}
                    textAlign={"center"}
                  >
                    {item?.name}
                  </Text>
                </div>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
