import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import TopSearch from "components/Navbars/TopSearchBar/TopSearch";
import RetailerReport from "../Report/Retailer/RetailerReport";
import InvoiceReport from "../Report/Retailer/InvoiceReport";
import UnpaidSalesReport from "../Report/Retailer/UnpaidSalesReport";

// import Categories from "./Categories/list/Categories";
// import Brands from "./Brands/list/Brands";
// import Manufacturer from "./Manufacturer/list/Manufacturer";
// import Floor from "./Floor/list/Floor";
// import Changepassword from "./Changepassword/Changepassword";

export default function BrandReport() {
  const [activePage, setActivePage] = useState("Brand");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <TopSearch onOpen={onOpen} activePage={activePage} />
      <Box
        display={"flex"}
        gap={"0.5rem"}
        justifyContent={"space-between"}
        background={"white"}
        pt={"4.5rem"}
      >
        <Accordion allowToggle width={"20rem"} boxShadow="md">
          <>
            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "buttongreen.400", color: "white" }}
                  onClick={() => setActivePage("Unpaidsales")}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                    Unpaid Sales
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "buttongreen.400", color: "white" }}
                  onClick={() => setActivePage("Brand")}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                    Brand Report
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton
                  _expanded={{ bg: "buttongreen.400", color: "white" }}
                  onClick={() => setActivePage("Invoice")}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                    Invoice Report
                  </Box>
                </AccordionButton>
              </h2>
            </AccordionItem>
          </>
        </Accordion>
        <Box width={"100%"}>
          {activePage === "Unpaidsales" && <UnpaidSalesReport />}
          {activePage === "Brand" && <RetailerReport />}
          {activePage === "Invoice" && <InvoiceReport />}

          {/* {activePage === "Brand" && (
            <Brands isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
          )}
        */}
        </Box>
      </Box>
    </Box>
  );
}
