import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CardImage from "../../../../assets/img/ImageArchitect1.png";
import { useGetIndividualMenu } from "./api/useGetIndividualMenu";
import { useLocation } from "react-router-dom";
import PaginationButton from "components/Common/PaginationButton";
import { useGetAllMenu } from "./api/useGetAllMenu";
import { NoResultUI } from "components/Common/NoResultUI";

export default function MenuCategory({
  handleItemChange,
  menuUuid,
  menuCategory,
  searchQuery,
  categoryActive,
  subCategoryActive,
  showPopup,
  setShowPopup,
  isMenuModalOpen,
  setIsMenuModalOpen,
  selectedItems,
  allMenu,
  setAllMenu,
}) {
  const [next, setNext] = useState(1);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const { data, refetch } = useGetIndividualMenu(menuUuid, searchQuery, next);
  const { data: categoryData, refetch: refetchData } = useGetAllMenu(
    menuCategory,
    searchQuery,
    next
  );
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [mainMenu, setMainMenu] = useState(categoryData);

  useEffect(() => {
    if (subCategoryActive) {
      setMainMenu([]);
    } else {
      setMainMenu(categoryData);
    }
  }, [subCategoryActive, categoryData]);

  useEffect(() => {
    if (data && subCategoryActive) {
      setSelectedMenu(data);
    }
    if (categoryData && categoryActive) {
      setSelectedMenu(mainMenu);
    }
  }, [data, categoryData, selectedMenu, categoryActive, subCategoryActive]);

  useEffect(() => {
    if (
      breakPoint[0] === false ||
      menuUuid ||
      searchQuery ||
      next ||
      selectedMenu
    ) {
      refetch();
    }
  }, [searchQuery, next, menuUuid]);

  useEffect(() => {
    if (menuCategory || selectedMenu) {
      refetchData();
    }
  }, [menuCategory, searchQuery, next]);

  useEffect(() => {
    if (menuCategory == "" && allMenu && subCategoryActive == false) {
      setSelectedMenu(categoryData);
    }
  }, [menuCategory, allMenu, subCategoryActive]);
  console.log("selectedMenu", selectedMenu);
  return (
    <>
      <Box display={breakPoint[0] ? "none" : "flex"} flexWrap="wrap" px="2rem">
        {data?.data?.length !== 0 ? (
          selectedMenu?.data?.map((item, index) => {
            return (
              <Box
                className="menucategory-container"
                onClick={() => handleItemChange(item)}
                key={index}
                position="relative" /* Added for relative positioning */
              >
                <Image
                  objectFit="cover"
                  src={item?.image}
                  height="120px"
                  width="145px"
                />
                <Box
                  position="absolute"
                  top="0rem" /* Adjust this value to position the stock text vertically */
                  left="0rem" /* Adjust this value to position the stock text horizontally */
                  background="#3ab689"
                  color="white"
                  fontSize="sm"
                  width="2rem"
                  height="2rem"
                  textAlign="center"
                  lineHeight="2rem"
                  borderTopLeftRadius={"0.5rem"}
                  borderBottomRightRadius={"0.5rem"}
                >
                  {item?.is_unlimited === true ? "∞" : item?.available_stock}
                </Box>
                <Box p="1" textAlign="center" borderTop="1px solid lightgray">
                  <Text
                    lineHeight="18px"
                    fontWeight="bold"
                    fontSize="14px"
                    color="#000000"
                  >
                    <div className="twolines">{item?.name}</div>
                  </Text>
                  <Text
                    mt="2px"
                    fontWeight="bold"
                    fontSize="12px"
                    color="#000000"
                  >
                    Rs.{item?.selling_price}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <>
            <NoResultUI />
          </>
        )}
      </Box>

      <Box
        display={breakPoint[0] ? "flex" : "none"}
        flexWrap={"wrap"}
        px={"2rem"}
      >
        {data?.data?.length !== 0 || categoryActive ? (
          categoryData?.data?.map((item, index) => {
            return (
              <Box
                className="menucategory-container"
                position={"relative"}
                onClick={() => handleItemChange(item)}
              >
                <Image
                  objectFit={"cover"}
                  src={item?.image}
                  height={"120px"}
                  width={"145px"}
                />
                <Box
                  position="absolute"
                  top="0rem" /* Adjust this value to position the stock text vertically */
                  left="0rem" /* Adjust this value to position the stock text horizontally */
                  background="#3ab689"
                  color="white"
                  fontSize="sm"
                  width="2rem"
                  height="2rem"
                  textAlign="center"
                  lineHeight="2rem"
                  borderTopLeftRadius={"0.5rem"}
                  borderBottomRightRadius={"0.5rem"}
                >
                  {item?.is_unlimited === true ? "∞" : item?.available_stock}
                </Box>
                <Box
                  p="1"
                  textAlign={"center"}
                  borderTop={"1px solid lightgray"}
                >
                  <Text
                    lineHeight={"18px"}
                    fontWeight={"bold"}
                    fontSize={"14px"}
                    color={"#000000"}
                  >
                    <div className="twolines">{item?.name}</div>
                  </Text>
                  <Text
                    mt={"2px"}
                    fontWeight={"bold"}
                    fontSize={"12px"}
                    color={"#000000"}
                  >
                    Rs.{item?.selling_price}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <NoResultUI left={"0%"} />
        )}
      </Box>

      <Button
        disabled={selectedItems?.length == 0 ? true : false}
        className="button-bottom"
        onClick={() => setIsMenuModalOpen(true)}
      >
        Your order({selectedItems?.length})
      </Button>

      <PaginationButton
        isPrevious={data?.previous}
        isNext={data?.next}
        next={next}
        setNext={setNext}
      />
    </>
  );
}
