import React, { useState, useEffect } from "react";
import { BsPeople } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import TableIcon from "../../../../../assets/icons/table.png";
import AddIcon from "../../../../../assets/icons/add.png";

import {
  Box,
  Button,
  Image,
  Text,
  useMediaQuery,
  useToast,
  Checkbox,
  CheckboxGroup,
  Input,
  Flex,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

export default function RetailerBill({
  selectedItems,
  setSelectedItems,
  submitValues,
  invoiceId,
  selected,
  setSelected,
  checkboxRef,
  handleCheckboxChange,
}) {
  const history = useHistory();
  const toast = useToast();
  const breakPoint = useMediaQuery("(max-width: 1400px)");
  const [sku, setSku] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const handleDelete = (itemId) => {
    const updatedItems = selectedItems?.filter((item) => item.uuid !== itemId);
    setSelectedItems(updatedItems);
    document.getElementById(`dis-${itemId}`).value = 0;
  };
  const handleQuantityChange = (productId, action) => {
    setSelectedItems((prevProducts) =>
      prevProducts.map((product) => {
        if (product.uuid === productId) {
          const newQuantity =
            action === "increase" ? product.qty + 1 : product.qty - 1;
          return { ...product, qty: newQuantity };
        }
        return product;
      })
    );
  };
  const calculateSubtotal = () => {
    const total = selectedItems?.reduce((accumulator, product) => {
      return accumulator + product.qty * product.selling_price;
    }, 0);
    setSubtotal(total);
  };
  useEffect(() => {
    calculateSubtotal();
  }, [selectedItems]);
  const paymentOrder = () => {
    history.push(`/admin/payment/${invoiceId}`);
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = event.clipboardData.getData("Text");
    setSku(pastedText);
  };
  const fetchData = async () => {
    if (sku) {
      const response = await authorizationAxios.get(
        `${BASE_URL}/api/itemsbysku/?sku_upc=${sku}`
      );
      if (response?.data?.data) {
        if (response?.data?.data?.available_stock > 0) {
          setSelectedItems([
            ...selectedItems,
            { ...response?.data?.data, qty: 1 },
          ]);
        } else {
          toast({
            title: "Out of Stock",
            description: "This product is out of stock",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
        document.getElementById("sku").value = "";
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [sku]);
  return (
    <Box
      minW={"25rem"}
      position={"fixed"}
      minH={breakPoint[0] ? "80vh" : "90vh"}
      display={"flex"}
      flexFlow={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px dashed #A9A9A9"}
          paddingBottom={"1rem"}
          width={"100%"}
          p={"1rem"}
        >
          <Box>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"590"}
            >
              ORDER #
            </Text>
          </Box>
          <Box marginRight={"1rem"}>
            <Text
              fontSize={breakPoint[0] ? "16px" : "20px"}
              color={"#2C3832"}
              fontWeight={"600"}
            >
              <Input
                fontSize={"15px"}
                onChange={(e) => setSku(e.target.value)}
                onPaste={handlePaste}
                width={"10rem"}
                type="text"
                textAlign={"right"}
                id="sku"
              />
            </Text>
            {/* <Text
              display={"flex"}
              gap="5px"
              alignItems={"baseline"}
              mt="5px"
              color={"#2C3832"}
              fontSize={breakPoint[0] ? "10px" : "12px"}
              fontWeight={"600"}
            >
              {" "}
              <Image src={TableIcon} width={"10px"} />
              TABLE:
              {bookedItem?.length && (
                <span style={{ color: "#D89F65" }}>
                  {bookedItem[0]?.table_name}
                </span>
              )}
              <span style={{ color: "#D89F65" }}>{tableNumber?.name}</span>
            </Text> */}
          </Box>
        </Box>
        <Table variant="unstyled" size="md" w={"100%"}>
          <Tbody>
            <Tr>
              <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                Items
              </Td>
              <Td
                color={"#828282"}
                fontSize={"12px"}
                fontWeight="600"
                pl={"2.85rem"}
              >
                Qty
              </Td>
              <Td color={"#828282"} fontSize={"12px"} fontWeight="600">
                Amt
              </Td>
            </Tr>

            {selectedItems?.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    paddingRight={"0px"}
                  >
                    <Text
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      width={"11rem"}
                      fontSize={"sm"}
                    >
                      {" "}
                      {item?.name}
                    </Text>
                    <Flex mt="0.5rem" gap="0.5rem">
                      <Text fontSize="12px">Dis %</Text>

                      <Input
                        placeholder="%"
                        defaultValue={0}
                        width={"2rem"}
                        height="14px"
                        fontSize="12px"
                        border={"none"}
                        borderBottom={"1px solid #1D2521"}
                        type="number"
                        padding="0"
                        borderRadius={"none"}
                        id={`dis-${item?.uuid}`}
                      />
                      <Text fontSize="12px"> Complimentry</Text>
                      <Checkbox
                        id={`com-${item?.uuid}`}
                        fontSize="xs"
                      ></Checkbox>
                    </Flex>
                  </Td>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    w={"110px"}
                    paddingRight={"0px"}
                  >
                    <Button
                      size="xs"
                      mr="5px"
                      bg="none"
                      onClick={() =>
                        handleQuantityChange(item?.uuid, "decrease")
                      }
                      disabled={item?.qty <= 1}
                    >
                      -
                    </Button>
                    {item?.qty}
                    <Button
                      size="xs"
                      bg={"none"}
                      onClick={
                        item?.is_unlimited === false &&
                        item?.closing_stock === item?.qty
                          ? () =>
                              toast({
                                title: "Inventory unavailable",
                                position: "top-right",
                                status: "error",
                                duration: 1000,
                                isClosable: true,
                              })
                          : () => handleQuantityChange(item?.uuid, "increase")
                      }
                    >
                      +
                    </Button>
                  </Td>
                  <Td
                    color={"#000000"}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    fontWeight={"600"}
                    display={"flex"}
                    gap="3px"
                  >
                    <Box display={"flex"} height={"38px"} alignItems={"center"}>
                      {item?.selling_price * item?.qty}

                      <AiFillDelete
                        color="#EC5A5A"
                        size={13}
                        cursor={"pointer"}
                        onClick={() => handleDelete(item.uuid)}
                      />
                    </Box>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Box px={"1rem"}>
        <TotalAmount
          breakPoint={breakPoint}
          subtotal={subtotal}
          submitValues={submitValues}
          invoiceId={invoiceId}
          paymentOrder={paymentOrder}
        />
      </Box>
    </Box>
  );
}
function TotalAmount({
  breakPoint,
  subtotal,
  submitValues,
  invoiceId,
  paymentOrder,
}) {
  return (
    <Box
      border={"1px dashed #A9A9A9"}
      borderRadius={"14px 14px 0px 0px"}
      padding={"1rem"}
      bottom={"1rem"}
      right={"1rem"}
      width={"100%"}
      backgroundColor={"#FAFAFA"}
    >
      {/* <CancelModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        cancelOrder={cancelOrder}
        bodyText={"Are you sure you want to cancel order?"}
      /> */}
      {/* TOTAL */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          <Text fontWeight={"600"} fontSize={"13px"}>
            SUBTOTAL
          </Text>
        </Box>
        <Box>
          <Text fontWeight={"600"}>Rs.{subtotal}</Text>
        </Box>
      </Box>
      <Box
        borderTop={"1px dashed #A9A9A9 "}
        paddingBlock={"1rem"}
        display={"flex"}
        mt="2rem"
        gap={breakPoint[0] ? "10rem" : "13rem"}
      >
        <Text fontSize={"20px"} fontWeight={"600"}>
          TOTAL
        </Text>
        <Text fontSize={"20px"} fontWeight={"600"}>
          Rs.{parseFloat(subtotal)}
        </Text>
      </Box>
      {/* BUTTON */}
      <Box display={"flex"} mt="1rem" mb={"1rem"} gap={"2rem"}>
        <Button
          color="#ffff"
          bg="buttongreen.400"
          ml="10px"
          w={"100%"}
          variant="secondary"
          onClick={submitValues}
        >
          <Image src={AddIcon} width={"14px"} mr="8px" />
          Add Order
        </Button>

        {invoiceId && (
          <Button
            height={"40px"}
            _hover={{ backgroundColor: "buttongreen.500", color: "white" }}
            size="sm"
            color="#ffff"
            bg={"buttongreen.400"}
            variant="secondary"
            onClick={paymentOrder}
          >
            MAKE PAYMENT
          </Button>
        )}
      </Box>
    </Box>
  );
}
