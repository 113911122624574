import { Box, Flex, VStack, Image, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useGetProfileApi } from "views/Pages/UserProfile/api/useGetProfileApi";

export const Profile = () => {
  // const { dataGetProfile, isSuccess } = useGetProfileApi();
  const history = useHistory();
  const profile = useSelector((state) => state.profile.profile);
  const breakPointSmall = useMediaQuery("(max-width: 650px)");
  const breakPoint = useMediaQuery("(max-width: 1400px)");

  return (
    <Flex gap="1rem" alignItems={"center"} justifyItems="center">
      <Image
        src={profile?.profile}
        boxSize={"50px"}
        borderRadius="full"
        objectFit="cover"
        marginRight={breakPoint[0] && "0.5rem"}
        border={"1px solid #3AB689"}
        onClick={() => history.push("/admin/profile")}
      />
      {!breakPointSmall[0] && (
        <Box>
          <Box fontSize={"md"}>{profile?.first_name}</Box>
          <Box fontSize={"xs"} color="gray.400">
            {profile?.email}
          </Box>
        </Box>
      )}
    </Flex>
  );
};
