import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery } from "react-query";

export const useGetAllMenu = (uuid, searchText, next) => {
  async function getAllMenu() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/category-item-detail/?category=${uuid}&search=${searchText}&page_number=${next}&page_size=24`
    );

    return res.data;
  }

  // const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
  //   "getAllMenu",
  //   getAllMenu
  // );
  const { data, error, isError, isLoading, refetch, isSuccess } = useQuery(
    ["getAllMenu", uuid],
    () => getAllMenu(uuid)
  );
  return {
    data,
    refetch,
    isLoading,
  };
};
