import React from "react";
import { Flex, Box, Input } from "@chakra-ui/react";

export const LeftSideLabelInput = ({
  label,
  type,
  onChange,
  value,
  disabled,
  register,
  registerName,
  w,
  required,
  defaultValue,
  placeHolder,
  alignment,
  bottomMargin,
  fontWeight,
  labelW,
  gap,

}) => {
  return (
    <>
      {registerName ? (
        <Flex
          display={alignment ? "block" : "flex"}
          gap={gap ?? "1.2rem"}
          alignItems="center"
          mt="1.5rem"
        >
          <Box mb={bottomMargin ? "0.5rem" : ""} w={labelW ?? "8rem"} fontSize={"sm"} >
            {label}
          </Box>
          <Box w={"100%"}>
            <Input
              ml={"6px"}
              fontWeight={fontWeight ?? ""}
              type={type}
              borderColor="#D3D3D3"
              borderRadius={"0.3rem"}
              w={{
                base: w ? w : "15rem",
                md: w ? w : "15rem",
                sm: w ? w : "10rem",
              }}
              size="sm"
              // onChange={(e) => onChange(e.target.value)}
              value={value}
              disabled={disabled}
              placeHolder={placeHolder}
              {...register(registerName, {
                valueAsNumber: type == "number" ? true : false,
              })}
              required={required ? true : false}
              defaultValue={defaultValue}
            />
          </Box>
        </Flex>
      ) : (
        <Flex
          gap="1.2rem"
          alignItems="center"
          mt="1.5rem"
          display={alignment ? "block" : "flex"}
        >
          <Box w={labelW ?? "8rem"} fontSize={"sm"}>
            {label}
          </Box>
          <Box w={"100%"}>
            <Input
              type={type}
              borderColor="#D3D3D3"
              borderRadius={"0.3rem"}
              w={{
                base: w ? w : "15rem",
                md: w ? w : "15rem",
                sm: w ? w : "10rem",
              }}
              size="sm"
              // onChange={(e) => onChange(e.target.value)}
              value={value}
              disabled={disabled}
              placeHolder={placeHolder}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};
