import { useNotify } from "common hooks/useNotify";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useGetProfileApi } from "../api/useGetProfileApi";
import { usePutProfileApi } from "../api/usePutProfileApi";
import { profile } from "../slice/profileSlice";

const useProfileDetails = () => {
  const dataGetProfile = useSelector((state) => state.profile.profile);
  const { refetch } = useGetProfileApi();
  const [uploadPhotoFile, SetUploadPhotoFile] = useState([]);
  const [birthDate, setBirthDate] = useState(dataGetProfile?.dob);
  // const [jobStartDate, setJobStartDate] = useState(
  //   dataGetProfile?.experience[0]?.start_date
  // );
  const [jobStartDate, setJobStartDate] = useState("");

  const { reset, register, handleSubmit, control, setValue } = useForm();
  const { putProfileMutation } = usePutProfileApi();
  const { successNotify, errorNotify } = useNotify();
  const { isSuccess, isError } = putProfileMutation;
  const dispatch = useDispatch();
  console.log("err", putProfileMutation?.message);
  useEffect(() => {
    if (isSuccess) {
      refetch();
      successNotify(putProfileMutation.data.data.message);
      // errorNotify();
      // dispatch(profile(dataGetProfile));
    }
    if (isError) {
      refetch();
      errorNotify(putProfileMutation?.error?.message);
      // errorNotify();
      // dispatch(profile(dataGetProfile));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues.first_name = dataGetProfile?.first_name;
    defaultValues.last_name = dataGetProfile?.last_name;
    defaultValues.gender = dataGetProfile?.gender;
    dataGetProfile?.address?.map((item, index) => {
      if (item?.address_type == "Permanent") {
        defaultValues.permaCountry = item?.country;
        defaultValues.permaCity = item?.city;
        defaultValues.permaState = item?.state;
      }
    });
    defaultValues.contact = dataGetProfile?.contact[0]?.value;

    defaultValues.temCountry = dataGetProfile?.address[1]?.country;
    defaultValues.temCity = dataGetProfile?.address[1]?.city;
    defaultValues.temState = dataGetProfile?.address[1]?.state;
    // defaultValues.start_date = dataGetProfile?.experience[0]?.start_date;
    // defaultValues.job = dataGetProfile?.experience[0]?.job;
    // defaultValues.start_date = dataGetProfile?.experience[0]?.start_date;
    reset({ ...defaultValues });
  }, []);
  const onSubmit = (values) => {
    console.log("data", values);

    const formData = new FormData();
    // formData.append("uuid", dataGetProfile?.uuid);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("gender", values.gender);
    formData.append("dob", values.dob);

    formData.append(
      "profile",
      uploadPhotoFile.length !== 0
        ? uploadPhotoFile[0]
        : dataGetProfile?.profile
    );

    formData.append("country", values.permaCountry);
    formData.append("city", values.permaCity);
    formData.append("state", values.permaState);
    formData.append("contact", values.contact);
    formData.append("dob", moment(values?.dob).format("YYYY-MM-DD"));
    putProfileMutation.mutate(formData);

    // formData.append(
    //   "contact",
    //   JSON.stringify([
    //     {
    //       uuid: dataGetProfile?.contact[0]?.uuid,
    //       value: values.contact,
    //       is_primary: 1,
    //       contact_type: dataGetProfile?.contact[0]?.contact_type.uuid,
    //     },
    //   ])
    // );
    // formData.append(
    //   "address",
    //   JSON.stringify([
    //     {
    //       uuid: dataGetProfile?.address[0]?.uuid,
    //       country: values.permaCountry,
    //       city: values.permaCity,
    //       state: values.permaState,
    //       address_type: "Permanent",
    //     },
    //     {
    //       uuid: dataGetProfile?.address[1]?.uuid,
    //       country: values.temCountry,
    //       city: values.temCity,
    //       state: values.temState,
    //       address_type: "Temporary",
    //     },
    //   ])
    // );
    // formData.append(
    //   "experience",
    //   JSON.stringify([
    //     {
    //       uuid: dataGetProfile?.experience[0]?.uuid,
    //       job: values.job,
    //       start_date: moment(jobStartDate).format("YYYY-MM-DD"),
    //     },
    //   ])
    // );
  };

  const uploadPhotoRef = useRef(null);
  function uploadPhotoOnClickHandler() {
    uploadPhotoRef.current.click();
  }

  function uploadPhotoChangeHandler(event) {
    SetUploadPhotoFile(Array.from(event.target.files));
  }

  // console.log("uploadPhotoFile", uploadPhotoFile);

  return {
    dataGetProfile,
    register,
    handleSubmit,
    onSubmit,
    uploadPhotoRef,
    uploadPhotoOnClickHandler,
    uploadPhotoChangeHandler,
    uploadPhotoFile,
    setBirthDate,
    birthDate,
    jobStartDate,
    setJobStartDate,
    control,
    setValue,
  };
};

export default useProfileDetails;
